@charset "UTF-8";
/* set the overriding variables */
.container {
  padding-right: 16px;
  padding-left: 16px;
}

/* Importing Bootstrap SCSS file. */
/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xxs: 0;
  --breakpoint-xs: 320px;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1400px;
  --breakpoint-xxxl: 1600px;
  --breakpoint-xxxxl: 1800px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(27, 27, 27, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xxxxl,
.container-xxxl,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm,
.container-xs,
.container-xxs {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.container {
  max-width: 0;
}

@media (min-width: 320px) {
  .container-xs, .container {
    max-width: 320px;
  }
}
@media (min-width: 576px) {
  .container-sm, .container-xs, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container-xs, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container-xs, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container-xs, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container-xs, .container {
    max-width: 1320px;
  }
}
@media (min-width: 1600px) {
  .container-xxxl, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container-xs, .container {
    max-width: 1500px;
  }
}
@media (min-width: 1800px) {
  .container-xxxxl, .container-xxxl, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container-xs, .container {
    max-width: 1640px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xxxxl,
.col-xxxxl-auto, .col-xxxxl-12, .col-xxxxl-11, .col-xxxxl-10, .col-xxxxl-9, .col-xxxxl-8, .col-xxxxl-7, .col-xxxxl-6, .col-xxxxl-5, .col-xxxxl-4, .col-xxxxl-3, .col-xxxxl-2, .col-xxxxl-1, .col-xxxl,
.col-xxxl-auto, .col-xxxl-12, .col-xxxl-11, .col-xxxl-10, .col-xxxl-9, .col-xxxl-8, .col-xxxl-7, .col-xxxl-6, .col-xxxl-5, .col-xxxl-4, .col-xxxl-3, .col-xxxl-2, .col-xxxl-1, .col-xxl,
.col-xxl-auto, .col-xxl-12, .col-xxl-11, .col-xxl-10, .col-xxl-9, .col-xxl-8, .col-xxl-7, .col-xxl-6, .col-xxl-5, .col-xxl-4, .col-xxl-3, .col-xxl-2, .col-xxl-1, .col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col-xs,
.col-xs-auto, .col-xs-12, .col-xs-11, .col-xs-10, .col-xs-9, .col-xs-8, .col-xs-7, .col-xs-6, .col-xs-5, .col-xs-4, .col-xs-3, .col-xs-2, .col-xs-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 320px) {
  .col-xs {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xs-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xs-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xs-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xs-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xs-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xs-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xs-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xs-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xs-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xs-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xs-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xs-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xs-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xs-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xs-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xs-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xs-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xs-first {
    order: -1;
  }
  .order-xs-last {
    order: 13;
  }
  .order-xs-0 {
    order: 0;
  }
  .order-xs-1 {
    order: 1;
  }
  .order-xs-2 {
    order: 2;
  }
  .order-xs-3 {
    order: 3;
  }
  .order-xs-4 {
    order: 4;
  }
  .order-xs-5 {
    order: 5;
  }
  .order-xs-6 {
    order: 6;
  }
  .order-xs-7 {
    order: 7;
  }
  .order-xs-8 {
    order: 8;
  }
  .order-xs-9 {
    order: 9;
  }
  .order-xs-10 {
    order: 10;
  }
  .order-xs-11 {
    order: 11;
  }
  .order-xs-12 {
    order: 12;
  }
  .offset-xs-0 {
    margin-left: 0;
  }
  .offset-xs-1 {
    margin-left: 8.33333333%;
  }
  .offset-xs-2 {
    margin-left: 16.66666667%;
  }
  .offset-xs-3 {
    margin-left: 25%;
  }
  .offset-xs-4 {
    margin-left: 33.33333333%;
  }
  .offset-xs-5 {
    margin-left: 41.66666667%;
  }
  .offset-xs-6 {
    margin-left: 50%;
  }
  .offset-xs-7 {
    margin-left: 58.33333333%;
  }
  .offset-xs-8 {
    margin-left: 66.66666667%;
  }
  .offset-xs-9 {
    margin-left: 75%;
  }
  .offset-xs-10 {
    margin-left: 83.33333333%;
  }
  .offset-xs-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xxl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-last {
    order: 13;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-6 {
    order: 6;
  }
  .order-xxl-7 {
    order: 7;
  }
  .order-xxl-8 {
    order: 8;
  }
  .order-xxl-9 {
    order: 9;
  }
  .order-xxl-10 {
    order: 10;
  }
  .order-xxl-11 {
    order: 11;
  }
  .order-xxl-12 {
    order: 12;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1600px) {
  .col-xxxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xxxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xxxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xxxl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xxxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xxxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xxxl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xxxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xxxl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xxxl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xxxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxxl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xxxl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xxxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxxl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xxxl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xxxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxxl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xxxl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xxxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxxl-first {
    order: -1;
  }
  .order-xxxl-last {
    order: 13;
  }
  .order-xxxl-0 {
    order: 0;
  }
  .order-xxxl-1 {
    order: 1;
  }
  .order-xxxl-2 {
    order: 2;
  }
  .order-xxxl-3 {
    order: 3;
  }
  .order-xxxl-4 {
    order: 4;
  }
  .order-xxxl-5 {
    order: 5;
  }
  .order-xxxl-6 {
    order: 6;
  }
  .order-xxxl-7 {
    order: 7;
  }
  .order-xxxl-8 {
    order: 8;
  }
  .order-xxxl-9 {
    order: 9;
  }
  .order-xxxl-10 {
    order: 10;
  }
  .order-xxxl-11 {
    order: 11;
  }
  .order-xxxl-12 {
    order: 12;
  }
  .offset-xxxl-0 {
    margin-left: 0;
  }
  .offset-xxxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxxl-3 {
    margin-left: 25%;
  }
  .offset-xxxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxxl-6 {
    margin-left: 50%;
  }
  .offset-xxxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxxl-9 {
    margin-left: 75%;
  }
  .offset-xxxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxxl-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1800px) {
  .col-xxxxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xxxxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xxxxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xxxxl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xxxxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xxxxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xxxxl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xxxxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xxxxl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xxxxl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xxxxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxxxl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xxxxl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xxxxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxxxl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xxxxl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xxxxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxxxl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xxxxl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xxxxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxxxl-first {
    order: -1;
  }
  .order-xxxxl-last {
    order: 13;
  }
  .order-xxxxl-0 {
    order: 0;
  }
  .order-xxxxl-1 {
    order: 1;
  }
  .order-xxxxl-2 {
    order: 2;
  }
  .order-xxxxl-3 {
    order: 3;
  }
  .order-xxxxl-4 {
    order: 4;
  }
  .order-xxxxl-5 {
    order: 5;
  }
  .order-xxxxl-6 {
    order: 6;
  }
  .order-xxxxl-7 {
    order: 7;
  }
  .order-xxxxl-8 {
    order: 8;
  }
  .order-xxxxl-9 {
    order: 9;
  }
  .order-xxxxl-10 {
    order: 10;
  }
  .order-xxxxl-11 {
    order: 11;
  }
  .order-xxxxl-12 {
    order: 12;
  }
  .offset-xxxxl-0 {
    margin-left: 0;
  }
  .offset-xxxxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxxxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxxxl-3 {
    margin-left: 25%;
  }
  .offset-xxxxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxxxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxxxl-6 {
    margin-left: 50%;
  }
  .offset-xxxxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxxxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxxxl-9 {
    margin-left: 75%;
  }
  .offset-xxxxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxxxl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff;
}

.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 319.98px) {
  .table-responsive-xs {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xs > .table-bordered {
    border: 0;
  }
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xxl > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1599.98px) {
  .table-responsive-xxxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xxxl > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1799.98px) {
  .table-responsive-xxxxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xxxxl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 320px) {
  .dropdown-menu-xs-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xs-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1600px) {
  .dropdown-menu-xxxl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxxl-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1800px) {
  .dropdown-menu-xxxxl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxxxl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-xxs,
.navbar .container-xs,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl,
.navbar .container-xxl,
.navbar .container-xxxl,
.navbar .container-xxxxl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 319.98px) {
  .navbar-expand-xs > .container,
  .navbar-expand-xs > .container-fluid,
  .navbar-expand-xs > .container-xxs,
  .navbar-expand-xs > .container-xs,
  .navbar-expand-xs > .container-sm,
  .navbar-expand-xs > .container-md,
  .navbar-expand-xs > .container-lg,
  .navbar-expand-xs > .container-xl,
  .navbar-expand-xs > .container-xxl,
  .navbar-expand-xs > .container-xxxl,
  .navbar-expand-xs > .container-xxxxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 320px) {
  .navbar-expand-xs {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xs .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xs .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xs .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xs > .container,
  .navbar-expand-xs > .container-fluid,
  .navbar-expand-xs > .container-xxs,
  .navbar-expand-xs > .container-xs,
  .navbar-expand-xs > .container-sm,
  .navbar-expand-xs > .container-md,
  .navbar-expand-xs > .container-lg,
  .navbar-expand-xs > .container-xl,
  .navbar-expand-xs > .container-xxl,
  .navbar-expand-xs > .container-xxxl,
  .navbar-expand-xs > .container-xxxxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xs .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xs .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xs .navbar-toggler {
    display: none;
  }
}
@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-xxs,
  .navbar-expand-sm > .container-xs,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl,
  .navbar-expand-sm > .container-xxl,
  .navbar-expand-sm > .container-xxxl,
  .navbar-expand-sm > .container-xxxxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-xxs,
  .navbar-expand-sm > .container-xs,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl,
  .navbar-expand-sm > .container-xxl,
  .navbar-expand-sm > .container-xxxl,
  .navbar-expand-sm > .container-xxxxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-xxs,
  .navbar-expand-md > .container-xs,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl,
  .navbar-expand-md > .container-xxl,
  .navbar-expand-md > .container-xxxl,
  .navbar-expand-md > .container-xxxxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-xxs,
  .navbar-expand-md > .container-xs,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl,
  .navbar-expand-md > .container-xxl,
  .navbar-expand-md > .container-xxxl,
  .navbar-expand-md > .container-xxxxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-xxs,
  .navbar-expand-lg > .container-xs,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl,
  .navbar-expand-lg > .container-xxl,
  .navbar-expand-lg > .container-xxxl,
  .navbar-expand-lg > .container-xxxxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-xxs,
  .navbar-expand-lg > .container-xs,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl,
  .navbar-expand-lg > .container-xxl,
  .navbar-expand-lg > .container-xxxl,
  .navbar-expand-lg > .container-xxxxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-xxs,
  .navbar-expand-xl > .container-xs,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl,
  .navbar-expand-xl > .container-xxl,
  .navbar-expand-xl > .container-xxxl,
  .navbar-expand-xl > .container-xxxxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-xxs,
  .navbar-expand-xl > .container-xs,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl,
  .navbar-expand-xl > .container-xxl,
  .navbar-expand-xl > .container-xxxl,
  .navbar-expand-xl > .container-xxxxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1399.98px) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid,
  .navbar-expand-xxl > .container-xxs,
  .navbar-expand-xxl > .container-xs,
  .navbar-expand-xxl > .container-sm,
  .navbar-expand-xxl > .container-md,
  .navbar-expand-xxl > .container-lg,
  .navbar-expand-xxl > .container-xl,
  .navbar-expand-xxl > .container-xxl,
  .navbar-expand-xxl > .container-xxxl,
  .navbar-expand-xxl > .container-xxxxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid,
  .navbar-expand-xxl > .container-xxs,
  .navbar-expand-xxl > .container-xs,
  .navbar-expand-xxl > .container-sm,
  .navbar-expand-xxl > .container-md,
  .navbar-expand-xxl > .container-lg,
  .navbar-expand-xxl > .container-xl,
  .navbar-expand-xxl > .container-xxl,
  .navbar-expand-xxl > .container-xxxl,
  .navbar-expand-xxl > .container-xxxxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1599.98px) {
  .navbar-expand-xxxl > .container,
  .navbar-expand-xxxl > .container-fluid,
  .navbar-expand-xxxl > .container-xxs,
  .navbar-expand-xxxl > .container-xs,
  .navbar-expand-xxxl > .container-sm,
  .navbar-expand-xxxl > .container-md,
  .navbar-expand-xxxl > .container-lg,
  .navbar-expand-xxxl > .container-xl,
  .navbar-expand-xxxl > .container-xxl,
  .navbar-expand-xxxl > .container-xxxl,
  .navbar-expand-xxxl > .container-xxxxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1600px) {
  .navbar-expand-xxxl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxxl > .container,
  .navbar-expand-xxxl > .container-fluid,
  .navbar-expand-xxxl > .container-xxs,
  .navbar-expand-xxxl > .container-xs,
  .navbar-expand-xxxl > .container-sm,
  .navbar-expand-xxxl > .container-md,
  .navbar-expand-xxxl > .container-lg,
  .navbar-expand-xxxl > .container-xl,
  .navbar-expand-xxxl > .container-xxl,
  .navbar-expand-xxxl > .container-xxxl,
  .navbar-expand-xxxl > .container-xxxxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xxxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxxl .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1799.98px) {
  .navbar-expand-xxxxl > .container,
  .navbar-expand-xxxxl > .container-fluid,
  .navbar-expand-xxxxl > .container-xxs,
  .navbar-expand-xxxxl > .container-xs,
  .navbar-expand-xxxxl > .container-sm,
  .navbar-expand-xxxxl > .container-md,
  .navbar-expand-xxxxl > .container-lg,
  .navbar-expand-xxxxl > .container-xl,
  .navbar-expand-xxxxl > .container-xxl,
  .navbar-expand-xxxxl > .container-xxxl,
  .navbar-expand-xxxxl > .container-xxxxl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1800px) {
  .navbar-expand-xxxxl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxxxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxxxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxxxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxxxl > .container,
  .navbar-expand-xxxxl > .container-fluid,
  .navbar-expand-xxxxl > .container-xxs,
  .navbar-expand-xxxxl > .container-xs,
  .navbar-expand-xxxxl > .container-sm,
  .navbar-expand-xxxxl > .container-md,
  .navbar-expand-xxxxl > .container-lg,
  .navbar-expand-xxxxl > .container-xl,
  .navbar-expand-xxxxl > .container-xxl,
  .navbar-expand-xxxxl > .container-xxxl,
  .navbar-expand-xxxxl > .container-xxxxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xxxxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxxxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxxxl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-xxs,
.navbar-expand > .container-xs,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl,
.navbar-expand > .container-xxl,
.navbar-expand > .container-xxxl,
.navbar-expand > .container-xxxxl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-xxs,
.navbar-expand > .container-xs,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl,
.navbar-expand > .container-xxl,
.navbar-expand > .container-xxxl,
.navbar-expand > .container-xxxxl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #0062cc;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #0d4d92;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #093463;
}

.alert-secondary {
  color: #454a4e;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #2d3033;
}

.alert-success {
  color: #226431;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #153e1e;
}

.alert-info {
  color: #19616d;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #0f3c44;
}

.alert-warning {
  color: #927111;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #644e0c;
}

.alert-danger {
  color: #7f2931;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #581d22;
}

.alert-light {
  color: #8e8e8f;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #747476;
}

.alert-dark {
  color: #282b2e;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #101213;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 320px) {
  .list-group-horizontal-xs {
    flex-direction: row;
  }
  .list-group-horizontal-xs > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xs > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xs > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xs > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xs > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1600px) {
  .list-group-horizontal-xxxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1800px) {
  .list-group-horizontal-xxxxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxxxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxxxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxxxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxxxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxxxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #0d4d92;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #0d4d92;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #0d4d92;
  border-color: #0d4d92;
}

.list-group-item-secondary {
  color: #454a4e;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #454a4e;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #454a4e;
  border-color: #454a4e;
}

.list-group-item-success {
  color: #226431;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #226431;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #226431;
  border-color: #226431;
}

.list-group-item-info {
  color: #19616d;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #19616d;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #19616d;
  border-color: #19616d;
}

.list-group-item-warning {
  color: #927111;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #927111;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #927111;
  border-color: #927111;
}

.list-group-item-danger {
  color: #7f2931;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #7f2931;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #7f2931;
  border-color: #7f2931;
}

.list-group-item-light {
  color: #8e8e8f;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #8e8e8f;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #8e8e8f;
  border-color: #8e8e8f;
}

.list-group-item-dark {
  color: #282b2e;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #282b2e;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #282b2e;
  border-color: #282b2e;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #007bff !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #007bff !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 320px) {
  .d-xs-none {
    display: none !important;
  }
  .d-xs-inline {
    display: inline !important;
  }
  .d-xs-inline-block {
    display: inline-block !important;
  }
  .d-xs-block {
    display: block !important;
  }
  .d-xs-table {
    display: table !important;
  }
  .d-xs-table-row {
    display: table-row !important;
  }
  .d-xs-table-cell {
    display: table-cell !important;
  }
  .d-xs-flex {
    display: flex !important;
  }
  .d-xs-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1400px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1600px) {
  .d-xxxl-none {
    display: none !important;
  }
  .d-xxxl-inline {
    display: inline !important;
  }
  .d-xxxl-inline-block {
    display: inline-block !important;
  }
  .d-xxxl-block {
    display: block !important;
  }
  .d-xxxl-table {
    display: table !important;
  }
  .d-xxxl-table-row {
    display: table-row !important;
  }
  .d-xxxl-table-cell {
    display: table-cell !important;
  }
  .d-xxxl-flex {
    display: flex !important;
  }
  .d-xxxl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1800px) {
  .d-xxxxl-none {
    display: none !important;
  }
  .d-xxxxl-inline {
    display: inline !important;
  }
  .d-xxxxl-inline-block {
    display: inline-block !important;
  }
  .d-xxxxl-block {
    display: block !important;
  }
  .d-xxxxl-table {
    display: table !important;
  }
  .d-xxxxl-table-row {
    display: table-row !important;
  }
  .d-xxxxl-table-cell {
    display: table-cell !important;
  }
  .d-xxxxl-flex {
    display: flex !important;
  }
  .d-xxxxl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 320px) {
  .flex-xs-row {
    flex-direction: row !important;
  }
  .flex-xs-column {
    flex-direction: column !important;
  }
  .flex-xs-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xs-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xs-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xs-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xs-fill {
    flex: 1 1 auto !important;
  }
  .flex-xs-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xs-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xs-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xs-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xs-start {
    justify-content: flex-start !important;
  }
  .justify-content-xs-end {
    justify-content: flex-end !important;
  }
  .justify-content-xs-center {
    justify-content: center !important;
  }
  .justify-content-xs-between {
    justify-content: space-between !important;
  }
  .justify-content-xs-around {
    justify-content: space-around !important;
  }
  .align-items-xs-start {
    align-items: flex-start !important;
  }
  .align-items-xs-end {
    align-items: flex-end !important;
  }
  .align-items-xs-center {
    align-items: center !important;
  }
  .align-items-xs-baseline {
    align-items: baseline !important;
  }
  .align-items-xs-stretch {
    align-items: stretch !important;
  }
  .align-content-xs-start {
    align-content: flex-start !important;
  }
  .align-content-xs-end {
    align-content: flex-end !important;
  }
  .align-content-xs-center {
    align-content: center !important;
  }
  .align-content-xs-between {
    align-content: space-between !important;
  }
  .align-content-xs-around {
    align-content: space-around !important;
  }
  .align-content-xs-stretch {
    align-content: stretch !important;
  }
  .align-self-xs-auto {
    align-self: auto !important;
  }
  .align-self-xs-start {
    align-self: flex-start !important;
  }
  .align-self-xs-end {
    align-self: flex-end !important;
  }
  .align-self-xs-center {
    align-self: center !important;
  }
  .align-self-xs-baseline {
    align-self: baseline !important;
  }
  .align-self-xs-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1400px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1600px) {
  .flex-xxxl-row {
    flex-direction: row !important;
  }
  .flex-xxxl-column {
    flex-direction: column !important;
  }
  .flex-xxxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xxxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxxl-center {
    justify-content: center !important;
  }
  .justify-content-xxxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxxl-around {
    justify-content: space-around !important;
  }
  .align-items-xxxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxxl-center {
    align-items: center !important;
  }
  .align-items-xxxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxxl-center {
    align-content: center !important;
  }
  .align-content-xxxl-between {
    align-content: space-between !important;
  }
  .align-content-xxxl-around {
    align-content: space-around !important;
  }
  .align-content-xxxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxxl-auto {
    align-self: auto !important;
  }
  .align-self-xxxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxxl-center {
    align-self: center !important;
  }
  .align-self-xxxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxxl-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1800px) {
  .flex-xxxxl-row {
    flex-direction: row !important;
  }
  .flex-xxxxl-column {
    flex-direction: column !important;
  }
  .flex-xxxxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxxxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxxxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxxxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxxxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxxxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxxxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxxxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxxxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxxxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xxxxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxxxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxxxl-center {
    justify-content: center !important;
  }
  .justify-content-xxxxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxxxl-around {
    justify-content: space-around !important;
  }
  .align-items-xxxxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxxxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxxxl-center {
    align-items: center !important;
  }
  .align-items-xxxxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxxxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxxxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxxxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxxxl-center {
    align-content: center !important;
  }
  .align-content-xxxxl-between {
    align-content: space-between !important;
  }
  .align-content-xxxxl-around {
    align-content: space-around !important;
  }
  .align-content-xxxxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxxxl-auto {
    align-self: auto !important;
  }
  .align-self-xxxxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxxxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxxxl-center {
    align-self: center !important;
  }
  .align-self-xxxxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxxxl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 320px) {
  .float-xs-left {
    float: left !important;
  }
  .float-xs-right {
    float: right !important;
  }
  .float-xs-none {
    float: none !important;
  }
}
@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-left {
    float: left !important;
  }
  .float-xxl-right {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
}
@media (min-width: 1600px) {
  .float-xxxl-left {
    float: left !important;
  }
  .float-xxxl-right {
    float: right !important;
  }
  .float-xxxl-none {
    float: none !important;
  }
}
@media (min-width: 1800px) {
  .float-xxxxl-left {
    float: left !important;
  }
  .float-xxxxl-right {
    float: right !important;
  }
  .float-xxxxl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 320px) {
  .m-xs-0 {
    margin: 0 !important;
  }
  .mt-xs-0,
  .my-xs-0 {
    margin-top: 0 !important;
  }
  .mr-xs-0,
  .mx-xs-0 {
    margin-right: 0 !important;
  }
  .mb-xs-0,
  .my-xs-0 {
    margin-bottom: 0 !important;
  }
  .ml-xs-0,
  .mx-xs-0 {
    margin-left: 0 !important;
  }
  .m-xs-1 {
    margin: 0.25rem !important;
  }
  .mt-xs-1,
  .my-xs-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xs-1,
  .mx-xs-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xs-1,
  .my-xs-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xs-1,
  .mx-xs-1 {
    margin-left: 0.25rem !important;
  }
  .m-xs-2 {
    margin: 0.5rem !important;
  }
  .mt-xs-2,
  .my-xs-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xs-2,
  .mx-xs-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xs-2,
  .my-xs-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xs-2,
  .mx-xs-2 {
    margin-left: 0.5rem !important;
  }
  .m-xs-3 {
    margin: 1rem !important;
  }
  .mt-xs-3,
  .my-xs-3 {
    margin-top: 1rem !important;
  }
  .mr-xs-3,
  .mx-xs-3 {
    margin-right: 1rem !important;
  }
  .mb-xs-3,
  .my-xs-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xs-3,
  .mx-xs-3 {
    margin-left: 1rem !important;
  }
  .m-xs-4 {
    margin: 1.5rem !important;
  }
  .mt-xs-4,
  .my-xs-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xs-4,
  .mx-xs-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xs-4,
  .my-xs-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xs-4,
  .mx-xs-4 {
    margin-left: 1.5rem !important;
  }
  .m-xs-5 {
    margin: 3rem !important;
  }
  .mt-xs-5,
  .my-xs-5 {
    margin-top: 3rem !important;
  }
  .mr-xs-5,
  .mx-xs-5 {
    margin-right: 3rem !important;
  }
  .mb-xs-5,
  .my-xs-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xs-5,
  .mx-xs-5 {
    margin-left: 3rem !important;
  }
  .p-xs-0 {
    padding: 0 !important;
  }
  .pt-xs-0,
  .py-xs-0 {
    padding-top: 0 !important;
  }
  .pr-xs-0,
  .px-xs-0 {
    padding-right: 0 !important;
  }
  .pb-xs-0,
  .py-xs-0 {
    padding-bottom: 0 !important;
  }
  .pl-xs-0,
  .px-xs-0 {
    padding-left: 0 !important;
  }
  .p-xs-1 {
    padding: 0.25rem !important;
  }
  .pt-xs-1,
  .py-xs-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xs-1,
  .px-xs-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xs-1,
  .py-xs-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xs-1,
  .px-xs-1 {
    padding-left: 0.25rem !important;
  }
  .p-xs-2 {
    padding: 0.5rem !important;
  }
  .pt-xs-2,
  .py-xs-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xs-2,
  .px-xs-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xs-2,
  .py-xs-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xs-2,
  .px-xs-2 {
    padding-left: 0.5rem !important;
  }
  .p-xs-3 {
    padding: 1rem !important;
  }
  .pt-xs-3,
  .py-xs-3 {
    padding-top: 1rem !important;
  }
  .pr-xs-3,
  .px-xs-3 {
    padding-right: 1rem !important;
  }
  .pb-xs-3,
  .py-xs-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xs-3,
  .px-xs-3 {
    padding-left: 1rem !important;
  }
  .p-xs-4 {
    padding: 1.5rem !important;
  }
  .pt-xs-4,
  .py-xs-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xs-4,
  .px-xs-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xs-4,
  .py-xs-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xs-4,
  .px-xs-4 {
    padding-left: 1.5rem !important;
  }
  .p-xs-5 {
    padding: 3rem !important;
  }
  .pt-xs-5,
  .py-xs-5 {
    padding-top: 3rem !important;
  }
  .pr-xs-5,
  .px-xs-5 {
    padding-right: 3rem !important;
  }
  .pb-xs-5,
  .py-xs-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xs-5,
  .px-xs-5 {
    padding-left: 3rem !important;
  }
  .m-xs-n1 {
    margin: -0.25rem !important;
  }
  .mt-xs-n1,
  .my-xs-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xs-n1,
  .mx-xs-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xs-n1,
  .my-xs-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xs-n1,
  .mx-xs-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xs-n2 {
    margin: -0.5rem !important;
  }
  .mt-xs-n2,
  .my-xs-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xs-n2,
  .mx-xs-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xs-n2,
  .my-xs-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xs-n2,
  .mx-xs-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xs-n3 {
    margin: -1rem !important;
  }
  .mt-xs-n3,
  .my-xs-n3 {
    margin-top: -1rem !important;
  }
  .mr-xs-n3,
  .mx-xs-n3 {
    margin-right: -1rem !important;
  }
  .mb-xs-n3,
  .my-xs-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xs-n3,
  .mx-xs-n3 {
    margin-left: -1rem !important;
  }
  .m-xs-n4 {
    margin: -1.5rem !important;
  }
  .mt-xs-n4,
  .my-xs-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xs-n4,
  .mx-xs-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xs-n4,
  .my-xs-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xs-n4,
  .mx-xs-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xs-n5 {
    margin: -3rem !important;
  }
  .mt-xs-n5,
  .my-xs-n5 {
    margin-top: -3rem !important;
  }
  .mr-xs-n5,
  .mx-xs-n5 {
    margin-right: -3rem !important;
  }
  .mb-xs-n5,
  .my-xs-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xs-n5,
  .mx-xs-n5 {
    margin-left: -3rem !important;
  }
  .m-xs-auto {
    margin: auto !important;
  }
  .mt-xs-auto,
  .my-xs-auto {
    margin-top: auto !important;
  }
  .mr-xs-auto,
  .mx-xs-auto {
    margin-right: auto !important;
  }
  .mb-xs-auto,
  .my-xs-auto {
    margin-bottom: auto !important;
  }
  .ml-xs-auto,
  .mx-xs-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1400px) {
  .m-xxl-0 {
    margin: 0 !important;
  }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important;
  }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important;
  }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 3rem !important;
  }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 3rem !important;
  }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 3rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important;
  }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important;
  }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 3rem !important;
  }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 3rem !important;
  }
  .m-xxl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xxl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -3rem !important;
  }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1600px) {
  .m-xxxl-0 {
    margin: 0 !important;
  }
  .mt-xxxl-0,
  .my-xxxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxxl-0,
  .mx-xxxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxxl-0,
  .my-xxxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxxl-0,
  .mx-xxxl-0 {
    margin-left: 0 !important;
  }
  .m-xxxl-1 {
    margin: 0.25rem !important;
  }
  .mt-xxxl-1,
  .my-xxxl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xxxl-1,
  .mx-xxxl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xxxl-1,
  .my-xxxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xxxl-1,
  .mx-xxxl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xxxl-2 {
    margin: 0.5rem !important;
  }
  .mt-xxxl-2,
  .my-xxxl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xxxl-2,
  .mx-xxxl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xxxl-2,
  .my-xxxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xxxl-2,
  .mx-xxxl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xxxl-3 {
    margin: 1rem !important;
  }
  .mt-xxxl-3,
  .my-xxxl-3 {
    margin-top: 1rem !important;
  }
  .mr-xxxl-3,
  .mx-xxxl-3 {
    margin-right: 1rem !important;
  }
  .mb-xxxl-3,
  .my-xxxl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xxxl-3,
  .mx-xxxl-3 {
    margin-left: 1rem !important;
  }
  .m-xxxl-4 {
    margin: 1.5rem !important;
  }
  .mt-xxxl-4,
  .my-xxxl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xxxl-4,
  .mx-xxxl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xxxl-4,
  .my-xxxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xxxl-4,
  .mx-xxxl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xxxl-5 {
    margin: 3rem !important;
  }
  .mt-xxxl-5,
  .my-xxxl-5 {
    margin-top: 3rem !important;
  }
  .mr-xxxl-5,
  .mx-xxxl-5 {
    margin-right: 3rem !important;
  }
  .mb-xxxl-5,
  .my-xxxl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xxxl-5,
  .mx-xxxl-5 {
    margin-left: 3rem !important;
  }
  .p-xxxl-0 {
    padding: 0 !important;
  }
  .pt-xxxl-0,
  .py-xxxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxxl-0,
  .px-xxxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxxl-0,
  .py-xxxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxxl-0,
  .px-xxxl-0 {
    padding-left: 0 !important;
  }
  .p-xxxl-1 {
    padding: 0.25rem !important;
  }
  .pt-xxxl-1,
  .py-xxxl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xxxl-1,
  .px-xxxl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xxxl-1,
  .py-xxxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xxxl-1,
  .px-xxxl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xxxl-2 {
    padding: 0.5rem !important;
  }
  .pt-xxxl-2,
  .py-xxxl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xxxl-2,
  .px-xxxl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xxxl-2,
  .py-xxxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xxxl-2,
  .px-xxxl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xxxl-3 {
    padding: 1rem !important;
  }
  .pt-xxxl-3,
  .py-xxxl-3 {
    padding-top: 1rem !important;
  }
  .pr-xxxl-3,
  .px-xxxl-3 {
    padding-right: 1rem !important;
  }
  .pb-xxxl-3,
  .py-xxxl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xxxl-3,
  .px-xxxl-3 {
    padding-left: 1rem !important;
  }
  .p-xxxl-4 {
    padding: 1.5rem !important;
  }
  .pt-xxxl-4,
  .py-xxxl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xxxl-4,
  .px-xxxl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xxxl-4,
  .py-xxxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xxxl-4,
  .px-xxxl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xxxl-5 {
    padding: 3rem !important;
  }
  .pt-xxxl-5,
  .py-xxxl-5 {
    padding-top: 3rem !important;
  }
  .pr-xxxl-5,
  .px-xxxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxxl-5,
  .py-xxxl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xxxl-5,
  .px-xxxl-5 {
    padding-left: 3rem !important;
  }
  .m-xxxl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xxxl-n1,
  .my-xxxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xxxl-n1,
  .mx-xxxl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xxxl-n1,
  .my-xxxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xxxl-n1,
  .mx-xxxl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xxxl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xxxl-n2,
  .my-xxxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xxxl-n2,
  .mx-xxxl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xxxl-n2,
  .my-xxxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xxxl-n2,
  .mx-xxxl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xxxl-n3 {
    margin: -1rem !important;
  }
  .mt-xxxl-n3,
  .my-xxxl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xxxl-n3,
  .mx-xxxl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xxxl-n3,
  .my-xxxl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xxxl-n3,
  .mx-xxxl-n3 {
    margin-left: -1rem !important;
  }
  .m-xxxl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xxxl-n4,
  .my-xxxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xxxl-n4,
  .mx-xxxl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xxxl-n4,
  .my-xxxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xxxl-n4,
  .mx-xxxl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xxxl-n5 {
    margin: -3rem !important;
  }
  .mt-xxxl-n5,
  .my-xxxl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xxxl-n5,
  .mx-xxxl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xxxl-n5,
  .my-xxxl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xxxl-n5,
  .mx-xxxl-n5 {
    margin-left: -3rem !important;
  }
  .m-xxxl-auto {
    margin: auto !important;
  }
  .mt-xxxl-auto,
  .my-xxxl-auto {
    margin-top: auto !important;
  }
  .mr-xxxl-auto,
  .mx-xxxl-auto {
    margin-right: auto !important;
  }
  .mb-xxxl-auto,
  .my-xxxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxxl-auto,
  .mx-xxxl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1800px) {
  .m-xxxxl-0 {
    margin: 0 !important;
  }
  .mt-xxxxl-0,
  .my-xxxxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxxxl-0,
  .mx-xxxxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxxxl-0,
  .my-xxxxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxxxl-0,
  .mx-xxxxl-0 {
    margin-left: 0 !important;
  }
  .m-xxxxl-1 {
    margin: 0.25rem !important;
  }
  .mt-xxxxl-1,
  .my-xxxxl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xxxxl-1,
  .mx-xxxxl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xxxxl-1,
  .my-xxxxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xxxxl-1,
  .mx-xxxxl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xxxxl-2 {
    margin: 0.5rem !important;
  }
  .mt-xxxxl-2,
  .my-xxxxl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xxxxl-2,
  .mx-xxxxl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xxxxl-2,
  .my-xxxxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xxxxl-2,
  .mx-xxxxl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xxxxl-3 {
    margin: 1rem !important;
  }
  .mt-xxxxl-3,
  .my-xxxxl-3 {
    margin-top: 1rem !important;
  }
  .mr-xxxxl-3,
  .mx-xxxxl-3 {
    margin-right: 1rem !important;
  }
  .mb-xxxxl-3,
  .my-xxxxl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xxxxl-3,
  .mx-xxxxl-3 {
    margin-left: 1rem !important;
  }
  .m-xxxxl-4 {
    margin: 1.5rem !important;
  }
  .mt-xxxxl-4,
  .my-xxxxl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xxxxl-4,
  .mx-xxxxl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xxxxl-4,
  .my-xxxxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xxxxl-4,
  .mx-xxxxl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xxxxl-5 {
    margin: 3rem !important;
  }
  .mt-xxxxl-5,
  .my-xxxxl-5 {
    margin-top: 3rem !important;
  }
  .mr-xxxxl-5,
  .mx-xxxxl-5 {
    margin-right: 3rem !important;
  }
  .mb-xxxxl-5,
  .my-xxxxl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xxxxl-5,
  .mx-xxxxl-5 {
    margin-left: 3rem !important;
  }
  .p-xxxxl-0 {
    padding: 0 !important;
  }
  .pt-xxxxl-0,
  .py-xxxxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxxxl-0,
  .px-xxxxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxxxl-0,
  .py-xxxxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxxxl-0,
  .px-xxxxl-0 {
    padding-left: 0 !important;
  }
  .p-xxxxl-1 {
    padding: 0.25rem !important;
  }
  .pt-xxxxl-1,
  .py-xxxxl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xxxxl-1,
  .px-xxxxl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xxxxl-1,
  .py-xxxxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xxxxl-1,
  .px-xxxxl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xxxxl-2 {
    padding: 0.5rem !important;
  }
  .pt-xxxxl-2,
  .py-xxxxl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xxxxl-2,
  .px-xxxxl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xxxxl-2,
  .py-xxxxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xxxxl-2,
  .px-xxxxl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xxxxl-3 {
    padding: 1rem !important;
  }
  .pt-xxxxl-3,
  .py-xxxxl-3 {
    padding-top: 1rem !important;
  }
  .pr-xxxxl-3,
  .px-xxxxl-3 {
    padding-right: 1rem !important;
  }
  .pb-xxxxl-3,
  .py-xxxxl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xxxxl-3,
  .px-xxxxl-3 {
    padding-left: 1rem !important;
  }
  .p-xxxxl-4 {
    padding: 1.5rem !important;
  }
  .pt-xxxxl-4,
  .py-xxxxl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xxxxl-4,
  .px-xxxxl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xxxxl-4,
  .py-xxxxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xxxxl-4,
  .px-xxxxl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xxxxl-5 {
    padding: 3rem !important;
  }
  .pt-xxxxl-5,
  .py-xxxxl-5 {
    padding-top: 3rem !important;
  }
  .pr-xxxxl-5,
  .px-xxxxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxxxl-5,
  .py-xxxxl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xxxxl-5,
  .px-xxxxl-5 {
    padding-left: 3rem !important;
  }
  .m-xxxxl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xxxxl-n1,
  .my-xxxxl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xxxxl-n1,
  .mx-xxxxl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xxxxl-n1,
  .my-xxxxl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xxxxl-n1,
  .mx-xxxxl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xxxxl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xxxxl-n2,
  .my-xxxxl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xxxxl-n2,
  .mx-xxxxl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xxxxl-n2,
  .my-xxxxl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xxxxl-n2,
  .mx-xxxxl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xxxxl-n3 {
    margin: -1rem !important;
  }
  .mt-xxxxl-n3,
  .my-xxxxl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xxxxl-n3,
  .mx-xxxxl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xxxxl-n3,
  .my-xxxxl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xxxxl-n3,
  .mx-xxxxl-n3 {
    margin-left: -1rem !important;
  }
  .m-xxxxl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xxxxl-n4,
  .my-xxxxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xxxxl-n4,
  .mx-xxxxl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xxxxl-n4,
  .my-xxxxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xxxxl-n4,
  .mx-xxxxl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xxxxl-n5 {
    margin: -3rem !important;
  }
  .mt-xxxxl-n5,
  .my-xxxxl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xxxxl-n5,
  .mx-xxxxl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xxxxl-n5,
  .my-xxxxl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xxxxl-n5,
  .mx-xxxxl-n5 {
    margin-left: -3rem !important;
  }
  .m-xxxxl-auto {
    margin: auto !important;
  }
  .mt-xxxxl-auto,
  .my-xxxxl-auto {
    margin-top: auto !important;
  }
  .mr-xxxxl-auto,
  .mx-xxxxl-auto {
    margin-right: auto !important;
  }
  .mb-xxxxl-auto,
  .my-xxxxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxxxl-auto,
  .mx-xxxxl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 320px) {
  .text-xs-left {
    text-align: left !important;
  }
  .text-xs-right {
    text-align: right !important;
  }
  .text-xs-center {
    text-align: center !important;
  }
}
@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .text-xxl-left {
    text-align: left !important;
  }
  .text-xxl-right {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1600px) {
  .text-xxxl-left {
    text-align: left !important;
  }
  .text-xxxl-right {
    text-align: right !important;
  }
  .text-xxxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1800px) {
  .text-xxxxl-left {
    text-align: left !important;
  }
  .text-xxxxl-right {
    text-align: right !important;
  }
  .text-xxxxl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #007bff !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(27, 27, 27, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #1B1B1B;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
@media (max-width: 575.98px) {
  .container {
    max-width: none;
  }
}
.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font-size: 112px;
  font-weight: 300;
  line-height: 112px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font-size: 56px;
  font-weight: 400;
  line-height: 56px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font-size: 45px;
  font-weight: 400;
  line-height: 48px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font-size: 34px;
  font-weight: 400;
  line-height: 40px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-bottom-sheet-container {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-datepicker-content .mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Roboto, "Helvetica Neue", sans-serif));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 14px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-dialog-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-option:hover:not(.mdc-list-item--disabled), .mat-mdc-option:focus:not(.mdc-list-item--disabled), .mat-mdc-option.mat-mdc-option-active, .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #003fae);
}

.mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #ffc825);
}

.mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #c8372d);
}

.mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #003fae;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #003fae;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ffc825;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ffc825;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ffc825;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ffc825;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #c8372d;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #c8372d;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-mdc-option {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Open Sans));
  font-size: var(--mdc-typography-body1-font-size, 15px);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, normal);
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-mdc-option {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-option:hover:not(.mdc-list-item--disabled), .mat-mdc-option:focus:not(.mdc-list-item--disabled), .mat-mdc-option.mat-mdc-option-active, .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #003fae);
}

.mat-accent .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-secondary, #ffc825);
}

.mat-warn .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: var(--mdc-theme-error, #c8372d);
}

.mat-mdc-optgroup-label {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #003fae;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #003fae;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ffc825;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ffc825;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ffc825;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ffc825;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #c8372d;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #c8372d;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-mdc-option {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Open Sans));
  font-size: var(--mdc-typography-body1-font-size, 15px);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, normal);
}

.mat-mdc-card {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-color: #fff;
}

.mat-mdc-card-outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-outline-color: #e0e0e0;
}

.mat-mdc-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-card-title {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-headline6-font-family, var(--mdc-typography-font-family, Open Sans));
  font-size: var(--mdc-typography-headline6-font-size, 20px);
  line-height: var(--mdc-typography-headline6-line-height, 32px);
  font-weight: var(--mdc-typography-headline6-font-weight, 500);
  letter-spacing: var(--mdc-typography-headline6-letter-spacing, normal);
  text-decoration: var(--mdc-typography-headline6-text-decoration, inherit);
  text-transform: var(--mdc-typography-headline6-text-transform, none);
}

.mat-mdc-card-subtitle {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Open Sans));
  font-size: var(--mdc-typography-subtitle2-font-size, 14px);
  line-height: var(--mdc-typography-subtitle2-line-height, 24px);
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle2-text-transform, none);
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #003fae;
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(0, 63, 174, 0.25)'/%3E%3C/svg%3E");
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(0, 63, 174, 0.25);
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #ffc825;
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(255, 200, 37, 0.25)'/%3E%3C/svg%3E");
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(255, 200, 37, 0.25);
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #c8372d;
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(200, 55, 45, 0.25)'/%3E%3C/svg%3E");
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(200, 55, 45, 0.25);
}
.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: white;
}

.mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-font: Open Sans;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: normal;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
@media all {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
@media all {
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
}
.mdc-text-field .mdc-text-field__input {
  caret-color: var(--mdc-theme-primary, #003fae);
}
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field--filled .mdc-text-field__ripple::before,
.mdc-text-field--filled .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, rgba(0, 0, 0, 0.87));
}
.mdc-text-field--filled:hover .mdc-text-field__ripple::before, .mdc-text-field--filled.mdc-ripple-surface--hover .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-hover-opacity, 0.04);
}
.mdc-text-field--filled.mdc-ripple-upgraded--background-focused .mdc-text-field__ripple::before, .mdc-text-field--filled:not(.mdc-ripple-upgraded):focus .mdc-text-field__ripple::before {
  opacity: var(--mdc-ripple-focus-opacity, 0.12);
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-primary, #003fae);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-primary, #003fae);
}
[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mdc-text-field--outlined .mdc-text-field__ripple::before,
.mdc-text-field--outlined .mdc-text-field__ripple::after {
  background-color: var(--mdc-ripple-color, transparent);
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 63, 174, 0.87);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #c8372d);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #c8372d);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #c8372d);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #c8372d);
}
.mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #c8372d);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #c8372d);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #c8372d);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #c8372d);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #c8372d);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #c8372d);
}
.mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.38);
}
@media all {
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
@media all {
  .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.38);
  }
}
.mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field-character-counter,
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field__icon--leading {
  color: rgba(0, 0, 0, 0.3);
}
.mdc-text-field--disabled .mdc-text-field__icon--trailing {
  color: rgba(0, 0, 0, 0.3);
}
.mdc-text-field--disabled .mdc-text-field__affix--prefix {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-text-field__affix--suffix {
  color: rgba(0, 0, 0, 0.38);
}
.mdc-text-field--disabled .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.mdc-text-field--disabled .mdc-notched-outline__leading,
.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__input:-ms-input-placeholder {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-floating-label {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field-character-counter,
  .mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__icon--leading {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__icon--trailing {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__affix--prefix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-text-field__affix--suffix {
    color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom-color: GrayText;
  }
}
@media screen and (forced-colors: active), (-ms-high-contrast: active) {
  .mdc-text-field--disabled .mdc-notched-outline__leading,
  .mdc-text-field--disabled .mdc-notched-outline__notch,
  .mdc-text-field--disabled .mdc-notched-outline__trailing {
    border-color: GrayText;
  }
}
.mdc-text-field--disabled.mdc-text-field--filled {
  background-color: #fafafa;
}
[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__input, .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.mat-mdc-form-field-error {
  color: var(--mdc-theme-error, #c8372d);
}

.mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}

.mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(0, 63, 174, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(255, 200, 37, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(200, 55, 45, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field.mat-accent .mdc-text-field__input {
  caret-color: var(--mdc-theme-secondary, #ffc825);
}
.mat-mdc-form-field.mat-accent:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-secondary, #ffc825);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(255, 200, 37, 0.87);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #c8372d);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #c8372d);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #c8372d);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #c8372d);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #c8372d);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #c8372d);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #c8372d);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #c8372d);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #c8372d);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #c8372d);
}
.mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-accent .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-secondary, #ffc825);
}

.mat-mdc-form-field.mat-warn .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #c8372d);
}
.mat-mdc-form-field.mat-warn:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #c8372d);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(200, 55, 45, 0.87);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #c8372d);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: var(--mdc-theme-error, #c8372d);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-error, #c8372d);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: var(--mdc-theme-error, #c8372d);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid .mdc-text-field__input {
  caret-color: var(--mdc-theme-error, #c8372d);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__icon--trailing {
  color: var(--mdc-theme-error, #c8372d);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
  border-bottom-color: var(--mdc-theme-error, #c8372d);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #c8372d);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #c8372d);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #c8372d);
}
.mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mat-mdc-form-field.mat-warn .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--mdc-theme-error, #c8372d);
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}

[dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}

.mat-mdc-form-field-infix {
  min-height: 56px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 28px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -34.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mdc-text-field__input {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Open Sans));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}
.mdc-text-field__affix {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Open Sans));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}
[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-leading-icon, .mdc-text-field--outlined.mdc-text-field--with-leading-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

[dir=rtl] .mdc-text-field--outlined.mdc-text-field--with-trailing-icon, .mdc-text-field--outlined.mdc-text-field--with-trailing-icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mdc-text-field--textarea .mdc-text-field__input {
  line-height: 1.5rem;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
[dir=rtl] .mdc-text-field--end-aligned .mdc-text-field__input, .mdc-text-field--end-aligned .mdc-text-field__input[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Open Sans));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
}

[dir=rtl] .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker), .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}
.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Open Sans));
  font-size: var(--mdc-typography-caption-font-size, 12px);
  line-height: var(--mdc-typography-caption-line-height, 20px);
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: var(--mdc-typography-caption-text-transform, none);
}

.mat-mdc-form-field,
.mat-mdc-floating-label {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Open Sans));
  font-size: var(--mdc-typography-body1-font-size, 15px);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mat-mdc-form-field .mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: calc(15px * var(--mat-mdc-form-field-floating-label-scale, 0.75));
}
.mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 15px;
}

.mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #003fae);
}
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #003fae);
}

.mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-divider::after {
  border-bottom-color: white;
}

.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-select-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-select-disabled .mat-mdc-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: rgba(0, 63, 174, 0.87);
}
.mat-mdc-form-field.mat-focused.mat-accent .mat-mdc-select-arrow {
  color: rgba(255, 200, 37, 0.87);
}
.mat-mdc-form-field.mat-focused.mat-warn .mat-mdc-select-arrow {
  color: rgba(200, 55, 45, 0.87);
}
.mat-mdc-form-field .mat-mdc-select.mat-mdc-select-invalid .mat-mdc-select-arrow {
  color: rgba(200, 55, 45, 0.87);
}
.mat-mdc-form-field .mat-mdc-select.mat-mdc-select-disabled .mat-mdc-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-select-panel {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Open Sans));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  line-height: var(--mdc-typography-subtitle1-line-height, 28px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 24px;
}

.mat-mdc-select {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Open Sans));
  font-size: var(--mdc-typography-body1-font-size, 15px);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #003fae);
}
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #003fae);
}

.mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-divider::after {
  border-bottom-color: white;
}

.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-autocomplete-panel {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Open Sans));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  line-height: var(--mdc-typography-subtitle1-line-height, 28px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 24px;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-with-divider-divider-color: rgba(0, 0, 0, 0.12);
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-dialog-container {
  --mdc-dialog-subhead-font: Open Sans;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: normal;
  --mdc-dialog-supporting-text-font: Open Sans;
  --mdc-dialog-supporting-text-line-height: 24px;
  --mdc-dialog-supporting-text-size: 15px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: normal;
}

.mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected, .mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #003fae;
  --mdc-chip-elevated-disabled-container-color: #003fae;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}
.mat-mdc-standard-chip.mat-accent.mat-mdc-chip-selected, .mat-mdc-standard-chip.mat-accent.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #ffc825;
  --mdc-chip-elevated-disabled-container-color: #ffc825;
  --mdc-chip-label-text-color: #1b1b1b;
  --mdc-chip-disabled-label-text-color: #1b1b1b;
  --mdc-chip-with-icon-icon-color: #1b1b1b;
  --mdc-chip-with-icon-disabled-icon-color: #1b1b1b;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #1b1b1b;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #1b1b1b;
  --mdc-chip-with-icon-selected-icon-color: #1b1b1b;
}
.mat-mdc-standard-chip.mat-warn.mat-mdc-chip-selected, .mat-mdc-standard-chip.mat-warn.mat-mdc-chip-highlighted {
  --mdc-chip-elevated-container-color: #c8372d;
  --mdc-chip-elevated-disabled-container-color: #c8372d;
  --mdc-chip-label-text-color: white;
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
}

.mat-mdc-chip-focus-overlay {
  background: black;
}

.mat-mdc-chip {
  height: 32px;
}

.mat-mdc-standard-chip {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Open Sans));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;
}
.mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-slide-toggle.mat-primary {
  --mdc-switch-selected-focus-state-layer-color: #0e49ba;
  --mdc-switch-selected-handle-color: #0e49ba;
  --mdc-switch-selected-hover-state-layer-color: #0e49ba;
  --mdc-switch-selected-pressed-state-layer-color: #0e49ba;
  --mdc-switch-selected-focus-handle-color: #00238e;
  --mdc-switch-selected-hover-handle-color: #00238e;
  --mdc-switch-selected-pressed-handle-color: #00238e;
  --mdc-switch-selected-focus-track-color: #6e86d6;
  --mdc-switch-selected-hover-track-color: #6e86d6;
  --mdc-switch-selected-pressed-track-color: #6e86d6;
  --mdc-switch-selected-track-color: #6e86d6;
}
.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #ffb100;
  --mdc-switch-selected-handle-color: #ffb100;
  --mdc-switch-selected-hover-state-layer-color: #ffb100;
  --mdc-switch-selected-pressed-state-layer-color: #ffb100;
  --mdc-switch-selected-focus-handle-color: #ff6d00;
  --mdc-switch-selected-hover-handle-color: #ff6d00;
  --mdc-switch-selected-pressed-handle-color: #ff6d00;
  --mdc-switch-selected-focus-track-color: #ffd44d;
  --mdc-switch-selected-hover-track-color: #ffd44d;
  --mdc-switch-selected-pressed-track-color: #ffd44d;
  --mdc-switch-selected-track-color: #ffd44d;
}
.mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #da4133;
  --mdc-switch-selected-handle-color: #da4133;
  --mdc-switch-selected-hover-state-layer-color: #da4133;
  --mdc-switch-selected-pressed-state-layer-color: #da4133;
  --mdc-switch-selected-focus-handle-color: #ab281c;
  --mdc-switch-selected-hover-handle-color: #ab281c;
  --mdc-switch-selected-pressed-handle-color: #ab281c;
  --mdc-switch-selected-focus-track-color: #dd7570;
  --mdc-switch-selected-hover-track-color: #dd7570;
  --mdc-switch-selected-pressed-track-color: #dd7570;
  --mdc-switch-selected-track-color: #dd7570;
}

.mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}

.mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Open Sans));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #003fae;
  --mdc-radio-selected-hover-icon-color: #003fae;
  --mdc-radio-selected-icon-color: #003fae;
  --mdc-radio-selected-pressed-icon-color: #003fae;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #003fae;
}
.mat-mdc-radio-button.mat-primary .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ffc825;
  --mdc-radio-selected-hover-icon-color: #ffc825;
  --mdc-radio-selected-icon-color: #ffc825;
  --mdc-radio-selected-pressed-icon-color: #ffc825;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #ffc825;
}
.mat-mdc-radio-button.mat-accent .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c8372d;
  --mdc-radio-selected-hover-icon-color: #c8372d;
  --mdc-radio-selected-icon-color: #c8372d;
  --mdc-radio-selected-pressed-icon-color: #c8372d;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #c8372d;
}
.mat-mdc-radio-button.mat-warn .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-radio-button .mdc-radio {
  padding: calc((40px - 20px) / 2);
}
.mat-mdc-radio-button .mdc-radio .mdc-radio__background::before {
  top: calc(-1 * (40px - 20px) / 2);
  left: calc(-1 * (40px - 20px) / 2);
  width: 40px;
  height: 40px;
}
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control {
  top: calc((40px - 40px) / 2);
  right: calc((40px - 40px) / 2);
  left: calc((40px - 40px) / 2);
  width: 40px;
  height: 40px;
}

.mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Open Sans));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #003fae;
  --mdc-slider-focus-handle-color: #003fae;
  --mdc-slider-hover-handle-color: #003fae;
  --mdc-slider-active-track-color: #003fae;
  --mdc-slider-inactive-track-color: #003fae;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #003fae;
  --mat-mdc-slider-ripple-color: #003fae;
  --mat-mdc-slider-hover-ripple-color: rgba(0, 63, 174, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(0, 63, 174, 0.2);
}
.mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #ffc825;
  --mdc-slider-focus-handle-color: #ffc825;
  --mdc-slider-hover-handle-color: #ffc825;
  --mdc-slider-active-track-color: #ffc825;
  --mdc-slider-inactive-track-color: #ffc825;
  --mdc-slider-with-tick-marks-active-container-color: #000;
  --mdc-slider-with-tick-marks-inactive-container-color: #ffc825;
  --mat-mdc-slider-ripple-color: #ffc825;
  --mat-mdc-slider-hover-ripple-color: rgba(255, 200, 37, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(255, 200, 37, 0.2);
}
.mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: #c8372d;
  --mdc-slider-focus-handle-color: #c8372d;
  --mdc-slider-hover-handle-color: #c8372d;
  --mdc-slider-active-track-color: #c8372d;
  --mdc-slider-inactive-track-color: #c8372d;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #c8372d;
  --mat-mdc-slider-ripple-color: #c8372d;
  --mat-mdc-slider-hover-ripple-color: rgba(200, 55, 45, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(200, 55, 45, 0.2);
}

.mat-mdc-slider {
  --mdc-slider-label-label-text-font: Open Sans;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 24px;
  --mdc-slider-label-label-text-tracking: normal;
  --mdc-slider-label-label-text-weight: 500;
}

.mdc-menu-surface {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: var(--mdc-theme-surface, #fff);
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item__primary-text {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-item__secondary-text {
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__overline-text {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  background-color: transparent;
}

.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__end {
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item--disabled .mdc-list-item__start,
.mdc-list-item--disabled .mdc-list-item__content,
.mdc-list-item--disabled .mdc-list-item__end {
  opacity: 0.38;
}

.mdc-list-item--disabled .mdc-list-item__primary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__secondary-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled .mdc-list-item__overline-text {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-icon .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-list-item--disabled.mdc-list-item--with-trailing-meta .mdc-list-item__end {
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--mdc-theme-primary, #003fae);
}
.mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--mdc-theme-primary, #003fae);
}

.mdc-deprecated-list-group__subheader {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list-divider::after {
  border-bottom-color: white;
}

.mdc-list-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-menu-item[disabled],
.mat-mdc-menu-item[disabled] .mat-mdc-menu-submenu-icon,
.mat-mdc-menu-item[disabled] .mat-icon-no-color {
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38));
}

.mat-mdc-menu-item .mat-icon-no-color,
.mat-mdc-menu-submenu-icon {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-mdc-menu-item:hover:not([disabled]),
.mat-mdc-menu-item.cdk-program-focused:not([disabled]),
.mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-mdc-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-mdc-menu-content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle1-font-family, var(--mdc-typography-font-family, Open Sans));
  font-size: var(--mdc-typography-subtitle1-font-size, 16px);
  line-height: var(--mdc-typography-subtitle1-line-height, 28px);
  font-weight: var(--mdc-typography-subtitle1-font-weight, 400);
  letter-spacing: var(--mdc-typography-subtitle1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle1-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle1-text-transform, none);
  /* @alternate */
  line-height: 24px;
}
.mat-mdc-menu-content,
.mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body1-font-family, var(--mdc-typography-font-family, Open Sans));
  font-size: var(--mdc-typography-body1-font-size, 15px);
  line-height: var(--mdc-typography-body1-line-height, 24px);
  font-weight: var(--mdc-typography-body1-font-weight, 400);
  letter-spacing: var(--mdc-typography-body1-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body1-text-decoration, inherit);
  text-transform: var(--mdc-typography-body1-text-transform, none);
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mat-mdc-list-option .mdc-list-item__start, .mat-mdc-list-option .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #003fae;
  --mdc-checkbox-selected-hover-icon-color: #003fae;
  --mdc-checkbox-selected-icon-color: #003fae;
  --mdc-checkbox-selected-pressed-icon-color: #003fae;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-list-option .mdc-list-item__start, .mat-mdc-list-option .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #003fae;
  --mdc-radio-selected-hover-icon-color: #003fae;
  --mdc-radio-selected-icon-color: #003fae;
  --mdc-radio-selected-pressed-icon-color: #003fae;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #003fae;
}
.mat-mdc-list-option .mdc-list-item__start .mdc-radio--disabled + label, .mat-mdc-list-option .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-list-option.mat-accent .mdc-list-item__start, .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #ffc825;
  --mdc-checkbox-selected-hover-icon-color: #ffc825;
  --mdc-checkbox-selected-icon-color: #ffc825;
  --mdc-checkbox-selected-pressed-icon-color: #ffc825;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-list-option.mat-accent .mdc-list-item__start, .mat-mdc-list-option.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #ffc825;
  --mdc-radio-selected-hover-icon-color: #ffc825;
  --mdc-radio-selected-icon-color: #ffc825;
  --mdc-radio-selected-pressed-icon-color: #ffc825;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #ffc825;
}
.mat-mdc-list-option.mat-accent .mdc-list-item__start .mdc-radio--disabled + label, .mat-mdc-list-option.mat-accent .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-list-option.mat-warn .mdc-list-item__start, .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #c8372d;
  --mdc-checkbox-selected-hover-icon-color: #c8372d;
  --mdc-checkbox-selected-icon-color: #c8372d;
  --mdc-checkbox-selected-pressed-icon-color: #c8372d;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-list-option.mat-warn .mdc-list-item__start, .mat-mdc-list-option.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #c8372d;
  --mdc-radio-selected-hover-icon-color: #c8372d;
  --mdc-radio-selected-icon-color: #c8372d;
  --mdc-radio-selected-pressed-icon-color: #c8372d;
  --mat-mdc-radio-ripple-color: #000;
  --mat-mdc-radio-checked-ripple-color: #c8372d;
}
.mat-mdc-list-option.mat-warn .mdc-list-item__start .mdc-radio--disabled + label, .mat-mdc-list-option.mat-warn .mdc-list-item__end .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #003fae;
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #003fae;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

.mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-font: Open Sans;
  --mdc-list-list-item-label-text-line-height: 24px;
  --mdc-list-list-item-label-text-size: 15px;
  --mdc-list-list-item-label-text-tracking: normal;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Open Sans;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: normal;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Open Sans;
  --mdc-list-list-item-trailing-supporting-text-line-height: 20px;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: normal;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  font-family: Open Sans;
  letter-spacing: normal;
}

.mat-mdc-paginator {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-mdc-paginator-decrement,
.mat-mdc-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-mdc-paginator-first,
.mat-mdc-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-mdc-icon-button[disabled] .mat-mdc-paginator-decrement,
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-increment,
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-first,
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-last {
  border-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  fill: rgba(0, 0, 0, 0.12);
}

.mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}

.mat-mdc-paginator-container {
  min-height: 56px;
}

.mat-mdc-paginator {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-caption-font-family, var(--mdc-typography-font-family, Open Sans));
  font-size: var(--mdc-typography-caption-font-size, 12px);
  line-height: var(--mdc-typography-caption-line-height, 20px);
  font-weight: var(--mdc-typography-caption-font-weight, 400);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, normal);
  text-decoration: var(--mdc-typography-caption-text-decoration, inherit);
  text-transform: var(--mdc-typography-caption-text-transform, none);
}

.mat-mdc-paginator .mat-mdc-select-value {
  font-size: 12px;
}

.mat-mdc-tab, .mat-mdc-tab-link {
  background-color: transparent;
}
.mat-mdc-tab .mdc-tab__text-label, .mat-mdc-tab-link .mdc-tab__text-label {
  color: rgba(0, 0, 0, 0.6);
}
.mat-mdc-tab.mat-mdc-tab-disabled .mdc-tab__ripple::before,
.mat-mdc-tab.mat-mdc-tab-disabled .mat-ripple-element, .mat-mdc-tab-link.mat-mdc-tab-disabled .mdc-tab__ripple::before,
.mat-mdc-tab-link.mat-mdc-tab-disabled .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #003fae;
}
.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #003fae);
}
.mdc-tab__ripple::before,
.mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-link .mat-ripple-element {
  background-color: #003fae;
}

.mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #ffc825;
}
.mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-group.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #ffc825);
}
.mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before,
.mat-mdc-tab-group.mat-accent .mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-group.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-group.mat-accent .mat-mdc-tab-link .mat-ripple-element, .mat-mdc-tab-nav-bar.mat-accent .mdc-tab__ripple::before,
.mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-accent .mat-mdc-tab-link .mat-ripple-element {
  background-color: #ffc825;
}
.mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label, .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
  color: #c8372d;
}
.mat-mdc-tab-group.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-group.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline, .mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--mdc-tab-indicator-active-indicator-color, #c8372d);
}
.mat-mdc-tab-group.mat-warn .mdc-tab__ripple::before,
.mat-mdc-tab-group.mat-warn .mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-group.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-group.mat-warn .mat-mdc-tab-link .mat-ripple-element, .mat-mdc-tab-nav-bar.mat-warn .mdc-tab__ripple::before,
.mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-header-pagination .mat-ripple-element,
.mat-mdc-tab-nav-bar.mat-warn .mat-mdc-tab-link .mat-ripple-element {
  background-color: #c8372d;
}

.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-mdc-tab-header-with-background-background-color: #003fae;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-mdc-tab-header-with-background-background-color: #ffc825;
  --mat-mdc-tab-header-with-background-foreground-color: #000;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-mdc-tab-header-with-background-background-color: #c8372d;
  --mat-mdc-tab-header-with-background-foreground-color: #fff;
}

.mat-mdc-tab-header-pagination-chevron {
  border-color: var(--mdc-theme-on-surface, #000);
}

.mat-mdc-tab-header .mdc-tab {
  height: 48px;
}

.mdc-tab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Open Sans));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 14px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-checkbox .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-checkbox .mdc-checkbox__ripple {
  background: #000;
}
.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #003fae;
  --mdc-checkbox-selected-hover-icon-color: #003fae;
  --mdc-checkbox-selected-icon-color: #003fae;
  --mdc-checkbox-selected-pressed-icon-color: #003fae;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(0, 63, 174, 0.1);
}
.mat-mdc-checkbox.mat-primary .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #003fae;
}
.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-checkmark-color: #000;
  --mdc-checkbox-selected-focus-icon-color: #ffc825;
  --mdc-checkbox-selected-hover-icon-color: #ffc825;
  --mdc-checkbox-selected-icon-color: #ffc825;
  --mdc-checkbox-selected-pressed-icon-color: #ffc825;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(255, 200, 37, 0.1);
}
.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #ffc825;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #c8372d;
  --mdc-checkbox-selected-hover-icon-color: #c8372d;
  --mdc-checkbox-selected-icon-color: #c8372d;
  --mdc-checkbox-selected-pressed-icon-color: #c8372d;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mat-mdc-checkbox-ripple .mat-ripple-element {
  background-color: rgba(200, 55, 45, 0.1);
}
.mat-mdc-checkbox.mat-warn .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: #c8372d;
}

.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-checkbox .mdc-checkbox {
  padding: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
  margin: calc((var(--mdc-checkbox-touch-target-size, 40px) - 40px) / 2);
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
  top: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
  left: calc((var(--mdc-checkbox-ripple-size, 40px) - 18px) / 2);
}
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control {
  top: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  right: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  left: calc((40px - var(--mdc-checkbox-touch-target-size, 40px)) / 2);
  width: var(--mdc-checkbox-touch-target-size, 40px);
  height: var(--mdc-checkbox-touch-target-size, 40px);
}

@media all and (-ms-high-contrast: none) {
  .mdc-checkbox .mdc-checkbox__focus-ring {
    display: none;
  }
}

.mdc-form-field {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Open Sans));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}
.mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #003fae;
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #ffc825;
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #c8372d;
}
.mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #003fae;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #ffc825;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #c8372d;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #003fae;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #ffc825;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #c8372d;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}

.mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #003fae;
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #ffc825;
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #c8372d;
}
.mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-button, .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button.mat-primary, .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #003fae;
  --mat-mdc-button-ripple-color: rgba(0, 63, 174, 0.1);
}
.mat-mdc-button.mat-accent, .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #ffc825;
  --mat-mdc-button-ripple-color: rgba(255, 200, 37, 0.1);
}
.mat-mdc-button.mat-warn, .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #c8372d;
  --mat-mdc-button-ripple-color: rgba(200, 55, 45, 0.1);
}

.mat-mdc-raised-button, .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button.mat-primary, .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent, .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button.mat-warn, .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}

.mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Open Sans));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 14px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}

.mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #003fae;
  --mat-mdc-button-ripple-color: rgba(0, 63, 174, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #ffc825;
  --mat-mdc-button-ripple-color: rgba(255, 200, 37, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #c8372d;
  --mat-mdc-button-ripple-color: rgba(200, 55, 45, 0.1);
}
.mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #003fae;
}
.mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #ffc825;
}
.mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #c8372d;
}
.mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  width: 48px;
  height: 48px;
  padding: 12px;
}
.mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 48px;
  max-width: 48px;
}
.mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}
.mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 48px;
  transform: translate(-50%, -50%);
}

.mat-mdc-fab, .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab.mat-primary, .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-accent, .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab.mat-warn, .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-unthemed, .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #fff;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-primary, .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #003fae;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab.mat-accent, .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #ffc825;
  --mdc-fab-icon-color: #000;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-warn, .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: #c8372d;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab[disabled][disabled], .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}

.mdc-fab--extended {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Open Sans));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 14px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: #ffc825;
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-supporting-text-font: Open Sans;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

.mdc-data-table {
  background-color: var(--mdc-theme-surface, #fff);
  border-color: rgba(0, 0, 0, 0.12);
}
.mdc-data-table__row {
  background-color: inherit;
}

.mdc-data-table__header-cell {
  background-color: var(--mdc-theme-surface, #fff);
}

.mdc-data-table__row--selected {
  background-color: rgba(0, 63, 174, 0.04);
}

.mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.mdc-data-table__pagination-rows-per-page-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.12);
}
.mdc-data-table__cell,
.mdc-data-table__header-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mdc-data-table__pagination {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.mdc-data-table__header-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-data-table__pagination-total,
.mdc-data-table__pagination-rows-per-page-label,
.mdc-data-table__cell {
  color: rgba(0, 0, 0, 0.87);
}

[dir=rtl] .mdc-data-table__pagination-button .mdc-button__icon, .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mat-mdc-table {
  background: white;
}

.mat-mdc-table .mdc-data-table__row {
  height: 52px;
}
.mat-mdc-table .mdc-data-table__pagination {
  min-height: 52px;
}
.mat-mdc-table .mdc-data-table__header-row {
  height: 56px;
}

.mdc-data-table__content {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Open Sans));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mdc-data-table__cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-body2-font-family, var(--mdc-typography-font-family, Open Sans));
  font-size: var(--mdc-typography-body2-font-size, 14px);
  line-height: var(--mdc-typography-body2-line-height, 20px);
  font-weight: var(--mdc-typography-body2-font-weight, 400);
  letter-spacing: var(--mdc-typography-body2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-body2-text-decoration, inherit);
  text-transform: var(--mdc-typography-body2-text-transform, none);
}

.mdc-data-table__header-cell {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-subtitle2-font-family, var(--mdc-typography-font-family, Open Sans));
  font-size: var(--mdc-typography-subtitle2-font-size, 14px);
  line-height: var(--mdc-typography-subtitle2-line-height, 24px);
  font-weight: var(--mdc-typography-subtitle2-font-weight, 500);
  letter-spacing: var(--mdc-typography-subtitle2-letter-spacing, normal);
  text-decoration: var(--mdc-typography-subtitle2-text-decoration, inherit);
  text-transform: var(--mdc-typography-subtitle2-text-transform, none);
}

[dir=rtl] .mdc-data-table__pagination-button .mdc-button__icon, .mdc-data-table__pagination-button .mdc-button__icon[dir=rtl] {
  /*rtl:begin:ignore*/
  /*rtl:end:ignore*/
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #003fae;
}
.mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #ffc825;
}
.mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: #c8372d;
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: white;
  background: #003fae;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #ffc825;
  color: #1b1b1b;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #c8372d;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Open Sans;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-bottom-sheet-container {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Open Sans;
  letter-spacing: normal;
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-button-toggle {
  font-family: Open Sans;
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(0, 63, 174, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 63, 174, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 63, 174, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #003fae;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 63, 174, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 63, 174, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 63, 174, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 200, 37, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 200, 37, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 200, 37, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ffc825;
  color: #1b1b1b;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 200, 37, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #1b1b1b;
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 200, 37, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 200, 37, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(200, 55, 45, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(200, 55, 45, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(200, 55, 45, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #c8372d;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(200, 55, 45, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(200, 55, 45, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(200, 55, 45, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #003fae;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #ffc825;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #c8372d;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px;
  height: 40px;
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 40px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 40px;
  transform: translate(-50%, -50%);
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

.mat-calendar {
  font-family: Open Sans;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-expansion-panel-header {
  font-family: Open Sans;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Open Sans;
  letter-spacing: normal;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

.mat-icon.mat-primary {
  color: #003fae;
}
.mat-icon.mat-accent {
  color: #ffc825;
}
.mat-icon.mat-warn {
  color: #c8372d;
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #003fae;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: #1b1b1b;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #ffc825;
  color: #1b1b1b;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #c8372d;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #c8372d;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #c8372d;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Open Sans;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #003fae;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #ffc825;
  color: #1b1b1b;
}
.mat-toolbar.mat-warn {
  background: #c8372d;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: Open Sans;
  letter-spacing: normal;
  margin: 0;
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-tree {
  font-family: Open Sans;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #003fae;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: #ffc825;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #c8372d;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #003fae;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ffc825;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #c8372d;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: #1b1b1b;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #003fae;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: #ffc825;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #c8372d;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(27, 27, 27, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-content .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #003fae;
}
.mat-datepicker-content .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #ffc825;
}
.mat-datepicker-content .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #c8372d;
}
.mat-datepicker-content .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.mat-datepicker-content .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #003fae;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #ffc825;
  --mdc-filled-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #c8372d;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}
.mat-datepicker-content .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #003fae;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #ffc825;
  --mdc-protected-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #c8372d;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-datepicker-content .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}
.mat-datepicker-content .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-datepicker-content .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.mat-datepicker-content .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #003fae;
}
.mat-datepicker-content .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #ffc825;
}
.mat-datepicker-content .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #c8372d;
}
.mat-datepicker-content .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-datepicker-content .mat-mdc-button, .mat-datepicker-content .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-datepicker-content .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-datepicker-content .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-button.mat-primary, .mat-datepicker-content .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #003fae;
  --mat-mdc-button-ripple-color: rgba(0, 63, 174, 0.1);
}
.mat-datepicker-content .mat-mdc-button.mat-accent, .mat-datepicker-content .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #ffc825;
  --mat-mdc-button-ripple-color: rgba(255, 200, 37, 0.1);
}
.mat-datepicker-content .mat-mdc-button.mat-warn, .mat-datepicker-content .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #c8372d;
  --mat-mdc-button-ripple-color: rgba(200, 55, 45, 0.1);
}
.mat-datepicker-content .mat-mdc-raised-button, .mat-datepicker-content .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-datepicker-content .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-datepicker-content .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-raised-button.mat-primary, .mat-datepicker-content .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-datepicker-content .mat-mdc-raised-button.mat-accent, .mat-datepicker-content .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-datepicker-content .mat-mdc-raised-button.mat-warn, .mat-datepicker-content .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-datepicker-content .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-datepicker-content .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #003fae;
  --mat-mdc-button-ripple-color: rgba(0, 63, 174, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #ffc825;
  --mat-mdc-button-ripple-color: rgba(255, 200, 37, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #c8372d;
  --mat-mdc-button-ripple-color: rgba(200, 55, 45, 0.1);
}
.mat-datepicker-content .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #003fae;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #ffc825;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #c8372d;
}
.mat-datepicker-content .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}

.mat-datepicker-content .mat-mdc-button.mat-mdc-button-base,
.mat-datepicker-content .mat-mdc-raised-button.mat-mdc-button-base,
.mat-datepicker-content .mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-datepicker-content .mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base {
  width: 48px;
  height: 48px;
  padding: 12px;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 48px;
  max-width: 48px;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-left: 4px;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-datepicker-content .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 48px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 48px;
  transform: translate(-50%, -50%);
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 500;
}

.mat-datepicker-content .mdc-button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mdc-typography-button-font-family, var(--mdc-typography-font-family, Open Sans));
  font-size: var(--mdc-typography-button-font-size, 14px);
  line-height: var(--mdc-typography-button-line-height, 14px);
  font-weight: var(--mdc-typography-button-font-weight, 500);
  letter-spacing: var(--mdc-typography-button-letter-spacing, normal);
  text-decoration: var(--mdc-typography-button-text-decoration, none);
  text-transform: var(--mdc-typography-button-text-transform, none);
}
.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #003fae;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ffc825;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #c8372d;
}

.mat-option {
  font-family: Open Sans;
  font-size: 16px;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-optgroup-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  font-family: Open Sans;
  letter-spacing: normal;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #003fae;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #003fae;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ffc825;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ffc825;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #ffc825;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #ffc825;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #c8372d;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #c8372d;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-card {
  font-family: Open Sans;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-progress-bar-background {
  fill: #bccbe7;
}

.mat-progress-bar-buffer {
  background-color: #bccbe7;
}

.mat-progress-bar-fill::after {
  background-color: #003fae;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #fbeec5;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #fbeec5;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ffc825;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #eec9c7;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #eec9c7;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #c8372d;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #003fae;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: #ffc825;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #c8372d;
}

.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tooltip {
  font-family: Open Sans;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #003fae;
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: #ffc825;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #c8372d;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #c8372d;
}

.mat-form-field-disabled .mat-form-field-prefix,
.mat-form-field-disabled .mat-form-field-suffix {
  color: rgba(0, 0, 0, 0.38);
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #003fae;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ffc825;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #c8372d;
}

.mat-focused .mat-form-field-required-marker {
  color: #ffc825;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #003fae;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ffc825;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #c8372d;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #003fae;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ffc825;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #c8372d;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #c8372d;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #c8372d;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #c8372d;
}

.mat-error {
  color: #c8372d;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #003fae;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ffc825;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #c8372d;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #c8372d;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Open Sans;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34373em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34372em) scale(0.75);
  width: 133.3333633333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00106px);
  width: 133.3333933333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00107px);
  width: 133.3334033333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00108px);
  width: 133.3334133333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28116em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28115em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28114em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59373em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59372em) scale(0.75);
  width: 133.3333633333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59373em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label] + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59372em) scale(0.75);
  width: 133.3333633333%;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #003fae;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ffc825;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #c8372d;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #c8372d;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select {
  font-family: Open Sans;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #003fae;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ffc825;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #c8372d;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #003fae;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ffc825;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #c8372d;
}

.mat-checkbox {
  font-family: Open Sans;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-dialog-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: Open Sans;
  letter-spacing: normal;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #003fae;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #c8372d;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ffc825;
  color: #1b1b1b;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: #1b1b1b;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(27, 27, 27, 0.1);
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffc825;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 200, 37, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ffc825;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #003fae;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 63, 174, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #003fae;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #c8372d;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(200, 55, 45, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #c8372d;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slide-toggle-content {
  font-family: Open Sans;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #003fae;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #003fae;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ffc825;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ffc825;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #c8372d;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #c8372d;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-radio-button {
  font-family: Open Sans;
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #003fae;
}
.mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(0, 63, 174, 0.2);
}
.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #ffc825;
}
.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: #1b1b1b;
}
.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 200, 37, 0.2);
}
.mat-slider.mat-warn .mat-slider-track-fill,
.mat-slider.mat-warn .mat-slider-thumb,
.mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #c8372d;
}
.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(200, 55, 45, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-thumb-label-text {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 500;
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-menu-item {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-list-item {
  font-family: Open Sans;
}

.mat-list-option {
  font-family: Open Sans;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 500;
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Open Sans;
  font-size: 12px;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused {
  background-color: rgba(110, 134, 214, 0.3);
}
.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #003fae;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 235, 178, 0.3);
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ffc825;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: #1b1b1b;
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused {
  background-color: rgba(251, 205, 208, 0.3);
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #c8372d;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused {
  background-color: rgba(110, 134, 214, 0.3);
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #003fae;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused {
  background-color: rgba(255, 235, 178, 0.3);
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #ffc825;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: #1b1b1b;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(27, 27, 27, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: #1b1b1b;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: #1b1b1b;
  opacity: 0.4;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: #1b1b1b;
  opacity: 0.12;
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused, .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused,
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused,
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused {
  background-color: rgba(251, 205, 208, 0.3);
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused.mat-tab-disabled,
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused.mat-tab-disabled {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #c8372d;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}

.mat-tab-group {
  font-family: Open Sans;
}

.mat-tab-label, .mat-tab-link {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 500;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: #ffc825;
}

.mat-simple-snackbar {
  font-family: Open Sans;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-table {
  font-family: Open Sans;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-badge-content {
  color: white;
  background: #003fae;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: #ffc825;
  color: #1b1b1b;
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #c8372d;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Open Sans;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-bottom-sheet-container {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Open Sans;
  letter-spacing: normal;
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-button-toggle {
  font-family: Open Sans;
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(0, 63, 174, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 63, 174, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 63, 174, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #003fae;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 63, 174, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 63, 174, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 63, 174, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 200, 37, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 200, 37, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 200, 37, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ffc825;
  color: #1b1b1b;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 200, 37, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px #1b1b1b;
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 200, 37, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 200, 37, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(200, 55, 45, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(200, 55, 45, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(200, 55, 45, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #c8372d;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(200, 55, 45, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(200, 55, 45, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(200, 55, 45, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #003fae;
}
.mat-datepicker-toggle-active.mat-accent {
  color: #ffc825;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #c8372d;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  width: 40px;
  height: 40px;
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__ripple {
  width: 40px;
  height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  margin-left: 0px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base.mdc-icon-button--reduced-size .mdc-icon-button__focus-ring {
  max-height: 40px;
  max-width: 40px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button__touch {
  position: absolute;
  top: 50%;
  height: 40px;
  /* @noflip */ /*rtl:ignore*/
  left: 50%;
  width: 40px;
  transform: translate(-50%, -50%);
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

.mat-calendar {
  font-family: Open Sans;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-expansion-panel-header {
  font-family: Open Sans;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: Open Sans;
  letter-spacing: normal;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

.mat-icon.mat-primary {
  color: #003fae;
}
.mat-icon.mat-accent {
  color: #ffc825;
}
.mat-icon.mat-warn {
  color: #c8372d;
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #003fae;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: #1b1b1b;
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #ffc825;
  color: #1b1b1b;
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #c8372d;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #c8372d;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #c8372d;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Open Sans;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #003fae;
  color: white;
}
.mat-toolbar.mat-accent {
  background: #ffc825;
  color: #1b1b1b;
}
.mat-toolbar.mat-warn {
  background: #c8372d;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  font-family: Open Sans;
  letter-spacing: normal;
  margin: 0;
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-tree {
  font-family: Open Sans;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-card-title,
.mat-card-subtitle {
  margin-top: 0 !important;
}

.mat-input-element {
  caret-color: unset !important;
}

.mat-input-element:not(input):not(textarea) {
  height: 21px !important;
}

.mat-form-field:not(.textarea) .mat-form-field-outline {
  height: 40px !important;
}

.mat-form-field-invalid.ng-invalid .mat-form-field-wrapper {
  padding-bottom: 20px;
}

mat-form-field:not(.textarea) .mat-form-field-wrapper {
  height: 56px;
}
.mat-form-field-wrapper {
  margin: 8px 0 4px 0 !important;
  padding-bottom: 16px;
}

.mat-form-field-infix {
  padding: 10px 0 10px 0 !important;
  border-top: 2px solid transparent !important;
}

.mat-form-field-suffix {
  top: -1px !important;
}
.mat-form-field-suffix img {
  vertical-align: unset;
  height: 20px;
}

.filled .mat-input-element:disabled {
  color: #1B1B1B;
  opacity: 1;
  -webkit-text-fill-color: #1B1B1B;
}

@supports (-webkit-touch-callout: none) {
  .mat-form-field-infix {
    border-top-width: 2px !important;
  }
  .mat-form-field-suffix {
    top: -1px !important;
  }
}
.mat-focused .mat-form-field-outline {
  box-shadow: 0 0 5px rgba(5, 101, 90, 0.2);
}
.mat-focused mat-error {
  display: none;
}

.not-touch-screen .mat-form-field-appearance-outline:hover:not(.mat-form-field-invalid):not(.mat-focused) .mat-form-field-outline-thick .mat-form-field-outline-start,
.not-touch-screen .mat-form-field-appearance-outline:hover:not(.mat-form-field-invalid):not(.mat-focused) .mat-form-field-outline-thick .mat-form-field-outline-gap,
.not-touch-screen .mat-form-field-appearance-outline:hover:not(.mat-form-field-invalid):not(.mat-focused) .mat-form-field-outline-thick .mat-form-field-outline-end {
  border-width: 1px !important;
}

.search-field .mat-form-field-prefix {
  top: 2px !important;
  margin-right: 4px;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #65707C;
}

.mat-form-field-appearance-outline.filled:not(.mat-form-field-invalid):not(.mat-focused):not(:hover) .mat-form-field-outline {
  color: #C4C8CC;
}

.not-touch-screen .mat-form-field-appearance-outline.filled:not(.mat-form-field-invalid):not(.mat-focused):hover .mat-form-field-outline {
  color: #1B1B1B;
}

.mat-form-field-appearance-outline.filled:not(.mat-form-field-invalid).mat-focused .mat-form-field-outline {
  color: #003FAE !important;
}

.mat-focused .mat-form-field-outline,
.mat-focused.mat-form-field-invalid.ng-invalid .mat-form-field-outline {
  color: #003FAE !important;
  box-shadow: 0 0 5px rgba(5, 101, 90, 0.2);
}

.mat-focused .mat-form-field-outline {
  box-shadow: none;
}

.not-touch-screen .mat-form-field-appearance-outline:hover .mat-form-field-outline:hover,
.not-touch-screen .mat-form-field-flex:hover .mat-form-field-outline {
  color: #1851C4;
}

.mat-form-field-appearance-outline .mat-form-field-flex {
  padding: 0 8px 0 8px !important;
}

.mat-form-field-subscript-wrapper {
  padding: 0 0 !important;
  margin-top: 4px !important;
  overflow: visible !important;
}

.mat-form-field-appearance-outline.note .mat-form-field-outline {
  color: transparent !important;
  background-color: #FAFBFC;
  border-radius: 5px;
}

.not-touch-screen .mat-form-field-appearance-outline.note:not(.mat-form-field-invalid):not(.mat-focused):hover .mat-form-field-outline {
  color: #C4C8CC !important;
}

.mat-form-field-appearance-outline.note:not(.mat-form-field-invalid).mat-focused .mat-form-field-outline {
  color: #003FAE !important;
  background-color: white;
}

.mat-autocomplete-panel {
  min-width: unset !important;
  max-height: 40dvh !important;
}

.small-mat-autocomplete .mat-autocomplete-panel {
  max-height: 20dvh !important;
}

.mat-selected {
  background-color: unset !important;
}

.mat-option:not(.time-suggestion) {
  font-size: inherit;
  letter-spacing: 0.44px;
  position: relative;
}
.mat-option:not(.time-suggestion):not(:last-of-type)::after {
  position: absolute;
  content: "";
  height: 1px;
  width: calc(100% - 32px);
  background-color: #BCBBC1;
  bottom: 0;
}

.mat-option.time-suggestion {
  padding-left: 10px;
  padding-right: 0;
  height: 32px;
}

.dimmed .mat-option-text {
  color: #65707C;
}

.mat-option.withSubtitle:not(.mat-option-disabled) {
  min-height: 88px !important;
}
.mat-option.withSubtitle:not(.mat-option-disabled) .mat-option-text {
  height: 88px !important;
  display: flex;
  flex-direction: column;
  align-items: start !important;
  overflow: hidden !important;
}
.mat-option.withSubtitle:not(.mat-option-disabled) .mat-option-text .subtitle {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  margin-bottom: 0;
  line-height: 20px;
  color: #65707C;
  display: block;
}

.mat-menu-content:not(:empty) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.not-touch-screen .mat-option:hover:not(.mat-option-disabled) {
  background-color: #FAFBFC !important;
}

.not-touch-screen .mat-option.withSubtitle:hover:not(.mat-option-disabled) .mat-option-text,
.not-touch-screen .mat-option:hover:not(.mat-option-disabled) .mat-option-text,
.not-touch-screen .mat-menu-item:hover .mat-option-text {
  text-shadow: 0 0 0.4px black, 0 0 0.4px black;
  height: 32px;
  display: flex;
  align-items: center;
}

.mat-option.mat-selected:not(.mat-option-disabled) {
  color: #1B1B1B !important;
  text-shadow: 0 0 0.4px black, 0 0 0.4px black !important;
  background-color: transparent !important;
}

.mat-option:hover:not(.mat-option-disabled),
.mat-option:focus:not(.mat-option-disabled) {
  background: transparent;
}

.mat-select-arrow-wrapper {
  transform: none !important;
}

.mat-select-arrow {
  border: none !important;
  background: url("../../../assets/icons/cheveron_down.svg") no-repeat;
  width: 16px !important;
  height: 8px !important;
}

.mat-step-header,
.mat-stepper-horizontal-line {
  display: none !important;
}

.mat-datepicker-close-button {
  display: none !important;
}

.cdk-overlay-backdrop:not(.mat-datepicker-0-backdrop):not(.mat-overlay-transparent-backdrop) {
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(4px);
}

.mat-horizontal-content-container {
  padding: 0 0 0 0 !important;
}

.mat-dialog-container {
  border-radius: 8px !important;
  padding: 0 !important;
  height: unset !important;
  box-shadow: 0 1px 1px 0 rgba(101, 112, 124, 0.2), 0 2px 2px 0 rgba(101, 112, 124, 0.14), 0 1px 5px 0 rgba(27, 27, 27, 0.12);
}

.cdk-overlay-pane {
  max-width: calc(100% - 32px) !important;
  max-height: calc(100% - 32px) !important;
  overflow: visible;
  border-radius: 5px;
}
.cdk-overlay-pane:not(.mat-datepicker-popup) {
  right: unset;
}

.image-viewer-overlay {
  max-width: 100% !important;
  max-height: 100% !important;
}
.image-viewer-overlay app-image-viewer {
  width: 100%;
  height: 100%;
}

@media (max-width: 575.98px) {
  .cdk-overlay-pane .mat-menu-panel:not(.no-margin) {
    margin-left: 16px;
    margin-right: 16px;
  }
}
.hide-active .active {
  display: none !important;
}

.info-icon-wrapper {
  display: flex;
  align-items: center;
}
.info-icon-wrapper.mt {
  margin-top: 8px;
}
.info-icon-wrapper.mb {
  margin-bottom: 8px;
}
.info-icon-wrapper .icon {
  margin-left: 4px;
  margin-right: 4px;
  height: 16px;
  width: 16px;
  background-color: #65707C;
}

.not-touch-screen .info-icon-wrapper .icon:hover {
  background-color: #1B1B1B;
}

.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical):not(:hover) {
  background-color: transparent !important;
}

.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical):hover {
  background-color: rgba(0, 63, 174, 0.15) !important;
}

.mat-calendar-body-disabled .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical):hover {
  background-color: transparent !important;
}

.dialog {
  border-radius: 8px;
  overflow-y: auto;
  border: 0.5px solid #f8f8f8;
  background-color: white;
}

.dialog-full-height {
  height: 100% !important;
}
.dialog-full-height .mat-dialog-container {
  max-height: unset !important;
  overflow: hidden !important;
}

.mat-datepicker-content {
  margin-top: -4px;
}

.dialog-full-height {
  height: 100%;
}
.dialog-full-height .cdk-overlay-pane {
  height: 100% !important;
}
.dialog-full-height .mat-dialog-container {
  max-height: unset !important;
  overflow: hidden !important;
}

.icon-button {
  display: flex;
  justify-content: start;
  cursor: pointer;
  align-items: center;
  gap: 8px;
  padding: 4px 2px;
}
.icon-button:hover svg {
  fill: #1B1B1B;
}
.icon-button:hover svg path {
  fill: #1B1B1B;
}
.icon-button:hover p {
  color: #1B1B1B;
}
.icon-button svg {
  fill: #65707C;
}
.icon-button svg path {
  fill: #65707C;
}
.icon-button p {
  font-weight: 500;
  color: #65707C;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.mdc-switch__track {
  height: 20px !important;
  border-radius: 16px !important;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after,
.mat-mdc-slide-toggle .mdc-switch__handle::after,
.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle::before {
  background: #ffffff !important;
  width: 14px;
  height: 14px;
  border-radius: 16px;
  top: 3px !important;
  left: 4px !important;
}

.mat-mdc-slide-toggle .mdc-switch__handle::after {
  left: 2px !important;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle::before {
  display: none !important;
}

.mdc-switch__icons,
.mdc-switch__ripple,
.mdc-switch__shadow {
  display: none !important;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
  background: #1851C4 !important;
}

.not-touch-screen .mdc-form-field:hover .mdc-switch:enabled .mdc-switch__track::after {
  background: #003FAE !important;
}
.not-touch-screen .mdc-form-field:hover .mdc-switch:enabled .mdc-switch__track::before {
  background: #B8C1CC !important;
}

.mdc-form-field > label {
  padding-left: 10px !important;
}

.mdc-form-field {
  cursor: pointer !important;
}
.mdc-form-field label {
  cursor: pointer !important;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: auto;
}

@media (max-width: 767.98px) {
  .mdc-form-field:hover .mdc-switch:enabled .mdc-switch__track::after {
    background: #1851C4 !important;
  }
}
.clickable {
  cursor: pointer !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.hide {
  display: none !important;
}

.tar {
  text-align: right !important;
}

.tal {
  text-align: left !important;
}

.tac {
  text-align: center !important;
}

.h--0f05 {
  height: 0.5px !important;
}

.bc-grey-300 {
  background-color: #E2E2E2 !important;
}

.col-black {
  color: #1B1B1B !important;
}

.br--0 {
  border-radius: 0px !important;
}

.br--1 {
  border-radius: 1px !important;
}

.br--2 {
  border-radius: 2px !important;
}

.br--3 {
  border-radius: 3px !important;
}

.br--4 {
  border-radius: 4px !important;
}

.br--5 {
  border-radius: 5px !important;
}

.br--6 {
  border-radius: 6px !important;
}

.br--7 {
  border-radius: 7px !important;
}

.br--8 {
  border-radius: 8px !important;
}

.br--9 {
  border-radius: 9px !important;
}

.br--10 {
  border-radius: 10px !important;
}

.br--11 {
  border-radius: 11px !important;
}

.br--12 {
  border-radius: 12px !important;
}

.br--13 {
  border-radius: 13px !important;
}

.br--14 {
  border-radius: 14px !important;
}

.br--15 {
  border-radius: 15px !important;
}

.br--16 {
  border-radius: 16px !important;
}

.br--17 {
  border-radius: 17px !important;
}

.br--18 {
  border-radius: 18px !important;
}

.br--19 {
  border-radius: 19px !important;
}

.br--20 {
  border-radius: 20px !important;
}

.br--21 {
  border-radius: 21px !important;
}

.br--22 {
  border-radius: 22px !important;
}

.br--23 {
  border-radius: 23px !important;
}

.br--24 {
  border-radius: 24px !important;
}

.br--25 {
  border-radius: 25px !important;
}

.br--26 {
  border-radius: 26px !important;
}

.br--27 {
  border-radius: 27px !important;
}

.br--28 {
  border-radius: 28px !important;
}

.br--29 {
  border-radius: 29px !important;
}

.br--30 {
  border-radius: 30px !important;
}

.br--31 {
  border-radius: 31px !important;
}

.br--32 {
  border-radius: 32px !important;
}

.br--33 {
  border-radius: 33px !important;
}

.br--34 {
  border-radius: 34px !important;
}

.br--35 {
  border-radius: 35px !important;
}

.br--36 {
  border-radius: 36px !important;
}

.br--37 {
  border-radius: 37px !important;
}

.br--38 {
  border-radius: 38px !important;
}

.br--39 {
  border-radius: 39px !important;
}

.br--40 {
  border-radius: 40px !important;
}

.br--41 {
  border-radius: 41px !important;
}

.br--42 {
  border-radius: 42px !important;
}

.br--43 {
  border-radius: 43px !important;
}

.br--44 {
  border-radius: 44px !important;
}

.br--45 {
  border-radius: 45px !important;
}

.br--46 {
  border-radius: 46px !important;
}

.br--47 {
  border-radius: 47px !important;
}

.br--48 {
  border-radius: 48px !important;
}

.p--0 {
  padding: 0px !important;
}

.px--0 {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.py--0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.pt--0 {
  padding-top: 0px !important;
}

.pr--0 {
  padding-right: 0px !important;
}

.pb--0 {
  padding-bottom: 0px !important;
}

.pl--0 {
  padding-left: 0px !important;
}

.m--0 {
  margin: 0px !important;
}

.mx--0 {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.my--0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.mt--0 {
  margin-top: 0px !important;
}

.mr--0 {
  margin-right: 0px !important;
}

.mb--0 {
  margin-bottom: 0px !important;
}

.ml--0 {
  margin-left: 0px !important;
}

.width--0 {
  width: 0px !important;
}

.height--0 {
  height: 0px !important;
}

.gap--0 {
  gap: 0px !important;
}

.scale--0 {
  scale: 0 !important;
}

.p--1 {
  padding: 1px !important;
}

.px--1 {
  padding-right: 1px !important;
  padding-left: 1px !important;
}

.py--1 {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.pt--1 {
  padding-top: 1px !important;
}

.pr--1 {
  padding-right: 1px !important;
}

.pb--1 {
  padding-bottom: 1px !important;
}

.pl--1 {
  padding-left: 1px !important;
}

.m--1 {
  margin: 1px !important;
}

.mx--1 {
  margin-right: 1px !important;
  margin-left: 1px !important;
}

.my--1 {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.mt--1 {
  margin-top: 1px !important;
}

.mr--1 {
  margin-right: 1px !important;
}

.mb--1 {
  margin-bottom: 1px !important;
}

.ml--1 {
  margin-left: 1px !important;
}

.width--1 {
  width: 1px !important;
}

.height--1 {
  height: 1px !important;
}

.gap--1 {
  gap: 1px !important;
}

.scale--1 {
  scale: 0.01 !important;
}

.p--2 {
  padding: 2px !important;
}

.px--2 {
  padding-right: 2px !important;
  padding-left: 2px !important;
}

.py--2 {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.pt--2 {
  padding-top: 2px !important;
}

.pr--2 {
  padding-right: 2px !important;
}

.pb--2 {
  padding-bottom: 2px !important;
}

.pl--2 {
  padding-left: 2px !important;
}

.m--2 {
  margin: 2px !important;
}

.mx--2 {
  margin-right: 2px !important;
  margin-left: 2px !important;
}

.my--2 {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.mt--2 {
  margin-top: 2px !important;
}

.mr--2 {
  margin-right: 2px !important;
}

.mb--2 {
  margin-bottom: 2px !important;
}

.ml--2 {
  margin-left: 2px !important;
}

.width--2 {
  width: 2px !important;
}

.height--2 {
  height: 2px !important;
}

.gap--2 {
  gap: 2px !important;
}

.scale--2 {
  scale: 0.02 !important;
}

.p--3 {
  padding: 3px !important;
}

.px--3 {
  padding-right: 3px !important;
  padding-left: 3px !important;
}

.py--3 {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.pt--3 {
  padding-top: 3px !important;
}

.pr--3 {
  padding-right: 3px !important;
}

.pb--3 {
  padding-bottom: 3px !important;
}

.pl--3 {
  padding-left: 3px !important;
}

.m--3 {
  margin: 3px !important;
}

.mx--3 {
  margin-right: 3px !important;
  margin-left: 3px !important;
}

.my--3 {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

.mt--3 {
  margin-top: 3px !important;
}

.mr--3 {
  margin-right: 3px !important;
}

.mb--3 {
  margin-bottom: 3px !important;
}

.ml--3 {
  margin-left: 3px !important;
}

.width--3 {
  width: 3px !important;
}

.height--3 {
  height: 3px !important;
}

.gap--3 {
  gap: 3px !important;
}

.scale--3 {
  scale: 0.03 !important;
}

.p--4 {
  padding: 4px !important;
}

.px--4 {
  padding-right: 4px !important;
  padding-left: 4px !important;
}

.py--4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.pt--4 {
  padding-top: 4px !important;
}

.pr--4 {
  padding-right: 4px !important;
}

.pb--4 {
  padding-bottom: 4px !important;
}

.pl--4 {
  padding-left: 4px !important;
}

.m--4 {
  margin: 4px !important;
}

.mx--4 {
  margin-right: 4px !important;
  margin-left: 4px !important;
}

.my--4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.mt--4 {
  margin-top: 4px !important;
}

.mr--4 {
  margin-right: 4px !important;
}

.mb--4 {
  margin-bottom: 4px !important;
}

.ml--4 {
  margin-left: 4px !important;
}

.width--4 {
  width: 4px !important;
}

.height--4 {
  height: 4px !important;
}

.gap--4 {
  gap: 4px !important;
}

.scale--4 {
  scale: 0.04 !important;
}

.p--5 {
  padding: 5px !important;
}

.px--5 {
  padding-right: 5px !important;
  padding-left: 5px !important;
}

.py--5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.pt--5 {
  padding-top: 5px !important;
}

.pr--5 {
  padding-right: 5px !important;
}

.pb--5 {
  padding-bottom: 5px !important;
}

.pl--5 {
  padding-left: 5px !important;
}

.m--5 {
  margin: 5px !important;
}

.mx--5 {
  margin-right: 5px !important;
  margin-left: 5px !important;
}

.my--5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.mt--5 {
  margin-top: 5px !important;
}

.mr--5 {
  margin-right: 5px !important;
}

.mb--5 {
  margin-bottom: 5px !important;
}

.ml--5 {
  margin-left: 5px !important;
}

.width--5 {
  width: 5px !important;
}

.height--5 {
  height: 5px !important;
}

.gap--5 {
  gap: 5px !important;
}

.scale--5 {
  scale: 0.05 !important;
}

.p--6 {
  padding: 6px !important;
}

.px--6 {
  padding-right: 6px !important;
  padding-left: 6px !important;
}

.py--6 {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.pt--6 {
  padding-top: 6px !important;
}

.pr--6 {
  padding-right: 6px !important;
}

.pb--6 {
  padding-bottom: 6px !important;
}

.pl--6 {
  padding-left: 6px !important;
}

.m--6 {
  margin: 6px !important;
}

.mx--6 {
  margin-right: 6px !important;
  margin-left: 6px !important;
}

.my--6 {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.mt--6 {
  margin-top: 6px !important;
}

.mr--6 {
  margin-right: 6px !important;
}

.mb--6 {
  margin-bottom: 6px !important;
}

.ml--6 {
  margin-left: 6px !important;
}

.width--6 {
  width: 6px !important;
}

.height--6 {
  height: 6px !important;
}

.gap--6 {
  gap: 6px !important;
}

.scale--6 {
  scale: 0.06 !important;
}

.p--7 {
  padding: 7px !important;
}

.px--7 {
  padding-right: 7px !important;
  padding-left: 7px !important;
}

.py--7 {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.pt--7 {
  padding-top: 7px !important;
}

.pr--7 {
  padding-right: 7px !important;
}

.pb--7 {
  padding-bottom: 7px !important;
}

.pl--7 {
  padding-left: 7px !important;
}

.m--7 {
  margin: 7px !important;
}

.mx--7 {
  margin-right: 7px !important;
  margin-left: 7px !important;
}

.my--7 {
  margin-top: 7px !important;
  margin-bottom: 7px !important;
}

.mt--7 {
  margin-top: 7px !important;
}

.mr--7 {
  margin-right: 7px !important;
}

.mb--7 {
  margin-bottom: 7px !important;
}

.ml--7 {
  margin-left: 7px !important;
}

.width--7 {
  width: 7px !important;
}

.height--7 {
  height: 7px !important;
}

.gap--7 {
  gap: 7px !important;
}

.scale--7 {
  scale: 0.07 !important;
}

.p--8 {
  padding: 8px !important;
}

.px--8 {
  padding-right: 8px !important;
  padding-left: 8px !important;
}

.py--8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.pt--8 {
  padding-top: 8px !important;
}

.pr--8 {
  padding-right: 8px !important;
}

.pb--8 {
  padding-bottom: 8px !important;
}

.pl--8 {
  padding-left: 8px !important;
}

.m--8 {
  margin: 8px !important;
}

.mx--8 {
  margin-right: 8px !important;
  margin-left: 8px !important;
}

.my--8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.mt--8 {
  margin-top: 8px !important;
}

.mr--8 {
  margin-right: 8px !important;
}

.mb--8 {
  margin-bottom: 8px !important;
}

.ml--8 {
  margin-left: 8px !important;
}

.width--8 {
  width: 8px !important;
}

.height--8 {
  height: 8px !important;
}

.gap--8 {
  gap: 8px !important;
}

.scale--8 {
  scale: 0.08 !important;
}

.p--9 {
  padding: 9px !important;
}

.px--9 {
  padding-right: 9px !important;
  padding-left: 9px !important;
}

.py--9 {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

.pt--9 {
  padding-top: 9px !important;
}

.pr--9 {
  padding-right: 9px !important;
}

.pb--9 {
  padding-bottom: 9px !important;
}

.pl--9 {
  padding-left: 9px !important;
}

.m--9 {
  margin: 9px !important;
}

.mx--9 {
  margin-right: 9px !important;
  margin-left: 9px !important;
}

.my--9 {
  margin-top: 9px !important;
  margin-bottom: 9px !important;
}

.mt--9 {
  margin-top: 9px !important;
}

.mr--9 {
  margin-right: 9px !important;
}

.mb--9 {
  margin-bottom: 9px !important;
}

.ml--9 {
  margin-left: 9px !important;
}

.width--9 {
  width: 9px !important;
}

.height--9 {
  height: 9px !important;
}

.gap--9 {
  gap: 9px !important;
}

.scale--9 {
  scale: 0.09 !important;
}

.p--10 {
  padding: 10px !important;
}

.px--10 {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.py--10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.pt--10 {
  padding-top: 10px !important;
}

.pr--10 {
  padding-right: 10px !important;
}

.pb--10 {
  padding-bottom: 10px !important;
}

.pl--10 {
  padding-left: 10px !important;
}

.m--10 {
  margin: 10px !important;
}

.mx--10 {
  margin-right: 10px !important;
  margin-left: 10px !important;
}

.my--10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.mt--10 {
  margin-top: 10px !important;
}

.mr--10 {
  margin-right: 10px !important;
}

.mb--10 {
  margin-bottom: 10px !important;
}

.ml--10 {
  margin-left: 10px !important;
}

.width--10 {
  width: 10px !important;
}

.height--10 {
  height: 10px !important;
}

.gap--10 {
  gap: 10px !important;
}

.scale--10 {
  scale: 0.1 !important;
}

.p--11 {
  padding: 11px !important;
}

.px--11 {
  padding-right: 11px !important;
  padding-left: 11px !important;
}

.py--11 {
  padding-top: 11px !important;
  padding-bottom: 11px !important;
}

.pt--11 {
  padding-top: 11px !important;
}

.pr--11 {
  padding-right: 11px !important;
}

.pb--11 {
  padding-bottom: 11px !important;
}

.pl--11 {
  padding-left: 11px !important;
}

.m--11 {
  margin: 11px !important;
}

.mx--11 {
  margin-right: 11px !important;
  margin-left: 11px !important;
}

.my--11 {
  margin-top: 11px !important;
  margin-bottom: 11px !important;
}

.mt--11 {
  margin-top: 11px !important;
}

.mr--11 {
  margin-right: 11px !important;
}

.mb--11 {
  margin-bottom: 11px !important;
}

.ml--11 {
  margin-left: 11px !important;
}

.width--11 {
  width: 11px !important;
}

.height--11 {
  height: 11px !important;
}

.gap--11 {
  gap: 11px !important;
}

.scale--11 {
  scale: 0.11 !important;
}

.p--12 {
  padding: 12px !important;
}

.px--12 {
  padding-right: 12px !important;
  padding-left: 12px !important;
}

.py--12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.pt--12 {
  padding-top: 12px !important;
}

.pr--12 {
  padding-right: 12px !important;
}

.pb--12 {
  padding-bottom: 12px !important;
}

.pl--12 {
  padding-left: 12px !important;
}

.m--12 {
  margin: 12px !important;
}

.mx--12 {
  margin-right: 12px !important;
  margin-left: 12px !important;
}

.my--12 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.mt--12 {
  margin-top: 12px !important;
}

.mr--12 {
  margin-right: 12px !important;
}

.mb--12 {
  margin-bottom: 12px !important;
}

.ml--12 {
  margin-left: 12px !important;
}

.width--12 {
  width: 12px !important;
}

.height--12 {
  height: 12px !important;
}

.gap--12 {
  gap: 12px !important;
}

.scale--12 {
  scale: 0.12 !important;
}

.p--13 {
  padding: 13px !important;
}

.px--13 {
  padding-right: 13px !important;
  padding-left: 13px !important;
}

.py--13 {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}

.pt--13 {
  padding-top: 13px !important;
}

.pr--13 {
  padding-right: 13px !important;
}

.pb--13 {
  padding-bottom: 13px !important;
}

.pl--13 {
  padding-left: 13px !important;
}

.m--13 {
  margin: 13px !important;
}

.mx--13 {
  margin-right: 13px !important;
  margin-left: 13px !important;
}

.my--13 {
  margin-top: 13px !important;
  margin-bottom: 13px !important;
}

.mt--13 {
  margin-top: 13px !important;
}

.mr--13 {
  margin-right: 13px !important;
}

.mb--13 {
  margin-bottom: 13px !important;
}

.ml--13 {
  margin-left: 13px !important;
}

.width--13 {
  width: 13px !important;
}

.height--13 {
  height: 13px !important;
}

.gap--13 {
  gap: 13px !important;
}

.scale--13 {
  scale: 0.13 !important;
}

.p--14 {
  padding: 14px !important;
}

.px--14 {
  padding-right: 14px !important;
  padding-left: 14px !important;
}

.py--14 {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

.pt--14 {
  padding-top: 14px !important;
}

.pr--14 {
  padding-right: 14px !important;
}

.pb--14 {
  padding-bottom: 14px !important;
}

.pl--14 {
  padding-left: 14px !important;
}

.m--14 {
  margin: 14px !important;
}

.mx--14 {
  margin-right: 14px !important;
  margin-left: 14px !important;
}

.my--14 {
  margin-top: 14px !important;
  margin-bottom: 14px !important;
}

.mt--14 {
  margin-top: 14px !important;
}

.mr--14 {
  margin-right: 14px !important;
}

.mb--14 {
  margin-bottom: 14px !important;
}

.ml--14 {
  margin-left: 14px !important;
}

.width--14 {
  width: 14px !important;
}

.height--14 {
  height: 14px !important;
}

.gap--14 {
  gap: 14px !important;
}

.scale--14 {
  scale: 0.14 !important;
}

.p--15 {
  padding: 15px !important;
}

.px--15 {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.py--15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.pt--15 {
  padding-top: 15px !important;
}

.pr--15 {
  padding-right: 15px !important;
}

.pb--15 {
  padding-bottom: 15px !important;
}

.pl--15 {
  padding-left: 15px !important;
}

.m--15 {
  margin: 15px !important;
}

.mx--15 {
  margin-right: 15px !important;
  margin-left: 15px !important;
}

.my--15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.mt--15 {
  margin-top: 15px !important;
}

.mr--15 {
  margin-right: 15px !important;
}

.mb--15 {
  margin-bottom: 15px !important;
}

.ml--15 {
  margin-left: 15px !important;
}

.width--15 {
  width: 15px !important;
}

.height--15 {
  height: 15px !important;
}

.gap--15 {
  gap: 15px !important;
}

.scale--15 {
  scale: 0.15 !important;
}

.p--16 {
  padding: 16px !important;
}

.px--16 {
  padding-right: 16px !important;
  padding-left: 16px !important;
}

.py--16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.pt--16 {
  padding-top: 16px !important;
}

.pr--16 {
  padding-right: 16px !important;
}

.pb--16 {
  padding-bottom: 16px !important;
}

.pl--16 {
  padding-left: 16px !important;
}

.m--16 {
  margin: 16px !important;
}

.mx--16 {
  margin-right: 16px !important;
  margin-left: 16px !important;
}

.my--16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.mt--16 {
  margin-top: 16px !important;
}

.mr--16 {
  margin-right: 16px !important;
}

.mb--16 {
  margin-bottom: 16px !important;
}

.ml--16 {
  margin-left: 16px !important;
}

.width--16 {
  width: 16px !important;
}

.height--16 {
  height: 16px !important;
}

.gap--16 {
  gap: 16px !important;
}

.scale--16 {
  scale: 0.16 !important;
}

.p--17 {
  padding: 17px !important;
}

.px--17 {
  padding-right: 17px !important;
  padding-left: 17px !important;
}

.py--17 {
  padding-top: 17px !important;
  padding-bottom: 17px !important;
}

.pt--17 {
  padding-top: 17px !important;
}

.pr--17 {
  padding-right: 17px !important;
}

.pb--17 {
  padding-bottom: 17px !important;
}

.pl--17 {
  padding-left: 17px !important;
}

.m--17 {
  margin: 17px !important;
}

.mx--17 {
  margin-right: 17px !important;
  margin-left: 17px !important;
}

.my--17 {
  margin-top: 17px !important;
  margin-bottom: 17px !important;
}

.mt--17 {
  margin-top: 17px !important;
}

.mr--17 {
  margin-right: 17px !important;
}

.mb--17 {
  margin-bottom: 17px !important;
}

.ml--17 {
  margin-left: 17px !important;
}

.width--17 {
  width: 17px !important;
}

.height--17 {
  height: 17px !important;
}

.gap--17 {
  gap: 17px !important;
}

.scale--17 {
  scale: 0.17 !important;
}

.p--18 {
  padding: 18px !important;
}

.px--18 {
  padding-right: 18px !important;
  padding-left: 18px !important;
}

.py--18 {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
}

.pt--18 {
  padding-top: 18px !important;
}

.pr--18 {
  padding-right: 18px !important;
}

.pb--18 {
  padding-bottom: 18px !important;
}

.pl--18 {
  padding-left: 18px !important;
}

.m--18 {
  margin: 18px !important;
}

.mx--18 {
  margin-right: 18px !important;
  margin-left: 18px !important;
}

.my--18 {
  margin-top: 18px !important;
  margin-bottom: 18px !important;
}

.mt--18 {
  margin-top: 18px !important;
}

.mr--18 {
  margin-right: 18px !important;
}

.mb--18 {
  margin-bottom: 18px !important;
}

.ml--18 {
  margin-left: 18px !important;
}

.width--18 {
  width: 18px !important;
}

.height--18 {
  height: 18px !important;
}

.gap--18 {
  gap: 18px !important;
}

.scale--18 {
  scale: 0.18 !important;
}

.p--19 {
  padding: 19px !important;
}

.px--19 {
  padding-right: 19px !important;
  padding-left: 19px !important;
}

.py--19 {
  padding-top: 19px !important;
  padding-bottom: 19px !important;
}

.pt--19 {
  padding-top: 19px !important;
}

.pr--19 {
  padding-right: 19px !important;
}

.pb--19 {
  padding-bottom: 19px !important;
}

.pl--19 {
  padding-left: 19px !important;
}

.m--19 {
  margin: 19px !important;
}

.mx--19 {
  margin-right: 19px !important;
  margin-left: 19px !important;
}

.my--19 {
  margin-top: 19px !important;
  margin-bottom: 19px !important;
}

.mt--19 {
  margin-top: 19px !important;
}

.mr--19 {
  margin-right: 19px !important;
}

.mb--19 {
  margin-bottom: 19px !important;
}

.ml--19 {
  margin-left: 19px !important;
}

.width--19 {
  width: 19px !important;
}

.height--19 {
  height: 19px !important;
}

.gap--19 {
  gap: 19px !important;
}

.scale--19 {
  scale: 0.19 !important;
}

.p--20 {
  padding: 20px !important;
}

.px--20 {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.py--20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.pt--20 {
  padding-top: 20px !important;
}

.pr--20 {
  padding-right: 20px !important;
}

.pb--20 {
  padding-bottom: 20px !important;
}

.pl--20 {
  padding-left: 20px !important;
}

.m--20 {
  margin: 20px !important;
}

.mx--20 {
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.my--20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.mt--20 {
  margin-top: 20px !important;
}

.mr--20 {
  margin-right: 20px !important;
}

.mb--20 {
  margin-bottom: 20px !important;
}

.ml--20 {
  margin-left: 20px !important;
}

.width--20 {
  width: 20px !important;
}

.height--20 {
  height: 20px !important;
}

.gap--20 {
  gap: 20px !important;
}

.scale--20 {
  scale: 0.2 !important;
}

.p--21 {
  padding: 21px !important;
}

.px--21 {
  padding-right: 21px !important;
  padding-left: 21px !important;
}

.py--21 {
  padding-top: 21px !important;
  padding-bottom: 21px !important;
}

.pt--21 {
  padding-top: 21px !important;
}

.pr--21 {
  padding-right: 21px !important;
}

.pb--21 {
  padding-bottom: 21px !important;
}

.pl--21 {
  padding-left: 21px !important;
}

.m--21 {
  margin: 21px !important;
}

.mx--21 {
  margin-right: 21px !important;
  margin-left: 21px !important;
}

.my--21 {
  margin-top: 21px !important;
  margin-bottom: 21px !important;
}

.mt--21 {
  margin-top: 21px !important;
}

.mr--21 {
  margin-right: 21px !important;
}

.mb--21 {
  margin-bottom: 21px !important;
}

.ml--21 {
  margin-left: 21px !important;
}

.width--21 {
  width: 21px !important;
}

.height--21 {
  height: 21px !important;
}

.gap--21 {
  gap: 21px !important;
}

.scale--21 {
  scale: 0.21 !important;
}

.p--22 {
  padding: 22px !important;
}

.px--22 {
  padding-right: 22px !important;
  padding-left: 22px !important;
}

.py--22 {
  padding-top: 22px !important;
  padding-bottom: 22px !important;
}

.pt--22 {
  padding-top: 22px !important;
}

.pr--22 {
  padding-right: 22px !important;
}

.pb--22 {
  padding-bottom: 22px !important;
}

.pl--22 {
  padding-left: 22px !important;
}

.m--22 {
  margin: 22px !important;
}

.mx--22 {
  margin-right: 22px !important;
  margin-left: 22px !important;
}

.my--22 {
  margin-top: 22px !important;
  margin-bottom: 22px !important;
}

.mt--22 {
  margin-top: 22px !important;
}

.mr--22 {
  margin-right: 22px !important;
}

.mb--22 {
  margin-bottom: 22px !important;
}

.ml--22 {
  margin-left: 22px !important;
}

.width--22 {
  width: 22px !important;
}

.height--22 {
  height: 22px !important;
}

.gap--22 {
  gap: 22px !important;
}

.scale--22 {
  scale: 0.22 !important;
}

.p--23 {
  padding: 23px !important;
}

.px--23 {
  padding-right: 23px !important;
  padding-left: 23px !important;
}

.py--23 {
  padding-top: 23px !important;
  padding-bottom: 23px !important;
}

.pt--23 {
  padding-top: 23px !important;
}

.pr--23 {
  padding-right: 23px !important;
}

.pb--23 {
  padding-bottom: 23px !important;
}

.pl--23 {
  padding-left: 23px !important;
}

.m--23 {
  margin: 23px !important;
}

.mx--23 {
  margin-right: 23px !important;
  margin-left: 23px !important;
}

.my--23 {
  margin-top: 23px !important;
  margin-bottom: 23px !important;
}

.mt--23 {
  margin-top: 23px !important;
}

.mr--23 {
  margin-right: 23px !important;
}

.mb--23 {
  margin-bottom: 23px !important;
}

.ml--23 {
  margin-left: 23px !important;
}

.width--23 {
  width: 23px !important;
}

.height--23 {
  height: 23px !important;
}

.gap--23 {
  gap: 23px !important;
}

.scale--23 {
  scale: 0.23 !important;
}

.p--24 {
  padding: 24px !important;
}

.px--24 {
  padding-right: 24px !important;
  padding-left: 24px !important;
}

.py--24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.pt--24 {
  padding-top: 24px !important;
}

.pr--24 {
  padding-right: 24px !important;
}

.pb--24 {
  padding-bottom: 24px !important;
}

.pl--24 {
  padding-left: 24px !important;
}

.m--24 {
  margin: 24px !important;
}

.mx--24 {
  margin-right: 24px !important;
  margin-left: 24px !important;
}

.my--24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.mt--24 {
  margin-top: 24px !important;
}

.mr--24 {
  margin-right: 24px !important;
}

.mb--24 {
  margin-bottom: 24px !important;
}

.ml--24 {
  margin-left: 24px !important;
}

.width--24 {
  width: 24px !important;
}

.height--24 {
  height: 24px !important;
}

.gap--24 {
  gap: 24px !important;
}

.scale--24 {
  scale: 0.24 !important;
}

.p--25 {
  padding: 25px !important;
}

.px--25 {
  padding-right: 25px !important;
  padding-left: 25px !important;
}

.py--25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.pt--25 {
  padding-top: 25px !important;
}

.pr--25 {
  padding-right: 25px !important;
}

.pb--25 {
  padding-bottom: 25px !important;
}

.pl--25 {
  padding-left: 25px !important;
}

.m--25 {
  margin: 25px !important;
}

.mx--25 {
  margin-right: 25px !important;
  margin-left: 25px !important;
}

.my--25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.mt--25 {
  margin-top: 25px !important;
}

.mr--25 {
  margin-right: 25px !important;
}

.mb--25 {
  margin-bottom: 25px !important;
}

.ml--25 {
  margin-left: 25px !important;
}

.width--25 {
  width: 25px !important;
}

.height--25 {
  height: 25px !important;
}

.gap--25 {
  gap: 25px !important;
}

.scale--25 {
  scale: 0.25 !important;
}

.p--26 {
  padding: 26px !important;
}

.px--26 {
  padding-right: 26px !important;
  padding-left: 26px !important;
}

.py--26 {
  padding-top: 26px !important;
  padding-bottom: 26px !important;
}

.pt--26 {
  padding-top: 26px !important;
}

.pr--26 {
  padding-right: 26px !important;
}

.pb--26 {
  padding-bottom: 26px !important;
}

.pl--26 {
  padding-left: 26px !important;
}

.m--26 {
  margin: 26px !important;
}

.mx--26 {
  margin-right: 26px !important;
  margin-left: 26px !important;
}

.my--26 {
  margin-top: 26px !important;
  margin-bottom: 26px !important;
}

.mt--26 {
  margin-top: 26px !important;
}

.mr--26 {
  margin-right: 26px !important;
}

.mb--26 {
  margin-bottom: 26px !important;
}

.ml--26 {
  margin-left: 26px !important;
}

.width--26 {
  width: 26px !important;
}

.height--26 {
  height: 26px !important;
}

.gap--26 {
  gap: 26px !important;
}

.scale--26 {
  scale: 0.26 !important;
}

.p--27 {
  padding: 27px !important;
}

.px--27 {
  padding-right: 27px !important;
  padding-left: 27px !important;
}

.py--27 {
  padding-top: 27px !important;
  padding-bottom: 27px !important;
}

.pt--27 {
  padding-top: 27px !important;
}

.pr--27 {
  padding-right: 27px !important;
}

.pb--27 {
  padding-bottom: 27px !important;
}

.pl--27 {
  padding-left: 27px !important;
}

.m--27 {
  margin: 27px !important;
}

.mx--27 {
  margin-right: 27px !important;
  margin-left: 27px !important;
}

.my--27 {
  margin-top: 27px !important;
  margin-bottom: 27px !important;
}

.mt--27 {
  margin-top: 27px !important;
}

.mr--27 {
  margin-right: 27px !important;
}

.mb--27 {
  margin-bottom: 27px !important;
}

.ml--27 {
  margin-left: 27px !important;
}

.width--27 {
  width: 27px !important;
}

.height--27 {
  height: 27px !important;
}

.gap--27 {
  gap: 27px !important;
}

.scale--27 {
  scale: 0.27 !important;
}

.p--28 {
  padding: 28px !important;
}

.px--28 {
  padding-right: 28px !important;
  padding-left: 28px !important;
}

.py--28 {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}

.pt--28 {
  padding-top: 28px !important;
}

.pr--28 {
  padding-right: 28px !important;
}

.pb--28 {
  padding-bottom: 28px !important;
}

.pl--28 {
  padding-left: 28px !important;
}

.m--28 {
  margin: 28px !important;
}

.mx--28 {
  margin-right: 28px !important;
  margin-left: 28px !important;
}

.my--28 {
  margin-top: 28px !important;
  margin-bottom: 28px !important;
}

.mt--28 {
  margin-top: 28px !important;
}

.mr--28 {
  margin-right: 28px !important;
}

.mb--28 {
  margin-bottom: 28px !important;
}

.ml--28 {
  margin-left: 28px !important;
}

.width--28 {
  width: 28px !important;
}

.height--28 {
  height: 28px !important;
}

.gap--28 {
  gap: 28px !important;
}

.scale--28 {
  scale: 0.28 !important;
}

.p--29 {
  padding: 29px !important;
}

.px--29 {
  padding-right: 29px !important;
  padding-left: 29px !important;
}

.py--29 {
  padding-top: 29px !important;
  padding-bottom: 29px !important;
}

.pt--29 {
  padding-top: 29px !important;
}

.pr--29 {
  padding-right: 29px !important;
}

.pb--29 {
  padding-bottom: 29px !important;
}

.pl--29 {
  padding-left: 29px !important;
}

.m--29 {
  margin: 29px !important;
}

.mx--29 {
  margin-right: 29px !important;
  margin-left: 29px !important;
}

.my--29 {
  margin-top: 29px !important;
  margin-bottom: 29px !important;
}

.mt--29 {
  margin-top: 29px !important;
}

.mr--29 {
  margin-right: 29px !important;
}

.mb--29 {
  margin-bottom: 29px !important;
}

.ml--29 {
  margin-left: 29px !important;
}

.width--29 {
  width: 29px !important;
}

.height--29 {
  height: 29px !important;
}

.gap--29 {
  gap: 29px !important;
}

.scale--29 {
  scale: 0.29 !important;
}

.p--30 {
  padding: 30px !important;
}

.px--30 {
  padding-right: 30px !important;
  padding-left: 30px !important;
}

.py--30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.pt--30 {
  padding-top: 30px !important;
}

.pr--30 {
  padding-right: 30px !important;
}

.pb--30 {
  padding-bottom: 30px !important;
}

.pl--30 {
  padding-left: 30px !important;
}

.m--30 {
  margin: 30px !important;
}

.mx--30 {
  margin-right: 30px !important;
  margin-left: 30px !important;
}

.my--30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.mt--30 {
  margin-top: 30px !important;
}

.mr--30 {
  margin-right: 30px !important;
}

.mb--30 {
  margin-bottom: 30px !important;
}

.ml--30 {
  margin-left: 30px !important;
}

.width--30 {
  width: 30px !important;
}

.height--30 {
  height: 30px !important;
}

.gap--30 {
  gap: 30px !important;
}

.scale--30 {
  scale: 0.3 !important;
}

.p--31 {
  padding: 31px !important;
}

.px--31 {
  padding-right: 31px !important;
  padding-left: 31px !important;
}

.py--31 {
  padding-top: 31px !important;
  padding-bottom: 31px !important;
}

.pt--31 {
  padding-top: 31px !important;
}

.pr--31 {
  padding-right: 31px !important;
}

.pb--31 {
  padding-bottom: 31px !important;
}

.pl--31 {
  padding-left: 31px !important;
}

.m--31 {
  margin: 31px !important;
}

.mx--31 {
  margin-right: 31px !important;
  margin-left: 31px !important;
}

.my--31 {
  margin-top: 31px !important;
  margin-bottom: 31px !important;
}

.mt--31 {
  margin-top: 31px !important;
}

.mr--31 {
  margin-right: 31px !important;
}

.mb--31 {
  margin-bottom: 31px !important;
}

.ml--31 {
  margin-left: 31px !important;
}

.width--31 {
  width: 31px !important;
}

.height--31 {
  height: 31px !important;
}

.gap--31 {
  gap: 31px !important;
}

.scale--31 {
  scale: 0.31 !important;
}

.p--32 {
  padding: 32px !important;
}

.px--32 {
  padding-right: 32px !important;
  padding-left: 32px !important;
}

.py--32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.pt--32 {
  padding-top: 32px !important;
}

.pr--32 {
  padding-right: 32px !important;
}

.pb--32 {
  padding-bottom: 32px !important;
}

.pl--32 {
  padding-left: 32px !important;
}

.m--32 {
  margin: 32px !important;
}

.mx--32 {
  margin-right: 32px !important;
  margin-left: 32px !important;
}

.my--32 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.mt--32 {
  margin-top: 32px !important;
}

.mr--32 {
  margin-right: 32px !important;
}

.mb--32 {
  margin-bottom: 32px !important;
}

.ml--32 {
  margin-left: 32px !important;
}

.width--32 {
  width: 32px !important;
}

.height--32 {
  height: 32px !important;
}

.gap--32 {
  gap: 32px !important;
}

.scale--32 {
  scale: 0.32 !important;
}

.p--33 {
  padding: 33px !important;
}

.px--33 {
  padding-right: 33px !important;
  padding-left: 33px !important;
}

.py--33 {
  padding-top: 33px !important;
  padding-bottom: 33px !important;
}

.pt--33 {
  padding-top: 33px !important;
}

.pr--33 {
  padding-right: 33px !important;
}

.pb--33 {
  padding-bottom: 33px !important;
}

.pl--33 {
  padding-left: 33px !important;
}

.m--33 {
  margin: 33px !important;
}

.mx--33 {
  margin-right: 33px !important;
  margin-left: 33px !important;
}

.my--33 {
  margin-top: 33px !important;
  margin-bottom: 33px !important;
}

.mt--33 {
  margin-top: 33px !important;
}

.mr--33 {
  margin-right: 33px !important;
}

.mb--33 {
  margin-bottom: 33px !important;
}

.ml--33 {
  margin-left: 33px !important;
}

.width--33 {
  width: 33px !important;
}

.height--33 {
  height: 33px !important;
}

.gap--33 {
  gap: 33px !important;
}

.scale--33 {
  scale: 0.33 !important;
}

.p--34 {
  padding: 34px !important;
}

.px--34 {
  padding-right: 34px !important;
  padding-left: 34px !important;
}

.py--34 {
  padding-top: 34px !important;
  padding-bottom: 34px !important;
}

.pt--34 {
  padding-top: 34px !important;
}

.pr--34 {
  padding-right: 34px !important;
}

.pb--34 {
  padding-bottom: 34px !important;
}

.pl--34 {
  padding-left: 34px !important;
}

.m--34 {
  margin: 34px !important;
}

.mx--34 {
  margin-right: 34px !important;
  margin-left: 34px !important;
}

.my--34 {
  margin-top: 34px !important;
  margin-bottom: 34px !important;
}

.mt--34 {
  margin-top: 34px !important;
}

.mr--34 {
  margin-right: 34px !important;
}

.mb--34 {
  margin-bottom: 34px !important;
}

.ml--34 {
  margin-left: 34px !important;
}

.width--34 {
  width: 34px !important;
}

.height--34 {
  height: 34px !important;
}

.gap--34 {
  gap: 34px !important;
}

.scale--34 {
  scale: 0.34 !important;
}

.p--35 {
  padding: 35px !important;
}

.px--35 {
  padding-right: 35px !important;
  padding-left: 35px !important;
}

.py--35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.pt--35 {
  padding-top: 35px !important;
}

.pr--35 {
  padding-right: 35px !important;
}

.pb--35 {
  padding-bottom: 35px !important;
}

.pl--35 {
  padding-left: 35px !important;
}

.m--35 {
  margin: 35px !important;
}

.mx--35 {
  margin-right: 35px !important;
  margin-left: 35px !important;
}

.my--35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.mt--35 {
  margin-top: 35px !important;
}

.mr--35 {
  margin-right: 35px !important;
}

.mb--35 {
  margin-bottom: 35px !important;
}

.ml--35 {
  margin-left: 35px !important;
}

.width--35 {
  width: 35px !important;
}

.height--35 {
  height: 35px !important;
}

.gap--35 {
  gap: 35px !important;
}

.scale--35 {
  scale: 0.35 !important;
}

.p--36 {
  padding: 36px !important;
}

.px--36 {
  padding-right: 36px !important;
  padding-left: 36px !important;
}

.py--36 {
  padding-top: 36px !important;
  padding-bottom: 36px !important;
}

.pt--36 {
  padding-top: 36px !important;
}

.pr--36 {
  padding-right: 36px !important;
}

.pb--36 {
  padding-bottom: 36px !important;
}

.pl--36 {
  padding-left: 36px !important;
}

.m--36 {
  margin: 36px !important;
}

.mx--36 {
  margin-right: 36px !important;
  margin-left: 36px !important;
}

.my--36 {
  margin-top: 36px !important;
  margin-bottom: 36px !important;
}

.mt--36 {
  margin-top: 36px !important;
}

.mr--36 {
  margin-right: 36px !important;
}

.mb--36 {
  margin-bottom: 36px !important;
}

.ml--36 {
  margin-left: 36px !important;
}

.width--36 {
  width: 36px !important;
}

.height--36 {
  height: 36px !important;
}

.gap--36 {
  gap: 36px !important;
}

.scale--36 {
  scale: 0.36 !important;
}

.p--37 {
  padding: 37px !important;
}

.px--37 {
  padding-right: 37px !important;
  padding-left: 37px !important;
}

.py--37 {
  padding-top: 37px !important;
  padding-bottom: 37px !important;
}

.pt--37 {
  padding-top: 37px !important;
}

.pr--37 {
  padding-right: 37px !important;
}

.pb--37 {
  padding-bottom: 37px !important;
}

.pl--37 {
  padding-left: 37px !important;
}

.m--37 {
  margin: 37px !important;
}

.mx--37 {
  margin-right: 37px !important;
  margin-left: 37px !important;
}

.my--37 {
  margin-top: 37px !important;
  margin-bottom: 37px !important;
}

.mt--37 {
  margin-top: 37px !important;
}

.mr--37 {
  margin-right: 37px !important;
}

.mb--37 {
  margin-bottom: 37px !important;
}

.ml--37 {
  margin-left: 37px !important;
}

.width--37 {
  width: 37px !important;
}

.height--37 {
  height: 37px !important;
}

.gap--37 {
  gap: 37px !important;
}

.scale--37 {
  scale: 0.37 !important;
}

.p--38 {
  padding: 38px !important;
}

.px--38 {
  padding-right: 38px !important;
  padding-left: 38px !important;
}

.py--38 {
  padding-top: 38px !important;
  padding-bottom: 38px !important;
}

.pt--38 {
  padding-top: 38px !important;
}

.pr--38 {
  padding-right: 38px !important;
}

.pb--38 {
  padding-bottom: 38px !important;
}

.pl--38 {
  padding-left: 38px !important;
}

.m--38 {
  margin: 38px !important;
}

.mx--38 {
  margin-right: 38px !important;
  margin-left: 38px !important;
}

.my--38 {
  margin-top: 38px !important;
  margin-bottom: 38px !important;
}

.mt--38 {
  margin-top: 38px !important;
}

.mr--38 {
  margin-right: 38px !important;
}

.mb--38 {
  margin-bottom: 38px !important;
}

.ml--38 {
  margin-left: 38px !important;
}

.width--38 {
  width: 38px !important;
}

.height--38 {
  height: 38px !important;
}

.gap--38 {
  gap: 38px !important;
}

.scale--38 {
  scale: 0.38 !important;
}

.p--39 {
  padding: 39px !important;
}

.px--39 {
  padding-right: 39px !important;
  padding-left: 39px !important;
}

.py--39 {
  padding-top: 39px !important;
  padding-bottom: 39px !important;
}

.pt--39 {
  padding-top: 39px !important;
}

.pr--39 {
  padding-right: 39px !important;
}

.pb--39 {
  padding-bottom: 39px !important;
}

.pl--39 {
  padding-left: 39px !important;
}

.m--39 {
  margin: 39px !important;
}

.mx--39 {
  margin-right: 39px !important;
  margin-left: 39px !important;
}

.my--39 {
  margin-top: 39px !important;
  margin-bottom: 39px !important;
}

.mt--39 {
  margin-top: 39px !important;
}

.mr--39 {
  margin-right: 39px !important;
}

.mb--39 {
  margin-bottom: 39px !important;
}

.ml--39 {
  margin-left: 39px !important;
}

.width--39 {
  width: 39px !important;
}

.height--39 {
  height: 39px !important;
}

.gap--39 {
  gap: 39px !important;
}

.scale--39 {
  scale: 0.39 !important;
}

.p--40 {
  padding: 40px !important;
}

.px--40 {
  padding-right: 40px !important;
  padding-left: 40px !important;
}

.py--40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.pt--40 {
  padding-top: 40px !important;
}

.pr--40 {
  padding-right: 40px !important;
}

.pb--40 {
  padding-bottom: 40px !important;
}

.pl--40 {
  padding-left: 40px !important;
}

.m--40 {
  margin: 40px !important;
}

.mx--40 {
  margin-right: 40px !important;
  margin-left: 40px !important;
}

.my--40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.mt--40 {
  margin-top: 40px !important;
}

.mr--40 {
  margin-right: 40px !important;
}

.mb--40 {
  margin-bottom: 40px !important;
}

.ml--40 {
  margin-left: 40px !important;
}

.width--40 {
  width: 40px !important;
}

.height--40 {
  height: 40px !important;
}

.gap--40 {
  gap: 40px !important;
}

.scale--40 {
  scale: 0.4 !important;
}

.p--41 {
  padding: 41px !important;
}

.px--41 {
  padding-right: 41px !important;
  padding-left: 41px !important;
}

.py--41 {
  padding-top: 41px !important;
  padding-bottom: 41px !important;
}

.pt--41 {
  padding-top: 41px !important;
}

.pr--41 {
  padding-right: 41px !important;
}

.pb--41 {
  padding-bottom: 41px !important;
}

.pl--41 {
  padding-left: 41px !important;
}

.m--41 {
  margin: 41px !important;
}

.mx--41 {
  margin-right: 41px !important;
  margin-left: 41px !important;
}

.my--41 {
  margin-top: 41px !important;
  margin-bottom: 41px !important;
}

.mt--41 {
  margin-top: 41px !important;
}

.mr--41 {
  margin-right: 41px !important;
}

.mb--41 {
  margin-bottom: 41px !important;
}

.ml--41 {
  margin-left: 41px !important;
}

.width--41 {
  width: 41px !important;
}

.height--41 {
  height: 41px !important;
}

.gap--41 {
  gap: 41px !important;
}

.scale--41 {
  scale: 0.41 !important;
}

.p--42 {
  padding: 42px !important;
}

.px--42 {
  padding-right: 42px !important;
  padding-left: 42px !important;
}

.py--42 {
  padding-top: 42px !important;
  padding-bottom: 42px !important;
}

.pt--42 {
  padding-top: 42px !important;
}

.pr--42 {
  padding-right: 42px !important;
}

.pb--42 {
  padding-bottom: 42px !important;
}

.pl--42 {
  padding-left: 42px !important;
}

.m--42 {
  margin: 42px !important;
}

.mx--42 {
  margin-right: 42px !important;
  margin-left: 42px !important;
}

.my--42 {
  margin-top: 42px !important;
  margin-bottom: 42px !important;
}

.mt--42 {
  margin-top: 42px !important;
}

.mr--42 {
  margin-right: 42px !important;
}

.mb--42 {
  margin-bottom: 42px !important;
}

.ml--42 {
  margin-left: 42px !important;
}

.width--42 {
  width: 42px !important;
}

.height--42 {
  height: 42px !important;
}

.gap--42 {
  gap: 42px !important;
}

.scale--42 {
  scale: 0.42 !important;
}

.p--43 {
  padding: 43px !important;
}

.px--43 {
  padding-right: 43px !important;
  padding-left: 43px !important;
}

.py--43 {
  padding-top: 43px !important;
  padding-bottom: 43px !important;
}

.pt--43 {
  padding-top: 43px !important;
}

.pr--43 {
  padding-right: 43px !important;
}

.pb--43 {
  padding-bottom: 43px !important;
}

.pl--43 {
  padding-left: 43px !important;
}

.m--43 {
  margin: 43px !important;
}

.mx--43 {
  margin-right: 43px !important;
  margin-left: 43px !important;
}

.my--43 {
  margin-top: 43px !important;
  margin-bottom: 43px !important;
}

.mt--43 {
  margin-top: 43px !important;
}

.mr--43 {
  margin-right: 43px !important;
}

.mb--43 {
  margin-bottom: 43px !important;
}

.ml--43 {
  margin-left: 43px !important;
}

.width--43 {
  width: 43px !important;
}

.height--43 {
  height: 43px !important;
}

.gap--43 {
  gap: 43px !important;
}

.scale--43 {
  scale: 0.43 !important;
}

.p--44 {
  padding: 44px !important;
}

.px--44 {
  padding-right: 44px !important;
  padding-left: 44px !important;
}

.py--44 {
  padding-top: 44px !important;
  padding-bottom: 44px !important;
}

.pt--44 {
  padding-top: 44px !important;
}

.pr--44 {
  padding-right: 44px !important;
}

.pb--44 {
  padding-bottom: 44px !important;
}

.pl--44 {
  padding-left: 44px !important;
}

.m--44 {
  margin: 44px !important;
}

.mx--44 {
  margin-right: 44px !important;
  margin-left: 44px !important;
}

.my--44 {
  margin-top: 44px !important;
  margin-bottom: 44px !important;
}

.mt--44 {
  margin-top: 44px !important;
}

.mr--44 {
  margin-right: 44px !important;
}

.mb--44 {
  margin-bottom: 44px !important;
}

.ml--44 {
  margin-left: 44px !important;
}

.width--44 {
  width: 44px !important;
}

.height--44 {
  height: 44px !important;
}

.gap--44 {
  gap: 44px !important;
}

.scale--44 {
  scale: 0.44 !important;
}

.p--45 {
  padding: 45px !important;
}

.px--45 {
  padding-right: 45px !important;
  padding-left: 45px !important;
}

.py--45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

.pt--45 {
  padding-top: 45px !important;
}

.pr--45 {
  padding-right: 45px !important;
}

.pb--45 {
  padding-bottom: 45px !important;
}

.pl--45 {
  padding-left: 45px !important;
}

.m--45 {
  margin: 45px !important;
}

.mx--45 {
  margin-right: 45px !important;
  margin-left: 45px !important;
}

.my--45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

.mt--45 {
  margin-top: 45px !important;
}

.mr--45 {
  margin-right: 45px !important;
}

.mb--45 {
  margin-bottom: 45px !important;
}

.ml--45 {
  margin-left: 45px !important;
}

.width--45 {
  width: 45px !important;
}

.height--45 {
  height: 45px !important;
}

.gap--45 {
  gap: 45px !important;
}

.scale--45 {
  scale: 0.45 !important;
}

.p--46 {
  padding: 46px !important;
}

.px--46 {
  padding-right: 46px !important;
  padding-left: 46px !important;
}

.py--46 {
  padding-top: 46px !important;
  padding-bottom: 46px !important;
}

.pt--46 {
  padding-top: 46px !important;
}

.pr--46 {
  padding-right: 46px !important;
}

.pb--46 {
  padding-bottom: 46px !important;
}

.pl--46 {
  padding-left: 46px !important;
}

.m--46 {
  margin: 46px !important;
}

.mx--46 {
  margin-right: 46px !important;
  margin-left: 46px !important;
}

.my--46 {
  margin-top: 46px !important;
  margin-bottom: 46px !important;
}

.mt--46 {
  margin-top: 46px !important;
}

.mr--46 {
  margin-right: 46px !important;
}

.mb--46 {
  margin-bottom: 46px !important;
}

.ml--46 {
  margin-left: 46px !important;
}

.width--46 {
  width: 46px !important;
}

.height--46 {
  height: 46px !important;
}

.gap--46 {
  gap: 46px !important;
}

.scale--46 {
  scale: 0.46 !important;
}

.p--47 {
  padding: 47px !important;
}

.px--47 {
  padding-right: 47px !important;
  padding-left: 47px !important;
}

.py--47 {
  padding-top: 47px !important;
  padding-bottom: 47px !important;
}

.pt--47 {
  padding-top: 47px !important;
}

.pr--47 {
  padding-right: 47px !important;
}

.pb--47 {
  padding-bottom: 47px !important;
}

.pl--47 {
  padding-left: 47px !important;
}

.m--47 {
  margin: 47px !important;
}

.mx--47 {
  margin-right: 47px !important;
  margin-left: 47px !important;
}

.my--47 {
  margin-top: 47px !important;
  margin-bottom: 47px !important;
}

.mt--47 {
  margin-top: 47px !important;
}

.mr--47 {
  margin-right: 47px !important;
}

.mb--47 {
  margin-bottom: 47px !important;
}

.ml--47 {
  margin-left: 47px !important;
}

.width--47 {
  width: 47px !important;
}

.height--47 {
  height: 47px !important;
}

.gap--47 {
  gap: 47px !important;
}

.scale--47 {
  scale: 0.47 !important;
}

.p--48 {
  padding: 48px !important;
}

.px--48 {
  padding-right: 48px !important;
  padding-left: 48px !important;
}

.py--48 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.pt--48 {
  padding-top: 48px !important;
}

.pr--48 {
  padding-right: 48px !important;
}

.pb--48 {
  padding-bottom: 48px !important;
}

.pl--48 {
  padding-left: 48px !important;
}

.m--48 {
  margin: 48px !important;
}

.mx--48 {
  margin-right: 48px !important;
  margin-left: 48px !important;
}

.my--48 {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}

.mt--48 {
  margin-top: 48px !important;
}

.mr--48 {
  margin-right: 48px !important;
}

.mb--48 {
  margin-bottom: 48px !important;
}

.ml--48 {
  margin-left: 48px !important;
}

.width--48 {
  width: 48px !important;
}

.height--48 {
  height: 48px !important;
}

.gap--48 {
  gap: 48px !important;
}

.scale--48 {
  scale: 0.48 !important;
}

.p--49 {
  padding: 49px !important;
}

.px--49 {
  padding-right: 49px !important;
  padding-left: 49px !important;
}

.py--49 {
  padding-top: 49px !important;
  padding-bottom: 49px !important;
}

.pt--49 {
  padding-top: 49px !important;
}

.pr--49 {
  padding-right: 49px !important;
}

.pb--49 {
  padding-bottom: 49px !important;
}

.pl--49 {
  padding-left: 49px !important;
}

.m--49 {
  margin: 49px !important;
}

.mx--49 {
  margin-right: 49px !important;
  margin-left: 49px !important;
}

.my--49 {
  margin-top: 49px !important;
  margin-bottom: 49px !important;
}

.mt--49 {
  margin-top: 49px !important;
}

.mr--49 {
  margin-right: 49px !important;
}

.mb--49 {
  margin-bottom: 49px !important;
}

.ml--49 {
  margin-left: 49px !important;
}

.width--49 {
  width: 49px !important;
}

.height--49 {
  height: 49px !important;
}

.gap--49 {
  gap: 49px !important;
}

.scale--49 {
  scale: 0.49 !important;
}

.p--50 {
  padding: 50px !important;
}

.px--50 {
  padding-right: 50px !important;
  padding-left: 50px !important;
}

.py--50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.pt--50 {
  padding-top: 50px !important;
}

.pr--50 {
  padding-right: 50px !important;
}

.pb--50 {
  padding-bottom: 50px !important;
}

.pl--50 {
  padding-left: 50px !important;
}

.m--50 {
  margin: 50px !important;
}

.mx--50 {
  margin-right: 50px !important;
  margin-left: 50px !important;
}

.my--50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}

.mt--50 {
  margin-top: 50px !important;
}

.mr--50 {
  margin-right: 50px !important;
}

.mb--50 {
  margin-bottom: 50px !important;
}

.ml--50 {
  margin-left: 50px !important;
}

.width--50 {
  width: 50px !important;
}

.height--50 {
  height: 50px !important;
}

.gap--50 {
  gap: 50px !important;
}

.scale--50 {
  scale: 0.5 !important;
}

.p--51 {
  padding: 51px !important;
}

.px--51 {
  padding-right: 51px !important;
  padding-left: 51px !important;
}

.py--51 {
  padding-top: 51px !important;
  padding-bottom: 51px !important;
}

.pt--51 {
  padding-top: 51px !important;
}

.pr--51 {
  padding-right: 51px !important;
}

.pb--51 {
  padding-bottom: 51px !important;
}

.pl--51 {
  padding-left: 51px !important;
}

.m--51 {
  margin: 51px !important;
}

.mx--51 {
  margin-right: 51px !important;
  margin-left: 51px !important;
}

.my--51 {
  margin-top: 51px !important;
  margin-bottom: 51px !important;
}

.mt--51 {
  margin-top: 51px !important;
}

.mr--51 {
  margin-right: 51px !important;
}

.mb--51 {
  margin-bottom: 51px !important;
}

.ml--51 {
  margin-left: 51px !important;
}

.width--51 {
  width: 51px !important;
}

.height--51 {
  height: 51px !important;
}

.gap--51 {
  gap: 51px !important;
}

.scale--51 {
  scale: 0.51 !important;
}

.p--52 {
  padding: 52px !important;
}

.px--52 {
  padding-right: 52px !important;
  padding-left: 52px !important;
}

.py--52 {
  padding-top: 52px !important;
  padding-bottom: 52px !important;
}

.pt--52 {
  padding-top: 52px !important;
}

.pr--52 {
  padding-right: 52px !important;
}

.pb--52 {
  padding-bottom: 52px !important;
}

.pl--52 {
  padding-left: 52px !important;
}

.m--52 {
  margin: 52px !important;
}

.mx--52 {
  margin-right: 52px !important;
  margin-left: 52px !important;
}

.my--52 {
  margin-top: 52px !important;
  margin-bottom: 52px !important;
}

.mt--52 {
  margin-top: 52px !important;
}

.mr--52 {
  margin-right: 52px !important;
}

.mb--52 {
  margin-bottom: 52px !important;
}

.ml--52 {
  margin-left: 52px !important;
}

.width--52 {
  width: 52px !important;
}

.height--52 {
  height: 52px !important;
}

.gap--52 {
  gap: 52px !important;
}

.scale--52 {
  scale: 0.52 !important;
}

.p--53 {
  padding: 53px !important;
}

.px--53 {
  padding-right: 53px !important;
  padding-left: 53px !important;
}

.py--53 {
  padding-top: 53px !important;
  padding-bottom: 53px !important;
}

.pt--53 {
  padding-top: 53px !important;
}

.pr--53 {
  padding-right: 53px !important;
}

.pb--53 {
  padding-bottom: 53px !important;
}

.pl--53 {
  padding-left: 53px !important;
}

.m--53 {
  margin: 53px !important;
}

.mx--53 {
  margin-right: 53px !important;
  margin-left: 53px !important;
}

.my--53 {
  margin-top: 53px !important;
  margin-bottom: 53px !important;
}

.mt--53 {
  margin-top: 53px !important;
}

.mr--53 {
  margin-right: 53px !important;
}

.mb--53 {
  margin-bottom: 53px !important;
}

.ml--53 {
  margin-left: 53px !important;
}

.width--53 {
  width: 53px !important;
}

.height--53 {
  height: 53px !important;
}

.gap--53 {
  gap: 53px !important;
}

.scale--53 {
  scale: 0.53 !important;
}

.p--54 {
  padding: 54px !important;
}

.px--54 {
  padding-right: 54px !important;
  padding-left: 54px !important;
}

.py--54 {
  padding-top: 54px !important;
  padding-bottom: 54px !important;
}

.pt--54 {
  padding-top: 54px !important;
}

.pr--54 {
  padding-right: 54px !important;
}

.pb--54 {
  padding-bottom: 54px !important;
}

.pl--54 {
  padding-left: 54px !important;
}

.m--54 {
  margin: 54px !important;
}

.mx--54 {
  margin-right: 54px !important;
  margin-left: 54px !important;
}

.my--54 {
  margin-top: 54px !important;
  margin-bottom: 54px !important;
}

.mt--54 {
  margin-top: 54px !important;
}

.mr--54 {
  margin-right: 54px !important;
}

.mb--54 {
  margin-bottom: 54px !important;
}

.ml--54 {
  margin-left: 54px !important;
}

.width--54 {
  width: 54px !important;
}

.height--54 {
  height: 54px !important;
}

.gap--54 {
  gap: 54px !important;
}

.scale--54 {
  scale: 0.54 !important;
}

.p--55 {
  padding: 55px !important;
}

.px--55 {
  padding-right: 55px !important;
  padding-left: 55px !important;
}

.py--55 {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
}

.pt--55 {
  padding-top: 55px !important;
}

.pr--55 {
  padding-right: 55px !important;
}

.pb--55 {
  padding-bottom: 55px !important;
}

.pl--55 {
  padding-left: 55px !important;
}

.m--55 {
  margin: 55px !important;
}

.mx--55 {
  margin-right: 55px !important;
  margin-left: 55px !important;
}

.my--55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important;
}

.mt--55 {
  margin-top: 55px !important;
}

.mr--55 {
  margin-right: 55px !important;
}

.mb--55 {
  margin-bottom: 55px !important;
}

.ml--55 {
  margin-left: 55px !important;
}

.width--55 {
  width: 55px !important;
}

.height--55 {
  height: 55px !important;
}

.gap--55 {
  gap: 55px !important;
}

.scale--55 {
  scale: 0.55 !important;
}

.p--56 {
  padding: 56px !important;
}

.px--56 {
  padding-right: 56px !important;
  padding-left: 56px !important;
}

.py--56 {
  padding-top: 56px !important;
  padding-bottom: 56px !important;
}

.pt--56 {
  padding-top: 56px !important;
}

.pr--56 {
  padding-right: 56px !important;
}

.pb--56 {
  padding-bottom: 56px !important;
}

.pl--56 {
  padding-left: 56px !important;
}

.m--56 {
  margin: 56px !important;
}

.mx--56 {
  margin-right: 56px !important;
  margin-left: 56px !important;
}

.my--56 {
  margin-top: 56px !important;
  margin-bottom: 56px !important;
}

.mt--56 {
  margin-top: 56px !important;
}

.mr--56 {
  margin-right: 56px !important;
}

.mb--56 {
  margin-bottom: 56px !important;
}

.ml--56 {
  margin-left: 56px !important;
}

.width--56 {
  width: 56px !important;
}

.height--56 {
  height: 56px !important;
}

.gap--56 {
  gap: 56px !important;
}

.scale--56 {
  scale: 0.56 !important;
}

.p--57 {
  padding: 57px !important;
}

.px--57 {
  padding-right: 57px !important;
  padding-left: 57px !important;
}

.py--57 {
  padding-top: 57px !important;
  padding-bottom: 57px !important;
}

.pt--57 {
  padding-top: 57px !important;
}

.pr--57 {
  padding-right: 57px !important;
}

.pb--57 {
  padding-bottom: 57px !important;
}

.pl--57 {
  padding-left: 57px !important;
}

.m--57 {
  margin: 57px !important;
}

.mx--57 {
  margin-right: 57px !important;
  margin-left: 57px !important;
}

.my--57 {
  margin-top: 57px !important;
  margin-bottom: 57px !important;
}

.mt--57 {
  margin-top: 57px !important;
}

.mr--57 {
  margin-right: 57px !important;
}

.mb--57 {
  margin-bottom: 57px !important;
}

.ml--57 {
  margin-left: 57px !important;
}

.width--57 {
  width: 57px !important;
}

.height--57 {
  height: 57px !important;
}

.gap--57 {
  gap: 57px !important;
}

.scale--57 {
  scale: 0.57 !important;
}

.p--58 {
  padding: 58px !important;
}

.px--58 {
  padding-right: 58px !important;
  padding-left: 58px !important;
}

.py--58 {
  padding-top: 58px !important;
  padding-bottom: 58px !important;
}

.pt--58 {
  padding-top: 58px !important;
}

.pr--58 {
  padding-right: 58px !important;
}

.pb--58 {
  padding-bottom: 58px !important;
}

.pl--58 {
  padding-left: 58px !important;
}

.m--58 {
  margin: 58px !important;
}

.mx--58 {
  margin-right: 58px !important;
  margin-left: 58px !important;
}

.my--58 {
  margin-top: 58px !important;
  margin-bottom: 58px !important;
}

.mt--58 {
  margin-top: 58px !important;
}

.mr--58 {
  margin-right: 58px !important;
}

.mb--58 {
  margin-bottom: 58px !important;
}

.ml--58 {
  margin-left: 58px !important;
}

.width--58 {
  width: 58px !important;
}

.height--58 {
  height: 58px !important;
}

.gap--58 {
  gap: 58px !important;
}

.scale--58 {
  scale: 0.58 !important;
}

.p--59 {
  padding: 59px !important;
}

.px--59 {
  padding-right: 59px !important;
  padding-left: 59px !important;
}

.py--59 {
  padding-top: 59px !important;
  padding-bottom: 59px !important;
}

.pt--59 {
  padding-top: 59px !important;
}

.pr--59 {
  padding-right: 59px !important;
}

.pb--59 {
  padding-bottom: 59px !important;
}

.pl--59 {
  padding-left: 59px !important;
}

.m--59 {
  margin: 59px !important;
}

.mx--59 {
  margin-right: 59px !important;
  margin-left: 59px !important;
}

.my--59 {
  margin-top: 59px !important;
  margin-bottom: 59px !important;
}

.mt--59 {
  margin-top: 59px !important;
}

.mr--59 {
  margin-right: 59px !important;
}

.mb--59 {
  margin-bottom: 59px !important;
}

.ml--59 {
  margin-left: 59px !important;
}

.width--59 {
  width: 59px !important;
}

.height--59 {
  height: 59px !important;
}

.gap--59 {
  gap: 59px !important;
}

.scale--59 {
  scale: 0.59 !important;
}

.p--60 {
  padding: 60px !important;
}

.px--60 {
  padding-right: 60px !important;
  padding-left: 60px !important;
}

.py--60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}

.pt--60 {
  padding-top: 60px !important;
}

.pr--60 {
  padding-right: 60px !important;
}

.pb--60 {
  padding-bottom: 60px !important;
}

.pl--60 {
  padding-left: 60px !important;
}

.m--60 {
  margin: 60px !important;
}

.mx--60 {
  margin-right: 60px !important;
  margin-left: 60px !important;
}

.my--60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}

.mt--60 {
  margin-top: 60px !important;
}

.mr--60 {
  margin-right: 60px !important;
}

.mb--60 {
  margin-bottom: 60px !important;
}

.ml--60 {
  margin-left: 60px !important;
}

.width--60 {
  width: 60px !important;
}

.height--60 {
  height: 60px !important;
}

.gap--60 {
  gap: 60px !important;
}

.scale--60 {
  scale: 0.6 !important;
}

.p--61 {
  padding: 61px !important;
}

.px--61 {
  padding-right: 61px !important;
  padding-left: 61px !important;
}

.py--61 {
  padding-top: 61px !important;
  padding-bottom: 61px !important;
}

.pt--61 {
  padding-top: 61px !important;
}

.pr--61 {
  padding-right: 61px !important;
}

.pb--61 {
  padding-bottom: 61px !important;
}

.pl--61 {
  padding-left: 61px !important;
}

.m--61 {
  margin: 61px !important;
}

.mx--61 {
  margin-right: 61px !important;
  margin-left: 61px !important;
}

.my--61 {
  margin-top: 61px !important;
  margin-bottom: 61px !important;
}

.mt--61 {
  margin-top: 61px !important;
}

.mr--61 {
  margin-right: 61px !important;
}

.mb--61 {
  margin-bottom: 61px !important;
}

.ml--61 {
  margin-left: 61px !important;
}

.width--61 {
  width: 61px !important;
}

.height--61 {
  height: 61px !important;
}

.gap--61 {
  gap: 61px !important;
}

.scale--61 {
  scale: 0.61 !important;
}

.p--62 {
  padding: 62px !important;
}

.px--62 {
  padding-right: 62px !important;
  padding-left: 62px !important;
}

.py--62 {
  padding-top: 62px !important;
  padding-bottom: 62px !important;
}

.pt--62 {
  padding-top: 62px !important;
}

.pr--62 {
  padding-right: 62px !important;
}

.pb--62 {
  padding-bottom: 62px !important;
}

.pl--62 {
  padding-left: 62px !important;
}

.m--62 {
  margin: 62px !important;
}

.mx--62 {
  margin-right: 62px !important;
  margin-left: 62px !important;
}

.my--62 {
  margin-top: 62px !important;
  margin-bottom: 62px !important;
}

.mt--62 {
  margin-top: 62px !important;
}

.mr--62 {
  margin-right: 62px !important;
}

.mb--62 {
  margin-bottom: 62px !important;
}

.ml--62 {
  margin-left: 62px !important;
}

.width--62 {
  width: 62px !important;
}

.height--62 {
  height: 62px !important;
}

.gap--62 {
  gap: 62px !important;
}

.scale--62 {
  scale: 0.62 !important;
}

.p--63 {
  padding: 63px !important;
}

.px--63 {
  padding-right: 63px !important;
  padding-left: 63px !important;
}

.py--63 {
  padding-top: 63px !important;
  padding-bottom: 63px !important;
}

.pt--63 {
  padding-top: 63px !important;
}

.pr--63 {
  padding-right: 63px !important;
}

.pb--63 {
  padding-bottom: 63px !important;
}

.pl--63 {
  padding-left: 63px !important;
}

.m--63 {
  margin: 63px !important;
}

.mx--63 {
  margin-right: 63px !important;
  margin-left: 63px !important;
}

.my--63 {
  margin-top: 63px !important;
  margin-bottom: 63px !important;
}

.mt--63 {
  margin-top: 63px !important;
}

.mr--63 {
  margin-right: 63px !important;
}

.mb--63 {
  margin-bottom: 63px !important;
}

.ml--63 {
  margin-left: 63px !important;
}

.width--63 {
  width: 63px !important;
}

.height--63 {
  height: 63px !important;
}

.gap--63 {
  gap: 63px !important;
}

.scale--63 {
  scale: 0.63 !important;
}

.p--64 {
  padding: 64px !important;
}

.px--64 {
  padding-right: 64px !important;
  padding-left: 64px !important;
}

.py--64 {
  padding-top: 64px !important;
  padding-bottom: 64px !important;
}

.pt--64 {
  padding-top: 64px !important;
}

.pr--64 {
  padding-right: 64px !important;
}

.pb--64 {
  padding-bottom: 64px !important;
}

.pl--64 {
  padding-left: 64px !important;
}

.m--64 {
  margin: 64px !important;
}

.mx--64 {
  margin-right: 64px !important;
  margin-left: 64px !important;
}

.my--64 {
  margin-top: 64px !important;
  margin-bottom: 64px !important;
}

.mt--64 {
  margin-top: 64px !important;
}

.mr--64 {
  margin-right: 64px !important;
}

.mb--64 {
  margin-bottom: 64px !important;
}

.ml--64 {
  margin-left: 64px !important;
}

.width--64 {
  width: 64px !important;
}

.height--64 {
  height: 64px !important;
}

.gap--64 {
  gap: 64px !important;
}

.scale--64 {
  scale: 0.64 !important;
}

.p--65 {
  padding: 65px !important;
}

.px--65 {
  padding-right: 65px !important;
  padding-left: 65px !important;
}

.py--65 {
  padding-top: 65px !important;
  padding-bottom: 65px !important;
}

.pt--65 {
  padding-top: 65px !important;
}

.pr--65 {
  padding-right: 65px !important;
}

.pb--65 {
  padding-bottom: 65px !important;
}

.pl--65 {
  padding-left: 65px !important;
}

.m--65 {
  margin: 65px !important;
}

.mx--65 {
  margin-right: 65px !important;
  margin-left: 65px !important;
}

.my--65 {
  margin-top: 65px !important;
  margin-bottom: 65px !important;
}

.mt--65 {
  margin-top: 65px !important;
}

.mr--65 {
  margin-right: 65px !important;
}

.mb--65 {
  margin-bottom: 65px !important;
}

.ml--65 {
  margin-left: 65px !important;
}

.width--65 {
  width: 65px !important;
}

.height--65 {
  height: 65px !important;
}

.gap--65 {
  gap: 65px !important;
}

.scale--65 {
  scale: 0.65 !important;
}

.p--66 {
  padding: 66px !important;
}

.px--66 {
  padding-right: 66px !important;
  padding-left: 66px !important;
}

.py--66 {
  padding-top: 66px !important;
  padding-bottom: 66px !important;
}

.pt--66 {
  padding-top: 66px !important;
}

.pr--66 {
  padding-right: 66px !important;
}

.pb--66 {
  padding-bottom: 66px !important;
}

.pl--66 {
  padding-left: 66px !important;
}

.m--66 {
  margin: 66px !important;
}

.mx--66 {
  margin-right: 66px !important;
  margin-left: 66px !important;
}

.my--66 {
  margin-top: 66px !important;
  margin-bottom: 66px !important;
}

.mt--66 {
  margin-top: 66px !important;
}

.mr--66 {
  margin-right: 66px !important;
}

.mb--66 {
  margin-bottom: 66px !important;
}

.ml--66 {
  margin-left: 66px !important;
}

.width--66 {
  width: 66px !important;
}

.height--66 {
  height: 66px !important;
}

.gap--66 {
  gap: 66px !important;
}

.scale--66 {
  scale: 0.66 !important;
}

.p--67 {
  padding: 67px !important;
}

.px--67 {
  padding-right: 67px !important;
  padding-left: 67px !important;
}

.py--67 {
  padding-top: 67px !important;
  padding-bottom: 67px !important;
}

.pt--67 {
  padding-top: 67px !important;
}

.pr--67 {
  padding-right: 67px !important;
}

.pb--67 {
  padding-bottom: 67px !important;
}

.pl--67 {
  padding-left: 67px !important;
}

.m--67 {
  margin: 67px !important;
}

.mx--67 {
  margin-right: 67px !important;
  margin-left: 67px !important;
}

.my--67 {
  margin-top: 67px !important;
  margin-bottom: 67px !important;
}

.mt--67 {
  margin-top: 67px !important;
}

.mr--67 {
  margin-right: 67px !important;
}

.mb--67 {
  margin-bottom: 67px !important;
}

.ml--67 {
  margin-left: 67px !important;
}

.width--67 {
  width: 67px !important;
}

.height--67 {
  height: 67px !important;
}

.gap--67 {
  gap: 67px !important;
}

.scale--67 {
  scale: 0.67 !important;
}

.p--68 {
  padding: 68px !important;
}

.px--68 {
  padding-right: 68px !important;
  padding-left: 68px !important;
}

.py--68 {
  padding-top: 68px !important;
  padding-bottom: 68px !important;
}

.pt--68 {
  padding-top: 68px !important;
}

.pr--68 {
  padding-right: 68px !important;
}

.pb--68 {
  padding-bottom: 68px !important;
}

.pl--68 {
  padding-left: 68px !important;
}

.m--68 {
  margin: 68px !important;
}

.mx--68 {
  margin-right: 68px !important;
  margin-left: 68px !important;
}

.my--68 {
  margin-top: 68px !important;
  margin-bottom: 68px !important;
}

.mt--68 {
  margin-top: 68px !important;
}

.mr--68 {
  margin-right: 68px !important;
}

.mb--68 {
  margin-bottom: 68px !important;
}

.ml--68 {
  margin-left: 68px !important;
}

.width--68 {
  width: 68px !important;
}

.height--68 {
  height: 68px !important;
}

.gap--68 {
  gap: 68px !important;
}

.scale--68 {
  scale: 0.68 !important;
}

.p--69 {
  padding: 69px !important;
}

.px--69 {
  padding-right: 69px !important;
  padding-left: 69px !important;
}

.py--69 {
  padding-top: 69px !important;
  padding-bottom: 69px !important;
}

.pt--69 {
  padding-top: 69px !important;
}

.pr--69 {
  padding-right: 69px !important;
}

.pb--69 {
  padding-bottom: 69px !important;
}

.pl--69 {
  padding-left: 69px !important;
}

.m--69 {
  margin: 69px !important;
}

.mx--69 {
  margin-right: 69px !important;
  margin-left: 69px !important;
}

.my--69 {
  margin-top: 69px !important;
  margin-bottom: 69px !important;
}

.mt--69 {
  margin-top: 69px !important;
}

.mr--69 {
  margin-right: 69px !important;
}

.mb--69 {
  margin-bottom: 69px !important;
}

.ml--69 {
  margin-left: 69px !important;
}

.width--69 {
  width: 69px !important;
}

.height--69 {
  height: 69px !important;
}

.gap--69 {
  gap: 69px !important;
}

.scale--69 {
  scale: 0.69 !important;
}

.p--70 {
  padding: 70px !important;
}

.px--70 {
  padding-right: 70px !important;
  padding-left: 70px !important;
}

.py--70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}

.pt--70 {
  padding-top: 70px !important;
}

.pr--70 {
  padding-right: 70px !important;
}

.pb--70 {
  padding-bottom: 70px !important;
}

.pl--70 {
  padding-left: 70px !important;
}

.m--70 {
  margin: 70px !important;
}

.mx--70 {
  margin-right: 70px !important;
  margin-left: 70px !important;
}

.my--70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}

.mt--70 {
  margin-top: 70px !important;
}

.mr--70 {
  margin-right: 70px !important;
}

.mb--70 {
  margin-bottom: 70px !important;
}

.ml--70 {
  margin-left: 70px !important;
}

.width--70 {
  width: 70px !important;
}

.height--70 {
  height: 70px !important;
}

.gap--70 {
  gap: 70px !important;
}

.scale--70 {
  scale: 0.7 !important;
}

.p--71 {
  padding: 71px !important;
}

.px--71 {
  padding-right: 71px !important;
  padding-left: 71px !important;
}

.py--71 {
  padding-top: 71px !important;
  padding-bottom: 71px !important;
}

.pt--71 {
  padding-top: 71px !important;
}

.pr--71 {
  padding-right: 71px !important;
}

.pb--71 {
  padding-bottom: 71px !important;
}

.pl--71 {
  padding-left: 71px !important;
}

.m--71 {
  margin: 71px !important;
}

.mx--71 {
  margin-right: 71px !important;
  margin-left: 71px !important;
}

.my--71 {
  margin-top: 71px !important;
  margin-bottom: 71px !important;
}

.mt--71 {
  margin-top: 71px !important;
}

.mr--71 {
  margin-right: 71px !important;
}

.mb--71 {
  margin-bottom: 71px !important;
}

.ml--71 {
  margin-left: 71px !important;
}

.width--71 {
  width: 71px !important;
}

.height--71 {
  height: 71px !important;
}

.gap--71 {
  gap: 71px !important;
}

.scale--71 {
  scale: 0.71 !important;
}

.p--72 {
  padding: 72px !important;
}

.px--72 {
  padding-right: 72px !important;
  padding-left: 72px !important;
}

.py--72 {
  padding-top: 72px !important;
  padding-bottom: 72px !important;
}

.pt--72 {
  padding-top: 72px !important;
}

.pr--72 {
  padding-right: 72px !important;
}

.pb--72 {
  padding-bottom: 72px !important;
}

.pl--72 {
  padding-left: 72px !important;
}

.m--72 {
  margin: 72px !important;
}

.mx--72 {
  margin-right: 72px !important;
  margin-left: 72px !important;
}

.my--72 {
  margin-top: 72px !important;
  margin-bottom: 72px !important;
}

.mt--72 {
  margin-top: 72px !important;
}

.mr--72 {
  margin-right: 72px !important;
}

.mb--72 {
  margin-bottom: 72px !important;
}

.ml--72 {
  margin-left: 72px !important;
}

.width--72 {
  width: 72px !important;
}

.height--72 {
  height: 72px !important;
}

.gap--72 {
  gap: 72px !important;
}

.scale--72 {
  scale: 0.72 !important;
}

.p--73 {
  padding: 73px !important;
}

.px--73 {
  padding-right: 73px !important;
  padding-left: 73px !important;
}

.py--73 {
  padding-top: 73px !important;
  padding-bottom: 73px !important;
}

.pt--73 {
  padding-top: 73px !important;
}

.pr--73 {
  padding-right: 73px !important;
}

.pb--73 {
  padding-bottom: 73px !important;
}

.pl--73 {
  padding-left: 73px !important;
}

.m--73 {
  margin: 73px !important;
}

.mx--73 {
  margin-right: 73px !important;
  margin-left: 73px !important;
}

.my--73 {
  margin-top: 73px !important;
  margin-bottom: 73px !important;
}

.mt--73 {
  margin-top: 73px !important;
}

.mr--73 {
  margin-right: 73px !important;
}

.mb--73 {
  margin-bottom: 73px !important;
}

.ml--73 {
  margin-left: 73px !important;
}

.width--73 {
  width: 73px !important;
}

.height--73 {
  height: 73px !important;
}

.gap--73 {
  gap: 73px !important;
}

.scale--73 {
  scale: 0.73 !important;
}

.p--74 {
  padding: 74px !important;
}

.px--74 {
  padding-right: 74px !important;
  padding-left: 74px !important;
}

.py--74 {
  padding-top: 74px !important;
  padding-bottom: 74px !important;
}

.pt--74 {
  padding-top: 74px !important;
}

.pr--74 {
  padding-right: 74px !important;
}

.pb--74 {
  padding-bottom: 74px !important;
}

.pl--74 {
  padding-left: 74px !important;
}

.m--74 {
  margin: 74px !important;
}

.mx--74 {
  margin-right: 74px !important;
  margin-left: 74px !important;
}

.my--74 {
  margin-top: 74px !important;
  margin-bottom: 74px !important;
}

.mt--74 {
  margin-top: 74px !important;
}

.mr--74 {
  margin-right: 74px !important;
}

.mb--74 {
  margin-bottom: 74px !important;
}

.ml--74 {
  margin-left: 74px !important;
}

.width--74 {
  width: 74px !important;
}

.height--74 {
  height: 74px !important;
}

.gap--74 {
  gap: 74px !important;
}

.scale--74 {
  scale: 0.74 !important;
}

.p--75 {
  padding: 75px !important;
}

.px--75 {
  padding-right: 75px !important;
  padding-left: 75px !important;
}

.py--75 {
  padding-top: 75px !important;
  padding-bottom: 75px !important;
}

.pt--75 {
  padding-top: 75px !important;
}

.pr--75 {
  padding-right: 75px !important;
}

.pb--75 {
  padding-bottom: 75px !important;
}

.pl--75 {
  padding-left: 75px !important;
}

.m--75 {
  margin: 75px !important;
}

.mx--75 {
  margin-right: 75px !important;
  margin-left: 75px !important;
}

.my--75 {
  margin-top: 75px !important;
  margin-bottom: 75px !important;
}

.mt--75 {
  margin-top: 75px !important;
}

.mr--75 {
  margin-right: 75px !important;
}

.mb--75 {
  margin-bottom: 75px !important;
}

.ml--75 {
  margin-left: 75px !important;
}

.width--75 {
  width: 75px !important;
}

.height--75 {
  height: 75px !important;
}

.gap--75 {
  gap: 75px !important;
}

.scale--75 {
  scale: 0.75 !important;
}

.p--76 {
  padding: 76px !important;
}

.px--76 {
  padding-right: 76px !important;
  padding-left: 76px !important;
}

.py--76 {
  padding-top: 76px !important;
  padding-bottom: 76px !important;
}

.pt--76 {
  padding-top: 76px !important;
}

.pr--76 {
  padding-right: 76px !important;
}

.pb--76 {
  padding-bottom: 76px !important;
}

.pl--76 {
  padding-left: 76px !important;
}

.m--76 {
  margin: 76px !important;
}

.mx--76 {
  margin-right: 76px !important;
  margin-left: 76px !important;
}

.my--76 {
  margin-top: 76px !important;
  margin-bottom: 76px !important;
}

.mt--76 {
  margin-top: 76px !important;
}

.mr--76 {
  margin-right: 76px !important;
}

.mb--76 {
  margin-bottom: 76px !important;
}

.ml--76 {
  margin-left: 76px !important;
}

.width--76 {
  width: 76px !important;
}

.height--76 {
  height: 76px !important;
}

.gap--76 {
  gap: 76px !important;
}

.scale--76 {
  scale: 0.76 !important;
}

.p--77 {
  padding: 77px !important;
}

.px--77 {
  padding-right: 77px !important;
  padding-left: 77px !important;
}

.py--77 {
  padding-top: 77px !important;
  padding-bottom: 77px !important;
}

.pt--77 {
  padding-top: 77px !important;
}

.pr--77 {
  padding-right: 77px !important;
}

.pb--77 {
  padding-bottom: 77px !important;
}

.pl--77 {
  padding-left: 77px !important;
}

.m--77 {
  margin: 77px !important;
}

.mx--77 {
  margin-right: 77px !important;
  margin-left: 77px !important;
}

.my--77 {
  margin-top: 77px !important;
  margin-bottom: 77px !important;
}

.mt--77 {
  margin-top: 77px !important;
}

.mr--77 {
  margin-right: 77px !important;
}

.mb--77 {
  margin-bottom: 77px !important;
}

.ml--77 {
  margin-left: 77px !important;
}

.width--77 {
  width: 77px !important;
}

.height--77 {
  height: 77px !important;
}

.gap--77 {
  gap: 77px !important;
}

.scale--77 {
  scale: 0.77 !important;
}

.p--78 {
  padding: 78px !important;
}

.px--78 {
  padding-right: 78px !important;
  padding-left: 78px !important;
}

.py--78 {
  padding-top: 78px !important;
  padding-bottom: 78px !important;
}

.pt--78 {
  padding-top: 78px !important;
}

.pr--78 {
  padding-right: 78px !important;
}

.pb--78 {
  padding-bottom: 78px !important;
}

.pl--78 {
  padding-left: 78px !important;
}

.m--78 {
  margin: 78px !important;
}

.mx--78 {
  margin-right: 78px !important;
  margin-left: 78px !important;
}

.my--78 {
  margin-top: 78px !important;
  margin-bottom: 78px !important;
}

.mt--78 {
  margin-top: 78px !important;
}

.mr--78 {
  margin-right: 78px !important;
}

.mb--78 {
  margin-bottom: 78px !important;
}

.ml--78 {
  margin-left: 78px !important;
}

.width--78 {
  width: 78px !important;
}

.height--78 {
  height: 78px !important;
}

.gap--78 {
  gap: 78px !important;
}

.scale--78 {
  scale: 0.78 !important;
}

.p--79 {
  padding: 79px !important;
}

.px--79 {
  padding-right: 79px !important;
  padding-left: 79px !important;
}

.py--79 {
  padding-top: 79px !important;
  padding-bottom: 79px !important;
}

.pt--79 {
  padding-top: 79px !important;
}

.pr--79 {
  padding-right: 79px !important;
}

.pb--79 {
  padding-bottom: 79px !important;
}

.pl--79 {
  padding-left: 79px !important;
}

.m--79 {
  margin: 79px !important;
}

.mx--79 {
  margin-right: 79px !important;
  margin-left: 79px !important;
}

.my--79 {
  margin-top: 79px !important;
  margin-bottom: 79px !important;
}

.mt--79 {
  margin-top: 79px !important;
}

.mr--79 {
  margin-right: 79px !important;
}

.mb--79 {
  margin-bottom: 79px !important;
}

.ml--79 {
  margin-left: 79px !important;
}

.width--79 {
  width: 79px !important;
}

.height--79 {
  height: 79px !important;
}

.gap--79 {
  gap: 79px !important;
}

.scale--79 {
  scale: 0.79 !important;
}

.p--80 {
  padding: 80px !important;
}

.px--80 {
  padding-right: 80px !important;
  padding-left: 80px !important;
}

.py--80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}

.pt--80 {
  padding-top: 80px !important;
}

.pr--80 {
  padding-right: 80px !important;
}

.pb--80 {
  padding-bottom: 80px !important;
}

.pl--80 {
  padding-left: 80px !important;
}

.m--80 {
  margin: 80px !important;
}

.mx--80 {
  margin-right: 80px !important;
  margin-left: 80px !important;
}

.my--80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.mt--80 {
  margin-top: 80px !important;
}

.mr--80 {
  margin-right: 80px !important;
}

.mb--80 {
  margin-bottom: 80px !important;
}

.ml--80 {
  margin-left: 80px !important;
}

.width--80 {
  width: 80px !important;
}

.height--80 {
  height: 80px !important;
}

.gap--80 {
  gap: 80px !important;
}

.scale--80 {
  scale: 0.8 !important;
}

.p--81 {
  padding: 81px !important;
}

.px--81 {
  padding-right: 81px !important;
  padding-left: 81px !important;
}

.py--81 {
  padding-top: 81px !important;
  padding-bottom: 81px !important;
}

.pt--81 {
  padding-top: 81px !important;
}

.pr--81 {
  padding-right: 81px !important;
}

.pb--81 {
  padding-bottom: 81px !important;
}

.pl--81 {
  padding-left: 81px !important;
}

.m--81 {
  margin: 81px !important;
}

.mx--81 {
  margin-right: 81px !important;
  margin-left: 81px !important;
}

.my--81 {
  margin-top: 81px !important;
  margin-bottom: 81px !important;
}

.mt--81 {
  margin-top: 81px !important;
}

.mr--81 {
  margin-right: 81px !important;
}

.mb--81 {
  margin-bottom: 81px !important;
}

.ml--81 {
  margin-left: 81px !important;
}

.width--81 {
  width: 81px !important;
}

.height--81 {
  height: 81px !important;
}

.gap--81 {
  gap: 81px !important;
}

.scale--81 {
  scale: 0.81 !important;
}

.p--82 {
  padding: 82px !important;
}

.px--82 {
  padding-right: 82px !important;
  padding-left: 82px !important;
}

.py--82 {
  padding-top: 82px !important;
  padding-bottom: 82px !important;
}

.pt--82 {
  padding-top: 82px !important;
}

.pr--82 {
  padding-right: 82px !important;
}

.pb--82 {
  padding-bottom: 82px !important;
}

.pl--82 {
  padding-left: 82px !important;
}

.m--82 {
  margin: 82px !important;
}

.mx--82 {
  margin-right: 82px !important;
  margin-left: 82px !important;
}

.my--82 {
  margin-top: 82px !important;
  margin-bottom: 82px !important;
}

.mt--82 {
  margin-top: 82px !important;
}

.mr--82 {
  margin-right: 82px !important;
}

.mb--82 {
  margin-bottom: 82px !important;
}

.ml--82 {
  margin-left: 82px !important;
}

.width--82 {
  width: 82px !important;
}

.height--82 {
  height: 82px !important;
}

.gap--82 {
  gap: 82px !important;
}

.scale--82 {
  scale: 0.82 !important;
}

.p--83 {
  padding: 83px !important;
}

.px--83 {
  padding-right: 83px !important;
  padding-left: 83px !important;
}

.py--83 {
  padding-top: 83px !important;
  padding-bottom: 83px !important;
}

.pt--83 {
  padding-top: 83px !important;
}

.pr--83 {
  padding-right: 83px !important;
}

.pb--83 {
  padding-bottom: 83px !important;
}

.pl--83 {
  padding-left: 83px !important;
}

.m--83 {
  margin: 83px !important;
}

.mx--83 {
  margin-right: 83px !important;
  margin-left: 83px !important;
}

.my--83 {
  margin-top: 83px !important;
  margin-bottom: 83px !important;
}

.mt--83 {
  margin-top: 83px !important;
}

.mr--83 {
  margin-right: 83px !important;
}

.mb--83 {
  margin-bottom: 83px !important;
}

.ml--83 {
  margin-left: 83px !important;
}

.width--83 {
  width: 83px !important;
}

.height--83 {
  height: 83px !important;
}

.gap--83 {
  gap: 83px !important;
}

.scale--83 {
  scale: 0.83 !important;
}

.p--84 {
  padding: 84px !important;
}

.px--84 {
  padding-right: 84px !important;
  padding-left: 84px !important;
}

.py--84 {
  padding-top: 84px !important;
  padding-bottom: 84px !important;
}

.pt--84 {
  padding-top: 84px !important;
}

.pr--84 {
  padding-right: 84px !important;
}

.pb--84 {
  padding-bottom: 84px !important;
}

.pl--84 {
  padding-left: 84px !important;
}

.m--84 {
  margin: 84px !important;
}

.mx--84 {
  margin-right: 84px !important;
  margin-left: 84px !important;
}

.my--84 {
  margin-top: 84px !important;
  margin-bottom: 84px !important;
}

.mt--84 {
  margin-top: 84px !important;
}

.mr--84 {
  margin-right: 84px !important;
}

.mb--84 {
  margin-bottom: 84px !important;
}

.ml--84 {
  margin-left: 84px !important;
}

.width--84 {
  width: 84px !important;
}

.height--84 {
  height: 84px !important;
}

.gap--84 {
  gap: 84px !important;
}

.scale--84 {
  scale: 0.84 !important;
}

.p--85 {
  padding: 85px !important;
}

.px--85 {
  padding-right: 85px !important;
  padding-left: 85px !important;
}

.py--85 {
  padding-top: 85px !important;
  padding-bottom: 85px !important;
}

.pt--85 {
  padding-top: 85px !important;
}

.pr--85 {
  padding-right: 85px !important;
}

.pb--85 {
  padding-bottom: 85px !important;
}

.pl--85 {
  padding-left: 85px !important;
}

.m--85 {
  margin: 85px !important;
}

.mx--85 {
  margin-right: 85px !important;
  margin-left: 85px !important;
}

.my--85 {
  margin-top: 85px !important;
  margin-bottom: 85px !important;
}

.mt--85 {
  margin-top: 85px !important;
}

.mr--85 {
  margin-right: 85px !important;
}

.mb--85 {
  margin-bottom: 85px !important;
}

.ml--85 {
  margin-left: 85px !important;
}

.width--85 {
  width: 85px !important;
}

.height--85 {
  height: 85px !important;
}

.gap--85 {
  gap: 85px !important;
}

.scale--85 {
  scale: 0.85 !important;
}

.p--86 {
  padding: 86px !important;
}

.px--86 {
  padding-right: 86px !important;
  padding-left: 86px !important;
}

.py--86 {
  padding-top: 86px !important;
  padding-bottom: 86px !important;
}

.pt--86 {
  padding-top: 86px !important;
}

.pr--86 {
  padding-right: 86px !important;
}

.pb--86 {
  padding-bottom: 86px !important;
}

.pl--86 {
  padding-left: 86px !important;
}

.m--86 {
  margin: 86px !important;
}

.mx--86 {
  margin-right: 86px !important;
  margin-left: 86px !important;
}

.my--86 {
  margin-top: 86px !important;
  margin-bottom: 86px !important;
}

.mt--86 {
  margin-top: 86px !important;
}

.mr--86 {
  margin-right: 86px !important;
}

.mb--86 {
  margin-bottom: 86px !important;
}

.ml--86 {
  margin-left: 86px !important;
}

.width--86 {
  width: 86px !important;
}

.height--86 {
  height: 86px !important;
}

.gap--86 {
  gap: 86px !important;
}

.scale--86 {
  scale: 0.86 !important;
}

.p--87 {
  padding: 87px !important;
}

.px--87 {
  padding-right: 87px !important;
  padding-left: 87px !important;
}

.py--87 {
  padding-top: 87px !important;
  padding-bottom: 87px !important;
}

.pt--87 {
  padding-top: 87px !important;
}

.pr--87 {
  padding-right: 87px !important;
}

.pb--87 {
  padding-bottom: 87px !important;
}

.pl--87 {
  padding-left: 87px !important;
}

.m--87 {
  margin: 87px !important;
}

.mx--87 {
  margin-right: 87px !important;
  margin-left: 87px !important;
}

.my--87 {
  margin-top: 87px !important;
  margin-bottom: 87px !important;
}

.mt--87 {
  margin-top: 87px !important;
}

.mr--87 {
  margin-right: 87px !important;
}

.mb--87 {
  margin-bottom: 87px !important;
}

.ml--87 {
  margin-left: 87px !important;
}

.width--87 {
  width: 87px !important;
}

.height--87 {
  height: 87px !important;
}

.gap--87 {
  gap: 87px !important;
}

.scale--87 {
  scale: 0.87 !important;
}

.p--88 {
  padding: 88px !important;
}

.px--88 {
  padding-right: 88px !important;
  padding-left: 88px !important;
}

.py--88 {
  padding-top: 88px !important;
  padding-bottom: 88px !important;
}

.pt--88 {
  padding-top: 88px !important;
}

.pr--88 {
  padding-right: 88px !important;
}

.pb--88 {
  padding-bottom: 88px !important;
}

.pl--88 {
  padding-left: 88px !important;
}

.m--88 {
  margin: 88px !important;
}

.mx--88 {
  margin-right: 88px !important;
  margin-left: 88px !important;
}

.my--88 {
  margin-top: 88px !important;
  margin-bottom: 88px !important;
}

.mt--88 {
  margin-top: 88px !important;
}

.mr--88 {
  margin-right: 88px !important;
}

.mb--88 {
  margin-bottom: 88px !important;
}

.ml--88 {
  margin-left: 88px !important;
}

.width--88 {
  width: 88px !important;
}

.height--88 {
  height: 88px !important;
}

.gap--88 {
  gap: 88px !important;
}

.scale--88 {
  scale: 0.88 !important;
}

.p--89 {
  padding: 89px !important;
}

.px--89 {
  padding-right: 89px !important;
  padding-left: 89px !important;
}

.py--89 {
  padding-top: 89px !important;
  padding-bottom: 89px !important;
}

.pt--89 {
  padding-top: 89px !important;
}

.pr--89 {
  padding-right: 89px !important;
}

.pb--89 {
  padding-bottom: 89px !important;
}

.pl--89 {
  padding-left: 89px !important;
}

.m--89 {
  margin: 89px !important;
}

.mx--89 {
  margin-right: 89px !important;
  margin-left: 89px !important;
}

.my--89 {
  margin-top: 89px !important;
  margin-bottom: 89px !important;
}

.mt--89 {
  margin-top: 89px !important;
}

.mr--89 {
  margin-right: 89px !important;
}

.mb--89 {
  margin-bottom: 89px !important;
}

.ml--89 {
  margin-left: 89px !important;
}

.width--89 {
  width: 89px !important;
}

.height--89 {
  height: 89px !important;
}

.gap--89 {
  gap: 89px !important;
}

.scale--89 {
  scale: 0.89 !important;
}

.p--90 {
  padding: 90px !important;
}

.px--90 {
  padding-right: 90px !important;
  padding-left: 90px !important;
}

.py--90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}

.pt--90 {
  padding-top: 90px !important;
}

.pr--90 {
  padding-right: 90px !important;
}

.pb--90 {
  padding-bottom: 90px !important;
}

.pl--90 {
  padding-left: 90px !important;
}

.m--90 {
  margin: 90px !important;
}

.mx--90 {
  margin-right: 90px !important;
  margin-left: 90px !important;
}

.my--90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}

.mt--90 {
  margin-top: 90px !important;
}

.mr--90 {
  margin-right: 90px !important;
}

.mb--90 {
  margin-bottom: 90px !important;
}

.ml--90 {
  margin-left: 90px !important;
}

.width--90 {
  width: 90px !important;
}

.height--90 {
  height: 90px !important;
}

.gap--90 {
  gap: 90px !important;
}

.scale--90 {
  scale: 0.9 !important;
}

.p--91 {
  padding: 91px !important;
}

.px--91 {
  padding-right: 91px !important;
  padding-left: 91px !important;
}

.py--91 {
  padding-top: 91px !important;
  padding-bottom: 91px !important;
}

.pt--91 {
  padding-top: 91px !important;
}

.pr--91 {
  padding-right: 91px !important;
}

.pb--91 {
  padding-bottom: 91px !important;
}

.pl--91 {
  padding-left: 91px !important;
}

.m--91 {
  margin: 91px !important;
}

.mx--91 {
  margin-right: 91px !important;
  margin-left: 91px !important;
}

.my--91 {
  margin-top: 91px !important;
  margin-bottom: 91px !important;
}

.mt--91 {
  margin-top: 91px !important;
}

.mr--91 {
  margin-right: 91px !important;
}

.mb--91 {
  margin-bottom: 91px !important;
}

.ml--91 {
  margin-left: 91px !important;
}

.width--91 {
  width: 91px !important;
}

.height--91 {
  height: 91px !important;
}

.gap--91 {
  gap: 91px !important;
}

.scale--91 {
  scale: 0.91 !important;
}

.p--92 {
  padding: 92px !important;
}

.px--92 {
  padding-right: 92px !important;
  padding-left: 92px !important;
}

.py--92 {
  padding-top: 92px !important;
  padding-bottom: 92px !important;
}

.pt--92 {
  padding-top: 92px !important;
}

.pr--92 {
  padding-right: 92px !important;
}

.pb--92 {
  padding-bottom: 92px !important;
}

.pl--92 {
  padding-left: 92px !important;
}

.m--92 {
  margin: 92px !important;
}

.mx--92 {
  margin-right: 92px !important;
  margin-left: 92px !important;
}

.my--92 {
  margin-top: 92px !important;
  margin-bottom: 92px !important;
}

.mt--92 {
  margin-top: 92px !important;
}

.mr--92 {
  margin-right: 92px !important;
}

.mb--92 {
  margin-bottom: 92px !important;
}

.ml--92 {
  margin-left: 92px !important;
}

.width--92 {
  width: 92px !important;
}

.height--92 {
  height: 92px !important;
}

.gap--92 {
  gap: 92px !important;
}

.scale--92 {
  scale: 0.92 !important;
}

.p--93 {
  padding: 93px !important;
}

.px--93 {
  padding-right: 93px !important;
  padding-left: 93px !important;
}

.py--93 {
  padding-top: 93px !important;
  padding-bottom: 93px !important;
}

.pt--93 {
  padding-top: 93px !important;
}

.pr--93 {
  padding-right: 93px !important;
}

.pb--93 {
  padding-bottom: 93px !important;
}

.pl--93 {
  padding-left: 93px !important;
}

.m--93 {
  margin: 93px !important;
}

.mx--93 {
  margin-right: 93px !important;
  margin-left: 93px !important;
}

.my--93 {
  margin-top: 93px !important;
  margin-bottom: 93px !important;
}

.mt--93 {
  margin-top: 93px !important;
}

.mr--93 {
  margin-right: 93px !important;
}

.mb--93 {
  margin-bottom: 93px !important;
}

.ml--93 {
  margin-left: 93px !important;
}

.width--93 {
  width: 93px !important;
}

.height--93 {
  height: 93px !important;
}

.gap--93 {
  gap: 93px !important;
}

.scale--93 {
  scale: 0.93 !important;
}

.p--94 {
  padding: 94px !important;
}

.px--94 {
  padding-right: 94px !important;
  padding-left: 94px !important;
}

.py--94 {
  padding-top: 94px !important;
  padding-bottom: 94px !important;
}

.pt--94 {
  padding-top: 94px !important;
}

.pr--94 {
  padding-right: 94px !important;
}

.pb--94 {
  padding-bottom: 94px !important;
}

.pl--94 {
  padding-left: 94px !important;
}

.m--94 {
  margin: 94px !important;
}

.mx--94 {
  margin-right: 94px !important;
  margin-left: 94px !important;
}

.my--94 {
  margin-top: 94px !important;
  margin-bottom: 94px !important;
}

.mt--94 {
  margin-top: 94px !important;
}

.mr--94 {
  margin-right: 94px !important;
}

.mb--94 {
  margin-bottom: 94px !important;
}

.ml--94 {
  margin-left: 94px !important;
}

.width--94 {
  width: 94px !important;
}

.height--94 {
  height: 94px !important;
}

.gap--94 {
  gap: 94px !important;
}

.scale--94 {
  scale: 0.94 !important;
}

.p--95 {
  padding: 95px !important;
}

.px--95 {
  padding-right: 95px !important;
  padding-left: 95px !important;
}

.py--95 {
  padding-top: 95px !important;
  padding-bottom: 95px !important;
}

.pt--95 {
  padding-top: 95px !important;
}

.pr--95 {
  padding-right: 95px !important;
}

.pb--95 {
  padding-bottom: 95px !important;
}

.pl--95 {
  padding-left: 95px !important;
}

.m--95 {
  margin: 95px !important;
}

.mx--95 {
  margin-right: 95px !important;
  margin-left: 95px !important;
}

.my--95 {
  margin-top: 95px !important;
  margin-bottom: 95px !important;
}

.mt--95 {
  margin-top: 95px !important;
}

.mr--95 {
  margin-right: 95px !important;
}

.mb--95 {
  margin-bottom: 95px !important;
}

.ml--95 {
  margin-left: 95px !important;
}

.width--95 {
  width: 95px !important;
}

.height--95 {
  height: 95px !important;
}

.gap--95 {
  gap: 95px !important;
}

.scale--95 {
  scale: 0.95 !important;
}

.p--96 {
  padding: 96px !important;
}

.px--96 {
  padding-right: 96px !important;
  padding-left: 96px !important;
}

.py--96 {
  padding-top: 96px !important;
  padding-bottom: 96px !important;
}

.pt--96 {
  padding-top: 96px !important;
}

.pr--96 {
  padding-right: 96px !important;
}

.pb--96 {
  padding-bottom: 96px !important;
}

.pl--96 {
  padding-left: 96px !important;
}

.m--96 {
  margin: 96px !important;
}

.mx--96 {
  margin-right: 96px !important;
  margin-left: 96px !important;
}

.my--96 {
  margin-top: 96px !important;
  margin-bottom: 96px !important;
}

.mt--96 {
  margin-top: 96px !important;
}

.mr--96 {
  margin-right: 96px !important;
}

.mb--96 {
  margin-bottom: 96px !important;
}

.ml--96 {
  margin-left: 96px !important;
}

.width--96 {
  width: 96px !important;
}

.height--96 {
  height: 96px !important;
}

.gap--96 {
  gap: 96px !important;
}

.scale--96 {
  scale: 0.96 !important;
}

.p--97 {
  padding: 97px !important;
}

.px--97 {
  padding-right: 97px !important;
  padding-left: 97px !important;
}

.py--97 {
  padding-top: 97px !important;
  padding-bottom: 97px !important;
}

.pt--97 {
  padding-top: 97px !important;
}

.pr--97 {
  padding-right: 97px !important;
}

.pb--97 {
  padding-bottom: 97px !important;
}

.pl--97 {
  padding-left: 97px !important;
}

.m--97 {
  margin: 97px !important;
}

.mx--97 {
  margin-right: 97px !important;
  margin-left: 97px !important;
}

.my--97 {
  margin-top: 97px !important;
  margin-bottom: 97px !important;
}

.mt--97 {
  margin-top: 97px !important;
}

.mr--97 {
  margin-right: 97px !important;
}

.mb--97 {
  margin-bottom: 97px !important;
}

.ml--97 {
  margin-left: 97px !important;
}

.width--97 {
  width: 97px !important;
}

.height--97 {
  height: 97px !important;
}

.gap--97 {
  gap: 97px !important;
}

.scale--97 {
  scale: 0.97 !important;
}

.p--98 {
  padding: 98px !important;
}

.px--98 {
  padding-right: 98px !important;
  padding-left: 98px !important;
}

.py--98 {
  padding-top: 98px !important;
  padding-bottom: 98px !important;
}

.pt--98 {
  padding-top: 98px !important;
}

.pr--98 {
  padding-right: 98px !important;
}

.pb--98 {
  padding-bottom: 98px !important;
}

.pl--98 {
  padding-left: 98px !important;
}

.m--98 {
  margin: 98px !important;
}

.mx--98 {
  margin-right: 98px !important;
  margin-left: 98px !important;
}

.my--98 {
  margin-top: 98px !important;
  margin-bottom: 98px !important;
}

.mt--98 {
  margin-top: 98px !important;
}

.mr--98 {
  margin-right: 98px !important;
}

.mb--98 {
  margin-bottom: 98px !important;
}

.ml--98 {
  margin-left: 98px !important;
}

.width--98 {
  width: 98px !important;
}

.height--98 {
  height: 98px !important;
}

.gap--98 {
  gap: 98px !important;
}

.scale--98 {
  scale: 0.98 !important;
}

.p--99 {
  padding: 99px !important;
}

.px--99 {
  padding-right: 99px !important;
  padding-left: 99px !important;
}

.py--99 {
  padding-top: 99px !important;
  padding-bottom: 99px !important;
}

.pt--99 {
  padding-top: 99px !important;
}

.pr--99 {
  padding-right: 99px !important;
}

.pb--99 {
  padding-bottom: 99px !important;
}

.pl--99 {
  padding-left: 99px !important;
}

.m--99 {
  margin: 99px !important;
}

.mx--99 {
  margin-right: 99px !important;
  margin-left: 99px !important;
}

.my--99 {
  margin-top: 99px !important;
  margin-bottom: 99px !important;
}

.mt--99 {
  margin-top: 99px !important;
}

.mr--99 {
  margin-right: 99px !important;
}

.mb--99 {
  margin-bottom: 99px !important;
}

.ml--99 {
  margin-left: 99px !important;
}

.width--99 {
  width: 99px !important;
}

.height--99 {
  height: 99px !important;
}

.gap--99 {
  gap: 99px !important;
}

.scale--99 {
  scale: 0.99 !important;
}

.p--100 {
  padding: 100px !important;
}

.px--100 {
  padding-right: 100px !important;
  padding-left: 100px !important;
}

.py--100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.pt--100 {
  padding-top: 100px !important;
}

.pr--100 {
  padding-right: 100px !important;
}

.pb--100 {
  padding-bottom: 100px !important;
}

.pl--100 {
  padding-left: 100px !important;
}

.m--100 {
  margin: 100px !important;
}

.mx--100 {
  margin-right: 100px !important;
  margin-left: 100px !important;
}

.my--100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}

.mt--100 {
  margin-top: 100px !important;
}

.mr--100 {
  margin-right: 100px !important;
}

.mb--100 {
  margin-bottom: 100px !important;
}

.ml--100 {
  margin-left: 100px !important;
}

.width--100 {
  width: 100px !important;
}

.height--100 {
  height: 100px !important;
}

.gap--100 {
  gap: 100px !important;
}

.scale--100 {
  scale: 1 !important;
}

.p--101 {
  padding: 101px !important;
}

.px--101 {
  padding-right: 101px !important;
  padding-left: 101px !important;
}

.py--101 {
  padding-top: 101px !important;
  padding-bottom: 101px !important;
}

.pt--101 {
  padding-top: 101px !important;
}

.pr--101 {
  padding-right: 101px !important;
}

.pb--101 {
  padding-bottom: 101px !important;
}

.pl--101 {
  padding-left: 101px !important;
}

.m--101 {
  margin: 101px !important;
}

.mx--101 {
  margin-right: 101px !important;
  margin-left: 101px !important;
}

.my--101 {
  margin-top: 101px !important;
  margin-bottom: 101px !important;
}

.mt--101 {
  margin-top: 101px !important;
}

.mr--101 {
  margin-right: 101px !important;
}

.mb--101 {
  margin-bottom: 101px !important;
}

.ml--101 {
  margin-left: 101px !important;
}

.width--101 {
  width: 101px !important;
}

.height--101 {
  height: 101px !important;
}

.gap--101 {
  gap: 101px !important;
}

.scale--101 {
  scale: 1.01 !important;
}

.p--102 {
  padding: 102px !important;
}

.px--102 {
  padding-right: 102px !important;
  padding-left: 102px !important;
}

.py--102 {
  padding-top: 102px !important;
  padding-bottom: 102px !important;
}

.pt--102 {
  padding-top: 102px !important;
}

.pr--102 {
  padding-right: 102px !important;
}

.pb--102 {
  padding-bottom: 102px !important;
}

.pl--102 {
  padding-left: 102px !important;
}

.m--102 {
  margin: 102px !important;
}

.mx--102 {
  margin-right: 102px !important;
  margin-left: 102px !important;
}

.my--102 {
  margin-top: 102px !important;
  margin-bottom: 102px !important;
}

.mt--102 {
  margin-top: 102px !important;
}

.mr--102 {
  margin-right: 102px !important;
}

.mb--102 {
  margin-bottom: 102px !important;
}

.ml--102 {
  margin-left: 102px !important;
}

.width--102 {
  width: 102px !important;
}

.height--102 {
  height: 102px !important;
}

.gap--102 {
  gap: 102px !important;
}

.scale--102 {
  scale: 1.02 !important;
}

.p--103 {
  padding: 103px !important;
}

.px--103 {
  padding-right: 103px !important;
  padding-left: 103px !important;
}

.py--103 {
  padding-top: 103px !important;
  padding-bottom: 103px !important;
}

.pt--103 {
  padding-top: 103px !important;
}

.pr--103 {
  padding-right: 103px !important;
}

.pb--103 {
  padding-bottom: 103px !important;
}

.pl--103 {
  padding-left: 103px !important;
}

.m--103 {
  margin: 103px !important;
}

.mx--103 {
  margin-right: 103px !important;
  margin-left: 103px !important;
}

.my--103 {
  margin-top: 103px !important;
  margin-bottom: 103px !important;
}

.mt--103 {
  margin-top: 103px !important;
}

.mr--103 {
  margin-right: 103px !important;
}

.mb--103 {
  margin-bottom: 103px !important;
}

.ml--103 {
  margin-left: 103px !important;
}

.width--103 {
  width: 103px !important;
}

.height--103 {
  height: 103px !important;
}

.gap--103 {
  gap: 103px !important;
}

.scale--103 {
  scale: 1.03 !important;
}

.p--104 {
  padding: 104px !important;
}

.px--104 {
  padding-right: 104px !important;
  padding-left: 104px !important;
}

.py--104 {
  padding-top: 104px !important;
  padding-bottom: 104px !important;
}

.pt--104 {
  padding-top: 104px !important;
}

.pr--104 {
  padding-right: 104px !important;
}

.pb--104 {
  padding-bottom: 104px !important;
}

.pl--104 {
  padding-left: 104px !important;
}

.m--104 {
  margin: 104px !important;
}

.mx--104 {
  margin-right: 104px !important;
  margin-left: 104px !important;
}

.my--104 {
  margin-top: 104px !important;
  margin-bottom: 104px !important;
}

.mt--104 {
  margin-top: 104px !important;
}

.mr--104 {
  margin-right: 104px !important;
}

.mb--104 {
  margin-bottom: 104px !important;
}

.ml--104 {
  margin-left: 104px !important;
}

.width--104 {
  width: 104px !important;
}

.height--104 {
  height: 104px !important;
}

.gap--104 {
  gap: 104px !important;
}

.scale--104 {
  scale: 1.04 !important;
}

.p--105 {
  padding: 105px !important;
}

.px--105 {
  padding-right: 105px !important;
  padding-left: 105px !important;
}

.py--105 {
  padding-top: 105px !important;
  padding-bottom: 105px !important;
}

.pt--105 {
  padding-top: 105px !important;
}

.pr--105 {
  padding-right: 105px !important;
}

.pb--105 {
  padding-bottom: 105px !important;
}

.pl--105 {
  padding-left: 105px !important;
}

.m--105 {
  margin: 105px !important;
}

.mx--105 {
  margin-right: 105px !important;
  margin-left: 105px !important;
}

.my--105 {
  margin-top: 105px !important;
  margin-bottom: 105px !important;
}

.mt--105 {
  margin-top: 105px !important;
}

.mr--105 {
  margin-right: 105px !important;
}

.mb--105 {
  margin-bottom: 105px !important;
}

.ml--105 {
  margin-left: 105px !important;
}

.width--105 {
  width: 105px !important;
}

.height--105 {
  height: 105px !important;
}

.gap--105 {
  gap: 105px !important;
}

.scale--105 {
  scale: 1.05 !important;
}

.p--106 {
  padding: 106px !important;
}

.px--106 {
  padding-right: 106px !important;
  padding-left: 106px !important;
}

.py--106 {
  padding-top: 106px !important;
  padding-bottom: 106px !important;
}

.pt--106 {
  padding-top: 106px !important;
}

.pr--106 {
  padding-right: 106px !important;
}

.pb--106 {
  padding-bottom: 106px !important;
}

.pl--106 {
  padding-left: 106px !important;
}

.m--106 {
  margin: 106px !important;
}

.mx--106 {
  margin-right: 106px !important;
  margin-left: 106px !important;
}

.my--106 {
  margin-top: 106px !important;
  margin-bottom: 106px !important;
}

.mt--106 {
  margin-top: 106px !important;
}

.mr--106 {
  margin-right: 106px !important;
}

.mb--106 {
  margin-bottom: 106px !important;
}

.ml--106 {
  margin-left: 106px !important;
}

.width--106 {
  width: 106px !important;
}

.height--106 {
  height: 106px !important;
}

.gap--106 {
  gap: 106px !important;
}

.scale--106 {
  scale: 1.06 !important;
}

.p--107 {
  padding: 107px !important;
}

.px--107 {
  padding-right: 107px !important;
  padding-left: 107px !important;
}

.py--107 {
  padding-top: 107px !important;
  padding-bottom: 107px !important;
}

.pt--107 {
  padding-top: 107px !important;
}

.pr--107 {
  padding-right: 107px !important;
}

.pb--107 {
  padding-bottom: 107px !important;
}

.pl--107 {
  padding-left: 107px !important;
}

.m--107 {
  margin: 107px !important;
}

.mx--107 {
  margin-right: 107px !important;
  margin-left: 107px !important;
}

.my--107 {
  margin-top: 107px !important;
  margin-bottom: 107px !important;
}

.mt--107 {
  margin-top: 107px !important;
}

.mr--107 {
  margin-right: 107px !important;
}

.mb--107 {
  margin-bottom: 107px !important;
}

.ml--107 {
  margin-left: 107px !important;
}

.width--107 {
  width: 107px !important;
}

.height--107 {
  height: 107px !important;
}

.gap--107 {
  gap: 107px !important;
}

.scale--107 {
  scale: 1.07 !important;
}

.p--108 {
  padding: 108px !important;
}

.px--108 {
  padding-right: 108px !important;
  padding-left: 108px !important;
}

.py--108 {
  padding-top: 108px !important;
  padding-bottom: 108px !important;
}

.pt--108 {
  padding-top: 108px !important;
}

.pr--108 {
  padding-right: 108px !important;
}

.pb--108 {
  padding-bottom: 108px !important;
}

.pl--108 {
  padding-left: 108px !important;
}

.m--108 {
  margin: 108px !important;
}

.mx--108 {
  margin-right: 108px !important;
  margin-left: 108px !important;
}

.my--108 {
  margin-top: 108px !important;
  margin-bottom: 108px !important;
}

.mt--108 {
  margin-top: 108px !important;
}

.mr--108 {
  margin-right: 108px !important;
}

.mb--108 {
  margin-bottom: 108px !important;
}

.ml--108 {
  margin-left: 108px !important;
}

.width--108 {
  width: 108px !important;
}

.height--108 {
  height: 108px !important;
}

.gap--108 {
  gap: 108px !important;
}

.scale--108 {
  scale: 1.08 !important;
}

.p--109 {
  padding: 109px !important;
}

.px--109 {
  padding-right: 109px !important;
  padding-left: 109px !important;
}

.py--109 {
  padding-top: 109px !important;
  padding-bottom: 109px !important;
}

.pt--109 {
  padding-top: 109px !important;
}

.pr--109 {
  padding-right: 109px !important;
}

.pb--109 {
  padding-bottom: 109px !important;
}

.pl--109 {
  padding-left: 109px !important;
}

.m--109 {
  margin: 109px !important;
}

.mx--109 {
  margin-right: 109px !important;
  margin-left: 109px !important;
}

.my--109 {
  margin-top: 109px !important;
  margin-bottom: 109px !important;
}

.mt--109 {
  margin-top: 109px !important;
}

.mr--109 {
  margin-right: 109px !important;
}

.mb--109 {
  margin-bottom: 109px !important;
}

.ml--109 {
  margin-left: 109px !important;
}

.width--109 {
  width: 109px !important;
}

.height--109 {
  height: 109px !important;
}

.gap--109 {
  gap: 109px !important;
}

.scale--109 {
  scale: 1.09 !important;
}

.p--110 {
  padding: 110px !important;
}

.px--110 {
  padding-right: 110px !important;
  padding-left: 110px !important;
}

.py--110 {
  padding-top: 110px !important;
  padding-bottom: 110px !important;
}

.pt--110 {
  padding-top: 110px !important;
}

.pr--110 {
  padding-right: 110px !important;
}

.pb--110 {
  padding-bottom: 110px !important;
}

.pl--110 {
  padding-left: 110px !important;
}

.m--110 {
  margin: 110px !important;
}

.mx--110 {
  margin-right: 110px !important;
  margin-left: 110px !important;
}

.my--110 {
  margin-top: 110px !important;
  margin-bottom: 110px !important;
}

.mt--110 {
  margin-top: 110px !important;
}

.mr--110 {
  margin-right: 110px !important;
}

.mb--110 {
  margin-bottom: 110px !important;
}

.ml--110 {
  margin-left: 110px !important;
}

.width--110 {
  width: 110px !important;
}

.height--110 {
  height: 110px !important;
}

.gap--110 {
  gap: 110px !important;
}

.scale--110 {
  scale: 1.1 !important;
}

.p--111 {
  padding: 111px !important;
}

.px--111 {
  padding-right: 111px !important;
  padding-left: 111px !important;
}

.py--111 {
  padding-top: 111px !important;
  padding-bottom: 111px !important;
}

.pt--111 {
  padding-top: 111px !important;
}

.pr--111 {
  padding-right: 111px !important;
}

.pb--111 {
  padding-bottom: 111px !important;
}

.pl--111 {
  padding-left: 111px !important;
}

.m--111 {
  margin: 111px !important;
}

.mx--111 {
  margin-right: 111px !important;
  margin-left: 111px !important;
}

.my--111 {
  margin-top: 111px !important;
  margin-bottom: 111px !important;
}

.mt--111 {
  margin-top: 111px !important;
}

.mr--111 {
  margin-right: 111px !important;
}

.mb--111 {
  margin-bottom: 111px !important;
}

.ml--111 {
  margin-left: 111px !important;
}

.width--111 {
  width: 111px !important;
}

.height--111 {
  height: 111px !important;
}

.gap--111 {
  gap: 111px !important;
}

.scale--111 {
  scale: 1.11 !important;
}

.p--112 {
  padding: 112px !important;
}

.px--112 {
  padding-right: 112px !important;
  padding-left: 112px !important;
}

.py--112 {
  padding-top: 112px !important;
  padding-bottom: 112px !important;
}

.pt--112 {
  padding-top: 112px !important;
}

.pr--112 {
  padding-right: 112px !important;
}

.pb--112 {
  padding-bottom: 112px !important;
}

.pl--112 {
  padding-left: 112px !important;
}

.m--112 {
  margin: 112px !important;
}

.mx--112 {
  margin-right: 112px !important;
  margin-left: 112px !important;
}

.my--112 {
  margin-top: 112px !important;
  margin-bottom: 112px !important;
}

.mt--112 {
  margin-top: 112px !important;
}

.mr--112 {
  margin-right: 112px !important;
}

.mb--112 {
  margin-bottom: 112px !important;
}

.ml--112 {
  margin-left: 112px !important;
}

.width--112 {
  width: 112px !important;
}

.height--112 {
  height: 112px !important;
}

.gap--112 {
  gap: 112px !important;
}

.scale--112 {
  scale: 1.12 !important;
}

.p--113 {
  padding: 113px !important;
}

.px--113 {
  padding-right: 113px !important;
  padding-left: 113px !important;
}

.py--113 {
  padding-top: 113px !important;
  padding-bottom: 113px !important;
}

.pt--113 {
  padding-top: 113px !important;
}

.pr--113 {
  padding-right: 113px !important;
}

.pb--113 {
  padding-bottom: 113px !important;
}

.pl--113 {
  padding-left: 113px !important;
}

.m--113 {
  margin: 113px !important;
}

.mx--113 {
  margin-right: 113px !important;
  margin-left: 113px !important;
}

.my--113 {
  margin-top: 113px !important;
  margin-bottom: 113px !important;
}

.mt--113 {
  margin-top: 113px !important;
}

.mr--113 {
  margin-right: 113px !important;
}

.mb--113 {
  margin-bottom: 113px !important;
}

.ml--113 {
  margin-left: 113px !important;
}

.width--113 {
  width: 113px !important;
}

.height--113 {
  height: 113px !important;
}

.gap--113 {
  gap: 113px !important;
}

.scale--113 {
  scale: 1.13 !important;
}

.p--114 {
  padding: 114px !important;
}

.px--114 {
  padding-right: 114px !important;
  padding-left: 114px !important;
}

.py--114 {
  padding-top: 114px !important;
  padding-bottom: 114px !important;
}

.pt--114 {
  padding-top: 114px !important;
}

.pr--114 {
  padding-right: 114px !important;
}

.pb--114 {
  padding-bottom: 114px !important;
}

.pl--114 {
  padding-left: 114px !important;
}

.m--114 {
  margin: 114px !important;
}

.mx--114 {
  margin-right: 114px !important;
  margin-left: 114px !important;
}

.my--114 {
  margin-top: 114px !important;
  margin-bottom: 114px !important;
}

.mt--114 {
  margin-top: 114px !important;
}

.mr--114 {
  margin-right: 114px !important;
}

.mb--114 {
  margin-bottom: 114px !important;
}

.ml--114 {
  margin-left: 114px !important;
}

.width--114 {
  width: 114px !important;
}

.height--114 {
  height: 114px !important;
}

.gap--114 {
  gap: 114px !important;
}

.scale--114 {
  scale: 1.14 !important;
}

.p--115 {
  padding: 115px !important;
}

.px--115 {
  padding-right: 115px !important;
  padding-left: 115px !important;
}

.py--115 {
  padding-top: 115px !important;
  padding-bottom: 115px !important;
}

.pt--115 {
  padding-top: 115px !important;
}

.pr--115 {
  padding-right: 115px !important;
}

.pb--115 {
  padding-bottom: 115px !important;
}

.pl--115 {
  padding-left: 115px !important;
}

.m--115 {
  margin: 115px !important;
}

.mx--115 {
  margin-right: 115px !important;
  margin-left: 115px !important;
}

.my--115 {
  margin-top: 115px !important;
  margin-bottom: 115px !important;
}

.mt--115 {
  margin-top: 115px !important;
}

.mr--115 {
  margin-right: 115px !important;
}

.mb--115 {
  margin-bottom: 115px !important;
}

.ml--115 {
  margin-left: 115px !important;
}

.width--115 {
  width: 115px !important;
}

.height--115 {
  height: 115px !important;
}

.gap--115 {
  gap: 115px !important;
}

.scale--115 {
  scale: 1.15 !important;
}

.p--116 {
  padding: 116px !important;
}

.px--116 {
  padding-right: 116px !important;
  padding-left: 116px !important;
}

.py--116 {
  padding-top: 116px !important;
  padding-bottom: 116px !important;
}

.pt--116 {
  padding-top: 116px !important;
}

.pr--116 {
  padding-right: 116px !important;
}

.pb--116 {
  padding-bottom: 116px !important;
}

.pl--116 {
  padding-left: 116px !important;
}

.m--116 {
  margin: 116px !important;
}

.mx--116 {
  margin-right: 116px !important;
  margin-left: 116px !important;
}

.my--116 {
  margin-top: 116px !important;
  margin-bottom: 116px !important;
}

.mt--116 {
  margin-top: 116px !important;
}

.mr--116 {
  margin-right: 116px !important;
}

.mb--116 {
  margin-bottom: 116px !important;
}

.ml--116 {
  margin-left: 116px !important;
}

.width--116 {
  width: 116px !important;
}

.height--116 {
  height: 116px !important;
}

.gap--116 {
  gap: 116px !important;
}

.scale--116 {
  scale: 1.16 !important;
}

.p--117 {
  padding: 117px !important;
}

.px--117 {
  padding-right: 117px !important;
  padding-left: 117px !important;
}

.py--117 {
  padding-top: 117px !important;
  padding-bottom: 117px !important;
}

.pt--117 {
  padding-top: 117px !important;
}

.pr--117 {
  padding-right: 117px !important;
}

.pb--117 {
  padding-bottom: 117px !important;
}

.pl--117 {
  padding-left: 117px !important;
}

.m--117 {
  margin: 117px !important;
}

.mx--117 {
  margin-right: 117px !important;
  margin-left: 117px !important;
}

.my--117 {
  margin-top: 117px !important;
  margin-bottom: 117px !important;
}

.mt--117 {
  margin-top: 117px !important;
}

.mr--117 {
  margin-right: 117px !important;
}

.mb--117 {
  margin-bottom: 117px !important;
}

.ml--117 {
  margin-left: 117px !important;
}

.width--117 {
  width: 117px !important;
}

.height--117 {
  height: 117px !important;
}

.gap--117 {
  gap: 117px !important;
}

.scale--117 {
  scale: 1.17 !important;
}

.p--118 {
  padding: 118px !important;
}

.px--118 {
  padding-right: 118px !important;
  padding-left: 118px !important;
}

.py--118 {
  padding-top: 118px !important;
  padding-bottom: 118px !important;
}

.pt--118 {
  padding-top: 118px !important;
}

.pr--118 {
  padding-right: 118px !important;
}

.pb--118 {
  padding-bottom: 118px !important;
}

.pl--118 {
  padding-left: 118px !important;
}

.m--118 {
  margin: 118px !important;
}

.mx--118 {
  margin-right: 118px !important;
  margin-left: 118px !important;
}

.my--118 {
  margin-top: 118px !important;
  margin-bottom: 118px !important;
}

.mt--118 {
  margin-top: 118px !important;
}

.mr--118 {
  margin-right: 118px !important;
}

.mb--118 {
  margin-bottom: 118px !important;
}

.ml--118 {
  margin-left: 118px !important;
}

.width--118 {
  width: 118px !important;
}

.height--118 {
  height: 118px !important;
}

.gap--118 {
  gap: 118px !important;
}

.scale--118 {
  scale: 1.18 !important;
}

.p--119 {
  padding: 119px !important;
}

.px--119 {
  padding-right: 119px !important;
  padding-left: 119px !important;
}

.py--119 {
  padding-top: 119px !important;
  padding-bottom: 119px !important;
}

.pt--119 {
  padding-top: 119px !important;
}

.pr--119 {
  padding-right: 119px !important;
}

.pb--119 {
  padding-bottom: 119px !important;
}

.pl--119 {
  padding-left: 119px !important;
}

.m--119 {
  margin: 119px !important;
}

.mx--119 {
  margin-right: 119px !important;
  margin-left: 119px !important;
}

.my--119 {
  margin-top: 119px !important;
  margin-bottom: 119px !important;
}

.mt--119 {
  margin-top: 119px !important;
}

.mr--119 {
  margin-right: 119px !important;
}

.mb--119 {
  margin-bottom: 119px !important;
}

.ml--119 {
  margin-left: 119px !important;
}

.width--119 {
  width: 119px !important;
}

.height--119 {
  height: 119px !important;
}

.gap--119 {
  gap: 119px !important;
}

.scale--119 {
  scale: 1.19 !important;
}

.p--120 {
  padding: 120px !important;
}

.px--120 {
  padding-right: 120px !important;
  padding-left: 120px !important;
}

.py--120 {
  padding-top: 120px !important;
  padding-bottom: 120px !important;
}

.pt--120 {
  padding-top: 120px !important;
}

.pr--120 {
  padding-right: 120px !important;
}

.pb--120 {
  padding-bottom: 120px !important;
}

.pl--120 {
  padding-left: 120px !important;
}

.m--120 {
  margin: 120px !important;
}

.mx--120 {
  margin-right: 120px !important;
  margin-left: 120px !important;
}

.my--120 {
  margin-top: 120px !important;
  margin-bottom: 120px !important;
}

.mt--120 {
  margin-top: 120px !important;
}

.mr--120 {
  margin-right: 120px !important;
}

.mb--120 {
  margin-bottom: 120px !important;
}

.ml--120 {
  margin-left: 120px !important;
}

.width--120 {
  width: 120px !important;
}

.height--120 {
  height: 120px !important;
}

.gap--120 {
  gap: 120px !important;
}

.scale--120 {
  scale: 1.2 !important;
}

.p--121 {
  padding: 121px !important;
}

.px--121 {
  padding-right: 121px !important;
  padding-left: 121px !important;
}

.py--121 {
  padding-top: 121px !important;
  padding-bottom: 121px !important;
}

.pt--121 {
  padding-top: 121px !important;
}

.pr--121 {
  padding-right: 121px !important;
}

.pb--121 {
  padding-bottom: 121px !important;
}

.pl--121 {
  padding-left: 121px !important;
}

.m--121 {
  margin: 121px !important;
}

.mx--121 {
  margin-right: 121px !important;
  margin-left: 121px !important;
}

.my--121 {
  margin-top: 121px !important;
  margin-bottom: 121px !important;
}

.mt--121 {
  margin-top: 121px !important;
}

.mr--121 {
  margin-right: 121px !important;
}

.mb--121 {
  margin-bottom: 121px !important;
}

.ml--121 {
  margin-left: 121px !important;
}

.width--121 {
  width: 121px !important;
}

.height--121 {
  height: 121px !important;
}

.gap--121 {
  gap: 121px !important;
}

.scale--121 {
  scale: 1.21 !important;
}

.p--122 {
  padding: 122px !important;
}

.px--122 {
  padding-right: 122px !important;
  padding-left: 122px !important;
}

.py--122 {
  padding-top: 122px !important;
  padding-bottom: 122px !important;
}

.pt--122 {
  padding-top: 122px !important;
}

.pr--122 {
  padding-right: 122px !important;
}

.pb--122 {
  padding-bottom: 122px !important;
}

.pl--122 {
  padding-left: 122px !important;
}

.m--122 {
  margin: 122px !important;
}

.mx--122 {
  margin-right: 122px !important;
  margin-left: 122px !important;
}

.my--122 {
  margin-top: 122px !important;
  margin-bottom: 122px !important;
}

.mt--122 {
  margin-top: 122px !important;
}

.mr--122 {
  margin-right: 122px !important;
}

.mb--122 {
  margin-bottom: 122px !important;
}

.ml--122 {
  margin-left: 122px !important;
}

.width--122 {
  width: 122px !important;
}

.height--122 {
  height: 122px !important;
}

.gap--122 {
  gap: 122px !important;
}

.scale--122 {
  scale: 1.22 !important;
}

.p--123 {
  padding: 123px !important;
}

.px--123 {
  padding-right: 123px !important;
  padding-left: 123px !important;
}

.py--123 {
  padding-top: 123px !important;
  padding-bottom: 123px !important;
}

.pt--123 {
  padding-top: 123px !important;
}

.pr--123 {
  padding-right: 123px !important;
}

.pb--123 {
  padding-bottom: 123px !important;
}

.pl--123 {
  padding-left: 123px !important;
}

.m--123 {
  margin: 123px !important;
}

.mx--123 {
  margin-right: 123px !important;
  margin-left: 123px !important;
}

.my--123 {
  margin-top: 123px !important;
  margin-bottom: 123px !important;
}

.mt--123 {
  margin-top: 123px !important;
}

.mr--123 {
  margin-right: 123px !important;
}

.mb--123 {
  margin-bottom: 123px !important;
}

.ml--123 {
  margin-left: 123px !important;
}

.width--123 {
  width: 123px !important;
}

.height--123 {
  height: 123px !important;
}

.gap--123 {
  gap: 123px !important;
}

.scale--123 {
  scale: 1.23 !important;
}

.p--124 {
  padding: 124px !important;
}

.px--124 {
  padding-right: 124px !important;
  padding-left: 124px !important;
}

.py--124 {
  padding-top: 124px !important;
  padding-bottom: 124px !important;
}

.pt--124 {
  padding-top: 124px !important;
}

.pr--124 {
  padding-right: 124px !important;
}

.pb--124 {
  padding-bottom: 124px !important;
}

.pl--124 {
  padding-left: 124px !important;
}

.m--124 {
  margin: 124px !important;
}

.mx--124 {
  margin-right: 124px !important;
  margin-left: 124px !important;
}

.my--124 {
  margin-top: 124px !important;
  margin-bottom: 124px !important;
}

.mt--124 {
  margin-top: 124px !important;
}

.mr--124 {
  margin-right: 124px !important;
}

.mb--124 {
  margin-bottom: 124px !important;
}

.ml--124 {
  margin-left: 124px !important;
}

.width--124 {
  width: 124px !important;
}

.height--124 {
  height: 124px !important;
}

.gap--124 {
  gap: 124px !important;
}

.scale--124 {
  scale: 1.24 !important;
}

.p--125 {
  padding: 125px !important;
}

.px--125 {
  padding-right: 125px !important;
  padding-left: 125px !important;
}

.py--125 {
  padding-top: 125px !important;
  padding-bottom: 125px !important;
}

.pt--125 {
  padding-top: 125px !important;
}

.pr--125 {
  padding-right: 125px !important;
}

.pb--125 {
  padding-bottom: 125px !important;
}

.pl--125 {
  padding-left: 125px !important;
}

.m--125 {
  margin: 125px !important;
}

.mx--125 {
  margin-right: 125px !important;
  margin-left: 125px !important;
}

.my--125 {
  margin-top: 125px !important;
  margin-bottom: 125px !important;
}

.mt--125 {
  margin-top: 125px !important;
}

.mr--125 {
  margin-right: 125px !important;
}

.mb--125 {
  margin-bottom: 125px !important;
}

.ml--125 {
  margin-left: 125px !important;
}

.width--125 {
  width: 125px !important;
}

.height--125 {
  height: 125px !important;
}

.gap--125 {
  gap: 125px !important;
}

.scale--125 {
  scale: 1.25 !important;
}

.p--126 {
  padding: 126px !important;
}

.px--126 {
  padding-right: 126px !important;
  padding-left: 126px !important;
}

.py--126 {
  padding-top: 126px !important;
  padding-bottom: 126px !important;
}

.pt--126 {
  padding-top: 126px !important;
}

.pr--126 {
  padding-right: 126px !important;
}

.pb--126 {
  padding-bottom: 126px !important;
}

.pl--126 {
  padding-left: 126px !important;
}

.m--126 {
  margin: 126px !important;
}

.mx--126 {
  margin-right: 126px !important;
  margin-left: 126px !important;
}

.my--126 {
  margin-top: 126px !important;
  margin-bottom: 126px !important;
}

.mt--126 {
  margin-top: 126px !important;
}

.mr--126 {
  margin-right: 126px !important;
}

.mb--126 {
  margin-bottom: 126px !important;
}

.ml--126 {
  margin-left: 126px !important;
}

.width--126 {
  width: 126px !important;
}

.height--126 {
  height: 126px !important;
}

.gap--126 {
  gap: 126px !important;
}

.scale--126 {
  scale: 1.26 !important;
}

.p--127 {
  padding: 127px !important;
}

.px--127 {
  padding-right: 127px !important;
  padding-left: 127px !important;
}

.py--127 {
  padding-top: 127px !important;
  padding-bottom: 127px !important;
}

.pt--127 {
  padding-top: 127px !important;
}

.pr--127 {
  padding-right: 127px !important;
}

.pb--127 {
  padding-bottom: 127px !important;
}

.pl--127 {
  padding-left: 127px !important;
}

.m--127 {
  margin: 127px !important;
}

.mx--127 {
  margin-right: 127px !important;
  margin-left: 127px !important;
}

.my--127 {
  margin-top: 127px !important;
  margin-bottom: 127px !important;
}

.mt--127 {
  margin-top: 127px !important;
}

.mr--127 {
  margin-right: 127px !important;
}

.mb--127 {
  margin-bottom: 127px !important;
}

.ml--127 {
  margin-left: 127px !important;
}

.width--127 {
  width: 127px !important;
}

.height--127 {
  height: 127px !important;
}

.gap--127 {
  gap: 127px !important;
}

.scale--127 {
  scale: 1.27 !important;
}

.p--128 {
  padding: 128px !important;
}

.px--128 {
  padding-right: 128px !important;
  padding-left: 128px !important;
}

.py--128 {
  padding-top: 128px !important;
  padding-bottom: 128px !important;
}

.pt--128 {
  padding-top: 128px !important;
}

.pr--128 {
  padding-right: 128px !important;
}

.pb--128 {
  padding-bottom: 128px !important;
}

.pl--128 {
  padding-left: 128px !important;
}

.m--128 {
  margin: 128px !important;
}

.mx--128 {
  margin-right: 128px !important;
  margin-left: 128px !important;
}

.my--128 {
  margin-top: 128px !important;
  margin-bottom: 128px !important;
}

.mt--128 {
  margin-top: 128px !important;
}

.mr--128 {
  margin-right: 128px !important;
}

.mb--128 {
  margin-bottom: 128px !important;
}

.ml--128 {
  margin-left: 128px !important;
}

.width--128 {
  width: 128px !important;
}

.height--128 {
  height: 128px !important;
}

.gap--128 {
  gap: 128px !important;
}

.scale--128 {
  scale: 1.28 !important;
}

.p--129 {
  padding: 129px !important;
}

.px--129 {
  padding-right: 129px !important;
  padding-left: 129px !important;
}

.py--129 {
  padding-top: 129px !important;
  padding-bottom: 129px !important;
}

.pt--129 {
  padding-top: 129px !important;
}

.pr--129 {
  padding-right: 129px !important;
}

.pb--129 {
  padding-bottom: 129px !important;
}

.pl--129 {
  padding-left: 129px !important;
}

.m--129 {
  margin: 129px !important;
}

.mx--129 {
  margin-right: 129px !important;
  margin-left: 129px !important;
}

.my--129 {
  margin-top: 129px !important;
  margin-bottom: 129px !important;
}

.mt--129 {
  margin-top: 129px !important;
}

.mr--129 {
  margin-right: 129px !important;
}

.mb--129 {
  margin-bottom: 129px !important;
}

.ml--129 {
  margin-left: 129px !important;
}

.width--129 {
  width: 129px !important;
}

.height--129 {
  height: 129px !important;
}

.gap--129 {
  gap: 129px !important;
}

.scale--129 {
  scale: 1.29 !important;
}

.p--130 {
  padding: 130px !important;
}

.px--130 {
  padding-right: 130px !important;
  padding-left: 130px !important;
}

.py--130 {
  padding-top: 130px !important;
  padding-bottom: 130px !important;
}

.pt--130 {
  padding-top: 130px !important;
}

.pr--130 {
  padding-right: 130px !important;
}

.pb--130 {
  padding-bottom: 130px !important;
}

.pl--130 {
  padding-left: 130px !important;
}

.m--130 {
  margin: 130px !important;
}

.mx--130 {
  margin-right: 130px !important;
  margin-left: 130px !important;
}

.my--130 {
  margin-top: 130px !important;
  margin-bottom: 130px !important;
}

.mt--130 {
  margin-top: 130px !important;
}

.mr--130 {
  margin-right: 130px !important;
}

.mb--130 {
  margin-bottom: 130px !important;
}

.ml--130 {
  margin-left: 130px !important;
}

.width--130 {
  width: 130px !important;
}

.height--130 {
  height: 130px !important;
}

.gap--130 {
  gap: 130px !important;
}

.scale--130 {
  scale: 1.3 !important;
}

.p--131 {
  padding: 131px !important;
}

.px--131 {
  padding-right: 131px !important;
  padding-left: 131px !important;
}

.py--131 {
  padding-top: 131px !important;
  padding-bottom: 131px !important;
}

.pt--131 {
  padding-top: 131px !important;
}

.pr--131 {
  padding-right: 131px !important;
}

.pb--131 {
  padding-bottom: 131px !important;
}

.pl--131 {
  padding-left: 131px !important;
}

.m--131 {
  margin: 131px !important;
}

.mx--131 {
  margin-right: 131px !important;
  margin-left: 131px !important;
}

.my--131 {
  margin-top: 131px !important;
  margin-bottom: 131px !important;
}

.mt--131 {
  margin-top: 131px !important;
}

.mr--131 {
  margin-right: 131px !important;
}

.mb--131 {
  margin-bottom: 131px !important;
}

.ml--131 {
  margin-left: 131px !important;
}

.width--131 {
  width: 131px !important;
}

.height--131 {
  height: 131px !important;
}

.gap--131 {
  gap: 131px !important;
}

.scale--131 {
  scale: 1.31 !important;
}

.p--132 {
  padding: 132px !important;
}

.px--132 {
  padding-right: 132px !important;
  padding-left: 132px !important;
}

.py--132 {
  padding-top: 132px !important;
  padding-bottom: 132px !important;
}

.pt--132 {
  padding-top: 132px !important;
}

.pr--132 {
  padding-right: 132px !important;
}

.pb--132 {
  padding-bottom: 132px !important;
}

.pl--132 {
  padding-left: 132px !important;
}

.m--132 {
  margin: 132px !important;
}

.mx--132 {
  margin-right: 132px !important;
  margin-left: 132px !important;
}

.my--132 {
  margin-top: 132px !important;
  margin-bottom: 132px !important;
}

.mt--132 {
  margin-top: 132px !important;
}

.mr--132 {
  margin-right: 132px !important;
}

.mb--132 {
  margin-bottom: 132px !important;
}

.ml--132 {
  margin-left: 132px !important;
}

.width--132 {
  width: 132px !important;
}

.height--132 {
  height: 132px !important;
}

.gap--132 {
  gap: 132px !important;
}

.scale--132 {
  scale: 1.32 !important;
}

.p--133 {
  padding: 133px !important;
}

.px--133 {
  padding-right: 133px !important;
  padding-left: 133px !important;
}

.py--133 {
  padding-top: 133px !important;
  padding-bottom: 133px !important;
}

.pt--133 {
  padding-top: 133px !important;
}

.pr--133 {
  padding-right: 133px !important;
}

.pb--133 {
  padding-bottom: 133px !important;
}

.pl--133 {
  padding-left: 133px !important;
}

.m--133 {
  margin: 133px !important;
}

.mx--133 {
  margin-right: 133px !important;
  margin-left: 133px !important;
}

.my--133 {
  margin-top: 133px !important;
  margin-bottom: 133px !important;
}

.mt--133 {
  margin-top: 133px !important;
}

.mr--133 {
  margin-right: 133px !important;
}

.mb--133 {
  margin-bottom: 133px !important;
}

.ml--133 {
  margin-left: 133px !important;
}

.width--133 {
  width: 133px !important;
}

.height--133 {
  height: 133px !important;
}

.gap--133 {
  gap: 133px !important;
}

.scale--133 {
  scale: 1.33 !important;
}

.p--134 {
  padding: 134px !important;
}

.px--134 {
  padding-right: 134px !important;
  padding-left: 134px !important;
}

.py--134 {
  padding-top: 134px !important;
  padding-bottom: 134px !important;
}

.pt--134 {
  padding-top: 134px !important;
}

.pr--134 {
  padding-right: 134px !important;
}

.pb--134 {
  padding-bottom: 134px !important;
}

.pl--134 {
  padding-left: 134px !important;
}

.m--134 {
  margin: 134px !important;
}

.mx--134 {
  margin-right: 134px !important;
  margin-left: 134px !important;
}

.my--134 {
  margin-top: 134px !important;
  margin-bottom: 134px !important;
}

.mt--134 {
  margin-top: 134px !important;
}

.mr--134 {
  margin-right: 134px !important;
}

.mb--134 {
  margin-bottom: 134px !important;
}

.ml--134 {
  margin-left: 134px !important;
}

.width--134 {
  width: 134px !important;
}

.height--134 {
  height: 134px !important;
}

.gap--134 {
  gap: 134px !important;
}

.scale--134 {
  scale: 1.34 !important;
}

.p--135 {
  padding: 135px !important;
}

.px--135 {
  padding-right: 135px !important;
  padding-left: 135px !important;
}

.py--135 {
  padding-top: 135px !important;
  padding-bottom: 135px !important;
}

.pt--135 {
  padding-top: 135px !important;
}

.pr--135 {
  padding-right: 135px !important;
}

.pb--135 {
  padding-bottom: 135px !important;
}

.pl--135 {
  padding-left: 135px !important;
}

.m--135 {
  margin: 135px !important;
}

.mx--135 {
  margin-right: 135px !important;
  margin-left: 135px !important;
}

.my--135 {
  margin-top: 135px !important;
  margin-bottom: 135px !important;
}

.mt--135 {
  margin-top: 135px !important;
}

.mr--135 {
  margin-right: 135px !important;
}

.mb--135 {
  margin-bottom: 135px !important;
}

.ml--135 {
  margin-left: 135px !important;
}

.width--135 {
  width: 135px !important;
}

.height--135 {
  height: 135px !important;
}

.gap--135 {
  gap: 135px !important;
}

.scale--135 {
  scale: 1.35 !important;
}

.p--136 {
  padding: 136px !important;
}

.px--136 {
  padding-right: 136px !important;
  padding-left: 136px !important;
}

.py--136 {
  padding-top: 136px !important;
  padding-bottom: 136px !important;
}

.pt--136 {
  padding-top: 136px !important;
}

.pr--136 {
  padding-right: 136px !important;
}

.pb--136 {
  padding-bottom: 136px !important;
}

.pl--136 {
  padding-left: 136px !important;
}

.m--136 {
  margin: 136px !important;
}

.mx--136 {
  margin-right: 136px !important;
  margin-left: 136px !important;
}

.my--136 {
  margin-top: 136px !important;
  margin-bottom: 136px !important;
}

.mt--136 {
  margin-top: 136px !important;
}

.mr--136 {
  margin-right: 136px !important;
}

.mb--136 {
  margin-bottom: 136px !important;
}

.ml--136 {
  margin-left: 136px !important;
}

.width--136 {
  width: 136px !important;
}

.height--136 {
  height: 136px !important;
}

.gap--136 {
  gap: 136px !important;
}

.scale--136 {
  scale: 1.36 !important;
}

.p--137 {
  padding: 137px !important;
}

.px--137 {
  padding-right: 137px !important;
  padding-left: 137px !important;
}

.py--137 {
  padding-top: 137px !important;
  padding-bottom: 137px !important;
}

.pt--137 {
  padding-top: 137px !important;
}

.pr--137 {
  padding-right: 137px !important;
}

.pb--137 {
  padding-bottom: 137px !important;
}

.pl--137 {
  padding-left: 137px !important;
}

.m--137 {
  margin: 137px !important;
}

.mx--137 {
  margin-right: 137px !important;
  margin-left: 137px !important;
}

.my--137 {
  margin-top: 137px !important;
  margin-bottom: 137px !important;
}

.mt--137 {
  margin-top: 137px !important;
}

.mr--137 {
  margin-right: 137px !important;
}

.mb--137 {
  margin-bottom: 137px !important;
}

.ml--137 {
  margin-left: 137px !important;
}

.width--137 {
  width: 137px !important;
}

.height--137 {
  height: 137px !important;
}

.gap--137 {
  gap: 137px !important;
}

.scale--137 {
  scale: 1.37 !important;
}

.p--138 {
  padding: 138px !important;
}

.px--138 {
  padding-right: 138px !important;
  padding-left: 138px !important;
}

.py--138 {
  padding-top: 138px !important;
  padding-bottom: 138px !important;
}

.pt--138 {
  padding-top: 138px !important;
}

.pr--138 {
  padding-right: 138px !important;
}

.pb--138 {
  padding-bottom: 138px !important;
}

.pl--138 {
  padding-left: 138px !important;
}

.m--138 {
  margin: 138px !important;
}

.mx--138 {
  margin-right: 138px !important;
  margin-left: 138px !important;
}

.my--138 {
  margin-top: 138px !important;
  margin-bottom: 138px !important;
}

.mt--138 {
  margin-top: 138px !important;
}

.mr--138 {
  margin-right: 138px !important;
}

.mb--138 {
  margin-bottom: 138px !important;
}

.ml--138 {
  margin-left: 138px !important;
}

.width--138 {
  width: 138px !important;
}

.height--138 {
  height: 138px !important;
}

.gap--138 {
  gap: 138px !important;
}

.scale--138 {
  scale: 1.38 !important;
}

.p--139 {
  padding: 139px !important;
}

.px--139 {
  padding-right: 139px !important;
  padding-left: 139px !important;
}

.py--139 {
  padding-top: 139px !important;
  padding-bottom: 139px !important;
}

.pt--139 {
  padding-top: 139px !important;
}

.pr--139 {
  padding-right: 139px !important;
}

.pb--139 {
  padding-bottom: 139px !important;
}

.pl--139 {
  padding-left: 139px !important;
}

.m--139 {
  margin: 139px !important;
}

.mx--139 {
  margin-right: 139px !important;
  margin-left: 139px !important;
}

.my--139 {
  margin-top: 139px !important;
  margin-bottom: 139px !important;
}

.mt--139 {
  margin-top: 139px !important;
}

.mr--139 {
  margin-right: 139px !important;
}

.mb--139 {
  margin-bottom: 139px !important;
}

.ml--139 {
  margin-left: 139px !important;
}

.width--139 {
  width: 139px !important;
}

.height--139 {
  height: 139px !important;
}

.gap--139 {
  gap: 139px !important;
}

.scale--139 {
  scale: 1.39 !important;
}

.p--140 {
  padding: 140px !important;
}

.px--140 {
  padding-right: 140px !important;
  padding-left: 140px !important;
}

.py--140 {
  padding-top: 140px !important;
  padding-bottom: 140px !important;
}

.pt--140 {
  padding-top: 140px !important;
}

.pr--140 {
  padding-right: 140px !important;
}

.pb--140 {
  padding-bottom: 140px !important;
}

.pl--140 {
  padding-left: 140px !important;
}

.m--140 {
  margin: 140px !important;
}

.mx--140 {
  margin-right: 140px !important;
  margin-left: 140px !important;
}

.my--140 {
  margin-top: 140px !important;
  margin-bottom: 140px !important;
}

.mt--140 {
  margin-top: 140px !important;
}

.mr--140 {
  margin-right: 140px !important;
}

.mb--140 {
  margin-bottom: 140px !important;
}

.ml--140 {
  margin-left: 140px !important;
}

.width--140 {
  width: 140px !important;
}

.height--140 {
  height: 140px !important;
}

.gap--140 {
  gap: 140px !important;
}

.scale--140 {
  scale: 1.4 !important;
}

.p--141 {
  padding: 141px !important;
}

.px--141 {
  padding-right: 141px !important;
  padding-left: 141px !important;
}

.py--141 {
  padding-top: 141px !important;
  padding-bottom: 141px !important;
}

.pt--141 {
  padding-top: 141px !important;
}

.pr--141 {
  padding-right: 141px !important;
}

.pb--141 {
  padding-bottom: 141px !important;
}

.pl--141 {
  padding-left: 141px !important;
}

.m--141 {
  margin: 141px !important;
}

.mx--141 {
  margin-right: 141px !important;
  margin-left: 141px !important;
}

.my--141 {
  margin-top: 141px !important;
  margin-bottom: 141px !important;
}

.mt--141 {
  margin-top: 141px !important;
}

.mr--141 {
  margin-right: 141px !important;
}

.mb--141 {
  margin-bottom: 141px !important;
}

.ml--141 {
  margin-left: 141px !important;
}

.width--141 {
  width: 141px !important;
}

.height--141 {
  height: 141px !important;
}

.gap--141 {
  gap: 141px !important;
}

.scale--141 {
  scale: 1.41 !important;
}

.p--142 {
  padding: 142px !important;
}

.px--142 {
  padding-right: 142px !important;
  padding-left: 142px !important;
}

.py--142 {
  padding-top: 142px !important;
  padding-bottom: 142px !important;
}

.pt--142 {
  padding-top: 142px !important;
}

.pr--142 {
  padding-right: 142px !important;
}

.pb--142 {
  padding-bottom: 142px !important;
}

.pl--142 {
  padding-left: 142px !important;
}

.m--142 {
  margin: 142px !important;
}

.mx--142 {
  margin-right: 142px !important;
  margin-left: 142px !important;
}

.my--142 {
  margin-top: 142px !important;
  margin-bottom: 142px !important;
}

.mt--142 {
  margin-top: 142px !important;
}

.mr--142 {
  margin-right: 142px !important;
}

.mb--142 {
  margin-bottom: 142px !important;
}

.ml--142 {
  margin-left: 142px !important;
}

.width--142 {
  width: 142px !important;
}

.height--142 {
  height: 142px !important;
}

.gap--142 {
  gap: 142px !important;
}

.scale--142 {
  scale: 1.42 !important;
}

.p--143 {
  padding: 143px !important;
}

.px--143 {
  padding-right: 143px !important;
  padding-left: 143px !important;
}

.py--143 {
  padding-top: 143px !important;
  padding-bottom: 143px !important;
}

.pt--143 {
  padding-top: 143px !important;
}

.pr--143 {
  padding-right: 143px !important;
}

.pb--143 {
  padding-bottom: 143px !important;
}

.pl--143 {
  padding-left: 143px !important;
}

.m--143 {
  margin: 143px !important;
}

.mx--143 {
  margin-right: 143px !important;
  margin-left: 143px !important;
}

.my--143 {
  margin-top: 143px !important;
  margin-bottom: 143px !important;
}

.mt--143 {
  margin-top: 143px !important;
}

.mr--143 {
  margin-right: 143px !important;
}

.mb--143 {
  margin-bottom: 143px !important;
}

.ml--143 {
  margin-left: 143px !important;
}

.width--143 {
  width: 143px !important;
}

.height--143 {
  height: 143px !important;
}

.gap--143 {
  gap: 143px !important;
}

.scale--143 {
  scale: 1.43 !important;
}

.p--144 {
  padding: 144px !important;
}

.px--144 {
  padding-right: 144px !important;
  padding-left: 144px !important;
}

.py--144 {
  padding-top: 144px !important;
  padding-bottom: 144px !important;
}

.pt--144 {
  padding-top: 144px !important;
}

.pr--144 {
  padding-right: 144px !important;
}

.pb--144 {
  padding-bottom: 144px !important;
}

.pl--144 {
  padding-left: 144px !important;
}

.m--144 {
  margin: 144px !important;
}

.mx--144 {
  margin-right: 144px !important;
  margin-left: 144px !important;
}

.my--144 {
  margin-top: 144px !important;
  margin-bottom: 144px !important;
}

.mt--144 {
  margin-top: 144px !important;
}

.mr--144 {
  margin-right: 144px !important;
}

.mb--144 {
  margin-bottom: 144px !important;
}

.ml--144 {
  margin-left: 144px !important;
}

.width--144 {
  width: 144px !important;
}

.height--144 {
  height: 144px !important;
}

.gap--144 {
  gap: 144px !important;
}

.scale--144 {
  scale: 1.44 !important;
}

.p--145 {
  padding: 145px !important;
}

.px--145 {
  padding-right: 145px !important;
  padding-left: 145px !important;
}

.py--145 {
  padding-top: 145px !important;
  padding-bottom: 145px !important;
}

.pt--145 {
  padding-top: 145px !important;
}

.pr--145 {
  padding-right: 145px !important;
}

.pb--145 {
  padding-bottom: 145px !important;
}

.pl--145 {
  padding-left: 145px !important;
}

.m--145 {
  margin: 145px !important;
}

.mx--145 {
  margin-right: 145px !important;
  margin-left: 145px !important;
}

.my--145 {
  margin-top: 145px !important;
  margin-bottom: 145px !important;
}

.mt--145 {
  margin-top: 145px !important;
}

.mr--145 {
  margin-right: 145px !important;
}

.mb--145 {
  margin-bottom: 145px !important;
}

.ml--145 {
  margin-left: 145px !important;
}

.width--145 {
  width: 145px !important;
}

.height--145 {
  height: 145px !important;
}

.gap--145 {
  gap: 145px !important;
}

.scale--145 {
  scale: 1.45 !important;
}

.p--146 {
  padding: 146px !important;
}

.px--146 {
  padding-right: 146px !important;
  padding-left: 146px !important;
}

.py--146 {
  padding-top: 146px !important;
  padding-bottom: 146px !important;
}

.pt--146 {
  padding-top: 146px !important;
}

.pr--146 {
  padding-right: 146px !important;
}

.pb--146 {
  padding-bottom: 146px !important;
}

.pl--146 {
  padding-left: 146px !important;
}

.m--146 {
  margin: 146px !important;
}

.mx--146 {
  margin-right: 146px !important;
  margin-left: 146px !important;
}

.my--146 {
  margin-top: 146px !important;
  margin-bottom: 146px !important;
}

.mt--146 {
  margin-top: 146px !important;
}

.mr--146 {
  margin-right: 146px !important;
}

.mb--146 {
  margin-bottom: 146px !important;
}

.ml--146 {
  margin-left: 146px !important;
}

.width--146 {
  width: 146px !important;
}

.height--146 {
  height: 146px !important;
}

.gap--146 {
  gap: 146px !important;
}

.scale--146 {
  scale: 1.46 !important;
}

.p--147 {
  padding: 147px !important;
}

.px--147 {
  padding-right: 147px !important;
  padding-left: 147px !important;
}

.py--147 {
  padding-top: 147px !important;
  padding-bottom: 147px !important;
}

.pt--147 {
  padding-top: 147px !important;
}

.pr--147 {
  padding-right: 147px !important;
}

.pb--147 {
  padding-bottom: 147px !important;
}

.pl--147 {
  padding-left: 147px !important;
}

.m--147 {
  margin: 147px !important;
}

.mx--147 {
  margin-right: 147px !important;
  margin-left: 147px !important;
}

.my--147 {
  margin-top: 147px !important;
  margin-bottom: 147px !important;
}

.mt--147 {
  margin-top: 147px !important;
}

.mr--147 {
  margin-right: 147px !important;
}

.mb--147 {
  margin-bottom: 147px !important;
}

.ml--147 {
  margin-left: 147px !important;
}

.width--147 {
  width: 147px !important;
}

.height--147 {
  height: 147px !important;
}

.gap--147 {
  gap: 147px !important;
}

.scale--147 {
  scale: 1.47 !important;
}

.p--148 {
  padding: 148px !important;
}

.px--148 {
  padding-right: 148px !important;
  padding-left: 148px !important;
}

.py--148 {
  padding-top: 148px !important;
  padding-bottom: 148px !important;
}

.pt--148 {
  padding-top: 148px !important;
}

.pr--148 {
  padding-right: 148px !important;
}

.pb--148 {
  padding-bottom: 148px !important;
}

.pl--148 {
  padding-left: 148px !important;
}

.m--148 {
  margin: 148px !important;
}

.mx--148 {
  margin-right: 148px !important;
  margin-left: 148px !important;
}

.my--148 {
  margin-top: 148px !important;
  margin-bottom: 148px !important;
}

.mt--148 {
  margin-top: 148px !important;
}

.mr--148 {
  margin-right: 148px !important;
}

.mb--148 {
  margin-bottom: 148px !important;
}

.ml--148 {
  margin-left: 148px !important;
}

.width--148 {
  width: 148px !important;
}

.height--148 {
  height: 148px !important;
}

.gap--148 {
  gap: 148px !important;
}

.scale--148 {
  scale: 1.48 !important;
}

.p--149 {
  padding: 149px !important;
}

.px--149 {
  padding-right: 149px !important;
  padding-left: 149px !important;
}

.py--149 {
  padding-top: 149px !important;
  padding-bottom: 149px !important;
}

.pt--149 {
  padding-top: 149px !important;
}

.pr--149 {
  padding-right: 149px !important;
}

.pb--149 {
  padding-bottom: 149px !important;
}

.pl--149 {
  padding-left: 149px !important;
}

.m--149 {
  margin: 149px !important;
}

.mx--149 {
  margin-right: 149px !important;
  margin-left: 149px !important;
}

.my--149 {
  margin-top: 149px !important;
  margin-bottom: 149px !important;
}

.mt--149 {
  margin-top: 149px !important;
}

.mr--149 {
  margin-right: 149px !important;
}

.mb--149 {
  margin-bottom: 149px !important;
}

.ml--149 {
  margin-left: 149px !important;
}

.width--149 {
  width: 149px !important;
}

.height--149 {
  height: 149px !important;
}

.gap--149 {
  gap: 149px !important;
}

.scale--149 {
  scale: 1.49 !important;
}

.p--150 {
  padding: 150px !important;
}

.px--150 {
  padding-right: 150px !important;
  padding-left: 150px !important;
}

.py--150 {
  padding-top: 150px !important;
  padding-bottom: 150px !important;
}

.pt--150 {
  padding-top: 150px !important;
}

.pr--150 {
  padding-right: 150px !important;
}

.pb--150 {
  padding-bottom: 150px !important;
}

.pl--150 {
  padding-left: 150px !important;
}

.m--150 {
  margin: 150px !important;
}

.mx--150 {
  margin-right: 150px !important;
  margin-left: 150px !important;
}

.my--150 {
  margin-top: 150px !important;
  margin-bottom: 150px !important;
}

.mt--150 {
  margin-top: 150px !important;
}

.mr--150 {
  margin-right: 150px !important;
}

.mb--150 {
  margin-bottom: 150px !important;
}

.ml--150 {
  margin-left: 150px !important;
}

.width--150 {
  width: 150px !important;
}

.height--150 {
  height: 150px !important;
}

.gap--150 {
  gap: 150px !important;
}

.scale--150 {
  scale: 1.5 !important;
}

.p--151 {
  padding: 151px !important;
}

.px--151 {
  padding-right: 151px !important;
  padding-left: 151px !important;
}

.py--151 {
  padding-top: 151px !important;
  padding-bottom: 151px !important;
}

.pt--151 {
  padding-top: 151px !important;
}

.pr--151 {
  padding-right: 151px !important;
}

.pb--151 {
  padding-bottom: 151px !important;
}

.pl--151 {
  padding-left: 151px !important;
}

.m--151 {
  margin: 151px !important;
}

.mx--151 {
  margin-right: 151px !important;
  margin-left: 151px !important;
}

.my--151 {
  margin-top: 151px !important;
  margin-bottom: 151px !important;
}

.mt--151 {
  margin-top: 151px !important;
}

.mr--151 {
  margin-right: 151px !important;
}

.mb--151 {
  margin-bottom: 151px !important;
}

.ml--151 {
  margin-left: 151px !important;
}

.width--151 {
  width: 151px !important;
}

.height--151 {
  height: 151px !important;
}

.gap--151 {
  gap: 151px !important;
}

.scale--151 {
  scale: 1.51 !important;
}

.p--152 {
  padding: 152px !important;
}

.px--152 {
  padding-right: 152px !important;
  padding-left: 152px !important;
}

.py--152 {
  padding-top: 152px !important;
  padding-bottom: 152px !important;
}

.pt--152 {
  padding-top: 152px !important;
}

.pr--152 {
  padding-right: 152px !important;
}

.pb--152 {
  padding-bottom: 152px !important;
}

.pl--152 {
  padding-left: 152px !important;
}

.m--152 {
  margin: 152px !important;
}

.mx--152 {
  margin-right: 152px !important;
  margin-left: 152px !important;
}

.my--152 {
  margin-top: 152px !important;
  margin-bottom: 152px !important;
}

.mt--152 {
  margin-top: 152px !important;
}

.mr--152 {
  margin-right: 152px !important;
}

.mb--152 {
  margin-bottom: 152px !important;
}

.ml--152 {
  margin-left: 152px !important;
}

.width--152 {
  width: 152px !important;
}

.height--152 {
  height: 152px !important;
}

.gap--152 {
  gap: 152px !important;
}

.scale--152 {
  scale: 1.52 !important;
}

.p--153 {
  padding: 153px !important;
}

.px--153 {
  padding-right: 153px !important;
  padding-left: 153px !important;
}

.py--153 {
  padding-top: 153px !important;
  padding-bottom: 153px !important;
}

.pt--153 {
  padding-top: 153px !important;
}

.pr--153 {
  padding-right: 153px !important;
}

.pb--153 {
  padding-bottom: 153px !important;
}

.pl--153 {
  padding-left: 153px !important;
}

.m--153 {
  margin: 153px !important;
}

.mx--153 {
  margin-right: 153px !important;
  margin-left: 153px !important;
}

.my--153 {
  margin-top: 153px !important;
  margin-bottom: 153px !important;
}

.mt--153 {
  margin-top: 153px !important;
}

.mr--153 {
  margin-right: 153px !important;
}

.mb--153 {
  margin-bottom: 153px !important;
}

.ml--153 {
  margin-left: 153px !important;
}

.width--153 {
  width: 153px !important;
}

.height--153 {
  height: 153px !important;
}

.gap--153 {
  gap: 153px !important;
}

.scale--153 {
  scale: 1.53 !important;
}

.p--154 {
  padding: 154px !important;
}

.px--154 {
  padding-right: 154px !important;
  padding-left: 154px !important;
}

.py--154 {
  padding-top: 154px !important;
  padding-bottom: 154px !important;
}

.pt--154 {
  padding-top: 154px !important;
}

.pr--154 {
  padding-right: 154px !important;
}

.pb--154 {
  padding-bottom: 154px !important;
}

.pl--154 {
  padding-left: 154px !important;
}

.m--154 {
  margin: 154px !important;
}

.mx--154 {
  margin-right: 154px !important;
  margin-left: 154px !important;
}

.my--154 {
  margin-top: 154px !important;
  margin-bottom: 154px !important;
}

.mt--154 {
  margin-top: 154px !important;
}

.mr--154 {
  margin-right: 154px !important;
}

.mb--154 {
  margin-bottom: 154px !important;
}

.ml--154 {
  margin-left: 154px !important;
}

.width--154 {
  width: 154px !important;
}

.height--154 {
  height: 154px !important;
}

.gap--154 {
  gap: 154px !important;
}

.scale--154 {
  scale: 1.54 !important;
}

.p--155 {
  padding: 155px !important;
}

.px--155 {
  padding-right: 155px !important;
  padding-left: 155px !important;
}

.py--155 {
  padding-top: 155px !important;
  padding-bottom: 155px !important;
}

.pt--155 {
  padding-top: 155px !important;
}

.pr--155 {
  padding-right: 155px !important;
}

.pb--155 {
  padding-bottom: 155px !important;
}

.pl--155 {
  padding-left: 155px !important;
}

.m--155 {
  margin: 155px !important;
}

.mx--155 {
  margin-right: 155px !important;
  margin-left: 155px !important;
}

.my--155 {
  margin-top: 155px !important;
  margin-bottom: 155px !important;
}

.mt--155 {
  margin-top: 155px !important;
}

.mr--155 {
  margin-right: 155px !important;
}

.mb--155 {
  margin-bottom: 155px !important;
}

.ml--155 {
  margin-left: 155px !important;
}

.width--155 {
  width: 155px !important;
}

.height--155 {
  height: 155px !important;
}

.gap--155 {
  gap: 155px !important;
}

.scale--155 {
  scale: 1.55 !important;
}

.p--156 {
  padding: 156px !important;
}

.px--156 {
  padding-right: 156px !important;
  padding-left: 156px !important;
}

.py--156 {
  padding-top: 156px !important;
  padding-bottom: 156px !important;
}

.pt--156 {
  padding-top: 156px !important;
}

.pr--156 {
  padding-right: 156px !important;
}

.pb--156 {
  padding-bottom: 156px !important;
}

.pl--156 {
  padding-left: 156px !important;
}

.m--156 {
  margin: 156px !important;
}

.mx--156 {
  margin-right: 156px !important;
  margin-left: 156px !important;
}

.my--156 {
  margin-top: 156px !important;
  margin-bottom: 156px !important;
}

.mt--156 {
  margin-top: 156px !important;
}

.mr--156 {
  margin-right: 156px !important;
}

.mb--156 {
  margin-bottom: 156px !important;
}

.ml--156 {
  margin-left: 156px !important;
}

.width--156 {
  width: 156px !important;
}

.height--156 {
  height: 156px !important;
}

.gap--156 {
  gap: 156px !important;
}

.scale--156 {
  scale: 1.56 !important;
}

.p--157 {
  padding: 157px !important;
}

.px--157 {
  padding-right: 157px !important;
  padding-left: 157px !important;
}

.py--157 {
  padding-top: 157px !important;
  padding-bottom: 157px !important;
}

.pt--157 {
  padding-top: 157px !important;
}

.pr--157 {
  padding-right: 157px !important;
}

.pb--157 {
  padding-bottom: 157px !important;
}

.pl--157 {
  padding-left: 157px !important;
}

.m--157 {
  margin: 157px !important;
}

.mx--157 {
  margin-right: 157px !important;
  margin-left: 157px !important;
}

.my--157 {
  margin-top: 157px !important;
  margin-bottom: 157px !important;
}

.mt--157 {
  margin-top: 157px !important;
}

.mr--157 {
  margin-right: 157px !important;
}

.mb--157 {
  margin-bottom: 157px !important;
}

.ml--157 {
  margin-left: 157px !important;
}

.width--157 {
  width: 157px !important;
}

.height--157 {
  height: 157px !important;
}

.gap--157 {
  gap: 157px !important;
}

.scale--157 {
  scale: 1.57 !important;
}

.p--158 {
  padding: 158px !important;
}

.px--158 {
  padding-right: 158px !important;
  padding-left: 158px !important;
}

.py--158 {
  padding-top: 158px !important;
  padding-bottom: 158px !important;
}

.pt--158 {
  padding-top: 158px !important;
}

.pr--158 {
  padding-right: 158px !important;
}

.pb--158 {
  padding-bottom: 158px !important;
}

.pl--158 {
  padding-left: 158px !important;
}

.m--158 {
  margin: 158px !important;
}

.mx--158 {
  margin-right: 158px !important;
  margin-left: 158px !important;
}

.my--158 {
  margin-top: 158px !important;
  margin-bottom: 158px !important;
}

.mt--158 {
  margin-top: 158px !important;
}

.mr--158 {
  margin-right: 158px !important;
}

.mb--158 {
  margin-bottom: 158px !important;
}

.ml--158 {
  margin-left: 158px !important;
}

.width--158 {
  width: 158px !important;
}

.height--158 {
  height: 158px !important;
}

.gap--158 {
  gap: 158px !important;
}

.scale--158 {
  scale: 1.58 !important;
}

.p--159 {
  padding: 159px !important;
}

.px--159 {
  padding-right: 159px !important;
  padding-left: 159px !important;
}

.py--159 {
  padding-top: 159px !important;
  padding-bottom: 159px !important;
}

.pt--159 {
  padding-top: 159px !important;
}

.pr--159 {
  padding-right: 159px !important;
}

.pb--159 {
  padding-bottom: 159px !important;
}

.pl--159 {
  padding-left: 159px !important;
}

.m--159 {
  margin: 159px !important;
}

.mx--159 {
  margin-right: 159px !important;
  margin-left: 159px !important;
}

.my--159 {
  margin-top: 159px !important;
  margin-bottom: 159px !important;
}

.mt--159 {
  margin-top: 159px !important;
}

.mr--159 {
  margin-right: 159px !important;
}

.mb--159 {
  margin-bottom: 159px !important;
}

.ml--159 {
  margin-left: 159px !important;
}

.width--159 {
  width: 159px !important;
}

.height--159 {
  height: 159px !important;
}

.gap--159 {
  gap: 159px !important;
}

.scale--159 {
  scale: 1.59 !important;
}

.p--160 {
  padding: 160px !important;
}

.px--160 {
  padding-right: 160px !important;
  padding-left: 160px !important;
}

.py--160 {
  padding-top: 160px !important;
  padding-bottom: 160px !important;
}

.pt--160 {
  padding-top: 160px !important;
}

.pr--160 {
  padding-right: 160px !important;
}

.pb--160 {
  padding-bottom: 160px !important;
}

.pl--160 {
  padding-left: 160px !important;
}

.m--160 {
  margin: 160px !important;
}

.mx--160 {
  margin-right: 160px !important;
  margin-left: 160px !important;
}

.my--160 {
  margin-top: 160px !important;
  margin-bottom: 160px !important;
}

.mt--160 {
  margin-top: 160px !important;
}

.mr--160 {
  margin-right: 160px !important;
}

.mb--160 {
  margin-bottom: 160px !important;
}

.ml--160 {
  margin-left: 160px !important;
}

.width--160 {
  width: 160px !important;
}

.height--160 {
  height: 160px !important;
}

.gap--160 {
  gap: 160px !important;
}

.scale--160 {
  scale: 1.6 !important;
}

.p--161 {
  padding: 161px !important;
}

.px--161 {
  padding-right: 161px !important;
  padding-left: 161px !important;
}

.py--161 {
  padding-top: 161px !important;
  padding-bottom: 161px !important;
}

.pt--161 {
  padding-top: 161px !important;
}

.pr--161 {
  padding-right: 161px !important;
}

.pb--161 {
  padding-bottom: 161px !important;
}

.pl--161 {
  padding-left: 161px !important;
}

.m--161 {
  margin: 161px !important;
}

.mx--161 {
  margin-right: 161px !important;
  margin-left: 161px !important;
}

.my--161 {
  margin-top: 161px !important;
  margin-bottom: 161px !important;
}

.mt--161 {
  margin-top: 161px !important;
}

.mr--161 {
  margin-right: 161px !important;
}

.mb--161 {
  margin-bottom: 161px !important;
}

.ml--161 {
  margin-left: 161px !important;
}

.width--161 {
  width: 161px !important;
}

.height--161 {
  height: 161px !important;
}

.gap--161 {
  gap: 161px !important;
}

.scale--161 {
  scale: 1.61 !important;
}

.p--162 {
  padding: 162px !important;
}

.px--162 {
  padding-right: 162px !important;
  padding-left: 162px !important;
}

.py--162 {
  padding-top: 162px !important;
  padding-bottom: 162px !important;
}

.pt--162 {
  padding-top: 162px !important;
}

.pr--162 {
  padding-right: 162px !important;
}

.pb--162 {
  padding-bottom: 162px !important;
}

.pl--162 {
  padding-left: 162px !important;
}

.m--162 {
  margin: 162px !important;
}

.mx--162 {
  margin-right: 162px !important;
  margin-left: 162px !important;
}

.my--162 {
  margin-top: 162px !important;
  margin-bottom: 162px !important;
}

.mt--162 {
  margin-top: 162px !important;
}

.mr--162 {
  margin-right: 162px !important;
}

.mb--162 {
  margin-bottom: 162px !important;
}

.ml--162 {
  margin-left: 162px !important;
}

.width--162 {
  width: 162px !important;
}

.height--162 {
  height: 162px !important;
}

.gap--162 {
  gap: 162px !important;
}

.scale--162 {
  scale: 1.62 !important;
}

.p--163 {
  padding: 163px !important;
}

.px--163 {
  padding-right: 163px !important;
  padding-left: 163px !important;
}

.py--163 {
  padding-top: 163px !important;
  padding-bottom: 163px !important;
}

.pt--163 {
  padding-top: 163px !important;
}

.pr--163 {
  padding-right: 163px !important;
}

.pb--163 {
  padding-bottom: 163px !important;
}

.pl--163 {
  padding-left: 163px !important;
}

.m--163 {
  margin: 163px !important;
}

.mx--163 {
  margin-right: 163px !important;
  margin-left: 163px !important;
}

.my--163 {
  margin-top: 163px !important;
  margin-bottom: 163px !important;
}

.mt--163 {
  margin-top: 163px !important;
}

.mr--163 {
  margin-right: 163px !important;
}

.mb--163 {
  margin-bottom: 163px !important;
}

.ml--163 {
  margin-left: 163px !important;
}

.width--163 {
  width: 163px !important;
}

.height--163 {
  height: 163px !important;
}

.gap--163 {
  gap: 163px !important;
}

.scale--163 {
  scale: 1.63 !important;
}

.p--164 {
  padding: 164px !important;
}

.px--164 {
  padding-right: 164px !important;
  padding-left: 164px !important;
}

.py--164 {
  padding-top: 164px !important;
  padding-bottom: 164px !important;
}

.pt--164 {
  padding-top: 164px !important;
}

.pr--164 {
  padding-right: 164px !important;
}

.pb--164 {
  padding-bottom: 164px !important;
}

.pl--164 {
  padding-left: 164px !important;
}

.m--164 {
  margin: 164px !important;
}

.mx--164 {
  margin-right: 164px !important;
  margin-left: 164px !important;
}

.my--164 {
  margin-top: 164px !important;
  margin-bottom: 164px !important;
}

.mt--164 {
  margin-top: 164px !important;
}

.mr--164 {
  margin-right: 164px !important;
}

.mb--164 {
  margin-bottom: 164px !important;
}

.ml--164 {
  margin-left: 164px !important;
}

.width--164 {
  width: 164px !important;
}

.height--164 {
  height: 164px !important;
}

.gap--164 {
  gap: 164px !important;
}

.scale--164 {
  scale: 1.64 !important;
}

.p--165 {
  padding: 165px !important;
}

.px--165 {
  padding-right: 165px !important;
  padding-left: 165px !important;
}

.py--165 {
  padding-top: 165px !important;
  padding-bottom: 165px !important;
}

.pt--165 {
  padding-top: 165px !important;
}

.pr--165 {
  padding-right: 165px !important;
}

.pb--165 {
  padding-bottom: 165px !important;
}

.pl--165 {
  padding-left: 165px !important;
}

.m--165 {
  margin: 165px !important;
}

.mx--165 {
  margin-right: 165px !important;
  margin-left: 165px !important;
}

.my--165 {
  margin-top: 165px !important;
  margin-bottom: 165px !important;
}

.mt--165 {
  margin-top: 165px !important;
}

.mr--165 {
  margin-right: 165px !important;
}

.mb--165 {
  margin-bottom: 165px !important;
}

.ml--165 {
  margin-left: 165px !important;
}

.width--165 {
  width: 165px !important;
}

.height--165 {
  height: 165px !important;
}

.gap--165 {
  gap: 165px !important;
}

.scale--165 {
  scale: 1.65 !important;
}

.p--166 {
  padding: 166px !important;
}

.px--166 {
  padding-right: 166px !important;
  padding-left: 166px !important;
}

.py--166 {
  padding-top: 166px !important;
  padding-bottom: 166px !important;
}

.pt--166 {
  padding-top: 166px !important;
}

.pr--166 {
  padding-right: 166px !important;
}

.pb--166 {
  padding-bottom: 166px !important;
}

.pl--166 {
  padding-left: 166px !important;
}

.m--166 {
  margin: 166px !important;
}

.mx--166 {
  margin-right: 166px !important;
  margin-left: 166px !important;
}

.my--166 {
  margin-top: 166px !important;
  margin-bottom: 166px !important;
}

.mt--166 {
  margin-top: 166px !important;
}

.mr--166 {
  margin-right: 166px !important;
}

.mb--166 {
  margin-bottom: 166px !important;
}

.ml--166 {
  margin-left: 166px !important;
}

.width--166 {
  width: 166px !important;
}

.height--166 {
  height: 166px !important;
}

.gap--166 {
  gap: 166px !important;
}

.scale--166 {
  scale: 1.66 !important;
}

.p--167 {
  padding: 167px !important;
}

.px--167 {
  padding-right: 167px !important;
  padding-left: 167px !important;
}

.py--167 {
  padding-top: 167px !important;
  padding-bottom: 167px !important;
}

.pt--167 {
  padding-top: 167px !important;
}

.pr--167 {
  padding-right: 167px !important;
}

.pb--167 {
  padding-bottom: 167px !important;
}

.pl--167 {
  padding-left: 167px !important;
}

.m--167 {
  margin: 167px !important;
}

.mx--167 {
  margin-right: 167px !important;
  margin-left: 167px !important;
}

.my--167 {
  margin-top: 167px !important;
  margin-bottom: 167px !important;
}

.mt--167 {
  margin-top: 167px !important;
}

.mr--167 {
  margin-right: 167px !important;
}

.mb--167 {
  margin-bottom: 167px !important;
}

.ml--167 {
  margin-left: 167px !important;
}

.width--167 {
  width: 167px !important;
}

.height--167 {
  height: 167px !important;
}

.gap--167 {
  gap: 167px !important;
}

.scale--167 {
  scale: 1.67 !important;
}

.p--168 {
  padding: 168px !important;
}

.px--168 {
  padding-right: 168px !important;
  padding-left: 168px !important;
}

.py--168 {
  padding-top: 168px !important;
  padding-bottom: 168px !important;
}

.pt--168 {
  padding-top: 168px !important;
}

.pr--168 {
  padding-right: 168px !important;
}

.pb--168 {
  padding-bottom: 168px !important;
}

.pl--168 {
  padding-left: 168px !important;
}

.m--168 {
  margin: 168px !important;
}

.mx--168 {
  margin-right: 168px !important;
  margin-left: 168px !important;
}

.my--168 {
  margin-top: 168px !important;
  margin-bottom: 168px !important;
}

.mt--168 {
  margin-top: 168px !important;
}

.mr--168 {
  margin-right: 168px !important;
}

.mb--168 {
  margin-bottom: 168px !important;
}

.ml--168 {
  margin-left: 168px !important;
}

.width--168 {
  width: 168px !important;
}

.height--168 {
  height: 168px !important;
}

.gap--168 {
  gap: 168px !important;
}

.scale--168 {
  scale: 1.68 !important;
}

.p--169 {
  padding: 169px !important;
}

.px--169 {
  padding-right: 169px !important;
  padding-left: 169px !important;
}

.py--169 {
  padding-top: 169px !important;
  padding-bottom: 169px !important;
}

.pt--169 {
  padding-top: 169px !important;
}

.pr--169 {
  padding-right: 169px !important;
}

.pb--169 {
  padding-bottom: 169px !important;
}

.pl--169 {
  padding-left: 169px !important;
}

.m--169 {
  margin: 169px !important;
}

.mx--169 {
  margin-right: 169px !important;
  margin-left: 169px !important;
}

.my--169 {
  margin-top: 169px !important;
  margin-bottom: 169px !important;
}

.mt--169 {
  margin-top: 169px !important;
}

.mr--169 {
  margin-right: 169px !important;
}

.mb--169 {
  margin-bottom: 169px !important;
}

.ml--169 {
  margin-left: 169px !important;
}

.width--169 {
  width: 169px !important;
}

.height--169 {
  height: 169px !important;
}

.gap--169 {
  gap: 169px !important;
}

.scale--169 {
  scale: 1.69 !important;
}

.p--170 {
  padding: 170px !important;
}

.px--170 {
  padding-right: 170px !important;
  padding-left: 170px !important;
}

.py--170 {
  padding-top: 170px !important;
  padding-bottom: 170px !important;
}

.pt--170 {
  padding-top: 170px !important;
}

.pr--170 {
  padding-right: 170px !important;
}

.pb--170 {
  padding-bottom: 170px !important;
}

.pl--170 {
  padding-left: 170px !important;
}

.m--170 {
  margin: 170px !important;
}

.mx--170 {
  margin-right: 170px !important;
  margin-left: 170px !important;
}

.my--170 {
  margin-top: 170px !important;
  margin-bottom: 170px !important;
}

.mt--170 {
  margin-top: 170px !important;
}

.mr--170 {
  margin-right: 170px !important;
}

.mb--170 {
  margin-bottom: 170px !important;
}

.ml--170 {
  margin-left: 170px !important;
}

.width--170 {
  width: 170px !important;
}

.height--170 {
  height: 170px !important;
}

.gap--170 {
  gap: 170px !important;
}

.scale--170 {
  scale: 1.7 !important;
}

.p--171 {
  padding: 171px !important;
}

.px--171 {
  padding-right: 171px !important;
  padding-left: 171px !important;
}

.py--171 {
  padding-top: 171px !important;
  padding-bottom: 171px !important;
}

.pt--171 {
  padding-top: 171px !important;
}

.pr--171 {
  padding-right: 171px !important;
}

.pb--171 {
  padding-bottom: 171px !important;
}

.pl--171 {
  padding-left: 171px !important;
}

.m--171 {
  margin: 171px !important;
}

.mx--171 {
  margin-right: 171px !important;
  margin-left: 171px !important;
}

.my--171 {
  margin-top: 171px !important;
  margin-bottom: 171px !important;
}

.mt--171 {
  margin-top: 171px !important;
}

.mr--171 {
  margin-right: 171px !important;
}

.mb--171 {
  margin-bottom: 171px !important;
}

.ml--171 {
  margin-left: 171px !important;
}

.width--171 {
  width: 171px !important;
}

.height--171 {
  height: 171px !important;
}

.gap--171 {
  gap: 171px !important;
}

.scale--171 {
  scale: 1.71 !important;
}

.p--172 {
  padding: 172px !important;
}

.px--172 {
  padding-right: 172px !important;
  padding-left: 172px !important;
}

.py--172 {
  padding-top: 172px !important;
  padding-bottom: 172px !important;
}

.pt--172 {
  padding-top: 172px !important;
}

.pr--172 {
  padding-right: 172px !important;
}

.pb--172 {
  padding-bottom: 172px !important;
}

.pl--172 {
  padding-left: 172px !important;
}

.m--172 {
  margin: 172px !important;
}

.mx--172 {
  margin-right: 172px !important;
  margin-left: 172px !important;
}

.my--172 {
  margin-top: 172px !important;
  margin-bottom: 172px !important;
}

.mt--172 {
  margin-top: 172px !important;
}

.mr--172 {
  margin-right: 172px !important;
}

.mb--172 {
  margin-bottom: 172px !important;
}

.ml--172 {
  margin-left: 172px !important;
}

.width--172 {
  width: 172px !important;
}

.height--172 {
  height: 172px !important;
}

.gap--172 {
  gap: 172px !important;
}

.scale--172 {
  scale: 1.72 !important;
}

.p--173 {
  padding: 173px !important;
}

.px--173 {
  padding-right: 173px !important;
  padding-left: 173px !important;
}

.py--173 {
  padding-top: 173px !important;
  padding-bottom: 173px !important;
}

.pt--173 {
  padding-top: 173px !important;
}

.pr--173 {
  padding-right: 173px !important;
}

.pb--173 {
  padding-bottom: 173px !important;
}

.pl--173 {
  padding-left: 173px !important;
}

.m--173 {
  margin: 173px !important;
}

.mx--173 {
  margin-right: 173px !important;
  margin-left: 173px !important;
}

.my--173 {
  margin-top: 173px !important;
  margin-bottom: 173px !important;
}

.mt--173 {
  margin-top: 173px !important;
}

.mr--173 {
  margin-right: 173px !important;
}

.mb--173 {
  margin-bottom: 173px !important;
}

.ml--173 {
  margin-left: 173px !important;
}

.width--173 {
  width: 173px !important;
}

.height--173 {
  height: 173px !important;
}

.gap--173 {
  gap: 173px !important;
}

.scale--173 {
  scale: 1.73 !important;
}

.p--174 {
  padding: 174px !important;
}

.px--174 {
  padding-right: 174px !important;
  padding-left: 174px !important;
}

.py--174 {
  padding-top: 174px !important;
  padding-bottom: 174px !important;
}

.pt--174 {
  padding-top: 174px !important;
}

.pr--174 {
  padding-right: 174px !important;
}

.pb--174 {
  padding-bottom: 174px !important;
}

.pl--174 {
  padding-left: 174px !important;
}

.m--174 {
  margin: 174px !important;
}

.mx--174 {
  margin-right: 174px !important;
  margin-left: 174px !important;
}

.my--174 {
  margin-top: 174px !important;
  margin-bottom: 174px !important;
}

.mt--174 {
  margin-top: 174px !important;
}

.mr--174 {
  margin-right: 174px !important;
}

.mb--174 {
  margin-bottom: 174px !important;
}

.ml--174 {
  margin-left: 174px !important;
}

.width--174 {
  width: 174px !important;
}

.height--174 {
  height: 174px !important;
}

.gap--174 {
  gap: 174px !important;
}

.scale--174 {
  scale: 1.74 !important;
}

.p--175 {
  padding: 175px !important;
}

.px--175 {
  padding-right: 175px !important;
  padding-left: 175px !important;
}

.py--175 {
  padding-top: 175px !important;
  padding-bottom: 175px !important;
}

.pt--175 {
  padding-top: 175px !important;
}

.pr--175 {
  padding-right: 175px !important;
}

.pb--175 {
  padding-bottom: 175px !important;
}

.pl--175 {
  padding-left: 175px !important;
}

.m--175 {
  margin: 175px !important;
}

.mx--175 {
  margin-right: 175px !important;
  margin-left: 175px !important;
}

.my--175 {
  margin-top: 175px !important;
  margin-bottom: 175px !important;
}

.mt--175 {
  margin-top: 175px !important;
}

.mr--175 {
  margin-right: 175px !important;
}

.mb--175 {
  margin-bottom: 175px !important;
}

.ml--175 {
  margin-left: 175px !important;
}

.width--175 {
  width: 175px !important;
}

.height--175 {
  height: 175px !important;
}

.gap--175 {
  gap: 175px !important;
}

.scale--175 {
  scale: 1.75 !important;
}

.p--176 {
  padding: 176px !important;
}

.px--176 {
  padding-right: 176px !important;
  padding-left: 176px !important;
}

.py--176 {
  padding-top: 176px !important;
  padding-bottom: 176px !important;
}

.pt--176 {
  padding-top: 176px !important;
}

.pr--176 {
  padding-right: 176px !important;
}

.pb--176 {
  padding-bottom: 176px !important;
}

.pl--176 {
  padding-left: 176px !important;
}

.m--176 {
  margin: 176px !important;
}

.mx--176 {
  margin-right: 176px !important;
  margin-left: 176px !important;
}

.my--176 {
  margin-top: 176px !important;
  margin-bottom: 176px !important;
}

.mt--176 {
  margin-top: 176px !important;
}

.mr--176 {
  margin-right: 176px !important;
}

.mb--176 {
  margin-bottom: 176px !important;
}

.ml--176 {
  margin-left: 176px !important;
}

.width--176 {
  width: 176px !important;
}

.height--176 {
  height: 176px !important;
}

.gap--176 {
  gap: 176px !important;
}

.scale--176 {
  scale: 1.76 !important;
}

.p--177 {
  padding: 177px !important;
}

.px--177 {
  padding-right: 177px !important;
  padding-left: 177px !important;
}

.py--177 {
  padding-top: 177px !important;
  padding-bottom: 177px !important;
}

.pt--177 {
  padding-top: 177px !important;
}

.pr--177 {
  padding-right: 177px !important;
}

.pb--177 {
  padding-bottom: 177px !important;
}

.pl--177 {
  padding-left: 177px !important;
}

.m--177 {
  margin: 177px !important;
}

.mx--177 {
  margin-right: 177px !important;
  margin-left: 177px !important;
}

.my--177 {
  margin-top: 177px !important;
  margin-bottom: 177px !important;
}

.mt--177 {
  margin-top: 177px !important;
}

.mr--177 {
  margin-right: 177px !important;
}

.mb--177 {
  margin-bottom: 177px !important;
}

.ml--177 {
  margin-left: 177px !important;
}

.width--177 {
  width: 177px !important;
}

.height--177 {
  height: 177px !important;
}

.gap--177 {
  gap: 177px !important;
}

.scale--177 {
  scale: 1.77 !important;
}

.p--178 {
  padding: 178px !important;
}

.px--178 {
  padding-right: 178px !important;
  padding-left: 178px !important;
}

.py--178 {
  padding-top: 178px !important;
  padding-bottom: 178px !important;
}

.pt--178 {
  padding-top: 178px !important;
}

.pr--178 {
  padding-right: 178px !important;
}

.pb--178 {
  padding-bottom: 178px !important;
}

.pl--178 {
  padding-left: 178px !important;
}

.m--178 {
  margin: 178px !important;
}

.mx--178 {
  margin-right: 178px !important;
  margin-left: 178px !important;
}

.my--178 {
  margin-top: 178px !important;
  margin-bottom: 178px !important;
}

.mt--178 {
  margin-top: 178px !important;
}

.mr--178 {
  margin-right: 178px !important;
}

.mb--178 {
  margin-bottom: 178px !important;
}

.ml--178 {
  margin-left: 178px !important;
}

.width--178 {
  width: 178px !important;
}

.height--178 {
  height: 178px !important;
}

.gap--178 {
  gap: 178px !important;
}

.scale--178 {
  scale: 1.78 !important;
}

.p--179 {
  padding: 179px !important;
}

.px--179 {
  padding-right: 179px !important;
  padding-left: 179px !important;
}

.py--179 {
  padding-top: 179px !important;
  padding-bottom: 179px !important;
}

.pt--179 {
  padding-top: 179px !important;
}

.pr--179 {
  padding-right: 179px !important;
}

.pb--179 {
  padding-bottom: 179px !important;
}

.pl--179 {
  padding-left: 179px !important;
}

.m--179 {
  margin: 179px !important;
}

.mx--179 {
  margin-right: 179px !important;
  margin-left: 179px !important;
}

.my--179 {
  margin-top: 179px !important;
  margin-bottom: 179px !important;
}

.mt--179 {
  margin-top: 179px !important;
}

.mr--179 {
  margin-right: 179px !important;
}

.mb--179 {
  margin-bottom: 179px !important;
}

.ml--179 {
  margin-left: 179px !important;
}

.width--179 {
  width: 179px !important;
}

.height--179 {
  height: 179px !important;
}

.gap--179 {
  gap: 179px !important;
}

.scale--179 {
  scale: 1.79 !important;
}

.p--180 {
  padding: 180px !important;
}

.px--180 {
  padding-right: 180px !important;
  padding-left: 180px !important;
}

.py--180 {
  padding-top: 180px !important;
  padding-bottom: 180px !important;
}

.pt--180 {
  padding-top: 180px !important;
}

.pr--180 {
  padding-right: 180px !important;
}

.pb--180 {
  padding-bottom: 180px !important;
}

.pl--180 {
  padding-left: 180px !important;
}

.m--180 {
  margin: 180px !important;
}

.mx--180 {
  margin-right: 180px !important;
  margin-left: 180px !important;
}

.my--180 {
  margin-top: 180px !important;
  margin-bottom: 180px !important;
}

.mt--180 {
  margin-top: 180px !important;
}

.mr--180 {
  margin-right: 180px !important;
}

.mb--180 {
  margin-bottom: 180px !important;
}

.ml--180 {
  margin-left: 180px !important;
}

.width--180 {
  width: 180px !important;
}

.height--180 {
  height: 180px !important;
}

.gap--180 {
  gap: 180px !important;
}

.scale--180 {
  scale: 1.8 !important;
}

.p--181 {
  padding: 181px !important;
}

.px--181 {
  padding-right: 181px !important;
  padding-left: 181px !important;
}

.py--181 {
  padding-top: 181px !important;
  padding-bottom: 181px !important;
}

.pt--181 {
  padding-top: 181px !important;
}

.pr--181 {
  padding-right: 181px !important;
}

.pb--181 {
  padding-bottom: 181px !important;
}

.pl--181 {
  padding-left: 181px !important;
}

.m--181 {
  margin: 181px !important;
}

.mx--181 {
  margin-right: 181px !important;
  margin-left: 181px !important;
}

.my--181 {
  margin-top: 181px !important;
  margin-bottom: 181px !important;
}

.mt--181 {
  margin-top: 181px !important;
}

.mr--181 {
  margin-right: 181px !important;
}

.mb--181 {
  margin-bottom: 181px !important;
}

.ml--181 {
  margin-left: 181px !important;
}

.width--181 {
  width: 181px !important;
}

.height--181 {
  height: 181px !important;
}

.gap--181 {
  gap: 181px !important;
}

.scale--181 {
  scale: 1.81 !important;
}

.p--182 {
  padding: 182px !important;
}

.px--182 {
  padding-right: 182px !important;
  padding-left: 182px !important;
}

.py--182 {
  padding-top: 182px !important;
  padding-bottom: 182px !important;
}

.pt--182 {
  padding-top: 182px !important;
}

.pr--182 {
  padding-right: 182px !important;
}

.pb--182 {
  padding-bottom: 182px !important;
}

.pl--182 {
  padding-left: 182px !important;
}

.m--182 {
  margin: 182px !important;
}

.mx--182 {
  margin-right: 182px !important;
  margin-left: 182px !important;
}

.my--182 {
  margin-top: 182px !important;
  margin-bottom: 182px !important;
}

.mt--182 {
  margin-top: 182px !important;
}

.mr--182 {
  margin-right: 182px !important;
}

.mb--182 {
  margin-bottom: 182px !important;
}

.ml--182 {
  margin-left: 182px !important;
}

.width--182 {
  width: 182px !important;
}

.height--182 {
  height: 182px !important;
}

.gap--182 {
  gap: 182px !important;
}

.scale--182 {
  scale: 1.82 !important;
}

.p--183 {
  padding: 183px !important;
}

.px--183 {
  padding-right: 183px !important;
  padding-left: 183px !important;
}

.py--183 {
  padding-top: 183px !important;
  padding-bottom: 183px !important;
}

.pt--183 {
  padding-top: 183px !important;
}

.pr--183 {
  padding-right: 183px !important;
}

.pb--183 {
  padding-bottom: 183px !important;
}

.pl--183 {
  padding-left: 183px !important;
}

.m--183 {
  margin: 183px !important;
}

.mx--183 {
  margin-right: 183px !important;
  margin-left: 183px !important;
}

.my--183 {
  margin-top: 183px !important;
  margin-bottom: 183px !important;
}

.mt--183 {
  margin-top: 183px !important;
}

.mr--183 {
  margin-right: 183px !important;
}

.mb--183 {
  margin-bottom: 183px !important;
}

.ml--183 {
  margin-left: 183px !important;
}

.width--183 {
  width: 183px !important;
}

.height--183 {
  height: 183px !important;
}

.gap--183 {
  gap: 183px !important;
}

.scale--183 {
  scale: 1.83 !important;
}

.p--184 {
  padding: 184px !important;
}

.px--184 {
  padding-right: 184px !important;
  padding-left: 184px !important;
}

.py--184 {
  padding-top: 184px !important;
  padding-bottom: 184px !important;
}

.pt--184 {
  padding-top: 184px !important;
}

.pr--184 {
  padding-right: 184px !important;
}

.pb--184 {
  padding-bottom: 184px !important;
}

.pl--184 {
  padding-left: 184px !important;
}

.m--184 {
  margin: 184px !important;
}

.mx--184 {
  margin-right: 184px !important;
  margin-left: 184px !important;
}

.my--184 {
  margin-top: 184px !important;
  margin-bottom: 184px !important;
}

.mt--184 {
  margin-top: 184px !important;
}

.mr--184 {
  margin-right: 184px !important;
}

.mb--184 {
  margin-bottom: 184px !important;
}

.ml--184 {
  margin-left: 184px !important;
}

.width--184 {
  width: 184px !important;
}

.height--184 {
  height: 184px !important;
}

.gap--184 {
  gap: 184px !important;
}

.scale--184 {
  scale: 1.84 !important;
}

.p--185 {
  padding: 185px !important;
}

.px--185 {
  padding-right: 185px !important;
  padding-left: 185px !important;
}

.py--185 {
  padding-top: 185px !important;
  padding-bottom: 185px !important;
}

.pt--185 {
  padding-top: 185px !important;
}

.pr--185 {
  padding-right: 185px !important;
}

.pb--185 {
  padding-bottom: 185px !important;
}

.pl--185 {
  padding-left: 185px !important;
}

.m--185 {
  margin: 185px !important;
}

.mx--185 {
  margin-right: 185px !important;
  margin-left: 185px !important;
}

.my--185 {
  margin-top: 185px !important;
  margin-bottom: 185px !important;
}

.mt--185 {
  margin-top: 185px !important;
}

.mr--185 {
  margin-right: 185px !important;
}

.mb--185 {
  margin-bottom: 185px !important;
}

.ml--185 {
  margin-left: 185px !important;
}

.width--185 {
  width: 185px !important;
}

.height--185 {
  height: 185px !important;
}

.gap--185 {
  gap: 185px !important;
}

.scale--185 {
  scale: 1.85 !important;
}

.p--186 {
  padding: 186px !important;
}

.px--186 {
  padding-right: 186px !important;
  padding-left: 186px !important;
}

.py--186 {
  padding-top: 186px !important;
  padding-bottom: 186px !important;
}

.pt--186 {
  padding-top: 186px !important;
}

.pr--186 {
  padding-right: 186px !important;
}

.pb--186 {
  padding-bottom: 186px !important;
}

.pl--186 {
  padding-left: 186px !important;
}

.m--186 {
  margin: 186px !important;
}

.mx--186 {
  margin-right: 186px !important;
  margin-left: 186px !important;
}

.my--186 {
  margin-top: 186px !important;
  margin-bottom: 186px !important;
}

.mt--186 {
  margin-top: 186px !important;
}

.mr--186 {
  margin-right: 186px !important;
}

.mb--186 {
  margin-bottom: 186px !important;
}

.ml--186 {
  margin-left: 186px !important;
}

.width--186 {
  width: 186px !important;
}

.height--186 {
  height: 186px !important;
}

.gap--186 {
  gap: 186px !important;
}

.scale--186 {
  scale: 1.86 !important;
}

.p--187 {
  padding: 187px !important;
}

.px--187 {
  padding-right: 187px !important;
  padding-left: 187px !important;
}

.py--187 {
  padding-top: 187px !important;
  padding-bottom: 187px !important;
}

.pt--187 {
  padding-top: 187px !important;
}

.pr--187 {
  padding-right: 187px !important;
}

.pb--187 {
  padding-bottom: 187px !important;
}

.pl--187 {
  padding-left: 187px !important;
}

.m--187 {
  margin: 187px !important;
}

.mx--187 {
  margin-right: 187px !important;
  margin-left: 187px !important;
}

.my--187 {
  margin-top: 187px !important;
  margin-bottom: 187px !important;
}

.mt--187 {
  margin-top: 187px !important;
}

.mr--187 {
  margin-right: 187px !important;
}

.mb--187 {
  margin-bottom: 187px !important;
}

.ml--187 {
  margin-left: 187px !important;
}

.width--187 {
  width: 187px !important;
}

.height--187 {
  height: 187px !important;
}

.gap--187 {
  gap: 187px !important;
}

.scale--187 {
  scale: 1.87 !important;
}

.p--188 {
  padding: 188px !important;
}

.px--188 {
  padding-right: 188px !important;
  padding-left: 188px !important;
}

.py--188 {
  padding-top: 188px !important;
  padding-bottom: 188px !important;
}

.pt--188 {
  padding-top: 188px !important;
}

.pr--188 {
  padding-right: 188px !important;
}

.pb--188 {
  padding-bottom: 188px !important;
}

.pl--188 {
  padding-left: 188px !important;
}

.m--188 {
  margin: 188px !important;
}

.mx--188 {
  margin-right: 188px !important;
  margin-left: 188px !important;
}

.my--188 {
  margin-top: 188px !important;
  margin-bottom: 188px !important;
}

.mt--188 {
  margin-top: 188px !important;
}

.mr--188 {
  margin-right: 188px !important;
}

.mb--188 {
  margin-bottom: 188px !important;
}

.ml--188 {
  margin-left: 188px !important;
}

.width--188 {
  width: 188px !important;
}

.height--188 {
  height: 188px !important;
}

.gap--188 {
  gap: 188px !important;
}

.scale--188 {
  scale: 1.88 !important;
}

.p--189 {
  padding: 189px !important;
}

.px--189 {
  padding-right: 189px !important;
  padding-left: 189px !important;
}

.py--189 {
  padding-top: 189px !important;
  padding-bottom: 189px !important;
}

.pt--189 {
  padding-top: 189px !important;
}

.pr--189 {
  padding-right: 189px !important;
}

.pb--189 {
  padding-bottom: 189px !important;
}

.pl--189 {
  padding-left: 189px !important;
}

.m--189 {
  margin: 189px !important;
}

.mx--189 {
  margin-right: 189px !important;
  margin-left: 189px !important;
}

.my--189 {
  margin-top: 189px !important;
  margin-bottom: 189px !important;
}

.mt--189 {
  margin-top: 189px !important;
}

.mr--189 {
  margin-right: 189px !important;
}

.mb--189 {
  margin-bottom: 189px !important;
}

.ml--189 {
  margin-left: 189px !important;
}

.width--189 {
  width: 189px !important;
}

.height--189 {
  height: 189px !important;
}

.gap--189 {
  gap: 189px !important;
}

.scale--189 {
  scale: 1.89 !important;
}

.p--190 {
  padding: 190px !important;
}

.px--190 {
  padding-right: 190px !important;
  padding-left: 190px !important;
}

.py--190 {
  padding-top: 190px !important;
  padding-bottom: 190px !important;
}

.pt--190 {
  padding-top: 190px !important;
}

.pr--190 {
  padding-right: 190px !important;
}

.pb--190 {
  padding-bottom: 190px !important;
}

.pl--190 {
  padding-left: 190px !important;
}

.m--190 {
  margin: 190px !important;
}

.mx--190 {
  margin-right: 190px !important;
  margin-left: 190px !important;
}

.my--190 {
  margin-top: 190px !important;
  margin-bottom: 190px !important;
}

.mt--190 {
  margin-top: 190px !important;
}

.mr--190 {
  margin-right: 190px !important;
}

.mb--190 {
  margin-bottom: 190px !important;
}

.ml--190 {
  margin-left: 190px !important;
}

.width--190 {
  width: 190px !important;
}

.height--190 {
  height: 190px !important;
}

.gap--190 {
  gap: 190px !important;
}

.scale--190 {
  scale: 1.9 !important;
}

.p--191 {
  padding: 191px !important;
}

.px--191 {
  padding-right: 191px !important;
  padding-left: 191px !important;
}

.py--191 {
  padding-top: 191px !important;
  padding-bottom: 191px !important;
}

.pt--191 {
  padding-top: 191px !important;
}

.pr--191 {
  padding-right: 191px !important;
}

.pb--191 {
  padding-bottom: 191px !important;
}

.pl--191 {
  padding-left: 191px !important;
}

.m--191 {
  margin: 191px !important;
}

.mx--191 {
  margin-right: 191px !important;
  margin-left: 191px !important;
}

.my--191 {
  margin-top: 191px !important;
  margin-bottom: 191px !important;
}

.mt--191 {
  margin-top: 191px !important;
}

.mr--191 {
  margin-right: 191px !important;
}

.mb--191 {
  margin-bottom: 191px !important;
}

.ml--191 {
  margin-left: 191px !important;
}

.width--191 {
  width: 191px !important;
}

.height--191 {
  height: 191px !important;
}

.gap--191 {
  gap: 191px !important;
}

.scale--191 {
  scale: 1.91 !important;
}

.p--192 {
  padding: 192px !important;
}

.px--192 {
  padding-right: 192px !important;
  padding-left: 192px !important;
}

.py--192 {
  padding-top: 192px !important;
  padding-bottom: 192px !important;
}

.pt--192 {
  padding-top: 192px !important;
}

.pr--192 {
  padding-right: 192px !important;
}

.pb--192 {
  padding-bottom: 192px !important;
}

.pl--192 {
  padding-left: 192px !important;
}

.m--192 {
  margin: 192px !important;
}

.mx--192 {
  margin-right: 192px !important;
  margin-left: 192px !important;
}

.my--192 {
  margin-top: 192px !important;
  margin-bottom: 192px !important;
}

.mt--192 {
  margin-top: 192px !important;
}

.mr--192 {
  margin-right: 192px !important;
}

.mb--192 {
  margin-bottom: 192px !important;
}

.ml--192 {
  margin-left: 192px !important;
}

.width--192 {
  width: 192px !important;
}

.height--192 {
  height: 192px !important;
}

.gap--192 {
  gap: 192px !important;
}

.scale--192 {
  scale: 1.92 !important;
}

.p--193 {
  padding: 193px !important;
}

.px--193 {
  padding-right: 193px !important;
  padding-left: 193px !important;
}

.py--193 {
  padding-top: 193px !important;
  padding-bottom: 193px !important;
}

.pt--193 {
  padding-top: 193px !important;
}

.pr--193 {
  padding-right: 193px !important;
}

.pb--193 {
  padding-bottom: 193px !important;
}

.pl--193 {
  padding-left: 193px !important;
}

.m--193 {
  margin: 193px !important;
}

.mx--193 {
  margin-right: 193px !important;
  margin-left: 193px !important;
}

.my--193 {
  margin-top: 193px !important;
  margin-bottom: 193px !important;
}

.mt--193 {
  margin-top: 193px !important;
}

.mr--193 {
  margin-right: 193px !important;
}

.mb--193 {
  margin-bottom: 193px !important;
}

.ml--193 {
  margin-left: 193px !important;
}

.width--193 {
  width: 193px !important;
}

.height--193 {
  height: 193px !important;
}

.gap--193 {
  gap: 193px !important;
}

.scale--193 {
  scale: 1.93 !important;
}

.p--194 {
  padding: 194px !important;
}

.px--194 {
  padding-right: 194px !important;
  padding-left: 194px !important;
}

.py--194 {
  padding-top: 194px !important;
  padding-bottom: 194px !important;
}

.pt--194 {
  padding-top: 194px !important;
}

.pr--194 {
  padding-right: 194px !important;
}

.pb--194 {
  padding-bottom: 194px !important;
}

.pl--194 {
  padding-left: 194px !important;
}

.m--194 {
  margin: 194px !important;
}

.mx--194 {
  margin-right: 194px !important;
  margin-left: 194px !important;
}

.my--194 {
  margin-top: 194px !important;
  margin-bottom: 194px !important;
}

.mt--194 {
  margin-top: 194px !important;
}

.mr--194 {
  margin-right: 194px !important;
}

.mb--194 {
  margin-bottom: 194px !important;
}

.ml--194 {
  margin-left: 194px !important;
}

.width--194 {
  width: 194px !important;
}

.height--194 {
  height: 194px !important;
}

.gap--194 {
  gap: 194px !important;
}

.scale--194 {
  scale: 1.94 !important;
}

.p--195 {
  padding: 195px !important;
}

.px--195 {
  padding-right: 195px !important;
  padding-left: 195px !important;
}

.py--195 {
  padding-top: 195px !important;
  padding-bottom: 195px !important;
}

.pt--195 {
  padding-top: 195px !important;
}

.pr--195 {
  padding-right: 195px !important;
}

.pb--195 {
  padding-bottom: 195px !important;
}

.pl--195 {
  padding-left: 195px !important;
}

.m--195 {
  margin: 195px !important;
}

.mx--195 {
  margin-right: 195px !important;
  margin-left: 195px !important;
}

.my--195 {
  margin-top: 195px !important;
  margin-bottom: 195px !important;
}

.mt--195 {
  margin-top: 195px !important;
}

.mr--195 {
  margin-right: 195px !important;
}

.mb--195 {
  margin-bottom: 195px !important;
}

.ml--195 {
  margin-left: 195px !important;
}

.width--195 {
  width: 195px !important;
}

.height--195 {
  height: 195px !important;
}

.gap--195 {
  gap: 195px !important;
}

.scale--195 {
  scale: 1.95 !important;
}

.p--196 {
  padding: 196px !important;
}

.px--196 {
  padding-right: 196px !important;
  padding-left: 196px !important;
}

.py--196 {
  padding-top: 196px !important;
  padding-bottom: 196px !important;
}

.pt--196 {
  padding-top: 196px !important;
}

.pr--196 {
  padding-right: 196px !important;
}

.pb--196 {
  padding-bottom: 196px !important;
}

.pl--196 {
  padding-left: 196px !important;
}

.m--196 {
  margin: 196px !important;
}

.mx--196 {
  margin-right: 196px !important;
  margin-left: 196px !important;
}

.my--196 {
  margin-top: 196px !important;
  margin-bottom: 196px !important;
}

.mt--196 {
  margin-top: 196px !important;
}

.mr--196 {
  margin-right: 196px !important;
}

.mb--196 {
  margin-bottom: 196px !important;
}

.ml--196 {
  margin-left: 196px !important;
}

.width--196 {
  width: 196px !important;
}

.height--196 {
  height: 196px !important;
}

.gap--196 {
  gap: 196px !important;
}

.scale--196 {
  scale: 1.96 !important;
}

.p--197 {
  padding: 197px !important;
}

.px--197 {
  padding-right: 197px !important;
  padding-left: 197px !important;
}

.py--197 {
  padding-top: 197px !important;
  padding-bottom: 197px !important;
}

.pt--197 {
  padding-top: 197px !important;
}

.pr--197 {
  padding-right: 197px !important;
}

.pb--197 {
  padding-bottom: 197px !important;
}

.pl--197 {
  padding-left: 197px !important;
}

.m--197 {
  margin: 197px !important;
}

.mx--197 {
  margin-right: 197px !important;
  margin-left: 197px !important;
}

.my--197 {
  margin-top: 197px !important;
  margin-bottom: 197px !important;
}

.mt--197 {
  margin-top: 197px !important;
}

.mr--197 {
  margin-right: 197px !important;
}

.mb--197 {
  margin-bottom: 197px !important;
}

.ml--197 {
  margin-left: 197px !important;
}

.width--197 {
  width: 197px !important;
}

.height--197 {
  height: 197px !important;
}

.gap--197 {
  gap: 197px !important;
}

.scale--197 {
  scale: 1.97 !important;
}

.p--198 {
  padding: 198px !important;
}

.px--198 {
  padding-right: 198px !important;
  padding-left: 198px !important;
}

.py--198 {
  padding-top: 198px !important;
  padding-bottom: 198px !important;
}

.pt--198 {
  padding-top: 198px !important;
}

.pr--198 {
  padding-right: 198px !important;
}

.pb--198 {
  padding-bottom: 198px !important;
}

.pl--198 {
  padding-left: 198px !important;
}

.m--198 {
  margin: 198px !important;
}

.mx--198 {
  margin-right: 198px !important;
  margin-left: 198px !important;
}

.my--198 {
  margin-top: 198px !important;
  margin-bottom: 198px !important;
}

.mt--198 {
  margin-top: 198px !important;
}

.mr--198 {
  margin-right: 198px !important;
}

.mb--198 {
  margin-bottom: 198px !important;
}

.ml--198 {
  margin-left: 198px !important;
}

.width--198 {
  width: 198px !important;
}

.height--198 {
  height: 198px !important;
}

.gap--198 {
  gap: 198px !important;
}

.scale--198 {
  scale: 1.98 !important;
}

.p--199 {
  padding: 199px !important;
}

.px--199 {
  padding-right: 199px !important;
  padding-left: 199px !important;
}

.py--199 {
  padding-top: 199px !important;
  padding-bottom: 199px !important;
}

.pt--199 {
  padding-top: 199px !important;
}

.pr--199 {
  padding-right: 199px !important;
}

.pb--199 {
  padding-bottom: 199px !important;
}

.pl--199 {
  padding-left: 199px !important;
}

.m--199 {
  margin: 199px !important;
}

.mx--199 {
  margin-right: 199px !important;
  margin-left: 199px !important;
}

.my--199 {
  margin-top: 199px !important;
  margin-bottom: 199px !important;
}

.mt--199 {
  margin-top: 199px !important;
}

.mr--199 {
  margin-right: 199px !important;
}

.mb--199 {
  margin-bottom: 199px !important;
}

.ml--199 {
  margin-left: 199px !important;
}

.width--199 {
  width: 199px !important;
}

.height--199 {
  height: 199px !important;
}

.gap--199 {
  gap: 199px !important;
}

.scale--199 {
  scale: 1.99 !important;
}

.p--200 {
  padding: 200px !important;
}

.px--200 {
  padding-right: 200px !important;
  padding-left: 200px !important;
}

.py--200 {
  padding-top: 200px !important;
  padding-bottom: 200px !important;
}

.pt--200 {
  padding-top: 200px !important;
}

.pr--200 {
  padding-right: 200px !important;
}

.pb--200 {
  padding-bottom: 200px !important;
}

.pl--200 {
  padding-left: 200px !important;
}

.m--200 {
  margin: 200px !important;
}

.mx--200 {
  margin-right: 200px !important;
  margin-left: 200px !important;
}

.my--200 {
  margin-top: 200px !important;
  margin-bottom: 200px !important;
}

.mt--200 {
  margin-top: 200px !important;
}

.mr--200 {
  margin-right: 200px !important;
}

.mb--200 {
  margin-bottom: 200px !important;
}

.ml--200 {
  margin-left: 200px !important;
}

.width--200 {
  width: 200px !important;
}

.height--200 {
  height: 200px !important;
}

.gap--200 {
  gap: 200px !important;
}

.scale--200 {
  scale: 2 !important;
}

.p--201 {
  padding: 201px !important;
}

.px--201 {
  padding-right: 201px !important;
  padding-left: 201px !important;
}

.py--201 {
  padding-top: 201px !important;
  padding-bottom: 201px !important;
}

.pt--201 {
  padding-top: 201px !important;
}

.pr--201 {
  padding-right: 201px !important;
}

.pb--201 {
  padding-bottom: 201px !important;
}

.pl--201 {
  padding-left: 201px !important;
}

.m--201 {
  margin: 201px !important;
}

.mx--201 {
  margin-right: 201px !important;
  margin-left: 201px !important;
}

.my--201 {
  margin-top: 201px !important;
  margin-bottom: 201px !important;
}

.mt--201 {
  margin-top: 201px !important;
}

.mr--201 {
  margin-right: 201px !important;
}

.mb--201 {
  margin-bottom: 201px !important;
}

.ml--201 {
  margin-left: 201px !important;
}

.width--201 {
  width: 201px !important;
}

.height--201 {
  height: 201px !important;
}

.gap--201 {
  gap: 201px !important;
}

.scale--201 {
  scale: 2.01 !important;
}

.p--202 {
  padding: 202px !important;
}

.px--202 {
  padding-right: 202px !important;
  padding-left: 202px !important;
}

.py--202 {
  padding-top: 202px !important;
  padding-bottom: 202px !important;
}

.pt--202 {
  padding-top: 202px !important;
}

.pr--202 {
  padding-right: 202px !important;
}

.pb--202 {
  padding-bottom: 202px !important;
}

.pl--202 {
  padding-left: 202px !important;
}

.m--202 {
  margin: 202px !important;
}

.mx--202 {
  margin-right: 202px !important;
  margin-left: 202px !important;
}

.my--202 {
  margin-top: 202px !important;
  margin-bottom: 202px !important;
}

.mt--202 {
  margin-top: 202px !important;
}

.mr--202 {
  margin-right: 202px !important;
}

.mb--202 {
  margin-bottom: 202px !important;
}

.ml--202 {
  margin-left: 202px !important;
}

.width--202 {
  width: 202px !important;
}

.height--202 {
  height: 202px !important;
}

.gap--202 {
  gap: 202px !important;
}

.scale--202 {
  scale: 2.02 !important;
}

.p--203 {
  padding: 203px !important;
}

.px--203 {
  padding-right: 203px !important;
  padding-left: 203px !important;
}

.py--203 {
  padding-top: 203px !important;
  padding-bottom: 203px !important;
}

.pt--203 {
  padding-top: 203px !important;
}

.pr--203 {
  padding-right: 203px !important;
}

.pb--203 {
  padding-bottom: 203px !important;
}

.pl--203 {
  padding-left: 203px !important;
}

.m--203 {
  margin: 203px !important;
}

.mx--203 {
  margin-right: 203px !important;
  margin-left: 203px !important;
}

.my--203 {
  margin-top: 203px !important;
  margin-bottom: 203px !important;
}

.mt--203 {
  margin-top: 203px !important;
}

.mr--203 {
  margin-right: 203px !important;
}

.mb--203 {
  margin-bottom: 203px !important;
}

.ml--203 {
  margin-left: 203px !important;
}

.width--203 {
  width: 203px !important;
}

.height--203 {
  height: 203px !important;
}

.gap--203 {
  gap: 203px !important;
}

.scale--203 {
  scale: 2.03 !important;
}

.p--204 {
  padding: 204px !important;
}

.px--204 {
  padding-right: 204px !important;
  padding-left: 204px !important;
}

.py--204 {
  padding-top: 204px !important;
  padding-bottom: 204px !important;
}

.pt--204 {
  padding-top: 204px !important;
}

.pr--204 {
  padding-right: 204px !important;
}

.pb--204 {
  padding-bottom: 204px !important;
}

.pl--204 {
  padding-left: 204px !important;
}

.m--204 {
  margin: 204px !important;
}

.mx--204 {
  margin-right: 204px !important;
  margin-left: 204px !important;
}

.my--204 {
  margin-top: 204px !important;
  margin-bottom: 204px !important;
}

.mt--204 {
  margin-top: 204px !important;
}

.mr--204 {
  margin-right: 204px !important;
}

.mb--204 {
  margin-bottom: 204px !important;
}

.ml--204 {
  margin-left: 204px !important;
}

.width--204 {
  width: 204px !important;
}

.height--204 {
  height: 204px !important;
}

.gap--204 {
  gap: 204px !important;
}

.scale--204 {
  scale: 2.04 !important;
}

.p--205 {
  padding: 205px !important;
}

.px--205 {
  padding-right: 205px !important;
  padding-left: 205px !important;
}

.py--205 {
  padding-top: 205px !important;
  padding-bottom: 205px !important;
}

.pt--205 {
  padding-top: 205px !important;
}

.pr--205 {
  padding-right: 205px !important;
}

.pb--205 {
  padding-bottom: 205px !important;
}

.pl--205 {
  padding-left: 205px !important;
}

.m--205 {
  margin: 205px !important;
}

.mx--205 {
  margin-right: 205px !important;
  margin-left: 205px !important;
}

.my--205 {
  margin-top: 205px !important;
  margin-bottom: 205px !important;
}

.mt--205 {
  margin-top: 205px !important;
}

.mr--205 {
  margin-right: 205px !important;
}

.mb--205 {
  margin-bottom: 205px !important;
}

.ml--205 {
  margin-left: 205px !important;
}

.width--205 {
  width: 205px !important;
}

.height--205 {
  height: 205px !important;
}

.gap--205 {
  gap: 205px !important;
}

.scale--205 {
  scale: 2.05 !important;
}

.p--206 {
  padding: 206px !important;
}

.px--206 {
  padding-right: 206px !important;
  padding-left: 206px !important;
}

.py--206 {
  padding-top: 206px !important;
  padding-bottom: 206px !important;
}

.pt--206 {
  padding-top: 206px !important;
}

.pr--206 {
  padding-right: 206px !important;
}

.pb--206 {
  padding-bottom: 206px !important;
}

.pl--206 {
  padding-left: 206px !important;
}

.m--206 {
  margin: 206px !important;
}

.mx--206 {
  margin-right: 206px !important;
  margin-left: 206px !important;
}

.my--206 {
  margin-top: 206px !important;
  margin-bottom: 206px !important;
}

.mt--206 {
  margin-top: 206px !important;
}

.mr--206 {
  margin-right: 206px !important;
}

.mb--206 {
  margin-bottom: 206px !important;
}

.ml--206 {
  margin-left: 206px !important;
}

.width--206 {
  width: 206px !important;
}

.height--206 {
  height: 206px !important;
}

.gap--206 {
  gap: 206px !important;
}

.scale--206 {
  scale: 2.06 !important;
}

.p--207 {
  padding: 207px !important;
}

.px--207 {
  padding-right: 207px !important;
  padding-left: 207px !important;
}

.py--207 {
  padding-top: 207px !important;
  padding-bottom: 207px !important;
}

.pt--207 {
  padding-top: 207px !important;
}

.pr--207 {
  padding-right: 207px !important;
}

.pb--207 {
  padding-bottom: 207px !important;
}

.pl--207 {
  padding-left: 207px !important;
}

.m--207 {
  margin: 207px !important;
}

.mx--207 {
  margin-right: 207px !important;
  margin-left: 207px !important;
}

.my--207 {
  margin-top: 207px !important;
  margin-bottom: 207px !important;
}

.mt--207 {
  margin-top: 207px !important;
}

.mr--207 {
  margin-right: 207px !important;
}

.mb--207 {
  margin-bottom: 207px !important;
}

.ml--207 {
  margin-left: 207px !important;
}

.width--207 {
  width: 207px !important;
}

.height--207 {
  height: 207px !important;
}

.gap--207 {
  gap: 207px !important;
}

.scale--207 {
  scale: 2.07 !important;
}

.p--208 {
  padding: 208px !important;
}

.px--208 {
  padding-right: 208px !important;
  padding-left: 208px !important;
}

.py--208 {
  padding-top: 208px !important;
  padding-bottom: 208px !important;
}

.pt--208 {
  padding-top: 208px !important;
}

.pr--208 {
  padding-right: 208px !important;
}

.pb--208 {
  padding-bottom: 208px !important;
}

.pl--208 {
  padding-left: 208px !important;
}

.m--208 {
  margin: 208px !important;
}

.mx--208 {
  margin-right: 208px !important;
  margin-left: 208px !important;
}

.my--208 {
  margin-top: 208px !important;
  margin-bottom: 208px !important;
}

.mt--208 {
  margin-top: 208px !important;
}

.mr--208 {
  margin-right: 208px !important;
}

.mb--208 {
  margin-bottom: 208px !important;
}

.ml--208 {
  margin-left: 208px !important;
}

.width--208 {
  width: 208px !important;
}

.height--208 {
  height: 208px !important;
}

.gap--208 {
  gap: 208px !important;
}

.scale--208 {
  scale: 2.08 !important;
}

.p--209 {
  padding: 209px !important;
}

.px--209 {
  padding-right: 209px !important;
  padding-left: 209px !important;
}

.py--209 {
  padding-top: 209px !important;
  padding-bottom: 209px !important;
}

.pt--209 {
  padding-top: 209px !important;
}

.pr--209 {
  padding-right: 209px !important;
}

.pb--209 {
  padding-bottom: 209px !important;
}

.pl--209 {
  padding-left: 209px !important;
}

.m--209 {
  margin: 209px !important;
}

.mx--209 {
  margin-right: 209px !important;
  margin-left: 209px !important;
}

.my--209 {
  margin-top: 209px !important;
  margin-bottom: 209px !important;
}

.mt--209 {
  margin-top: 209px !important;
}

.mr--209 {
  margin-right: 209px !important;
}

.mb--209 {
  margin-bottom: 209px !important;
}

.ml--209 {
  margin-left: 209px !important;
}

.width--209 {
  width: 209px !important;
}

.height--209 {
  height: 209px !important;
}

.gap--209 {
  gap: 209px !important;
}

.scale--209 {
  scale: 2.09 !important;
}

.p--210 {
  padding: 210px !important;
}

.px--210 {
  padding-right: 210px !important;
  padding-left: 210px !important;
}

.py--210 {
  padding-top: 210px !important;
  padding-bottom: 210px !important;
}

.pt--210 {
  padding-top: 210px !important;
}

.pr--210 {
  padding-right: 210px !important;
}

.pb--210 {
  padding-bottom: 210px !important;
}

.pl--210 {
  padding-left: 210px !important;
}

.m--210 {
  margin: 210px !important;
}

.mx--210 {
  margin-right: 210px !important;
  margin-left: 210px !important;
}

.my--210 {
  margin-top: 210px !important;
  margin-bottom: 210px !important;
}

.mt--210 {
  margin-top: 210px !important;
}

.mr--210 {
  margin-right: 210px !important;
}

.mb--210 {
  margin-bottom: 210px !important;
}

.ml--210 {
  margin-left: 210px !important;
}

.width--210 {
  width: 210px !important;
}

.height--210 {
  height: 210px !important;
}

.gap--210 {
  gap: 210px !important;
}

.scale--210 {
  scale: 2.1 !important;
}

.p--211 {
  padding: 211px !important;
}

.px--211 {
  padding-right: 211px !important;
  padding-left: 211px !important;
}

.py--211 {
  padding-top: 211px !important;
  padding-bottom: 211px !important;
}

.pt--211 {
  padding-top: 211px !important;
}

.pr--211 {
  padding-right: 211px !important;
}

.pb--211 {
  padding-bottom: 211px !important;
}

.pl--211 {
  padding-left: 211px !important;
}

.m--211 {
  margin: 211px !important;
}

.mx--211 {
  margin-right: 211px !important;
  margin-left: 211px !important;
}

.my--211 {
  margin-top: 211px !important;
  margin-bottom: 211px !important;
}

.mt--211 {
  margin-top: 211px !important;
}

.mr--211 {
  margin-right: 211px !important;
}

.mb--211 {
  margin-bottom: 211px !important;
}

.ml--211 {
  margin-left: 211px !important;
}

.width--211 {
  width: 211px !important;
}

.height--211 {
  height: 211px !important;
}

.gap--211 {
  gap: 211px !important;
}

.scale--211 {
  scale: 2.11 !important;
}

.p--212 {
  padding: 212px !important;
}

.px--212 {
  padding-right: 212px !important;
  padding-left: 212px !important;
}

.py--212 {
  padding-top: 212px !important;
  padding-bottom: 212px !important;
}

.pt--212 {
  padding-top: 212px !important;
}

.pr--212 {
  padding-right: 212px !important;
}

.pb--212 {
  padding-bottom: 212px !important;
}

.pl--212 {
  padding-left: 212px !important;
}

.m--212 {
  margin: 212px !important;
}

.mx--212 {
  margin-right: 212px !important;
  margin-left: 212px !important;
}

.my--212 {
  margin-top: 212px !important;
  margin-bottom: 212px !important;
}

.mt--212 {
  margin-top: 212px !important;
}

.mr--212 {
  margin-right: 212px !important;
}

.mb--212 {
  margin-bottom: 212px !important;
}

.ml--212 {
  margin-left: 212px !important;
}

.width--212 {
  width: 212px !important;
}

.height--212 {
  height: 212px !important;
}

.gap--212 {
  gap: 212px !important;
}

.scale--212 {
  scale: 2.12 !important;
}

.p--213 {
  padding: 213px !important;
}

.px--213 {
  padding-right: 213px !important;
  padding-left: 213px !important;
}

.py--213 {
  padding-top: 213px !important;
  padding-bottom: 213px !important;
}

.pt--213 {
  padding-top: 213px !important;
}

.pr--213 {
  padding-right: 213px !important;
}

.pb--213 {
  padding-bottom: 213px !important;
}

.pl--213 {
  padding-left: 213px !important;
}

.m--213 {
  margin: 213px !important;
}

.mx--213 {
  margin-right: 213px !important;
  margin-left: 213px !important;
}

.my--213 {
  margin-top: 213px !important;
  margin-bottom: 213px !important;
}

.mt--213 {
  margin-top: 213px !important;
}

.mr--213 {
  margin-right: 213px !important;
}

.mb--213 {
  margin-bottom: 213px !important;
}

.ml--213 {
  margin-left: 213px !important;
}

.width--213 {
  width: 213px !important;
}

.height--213 {
  height: 213px !important;
}

.gap--213 {
  gap: 213px !important;
}

.scale--213 {
  scale: 2.13 !important;
}

.p--214 {
  padding: 214px !important;
}

.px--214 {
  padding-right: 214px !important;
  padding-left: 214px !important;
}

.py--214 {
  padding-top: 214px !important;
  padding-bottom: 214px !important;
}

.pt--214 {
  padding-top: 214px !important;
}

.pr--214 {
  padding-right: 214px !important;
}

.pb--214 {
  padding-bottom: 214px !important;
}

.pl--214 {
  padding-left: 214px !important;
}

.m--214 {
  margin: 214px !important;
}

.mx--214 {
  margin-right: 214px !important;
  margin-left: 214px !important;
}

.my--214 {
  margin-top: 214px !important;
  margin-bottom: 214px !important;
}

.mt--214 {
  margin-top: 214px !important;
}

.mr--214 {
  margin-right: 214px !important;
}

.mb--214 {
  margin-bottom: 214px !important;
}

.ml--214 {
  margin-left: 214px !important;
}

.width--214 {
  width: 214px !important;
}

.height--214 {
  height: 214px !important;
}

.gap--214 {
  gap: 214px !important;
}

.scale--214 {
  scale: 2.14 !important;
}

.p--215 {
  padding: 215px !important;
}

.px--215 {
  padding-right: 215px !important;
  padding-left: 215px !important;
}

.py--215 {
  padding-top: 215px !important;
  padding-bottom: 215px !important;
}

.pt--215 {
  padding-top: 215px !important;
}

.pr--215 {
  padding-right: 215px !important;
}

.pb--215 {
  padding-bottom: 215px !important;
}

.pl--215 {
  padding-left: 215px !important;
}

.m--215 {
  margin: 215px !important;
}

.mx--215 {
  margin-right: 215px !important;
  margin-left: 215px !important;
}

.my--215 {
  margin-top: 215px !important;
  margin-bottom: 215px !important;
}

.mt--215 {
  margin-top: 215px !important;
}

.mr--215 {
  margin-right: 215px !important;
}

.mb--215 {
  margin-bottom: 215px !important;
}

.ml--215 {
  margin-left: 215px !important;
}

.width--215 {
  width: 215px !important;
}

.height--215 {
  height: 215px !important;
}

.gap--215 {
  gap: 215px !important;
}

.scale--215 {
  scale: 2.15 !important;
}

.p--216 {
  padding: 216px !important;
}

.px--216 {
  padding-right: 216px !important;
  padding-left: 216px !important;
}

.py--216 {
  padding-top: 216px !important;
  padding-bottom: 216px !important;
}

.pt--216 {
  padding-top: 216px !important;
}

.pr--216 {
  padding-right: 216px !important;
}

.pb--216 {
  padding-bottom: 216px !important;
}

.pl--216 {
  padding-left: 216px !important;
}

.m--216 {
  margin: 216px !important;
}

.mx--216 {
  margin-right: 216px !important;
  margin-left: 216px !important;
}

.my--216 {
  margin-top: 216px !important;
  margin-bottom: 216px !important;
}

.mt--216 {
  margin-top: 216px !important;
}

.mr--216 {
  margin-right: 216px !important;
}

.mb--216 {
  margin-bottom: 216px !important;
}

.ml--216 {
  margin-left: 216px !important;
}

.width--216 {
  width: 216px !important;
}

.height--216 {
  height: 216px !important;
}

.gap--216 {
  gap: 216px !important;
}

.scale--216 {
  scale: 2.16 !important;
}

.p--217 {
  padding: 217px !important;
}

.px--217 {
  padding-right: 217px !important;
  padding-left: 217px !important;
}

.py--217 {
  padding-top: 217px !important;
  padding-bottom: 217px !important;
}

.pt--217 {
  padding-top: 217px !important;
}

.pr--217 {
  padding-right: 217px !important;
}

.pb--217 {
  padding-bottom: 217px !important;
}

.pl--217 {
  padding-left: 217px !important;
}

.m--217 {
  margin: 217px !important;
}

.mx--217 {
  margin-right: 217px !important;
  margin-left: 217px !important;
}

.my--217 {
  margin-top: 217px !important;
  margin-bottom: 217px !important;
}

.mt--217 {
  margin-top: 217px !important;
}

.mr--217 {
  margin-right: 217px !important;
}

.mb--217 {
  margin-bottom: 217px !important;
}

.ml--217 {
  margin-left: 217px !important;
}

.width--217 {
  width: 217px !important;
}

.height--217 {
  height: 217px !important;
}

.gap--217 {
  gap: 217px !important;
}

.scale--217 {
  scale: 2.17 !important;
}

.p--218 {
  padding: 218px !important;
}

.px--218 {
  padding-right: 218px !important;
  padding-left: 218px !important;
}

.py--218 {
  padding-top: 218px !important;
  padding-bottom: 218px !important;
}

.pt--218 {
  padding-top: 218px !important;
}

.pr--218 {
  padding-right: 218px !important;
}

.pb--218 {
  padding-bottom: 218px !important;
}

.pl--218 {
  padding-left: 218px !important;
}

.m--218 {
  margin: 218px !important;
}

.mx--218 {
  margin-right: 218px !important;
  margin-left: 218px !important;
}

.my--218 {
  margin-top: 218px !important;
  margin-bottom: 218px !important;
}

.mt--218 {
  margin-top: 218px !important;
}

.mr--218 {
  margin-right: 218px !important;
}

.mb--218 {
  margin-bottom: 218px !important;
}

.ml--218 {
  margin-left: 218px !important;
}

.width--218 {
  width: 218px !important;
}

.height--218 {
  height: 218px !important;
}

.gap--218 {
  gap: 218px !important;
}

.scale--218 {
  scale: 2.18 !important;
}

.p--219 {
  padding: 219px !important;
}

.px--219 {
  padding-right: 219px !important;
  padding-left: 219px !important;
}

.py--219 {
  padding-top: 219px !important;
  padding-bottom: 219px !important;
}

.pt--219 {
  padding-top: 219px !important;
}

.pr--219 {
  padding-right: 219px !important;
}

.pb--219 {
  padding-bottom: 219px !important;
}

.pl--219 {
  padding-left: 219px !important;
}

.m--219 {
  margin: 219px !important;
}

.mx--219 {
  margin-right: 219px !important;
  margin-left: 219px !important;
}

.my--219 {
  margin-top: 219px !important;
  margin-bottom: 219px !important;
}

.mt--219 {
  margin-top: 219px !important;
}

.mr--219 {
  margin-right: 219px !important;
}

.mb--219 {
  margin-bottom: 219px !important;
}

.ml--219 {
  margin-left: 219px !important;
}

.width--219 {
  width: 219px !important;
}

.height--219 {
  height: 219px !important;
}

.gap--219 {
  gap: 219px !important;
}

.scale--219 {
  scale: 2.19 !important;
}

.p--220 {
  padding: 220px !important;
}

.px--220 {
  padding-right: 220px !important;
  padding-left: 220px !important;
}

.py--220 {
  padding-top: 220px !important;
  padding-bottom: 220px !important;
}

.pt--220 {
  padding-top: 220px !important;
}

.pr--220 {
  padding-right: 220px !important;
}

.pb--220 {
  padding-bottom: 220px !important;
}

.pl--220 {
  padding-left: 220px !important;
}

.m--220 {
  margin: 220px !important;
}

.mx--220 {
  margin-right: 220px !important;
  margin-left: 220px !important;
}

.my--220 {
  margin-top: 220px !important;
  margin-bottom: 220px !important;
}

.mt--220 {
  margin-top: 220px !important;
}

.mr--220 {
  margin-right: 220px !important;
}

.mb--220 {
  margin-bottom: 220px !important;
}

.ml--220 {
  margin-left: 220px !important;
}

.width--220 {
  width: 220px !important;
}

.height--220 {
  height: 220px !important;
}

.gap--220 {
  gap: 220px !important;
}

.scale--220 {
  scale: 2.2 !important;
}

.p--221 {
  padding: 221px !important;
}

.px--221 {
  padding-right: 221px !important;
  padding-left: 221px !important;
}

.py--221 {
  padding-top: 221px !important;
  padding-bottom: 221px !important;
}

.pt--221 {
  padding-top: 221px !important;
}

.pr--221 {
  padding-right: 221px !important;
}

.pb--221 {
  padding-bottom: 221px !important;
}

.pl--221 {
  padding-left: 221px !important;
}

.m--221 {
  margin: 221px !important;
}

.mx--221 {
  margin-right: 221px !important;
  margin-left: 221px !important;
}

.my--221 {
  margin-top: 221px !important;
  margin-bottom: 221px !important;
}

.mt--221 {
  margin-top: 221px !important;
}

.mr--221 {
  margin-right: 221px !important;
}

.mb--221 {
  margin-bottom: 221px !important;
}

.ml--221 {
  margin-left: 221px !important;
}

.width--221 {
  width: 221px !important;
}

.height--221 {
  height: 221px !important;
}

.gap--221 {
  gap: 221px !important;
}

.scale--221 {
  scale: 2.21 !important;
}

.p--222 {
  padding: 222px !important;
}

.px--222 {
  padding-right: 222px !important;
  padding-left: 222px !important;
}

.py--222 {
  padding-top: 222px !important;
  padding-bottom: 222px !important;
}

.pt--222 {
  padding-top: 222px !important;
}

.pr--222 {
  padding-right: 222px !important;
}

.pb--222 {
  padding-bottom: 222px !important;
}

.pl--222 {
  padding-left: 222px !important;
}

.m--222 {
  margin: 222px !important;
}

.mx--222 {
  margin-right: 222px !important;
  margin-left: 222px !important;
}

.my--222 {
  margin-top: 222px !important;
  margin-bottom: 222px !important;
}

.mt--222 {
  margin-top: 222px !important;
}

.mr--222 {
  margin-right: 222px !important;
}

.mb--222 {
  margin-bottom: 222px !important;
}

.ml--222 {
  margin-left: 222px !important;
}

.width--222 {
  width: 222px !important;
}

.height--222 {
  height: 222px !important;
}

.gap--222 {
  gap: 222px !important;
}

.scale--222 {
  scale: 2.22 !important;
}

.p--223 {
  padding: 223px !important;
}

.px--223 {
  padding-right: 223px !important;
  padding-left: 223px !important;
}

.py--223 {
  padding-top: 223px !important;
  padding-bottom: 223px !important;
}

.pt--223 {
  padding-top: 223px !important;
}

.pr--223 {
  padding-right: 223px !important;
}

.pb--223 {
  padding-bottom: 223px !important;
}

.pl--223 {
  padding-left: 223px !important;
}

.m--223 {
  margin: 223px !important;
}

.mx--223 {
  margin-right: 223px !important;
  margin-left: 223px !important;
}

.my--223 {
  margin-top: 223px !important;
  margin-bottom: 223px !important;
}

.mt--223 {
  margin-top: 223px !important;
}

.mr--223 {
  margin-right: 223px !important;
}

.mb--223 {
  margin-bottom: 223px !important;
}

.ml--223 {
  margin-left: 223px !important;
}

.width--223 {
  width: 223px !important;
}

.height--223 {
  height: 223px !important;
}

.gap--223 {
  gap: 223px !important;
}

.scale--223 {
  scale: 2.23 !important;
}

.p--224 {
  padding: 224px !important;
}

.px--224 {
  padding-right: 224px !important;
  padding-left: 224px !important;
}

.py--224 {
  padding-top: 224px !important;
  padding-bottom: 224px !important;
}

.pt--224 {
  padding-top: 224px !important;
}

.pr--224 {
  padding-right: 224px !important;
}

.pb--224 {
  padding-bottom: 224px !important;
}

.pl--224 {
  padding-left: 224px !important;
}

.m--224 {
  margin: 224px !important;
}

.mx--224 {
  margin-right: 224px !important;
  margin-left: 224px !important;
}

.my--224 {
  margin-top: 224px !important;
  margin-bottom: 224px !important;
}

.mt--224 {
  margin-top: 224px !important;
}

.mr--224 {
  margin-right: 224px !important;
}

.mb--224 {
  margin-bottom: 224px !important;
}

.ml--224 {
  margin-left: 224px !important;
}

.width--224 {
  width: 224px !important;
}

.height--224 {
  height: 224px !important;
}

.gap--224 {
  gap: 224px !important;
}

.scale--224 {
  scale: 2.24 !important;
}

.p--225 {
  padding: 225px !important;
}

.px--225 {
  padding-right: 225px !important;
  padding-left: 225px !important;
}

.py--225 {
  padding-top: 225px !important;
  padding-bottom: 225px !important;
}

.pt--225 {
  padding-top: 225px !important;
}

.pr--225 {
  padding-right: 225px !important;
}

.pb--225 {
  padding-bottom: 225px !important;
}

.pl--225 {
  padding-left: 225px !important;
}

.m--225 {
  margin: 225px !important;
}

.mx--225 {
  margin-right: 225px !important;
  margin-left: 225px !important;
}

.my--225 {
  margin-top: 225px !important;
  margin-bottom: 225px !important;
}

.mt--225 {
  margin-top: 225px !important;
}

.mr--225 {
  margin-right: 225px !important;
}

.mb--225 {
  margin-bottom: 225px !important;
}

.ml--225 {
  margin-left: 225px !important;
}

.width--225 {
  width: 225px !important;
}

.height--225 {
  height: 225px !important;
}

.gap--225 {
  gap: 225px !important;
}

.scale--225 {
  scale: 2.25 !important;
}

.p--226 {
  padding: 226px !important;
}

.px--226 {
  padding-right: 226px !important;
  padding-left: 226px !important;
}

.py--226 {
  padding-top: 226px !important;
  padding-bottom: 226px !important;
}

.pt--226 {
  padding-top: 226px !important;
}

.pr--226 {
  padding-right: 226px !important;
}

.pb--226 {
  padding-bottom: 226px !important;
}

.pl--226 {
  padding-left: 226px !important;
}

.m--226 {
  margin: 226px !important;
}

.mx--226 {
  margin-right: 226px !important;
  margin-left: 226px !important;
}

.my--226 {
  margin-top: 226px !important;
  margin-bottom: 226px !important;
}

.mt--226 {
  margin-top: 226px !important;
}

.mr--226 {
  margin-right: 226px !important;
}

.mb--226 {
  margin-bottom: 226px !important;
}

.ml--226 {
  margin-left: 226px !important;
}

.width--226 {
  width: 226px !important;
}

.height--226 {
  height: 226px !important;
}

.gap--226 {
  gap: 226px !important;
}

.scale--226 {
  scale: 2.26 !important;
}

.p--227 {
  padding: 227px !important;
}

.px--227 {
  padding-right: 227px !important;
  padding-left: 227px !important;
}

.py--227 {
  padding-top: 227px !important;
  padding-bottom: 227px !important;
}

.pt--227 {
  padding-top: 227px !important;
}

.pr--227 {
  padding-right: 227px !important;
}

.pb--227 {
  padding-bottom: 227px !important;
}

.pl--227 {
  padding-left: 227px !important;
}

.m--227 {
  margin: 227px !important;
}

.mx--227 {
  margin-right: 227px !important;
  margin-left: 227px !important;
}

.my--227 {
  margin-top: 227px !important;
  margin-bottom: 227px !important;
}

.mt--227 {
  margin-top: 227px !important;
}

.mr--227 {
  margin-right: 227px !important;
}

.mb--227 {
  margin-bottom: 227px !important;
}

.ml--227 {
  margin-left: 227px !important;
}

.width--227 {
  width: 227px !important;
}

.height--227 {
  height: 227px !important;
}

.gap--227 {
  gap: 227px !important;
}

.scale--227 {
  scale: 2.27 !important;
}

.p--228 {
  padding: 228px !important;
}

.px--228 {
  padding-right: 228px !important;
  padding-left: 228px !important;
}

.py--228 {
  padding-top: 228px !important;
  padding-bottom: 228px !important;
}

.pt--228 {
  padding-top: 228px !important;
}

.pr--228 {
  padding-right: 228px !important;
}

.pb--228 {
  padding-bottom: 228px !important;
}

.pl--228 {
  padding-left: 228px !important;
}

.m--228 {
  margin: 228px !important;
}

.mx--228 {
  margin-right: 228px !important;
  margin-left: 228px !important;
}

.my--228 {
  margin-top: 228px !important;
  margin-bottom: 228px !important;
}

.mt--228 {
  margin-top: 228px !important;
}

.mr--228 {
  margin-right: 228px !important;
}

.mb--228 {
  margin-bottom: 228px !important;
}

.ml--228 {
  margin-left: 228px !important;
}

.width--228 {
  width: 228px !important;
}

.height--228 {
  height: 228px !important;
}

.gap--228 {
  gap: 228px !important;
}

.scale--228 {
  scale: 2.28 !important;
}

.p--229 {
  padding: 229px !important;
}

.px--229 {
  padding-right: 229px !important;
  padding-left: 229px !important;
}

.py--229 {
  padding-top: 229px !important;
  padding-bottom: 229px !important;
}

.pt--229 {
  padding-top: 229px !important;
}

.pr--229 {
  padding-right: 229px !important;
}

.pb--229 {
  padding-bottom: 229px !important;
}

.pl--229 {
  padding-left: 229px !important;
}

.m--229 {
  margin: 229px !important;
}

.mx--229 {
  margin-right: 229px !important;
  margin-left: 229px !important;
}

.my--229 {
  margin-top: 229px !important;
  margin-bottom: 229px !important;
}

.mt--229 {
  margin-top: 229px !important;
}

.mr--229 {
  margin-right: 229px !important;
}

.mb--229 {
  margin-bottom: 229px !important;
}

.ml--229 {
  margin-left: 229px !important;
}

.width--229 {
  width: 229px !important;
}

.height--229 {
  height: 229px !important;
}

.gap--229 {
  gap: 229px !important;
}

.scale--229 {
  scale: 2.29 !important;
}

.p--230 {
  padding: 230px !important;
}

.px--230 {
  padding-right: 230px !important;
  padding-left: 230px !important;
}

.py--230 {
  padding-top: 230px !important;
  padding-bottom: 230px !important;
}

.pt--230 {
  padding-top: 230px !important;
}

.pr--230 {
  padding-right: 230px !important;
}

.pb--230 {
  padding-bottom: 230px !important;
}

.pl--230 {
  padding-left: 230px !important;
}

.m--230 {
  margin: 230px !important;
}

.mx--230 {
  margin-right: 230px !important;
  margin-left: 230px !important;
}

.my--230 {
  margin-top: 230px !important;
  margin-bottom: 230px !important;
}

.mt--230 {
  margin-top: 230px !important;
}

.mr--230 {
  margin-right: 230px !important;
}

.mb--230 {
  margin-bottom: 230px !important;
}

.ml--230 {
  margin-left: 230px !important;
}

.width--230 {
  width: 230px !important;
}

.height--230 {
  height: 230px !important;
}

.gap--230 {
  gap: 230px !important;
}

.scale--230 {
  scale: 2.3 !important;
}

.p--231 {
  padding: 231px !important;
}

.px--231 {
  padding-right: 231px !important;
  padding-left: 231px !important;
}

.py--231 {
  padding-top: 231px !important;
  padding-bottom: 231px !important;
}

.pt--231 {
  padding-top: 231px !important;
}

.pr--231 {
  padding-right: 231px !important;
}

.pb--231 {
  padding-bottom: 231px !important;
}

.pl--231 {
  padding-left: 231px !important;
}

.m--231 {
  margin: 231px !important;
}

.mx--231 {
  margin-right: 231px !important;
  margin-left: 231px !important;
}

.my--231 {
  margin-top: 231px !important;
  margin-bottom: 231px !important;
}

.mt--231 {
  margin-top: 231px !important;
}

.mr--231 {
  margin-right: 231px !important;
}

.mb--231 {
  margin-bottom: 231px !important;
}

.ml--231 {
  margin-left: 231px !important;
}

.width--231 {
  width: 231px !important;
}

.height--231 {
  height: 231px !important;
}

.gap--231 {
  gap: 231px !important;
}

.scale--231 {
  scale: 2.31 !important;
}

.p--232 {
  padding: 232px !important;
}

.px--232 {
  padding-right: 232px !important;
  padding-left: 232px !important;
}

.py--232 {
  padding-top: 232px !important;
  padding-bottom: 232px !important;
}

.pt--232 {
  padding-top: 232px !important;
}

.pr--232 {
  padding-right: 232px !important;
}

.pb--232 {
  padding-bottom: 232px !important;
}

.pl--232 {
  padding-left: 232px !important;
}

.m--232 {
  margin: 232px !important;
}

.mx--232 {
  margin-right: 232px !important;
  margin-left: 232px !important;
}

.my--232 {
  margin-top: 232px !important;
  margin-bottom: 232px !important;
}

.mt--232 {
  margin-top: 232px !important;
}

.mr--232 {
  margin-right: 232px !important;
}

.mb--232 {
  margin-bottom: 232px !important;
}

.ml--232 {
  margin-left: 232px !important;
}

.width--232 {
  width: 232px !important;
}

.height--232 {
  height: 232px !important;
}

.gap--232 {
  gap: 232px !important;
}

.scale--232 {
  scale: 2.32 !important;
}

.p--233 {
  padding: 233px !important;
}

.px--233 {
  padding-right: 233px !important;
  padding-left: 233px !important;
}

.py--233 {
  padding-top: 233px !important;
  padding-bottom: 233px !important;
}

.pt--233 {
  padding-top: 233px !important;
}

.pr--233 {
  padding-right: 233px !important;
}

.pb--233 {
  padding-bottom: 233px !important;
}

.pl--233 {
  padding-left: 233px !important;
}

.m--233 {
  margin: 233px !important;
}

.mx--233 {
  margin-right: 233px !important;
  margin-left: 233px !important;
}

.my--233 {
  margin-top: 233px !important;
  margin-bottom: 233px !important;
}

.mt--233 {
  margin-top: 233px !important;
}

.mr--233 {
  margin-right: 233px !important;
}

.mb--233 {
  margin-bottom: 233px !important;
}

.ml--233 {
  margin-left: 233px !important;
}

.width--233 {
  width: 233px !important;
}

.height--233 {
  height: 233px !important;
}

.gap--233 {
  gap: 233px !important;
}

.scale--233 {
  scale: 2.33 !important;
}

.p--234 {
  padding: 234px !important;
}

.px--234 {
  padding-right: 234px !important;
  padding-left: 234px !important;
}

.py--234 {
  padding-top: 234px !important;
  padding-bottom: 234px !important;
}

.pt--234 {
  padding-top: 234px !important;
}

.pr--234 {
  padding-right: 234px !important;
}

.pb--234 {
  padding-bottom: 234px !important;
}

.pl--234 {
  padding-left: 234px !important;
}

.m--234 {
  margin: 234px !important;
}

.mx--234 {
  margin-right: 234px !important;
  margin-left: 234px !important;
}

.my--234 {
  margin-top: 234px !important;
  margin-bottom: 234px !important;
}

.mt--234 {
  margin-top: 234px !important;
}

.mr--234 {
  margin-right: 234px !important;
}

.mb--234 {
  margin-bottom: 234px !important;
}

.ml--234 {
  margin-left: 234px !important;
}

.width--234 {
  width: 234px !important;
}

.height--234 {
  height: 234px !important;
}

.gap--234 {
  gap: 234px !important;
}

.scale--234 {
  scale: 2.34 !important;
}

.p--235 {
  padding: 235px !important;
}

.px--235 {
  padding-right: 235px !important;
  padding-left: 235px !important;
}

.py--235 {
  padding-top: 235px !important;
  padding-bottom: 235px !important;
}

.pt--235 {
  padding-top: 235px !important;
}

.pr--235 {
  padding-right: 235px !important;
}

.pb--235 {
  padding-bottom: 235px !important;
}

.pl--235 {
  padding-left: 235px !important;
}

.m--235 {
  margin: 235px !important;
}

.mx--235 {
  margin-right: 235px !important;
  margin-left: 235px !important;
}

.my--235 {
  margin-top: 235px !important;
  margin-bottom: 235px !important;
}

.mt--235 {
  margin-top: 235px !important;
}

.mr--235 {
  margin-right: 235px !important;
}

.mb--235 {
  margin-bottom: 235px !important;
}

.ml--235 {
  margin-left: 235px !important;
}

.width--235 {
  width: 235px !important;
}

.height--235 {
  height: 235px !important;
}

.gap--235 {
  gap: 235px !important;
}

.scale--235 {
  scale: 2.35 !important;
}

.p--236 {
  padding: 236px !important;
}

.px--236 {
  padding-right: 236px !important;
  padding-left: 236px !important;
}

.py--236 {
  padding-top: 236px !important;
  padding-bottom: 236px !important;
}

.pt--236 {
  padding-top: 236px !important;
}

.pr--236 {
  padding-right: 236px !important;
}

.pb--236 {
  padding-bottom: 236px !important;
}

.pl--236 {
  padding-left: 236px !important;
}

.m--236 {
  margin: 236px !important;
}

.mx--236 {
  margin-right: 236px !important;
  margin-left: 236px !important;
}

.my--236 {
  margin-top: 236px !important;
  margin-bottom: 236px !important;
}

.mt--236 {
  margin-top: 236px !important;
}

.mr--236 {
  margin-right: 236px !important;
}

.mb--236 {
  margin-bottom: 236px !important;
}

.ml--236 {
  margin-left: 236px !important;
}

.width--236 {
  width: 236px !important;
}

.height--236 {
  height: 236px !important;
}

.gap--236 {
  gap: 236px !important;
}

.scale--236 {
  scale: 2.36 !important;
}

.p--237 {
  padding: 237px !important;
}

.px--237 {
  padding-right: 237px !important;
  padding-left: 237px !important;
}

.py--237 {
  padding-top: 237px !important;
  padding-bottom: 237px !important;
}

.pt--237 {
  padding-top: 237px !important;
}

.pr--237 {
  padding-right: 237px !important;
}

.pb--237 {
  padding-bottom: 237px !important;
}

.pl--237 {
  padding-left: 237px !important;
}

.m--237 {
  margin: 237px !important;
}

.mx--237 {
  margin-right: 237px !important;
  margin-left: 237px !important;
}

.my--237 {
  margin-top: 237px !important;
  margin-bottom: 237px !important;
}

.mt--237 {
  margin-top: 237px !important;
}

.mr--237 {
  margin-right: 237px !important;
}

.mb--237 {
  margin-bottom: 237px !important;
}

.ml--237 {
  margin-left: 237px !important;
}

.width--237 {
  width: 237px !important;
}

.height--237 {
  height: 237px !important;
}

.gap--237 {
  gap: 237px !important;
}

.scale--237 {
  scale: 2.37 !important;
}

.p--238 {
  padding: 238px !important;
}

.px--238 {
  padding-right: 238px !important;
  padding-left: 238px !important;
}

.py--238 {
  padding-top: 238px !important;
  padding-bottom: 238px !important;
}

.pt--238 {
  padding-top: 238px !important;
}

.pr--238 {
  padding-right: 238px !important;
}

.pb--238 {
  padding-bottom: 238px !important;
}

.pl--238 {
  padding-left: 238px !important;
}

.m--238 {
  margin: 238px !important;
}

.mx--238 {
  margin-right: 238px !important;
  margin-left: 238px !important;
}

.my--238 {
  margin-top: 238px !important;
  margin-bottom: 238px !important;
}

.mt--238 {
  margin-top: 238px !important;
}

.mr--238 {
  margin-right: 238px !important;
}

.mb--238 {
  margin-bottom: 238px !important;
}

.ml--238 {
  margin-left: 238px !important;
}

.width--238 {
  width: 238px !important;
}

.height--238 {
  height: 238px !important;
}

.gap--238 {
  gap: 238px !important;
}

.scale--238 {
  scale: 2.38 !important;
}

.p--239 {
  padding: 239px !important;
}

.px--239 {
  padding-right: 239px !important;
  padding-left: 239px !important;
}

.py--239 {
  padding-top: 239px !important;
  padding-bottom: 239px !important;
}

.pt--239 {
  padding-top: 239px !important;
}

.pr--239 {
  padding-right: 239px !important;
}

.pb--239 {
  padding-bottom: 239px !important;
}

.pl--239 {
  padding-left: 239px !important;
}

.m--239 {
  margin: 239px !important;
}

.mx--239 {
  margin-right: 239px !important;
  margin-left: 239px !important;
}

.my--239 {
  margin-top: 239px !important;
  margin-bottom: 239px !important;
}

.mt--239 {
  margin-top: 239px !important;
}

.mr--239 {
  margin-right: 239px !important;
}

.mb--239 {
  margin-bottom: 239px !important;
}

.ml--239 {
  margin-left: 239px !important;
}

.width--239 {
  width: 239px !important;
}

.height--239 {
  height: 239px !important;
}

.gap--239 {
  gap: 239px !important;
}

.scale--239 {
  scale: 2.39 !important;
}

.p--240 {
  padding: 240px !important;
}

.px--240 {
  padding-right: 240px !important;
  padding-left: 240px !important;
}

.py--240 {
  padding-top: 240px !important;
  padding-bottom: 240px !important;
}

.pt--240 {
  padding-top: 240px !important;
}

.pr--240 {
  padding-right: 240px !important;
}

.pb--240 {
  padding-bottom: 240px !important;
}

.pl--240 {
  padding-left: 240px !important;
}

.m--240 {
  margin: 240px !important;
}

.mx--240 {
  margin-right: 240px !important;
  margin-left: 240px !important;
}

.my--240 {
  margin-top: 240px !important;
  margin-bottom: 240px !important;
}

.mt--240 {
  margin-top: 240px !important;
}

.mr--240 {
  margin-right: 240px !important;
}

.mb--240 {
  margin-bottom: 240px !important;
}

.ml--240 {
  margin-left: 240px !important;
}

.width--240 {
  width: 240px !important;
}

.height--240 {
  height: 240px !important;
}

.gap--240 {
  gap: 240px !important;
}

.scale--240 {
  scale: 2.4 !important;
}

.p--241 {
  padding: 241px !important;
}

.px--241 {
  padding-right: 241px !important;
  padding-left: 241px !important;
}

.py--241 {
  padding-top: 241px !important;
  padding-bottom: 241px !important;
}

.pt--241 {
  padding-top: 241px !important;
}

.pr--241 {
  padding-right: 241px !important;
}

.pb--241 {
  padding-bottom: 241px !important;
}

.pl--241 {
  padding-left: 241px !important;
}

.m--241 {
  margin: 241px !important;
}

.mx--241 {
  margin-right: 241px !important;
  margin-left: 241px !important;
}

.my--241 {
  margin-top: 241px !important;
  margin-bottom: 241px !important;
}

.mt--241 {
  margin-top: 241px !important;
}

.mr--241 {
  margin-right: 241px !important;
}

.mb--241 {
  margin-bottom: 241px !important;
}

.ml--241 {
  margin-left: 241px !important;
}

.width--241 {
  width: 241px !important;
}

.height--241 {
  height: 241px !important;
}

.gap--241 {
  gap: 241px !important;
}

.scale--241 {
  scale: 2.41 !important;
}

.p--242 {
  padding: 242px !important;
}

.px--242 {
  padding-right: 242px !important;
  padding-left: 242px !important;
}

.py--242 {
  padding-top: 242px !important;
  padding-bottom: 242px !important;
}

.pt--242 {
  padding-top: 242px !important;
}

.pr--242 {
  padding-right: 242px !important;
}

.pb--242 {
  padding-bottom: 242px !important;
}

.pl--242 {
  padding-left: 242px !important;
}

.m--242 {
  margin: 242px !important;
}

.mx--242 {
  margin-right: 242px !important;
  margin-left: 242px !important;
}

.my--242 {
  margin-top: 242px !important;
  margin-bottom: 242px !important;
}

.mt--242 {
  margin-top: 242px !important;
}

.mr--242 {
  margin-right: 242px !important;
}

.mb--242 {
  margin-bottom: 242px !important;
}

.ml--242 {
  margin-left: 242px !important;
}

.width--242 {
  width: 242px !important;
}

.height--242 {
  height: 242px !important;
}

.gap--242 {
  gap: 242px !important;
}

.scale--242 {
  scale: 2.42 !important;
}

.p--243 {
  padding: 243px !important;
}

.px--243 {
  padding-right: 243px !important;
  padding-left: 243px !important;
}

.py--243 {
  padding-top: 243px !important;
  padding-bottom: 243px !important;
}

.pt--243 {
  padding-top: 243px !important;
}

.pr--243 {
  padding-right: 243px !important;
}

.pb--243 {
  padding-bottom: 243px !important;
}

.pl--243 {
  padding-left: 243px !important;
}

.m--243 {
  margin: 243px !important;
}

.mx--243 {
  margin-right: 243px !important;
  margin-left: 243px !important;
}

.my--243 {
  margin-top: 243px !important;
  margin-bottom: 243px !important;
}

.mt--243 {
  margin-top: 243px !important;
}

.mr--243 {
  margin-right: 243px !important;
}

.mb--243 {
  margin-bottom: 243px !important;
}

.ml--243 {
  margin-left: 243px !important;
}

.width--243 {
  width: 243px !important;
}

.height--243 {
  height: 243px !important;
}

.gap--243 {
  gap: 243px !important;
}

.scale--243 {
  scale: 2.43 !important;
}

.p--244 {
  padding: 244px !important;
}

.px--244 {
  padding-right: 244px !important;
  padding-left: 244px !important;
}

.py--244 {
  padding-top: 244px !important;
  padding-bottom: 244px !important;
}

.pt--244 {
  padding-top: 244px !important;
}

.pr--244 {
  padding-right: 244px !important;
}

.pb--244 {
  padding-bottom: 244px !important;
}

.pl--244 {
  padding-left: 244px !important;
}

.m--244 {
  margin: 244px !important;
}

.mx--244 {
  margin-right: 244px !important;
  margin-left: 244px !important;
}

.my--244 {
  margin-top: 244px !important;
  margin-bottom: 244px !important;
}

.mt--244 {
  margin-top: 244px !important;
}

.mr--244 {
  margin-right: 244px !important;
}

.mb--244 {
  margin-bottom: 244px !important;
}

.ml--244 {
  margin-left: 244px !important;
}

.width--244 {
  width: 244px !important;
}

.height--244 {
  height: 244px !important;
}

.gap--244 {
  gap: 244px !important;
}

.scale--244 {
  scale: 2.44 !important;
}

.p--245 {
  padding: 245px !important;
}

.px--245 {
  padding-right: 245px !important;
  padding-left: 245px !important;
}

.py--245 {
  padding-top: 245px !important;
  padding-bottom: 245px !important;
}

.pt--245 {
  padding-top: 245px !important;
}

.pr--245 {
  padding-right: 245px !important;
}

.pb--245 {
  padding-bottom: 245px !important;
}

.pl--245 {
  padding-left: 245px !important;
}

.m--245 {
  margin: 245px !important;
}

.mx--245 {
  margin-right: 245px !important;
  margin-left: 245px !important;
}

.my--245 {
  margin-top: 245px !important;
  margin-bottom: 245px !important;
}

.mt--245 {
  margin-top: 245px !important;
}

.mr--245 {
  margin-right: 245px !important;
}

.mb--245 {
  margin-bottom: 245px !important;
}

.ml--245 {
  margin-left: 245px !important;
}

.width--245 {
  width: 245px !important;
}

.height--245 {
  height: 245px !important;
}

.gap--245 {
  gap: 245px !important;
}

.scale--245 {
  scale: 2.45 !important;
}

.p--246 {
  padding: 246px !important;
}

.px--246 {
  padding-right: 246px !important;
  padding-left: 246px !important;
}

.py--246 {
  padding-top: 246px !important;
  padding-bottom: 246px !important;
}

.pt--246 {
  padding-top: 246px !important;
}

.pr--246 {
  padding-right: 246px !important;
}

.pb--246 {
  padding-bottom: 246px !important;
}

.pl--246 {
  padding-left: 246px !important;
}

.m--246 {
  margin: 246px !important;
}

.mx--246 {
  margin-right: 246px !important;
  margin-left: 246px !important;
}

.my--246 {
  margin-top: 246px !important;
  margin-bottom: 246px !important;
}

.mt--246 {
  margin-top: 246px !important;
}

.mr--246 {
  margin-right: 246px !important;
}

.mb--246 {
  margin-bottom: 246px !important;
}

.ml--246 {
  margin-left: 246px !important;
}

.width--246 {
  width: 246px !important;
}

.height--246 {
  height: 246px !important;
}

.gap--246 {
  gap: 246px !important;
}

.scale--246 {
  scale: 2.46 !important;
}

.p--247 {
  padding: 247px !important;
}

.px--247 {
  padding-right: 247px !important;
  padding-left: 247px !important;
}

.py--247 {
  padding-top: 247px !important;
  padding-bottom: 247px !important;
}

.pt--247 {
  padding-top: 247px !important;
}

.pr--247 {
  padding-right: 247px !important;
}

.pb--247 {
  padding-bottom: 247px !important;
}

.pl--247 {
  padding-left: 247px !important;
}

.m--247 {
  margin: 247px !important;
}

.mx--247 {
  margin-right: 247px !important;
  margin-left: 247px !important;
}

.my--247 {
  margin-top: 247px !important;
  margin-bottom: 247px !important;
}

.mt--247 {
  margin-top: 247px !important;
}

.mr--247 {
  margin-right: 247px !important;
}

.mb--247 {
  margin-bottom: 247px !important;
}

.ml--247 {
  margin-left: 247px !important;
}

.width--247 {
  width: 247px !important;
}

.height--247 {
  height: 247px !important;
}

.gap--247 {
  gap: 247px !important;
}

.scale--247 {
  scale: 2.47 !important;
}

.p--248 {
  padding: 248px !important;
}

.px--248 {
  padding-right: 248px !important;
  padding-left: 248px !important;
}

.py--248 {
  padding-top: 248px !important;
  padding-bottom: 248px !important;
}

.pt--248 {
  padding-top: 248px !important;
}

.pr--248 {
  padding-right: 248px !important;
}

.pb--248 {
  padding-bottom: 248px !important;
}

.pl--248 {
  padding-left: 248px !important;
}

.m--248 {
  margin: 248px !important;
}

.mx--248 {
  margin-right: 248px !important;
  margin-left: 248px !important;
}

.my--248 {
  margin-top: 248px !important;
  margin-bottom: 248px !important;
}

.mt--248 {
  margin-top: 248px !important;
}

.mr--248 {
  margin-right: 248px !important;
}

.mb--248 {
  margin-bottom: 248px !important;
}

.ml--248 {
  margin-left: 248px !important;
}

.width--248 {
  width: 248px !important;
}

.height--248 {
  height: 248px !important;
}

.gap--248 {
  gap: 248px !important;
}

.scale--248 {
  scale: 2.48 !important;
}

.p--249 {
  padding: 249px !important;
}

.px--249 {
  padding-right: 249px !important;
  padding-left: 249px !important;
}

.py--249 {
  padding-top: 249px !important;
  padding-bottom: 249px !important;
}

.pt--249 {
  padding-top: 249px !important;
}

.pr--249 {
  padding-right: 249px !important;
}

.pb--249 {
  padding-bottom: 249px !important;
}

.pl--249 {
  padding-left: 249px !important;
}

.m--249 {
  margin: 249px !important;
}

.mx--249 {
  margin-right: 249px !important;
  margin-left: 249px !important;
}

.my--249 {
  margin-top: 249px !important;
  margin-bottom: 249px !important;
}

.mt--249 {
  margin-top: 249px !important;
}

.mr--249 {
  margin-right: 249px !important;
}

.mb--249 {
  margin-bottom: 249px !important;
}

.ml--249 {
  margin-left: 249px !important;
}

.width--249 {
  width: 249px !important;
}

.height--249 {
  height: 249px !important;
}

.gap--249 {
  gap: 249px !important;
}

.scale--249 {
  scale: 2.49 !important;
}

.p--250 {
  padding: 250px !important;
}

.px--250 {
  padding-right: 250px !important;
  padding-left: 250px !important;
}

.py--250 {
  padding-top: 250px !important;
  padding-bottom: 250px !important;
}

.pt--250 {
  padding-top: 250px !important;
}

.pr--250 {
  padding-right: 250px !important;
}

.pb--250 {
  padding-bottom: 250px !important;
}

.pl--250 {
  padding-left: 250px !important;
}

.m--250 {
  margin: 250px !important;
}

.mx--250 {
  margin-right: 250px !important;
  margin-left: 250px !important;
}

.my--250 {
  margin-top: 250px !important;
  margin-bottom: 250px !important;
}

.mt--250 {
  margin-top: 250px !important;
}

.mr--250 {
  margin-right: 250px !important;
}

.mb--250 {
  margin-bottom: 250px !important;
}

.ml--250 {
  margin-left: 250px !important;
}

.width--250 {
  width: 250px !important;
}

.height--250 {
  height: 250px !important;
}

.gap--250 {
  gap: 250px !important;
}

.scale--250 {
  scale: 2.5 !important;
}

.p--251 {
  padding: 251px !important;
}

.px--251 {
  padding-right: 251px !important;
  padding-left: 251px !important;
}

.py--251 {
  padding-top: 251px !important;
  padding-bottom: 251px !important;
}

.pt--251 {
  padding-top: 251px !important;
}

.pr--251 {
  padding-right: 251px !important;
}

.pb--251 {
  padding-bottom: 251px !important;
}

.pl--251 {
  padding-left: 251px !important;
}

.m--251 {
  margin: 251px !important;
}

.mx--251 {
  margin-right: 251px !important;
  margin-left: 251px !important;
}

.my--251 {
  margin-top: 251px !important;
  margin-bottom: 251px !important;
}

.mt--251 {
  margin-top: 251px !important;
}

.mr--251 {
  margin-right: 251px !important;
}

.mb--251 {
  margin-bottom: 251px !important;
}

.ml--251 {
  margin-left: 251px !important;
}

.width--251 {
  width: 251px !important;
}

.height--251 {
  height: 251px !important;
}

.gap--251 {
  gap: 251px !important;
}

.scale--251 {
  scale: 2.51 !important;
}

.p--252 {
  padding: 252px !important;
}

.px--252 {
  padding-right: 252px !important;
  padding-left: 252px !important;
}

.py--252 {
  padding-top: 252px !important;
  padding-bottom: 252px !important;
}

.pt--252 {
  padding-top: 252px !important;
}

.pr--252 {
  padding-right: 252px !important;
}

.pb--252 {
  padding-bottom: 252px !important;
}

.pl--252 {
  padding-left: 252px !important;
}

.m--252 {
  margin: 252px !important;
}

.mx--252 {
  margin-right: 252px !important;
  margin-left: 252px !important;
}

.my--252 {
  margin-top: 252px !important;
  margin-bottom: 252px !important;
}

.mt--252 {
  margin-top: 252px !important;
}

.mr--252 {
  margin-right: 252px !important;
}

.mb--252 {
  margin-bottom: 252px !important;
}

.ml--252 {
  margin-left: 252px !important;
}

.width--252 {
  width: 252px !important;
}

.height--252 {
  height: 252px !important;
}

.gap--252 {
  gap: 252px !important;
}

.scale--252 {
  scale: 2.52 !important;
}

.p--253 {
  padding: 253px !important;
}

.px--253 {
  padding-right: 253px !important;
  padding-left: 253px !important;
}

.py--253 {
  padding-top: 253px !important;
  padding-bottom: 253px !important;
}

.pt--253 {
  padding-top: 253px !important;
}

.pr--253 {
  padding-right: 253px !important;
}

.pb--253 {
  padding-bottom: 253px !important;
}

.pl--253 {
  padding-left: 253px !important;
}

.m--253 {
  margin: 253px !important;
}

.mx--253 {
  margin-right: 253px !important;
  margin-left: 253px !important;
}

.my--253 {
  margin-top: 253px !important;
  margin-bottom: 253px !important;
}

.mt--253 {
  margin-top: 253px !important;
}

.mr--253 {
  margin-right: 253px !important;
}

.mb--253 {
  margin-bottom: 253px !important;
}

.ml--253 {
  margin-left: 253px !important;
}

.width--253 {
  width: 253px !important;
}

.height--253 {
  height: 253px !important;
}

.gap--253 {
  gap: 253px !important;
}

.scale--253 {
  scale: 2.53 !important;
}

.p--254 {
  padding: 254px !important;
}

.px--254 {
  padding-right: 254px !important;
  padding-left: 254px !important;
}

.py--254 {
  padding-top: 254px !important;
  padding-bottom: 254px !important;
}

.pt--254 {
  padding-top: 254px !important;
}

.pr--254 {
  padding-right: 254px !important;
}

.pb--254 {
  padding-bottom: 254px !important;
}

.pl--254 {
  padding-left: 254px !important;
}

.m--254 {
  margin: 254px !important;
}

.mx--254 {
  margin-right: 254px !important;
  margin-left: 254px !important;
}

.my--254 {
  margin-top: 254px !important;
  margin-bottom: 254px !important;
}

.mt--254 {
  margin-top: 254px !important;
}

.mr--254 {
  margin-right: 254px !important;
}

.mb--254 {
  margin-bottom: 254px !important;
}

.ml--254 {
  margin-left: 254px !important;
}

.width--254 {
  width: 254px !important;
}

.height--254 {
  height: 254px !important;
}

.gap--254 {
  gap: 254px !important;
}

.scale--254 {
  scale: 2.54 !important;
}

.p--255 {
  padding: 255px !important;
}

.px--255 {
  padding-right: 255px !important;
  padding-left: 255px !important;
}

.py--255 {
  padding-top: 255px !important;
  padding-bottom: 255px !important;
}

.pt--255 {
  padding-top: 255px !important;
}

.pr--255 {
  padding-right: 255px !important;
}

.pb--255 {
  padding-bottom: 255px !important;
}

.pl--255 {
  padding-left: 255px !important;
}

.m--255 {
  margin: 255px !important;
}

.mx--255 {
  margin-right: 255px !important;
  margin-left: 255px !important;
}

.my--255 {
  margin-top: 255px !important;
  margin-bottom: 255px !important;
}

.mt--255 {
  margin-top: 255px !important;
}

.mr--255 {
  margin-right: 255px !important;
}

.mb--255 {
  margin-bottom: 255px !important;
}

.ml--255 {
  margin-left: 255px !important;
}

.width--255 {
  width: 255px !important;
}

.height--255 {
  height: 255px !important;
}

.gap--255 {
  gap: 255px !important;
}

.scale--255 {
  scale: 2.55 !important;
}

.p--256 {
  padding: 256px !important;
}

.px--256 {
  padding-right: 256px !important;
  padding-left: 256px !important;
}

.py--256 {
  padding-top: 256px !important;
  padding-bottom: 256px !important;
}

.pt--256 {
  padding-top: 256px !important;
}

.pr--256 {
  padding-right: 256px !important;
}

.pb--256 {
  padding-bottom: 256px !important;
}

.pl--256 {
  padding-left: 256px !important;
}

.m--256 {
  margin: 256px !important;
}

.mx--256 {
  margin-right: 256px !important;
  margin-left: 256px !important;
}

.my--256 {
  margin-top: 256px !important;
  margin-bottom: 256px !important;
}

.mt--256 {
  margin-top: 256px !important;
}

.mr--256 {
  margin-right: 256px !important;
}

.mb--256 {
  margin-bottom: 256px !important;
}

.ml--256 {
  margin-left: 256px !important;
}

.width--256 {
  width: 256px !important;
}

.height--256 {
  height: 256px !important;
}

.gap--256 {
  gap: 256px !important;
}

.scale--256 {
  scale: 2.56 !important;
}

.p--257 {
  padding: 257px !important;
}

.px--257 {
  padding-right: 257px !important;
  padding-left: 257px !important;
}

.py--257 {
  padding-top: 257px !important;
  padding-bottom: 257px !important;
}

.pt--257 {
  padding-top: 257px !important;
}

.pr--257 {
  padding-right: 257px !important;
}

.pb--257 {
  padding-bottom: 257px !important;
}

.pl--257 {
  padding-left: 257px !important;
}

.m--257 {
  margin: 257px !important;
}

.mx--257 {
  margin-right: 257px !important;
  margin-left: 257px !important;
}

.my--257 {
  margin-top: 257px !important;
  margin-bottom: 257px !important;
}

.mt--257 {
  margin-top: 257px !important;
}

.mr--257 {
  margin-right: 257px !important;
}

.mb--257 {
  margin-bottom: 257px !important;
}

.ml--257 {
  margin-left: 257px !important;
}

.width--257 {
  width: 257px !important;
}

.height--257 {
  height: 257px !important;
}

.gap--257 {
  gap: 257px !important;
}

.scale--257 {
  scale: 2.57 !important;
}

.p--258 {
  padding: 258px !important;
}

.px--258 {
  padding-right: 258px !important;
  padding-left: 258px !important;
}

.py--258 {
  padding-top: 258px !important;
  padding-bottom: 258px !important;
}

.pt--258 {
  padding-top: 258px !important;
}

.pr--258 {
  padding-right: 258px !important;
}

.pb--258 {
  padding-bottom: 258px !important;
}

.pl--258 {
  padding-left: 258px !important;
}

.m--258 {
  margin: 258px !important;
}

.mx--258 {
  margin-right: 258px !important;
  margin-left: 258px !important;
}

.my--258 {
  margin-top: 258px !important;
  margin-bottom: 258px !important;
}

.mt--258 {
  margin-top: 258px !important;
}

.mr--258 {
  margin-right: 258px !important;
}

.mb--258 {
  margin-bottom: 258px !important;
}

.ml--258 {
  margin-left: 258px !important;
}

.width--258 {
  width: 258px !important;
}

.height--258 {
  height: 258px !important;
}

.gap--258 {
  gap: 258px !important;
}

.scale--258 {
  scale: 2.58 !important;
}

.p--259 {
  padding: 259px !important;
}

.px--259 {
  padding-right: 259px !important;
  padding-left: 259px !important;
}

.py--259 {
  padding-top: 259px !important;
  padding-bottom: 259px !important;
}

.pt--259 {
  padding-top: 259px !important;
}

.pr--259 {
  padding-right: 259px !important;
}

.pb--259 {
  padding-bottom: 259px !important;
}

.pl--259 {
  padding-left: 259px !important;
}

.m--259 {
  margin: 259px !important;
}

.mx--259 {
  margin-right: 259px !important;
  margin-left: 259px !important;
}

.my--259 {
  margin-top: 259px !important;
  margin-bottom: 259px !important;
}

.mt--259 {
  margin-top: 259px !important;
}

.mr--259 {
  margin-right: 259px !important;
}

.mb--259 {
  margin-bottom: 259px !important;
}

.ml--259 {
  margin-left: 259px !important;
}

.width--259 {
  width: 259px !important;
}

.height--259 {
  height: 259px !important;
}

.gap--259 {
  gap: 259px !important;
}

.scale--259 {
  scale: 2.59 !important;
}

.p--260 {
  padding: 260px !important;
}

.px--260 {
  padding-right: 260px !important;
  padding-left: 260px !important;
}

.py--260 {
  padding-top: 260px !important;
  padding-bottom: 260px !important;
}

.pt--260 {
  padding-top: 260px !important;
}

.pr--260 {
  padding-right: 260px !important;
}

.pb--260 {
  padding-bottom: 260px !important;
}

.pl--260 {
  padding-left: 260px !important;
}

.m--260 {
  margin: 260px !important;
}

.mx--260 {
  margin-right: 260px !important;
  margin-left: 260px !important;
}

.my--260 {
  margin-top: 260px !important;
  margin-bottom: 260px !important;
}

.mt--260 {
  margin-top: 260px !important;
}

.mr--260 {
  margin-right: 260px !important;
}

.mb--260 {
  margin-bottom: 260px !important;
}

.ml--260 {
  margin-left: 260px !important;
}

.width--260 {
  width: 260px !important;
}

.height--260 {
  height: 260px !important;
}

.gap--260 {
  gap: 260px !important;
}

.scale--260 {
  scale: 2.6 !important;
}

.p--261 {
  padding: 261px !important;
}

.px--261 {
  padding-right: 261px !important;
  padding-left: 261px !important;
}

.py--261 {
  padding-top: 261px !important;
  padding-bottom: 261px !important;
}

.pt--261 {
  padding-top: 261px !important;
}

.pr--261 {
  padding-right: 261px !important;
}

.pb--261 {
  padding-bottom: 261px !important;
}

.pl--261 {
  padding-left: 261px !important;
}

.m--261 {
  margin: 261px !important;
}

.mx--261 {
  margin-right: 261px !important;
  margin-left: 261px !important;
}

.my--261 {
  margin-top: 261px !important;
  margin-bottom: 261px !important;
}

.mt--261 {
  margin-top: 261px !important;
}

.mr--261 {
  margin-right: 261px !important;
}

.mb--261 {
  margin-bottom: 261px !important;
}

.ml--261 {
  margin-left: 261px !important;
}

.width--261 {
  width: 261px !important;
}

.height--261 {
  height: 261px !important;
}

.gap--261 {
  gap: 261px !important;
}

.scale--261 {
  scale: 2.61 !important;
}

.p--262 {
  padding: 262px !important;
}

.px--262 {
  padding-right: 262px !important;
  padding-left: 262px !important;
}

.py--262 {
  padding-top: 262px !important;
  padding-bottom: 262px !important;
}

.pt--262 {
  padding-top: 262px !important;
}

.pr--262 {
  padding-right: 262px !important;
}

.pb--262 {
  padding-bottom: 262px !important;
}

.pl--262 {
  padding-left: 262px !important;
}

.m--262 {
  margin: 262px !important;
}

.mx--262 {
  margin-right: 262px !important;
  margin-left: 262px !important;
}

.my--262 {
  margin-top: 262px !important;
  margin-bottom: 262px !important;
}

.mt--262 {
  margin-top: 262px !important;
}

.mr--262 {
  margin-right: 262px !important;
}

.mb--262 {
  margin-bottom: 262px !important;
}

.ml--262 {
  margin-left: 262px !important;
}

.width--262 {
  width: 262px !important;
}

.height--262 {
  height: 262px !important;
}

.gap--262 {
  gap: 262px !important;
}

.scale--262 {
  scale: 2.62 !important;
}

.p--263 {
  padding: 263px !important;
}

.px--263 {
  padding-right: 263px !important;
  padding-left: 263px !important;
}

.py--263 {
  padding-top: 263px !important;
  padding-bottom: 263px !important;
}

.pt--263 {
  padding-top: 263px !important;
}

.pr--263 {
  padding-right: 263px !important;
}

.pb--263 {
  padding-bottom: 263px !important;
}

.pl--263 {
  padding-left: 263px !important;
}

.m--263 {
  margin: 263px !important;
}

.mx--263 {
  margin-right: 263px !important;
  margin-left: 263px !important;
}

.my--263 {
  margin-top: 263px !important;
  margin-bottom: 263px !important;
}

.mt--263 {
  margin-top: 263px !important;
}

.mr--263 {
  margin-right: 263px !important;
}

.mb--263 {
  margin-bottom: 263px !important;
}

.ml--263 {
  margin-left: 263px !important;
}

.width--263 {
  width: 263px !important;
}

.height--263 {
  height: 263px !important;
}

.gap--263 {
  gap: 263px !important;
}

.scale--263 {
  scale: 2.63 !important;
}

.p--264 {
  padding: 264px !important;
}

.px--264 {
  padding-right: 264px !important;
  padding-left: 264px !important;
}

.py--264 {
  padding-top: 264px !important;
  padding-bottom: 264px !important;
}

.pt--264 {
  padding-top: 264px !important;
}

.pr--264 {
  padding-right: 264px !important;
}

.pb--264 {
  padding-bottom: 264px !important;
}

.pl--264 {
  padding-left: 264px !important;
}

.m--264 {
  margin: 264px !important;
}

.mx--264 {
  margin-right: 264px !important;
  margin-left: 264px !important;
}

.my--264 {
  margin-top: 264px !important;
  margin-bottom: 264px !important;
}

.mt--264 {
  margin-top: 264px !important;
}

.mr--264 {
  margin-right: 264px !important;
}

.mb--264 {
  margin-bottom: 264px !important;
}

.ml--264 {
  margin-left: 264px !important;
}

.width--264 {
  width: 264px !important;
}

.height--264 {
  height: 264px !important;
}

.gap--264 {
  gap: 264px !important;
}

.scale--264 {
  scale: 2.64 !important;
}

.p--265 {
  padding: 265px !important;
}

.px--265 {
  padding-right: 265px !important;
  padding-left: 265px !important;
}

.py--265 {
  padding-top: 265px !important;
  padding-bottom: 265px !important;
}

.pt--265 {
  padding-top: 265px !important;
}

.pr--265 {
  padding-right: 265px !important;
}

.pb--265 {
  padding-bottom: 265px !important;
}

.pl--265 {
  padding-left: 265px !important;
}

.m--265 {
  margin: 265px !important;
}

.mx--265 {
  margin-right: 265px !important;
  margin-left: 265px !important;
}

.my--265 {
  margin-top: 265px !important;
  margin-bottom: 265px !important;
}

.mt--265 {
  margin-top: 265px !important;
}

.mr--265 {
  margin-right: 265px !important;
}

.mb--265 {
  margin-bottom: 265px !important;
}

.ml--265 {
  margin-left: 265px !important;
}

.width--265 {
  width: 265px !important;
}

.height--265 {
  height: 265px !important;
}

.gap--265 {
  gap: 265px !important;
}

.scale--265 {
  scale: 2.65 !important;
}

.p--266 {
  padding: 266px !important;
}

.px--266 {
  padding-right: 266px !important;
  padding-left: 266px !important;
}

.py--266 {
  padding-top: 266px !important;
  padding-bottom: 266px !important;
}

.pt--266 {
  padding-top: 266px !important;
}

.pr--266 {
  padding-right: 266px !important;
}

.pb--266 {
  padding-bottom: 266px !important;
}

.pl--266 {
  padding-left: 266px !important;
}

.m--266 {
  margin: 266px !important;
}

.mx--266 {
  margin-right: 266px !important;
  margin-left: 266px !important;
}

.my--266 {
  margin-top: 266px !important;
  margin-bottom: 266px !important;
}

.mt--266 {
  margin-top: 266px !important;
}

.mr--266 {
  margin-right: 266px !important;
}

.mb--266 {
  margin-bottom: 266px !important;
}

.ml--266 {
  margin-left: 266px !important;
}

.width--266 {
  width: 266px !important;
}

.height--266 {
  height: 266px !important;
}

.gap--266 {
  gap: 266px !important;
}

.scale--266 {
  scale: 2.66 !important;
}

.p--267 {
  padding: 267px !important;
}

.px--267 {
  padding-right: 267px !important;
  padding-left: 267px !important;
}

.py--267 {
  padding-top: 267px !important;
  padding-bottom: 267px !important;
}

.pt--267 {
  padding-top: 267px !important;
}

.pr--267 {
  padding-right: 267px !important;
}

.pb--267 {
  padding-bottom: 267px !important;
}

.pl--267 {
  padding-left: 267px !important;
}

.m--267 {
  margin: 267px !important;
}

.mx--267 {
  margin-right: 267px !important;
  margin-left: 267px !important;
}

.my--267 {
  margin-top: 267px !important;
  margin-bottom: 267px !important;
}

.mt--267 {
  margin-top: 267px !important;
}

.mr--267 {
  margin-right: 267px !important;
}

.mb--267 {
  margin-bottom: 267px !important;
}

.ml--267 {
  margin-left: 267px !important;
}

.width--267 {
  width: 267px !important;
}

.height--267 {
  height: 267px !important;
}

.gap--267 {
  gap: 267px !important;
}

.scale--267 {
  scale: 2.67 !important;
}

.p--268 {
  padding: 268px !important;
}

.px--268 {
  padding-right: 268px !important;
  padding-left: 268px !important;
}

.py--268 {
  padding-top: 268px !important;
  padding-bottom: 268px !important;
}

.pt--268 {
  padding-top: 268px !important;
}

.pr--268 {
  padding-right: 268px !important;
}

.pb--268 {
  padding-bottom: 268px !important;
}

.pl--268 {
  padding-left: 268px !important;
}

.m--268 {
  margin: 268px !important;
}

.mx--268 {
  margin-right: 268px !important;
  margin-left: 268px !important;
}

.my--268 {
  margin-top: 268px !important;
  margin-bottom: 268px !important;
}

.mt--268 {
  margin-top: 268px !important;
}

.mr--268 {
  margin-right: 268px !important;
}

.mb--268 {
  margin-bottom: 268px !important;
}

.ml--268 {
  margin-left: 268px !important;
}

.width--268 {
  width: 268px !important;
}

.height--268 {
  height: 268px !important;
}

.gap--268 {
  gap: 268px !important;
}

.scale--268 {
  scale: 2.68 !important;
}

.p--269 {
  padding: 269px !important;
}

.px--269 {
  padding-right: 269px !important;
  padding-left: 269px !important;
}

.py--269 {
  padding-top: 269px !important;
  padding-bottom: 269px !important;
}

.pt--269 {
  padding-top: 269px !important;
}

.pr--269 {
  padding-right: 269px !important;
}

.pb--269 {
  padding-bottom: 269px !important;
}

.pl--269 {
  padding-left: 269px !important;
}

.m--269 {
  margin: 269px !important;
}

.mx--269 {
  margin-right: 269px !important;
  margin-left: 269px !important;
}

.my--269 {
  margin-top: 269px !important;
  margin-bottom: 269px !important;
}

.mt--269 {
  margin-top: 269px !important;
}

.mr--269 {
  margin-right: 269px !important;
}

.mb--269 {
  margin-bottom: 269px !important;
}

.ml--269 {
  margin-left: 269px !important;
}

.width--269 {
  width: 269px !important;
}

.height--269 {
  height: 269px !important;
}

.gap--269 {
  gap: 269px !important;
}

.scale--269 {
  scale: 2.69 !important;
}

.p--270 {
  padding: 270px !important;
}

.px--270 {
  padding-right: 270px !important;
  padding-left: 270px !important;
}

.py--270 {
  padding-top: 270px !important;
  padding-bottom: 270px !important;
}

.pt--270 {
  padding-top: 270px !important;
}

.pr--270 {
  padding-right: 270px !important;
}

.pb--270 {
  padding-bottom: 270px !important;
}

.pl--270 {
  padding-left: 270px !important;
}

.m--270 {
  margin: 270px !important;
}

.mx--270 {
  margin-right: 270px !important;
  margin-left: 270px !important;
}

.my--270 {
  margin-top: 270px !important;
  margin-bottom: 270px !important;
}

.mt--270 {
  margin-top: 270px !important;
}

.mr--270 {
  margin-right: 270px !important;
}

.mb--270 {
  margin-bottom: 270px !important;
}

.ml--270 {
  margin-left: 270px !important;
}

.width--270 {
  width: 270px !important;
}

.height--270 {
  height: 270px !important;
}

.gap--270 {
  gap: 270px !important;
}

.scale--270 {
  scale: 2.7 !important;
}

.p--271 {
  padding: 271px !important;
}

.px--271 {
  padding-right: 271px !important;
  padding-left: 271px !important;
}

.py--271 {
  padding-top: 271px !important;
  padding-bottom: 271px !important;
}

.pt--271 {
  padding-top: 271px !important;
}

.pr--271 {
  padding-right: 271px !important;
}

.pb--271 {
  padding-bottom: 271px !important;
}

.pl--271 {
  padding-left: 271px !important;
}

.m--271 {
  margin: 271px !important;
}

.mx--271 {
  margin-right: 271px !important;
  margin-left: 271px !important;
}

.my--271 {
  margin-top: 271px !important;
  margin-bottom: 271px !important;
}

.mt--271 {
  margin-top: 271px !important;
}

.mr--271 {
  margin-right: 271px !important;
}

.mb--271 {
  margin-bottom: 271px !important;
}

.ml--271 {
  margin-left: 271px !important;
}

.width--271 {
  width: 271px !important;
}

.height--271 {
  height: 271px !important;
}

.gap--271 {
  gap: 271px !important;
}

.scale--271 {
  scale: 2.71 !important;
}

.p--272 {
  padding: 272px !important;
}

.px--272 {
  padding-right: 272px !important;
  padding-left: 272px !important;
}

.py--272 {
  padding-top: 272px !important;
  padding-bottom: 272px !important;
}

.pt--272 {
  padding-top: 272px !important;
}

.pr--272 {
  padding-right: 272px !important;
}

.pb--272 {
  padding-bottom: 272px !important;
}

.pl--272 {
  padding-left: 272px !important;
}

.m--272 {
  margin: 272px !important;
}

.mx--272 {
  margin-right: 272px !important;
  margin-left: 272px !important;
}

.my--272 {
  margin-top: 272px !important;
  margin-bottom: 272px !important;
}

.mt--272 {
  margin-top: 272px !important;
}

.mr--272 {
  margin-right: 272px !important;
}

.mb--272 {
  margin-bottom: 272px !important;
}

.ml--272 {
  margin-left: 272px !important;
}

.width--272 {
  width: 272px !important;
}

.height--272 {
  height: 272px !important;
}

.gap--272 {
  gap: 272px !important;
}

.scale--272 {
  scale: 2.72 !important;
}

.p--273 {
  padding: 273px !important;
}

.px--273 {
  padding-right: 273px !important;
  padding-left: 273px !important;
}

.py--273 {
  padding-top: 273px !important;
  padding-bottom: 273px !important;
}

.pt--273 {
  padding-top: 273px !important;
}

.pr--273 {
  padding-right: 273px !important;
}

.pb--273 {
  padding-bottom: 273px !important;
}

.pl--273 {
  padding-left: 273px !important;
}

.m--273 {
  margin: 273px !important;
}

.mx--273 {
  margin-right: 273px !important;
  margin-left: 273px !important;
}

.my--273 {
  margin-top: 273px !important;
  margin-bottom: 273px !important;
}

.mt--273 {
  margin-top: 273px !important;
}

.mr--273 {
  margin-right: 273px !important;
}

.mb--273 {
  margin-bottom: 273px !important;
}

.ml--273 {
  margin-left: 273px !important;
}

.width--273 {
  width: 273px !important;
}

.height--273 {
  height: 273px !important;
}

.gap--273 {
  gap: 273px !important;
}

.scale--273 {
  scale: 2.73 !important;
}

.p--274 {
  padding: 274px !important;
}

.px--274 {
  padding-right: 274px !important;
  padding-left: 274px !important;
}

.py--274 {
  padding-top: 274px !important;
  padding-bottom: 274px !important;
}

.pt--274 {
  padding-top: 274px !important;
}

.pr--274 {
  padding-right: 274px !important;
}

.pb--274 {
  padding-bottom: 274px !important;
}

.pl--274 {
  padding-left: 274px !important;
}

.m--274 {
  margin: 274px !important;
}

.mx--274 {
  margin-right: 274px !important;
  margin-left: 274px !important;
}

.my--274 {
  margin-top: 274px !important;
  margin-bottom: 274px !important;
}

.mt--274 {
  margin-top: 274px !important;
}

.mr--274 {
  margin-right: 274px !important;
}

.mb--274 {
  margin-bottom: 274px !important;
}

.ml--274 {
  margin-left: 274px !important;
}

.width--274 {
  width: 274px !important;
}

.height--274 {
  height: 274px !important;
}

.gap--274 {
  gap: 274px !important;
}

.scale--274 {
  scale: 2.74 !important;
}

.p--275 {
  padding: 275px !important;
}

.px--275 {
  padding-right: 275px !important;
  padding-left: 275px !important;
}

.py--275 {
  padding-top: 275px !important;
  padding-bottom: 275px !important;
}

.pt--275 {
  padding-top: 275px !important;
}

.pr--275 {
  padding-right: 275px !important;
}

.pb--275 {
  padding-bottom: 275px !important;
}

.pl--275 {
  padding-left: 275px !important;
}

.m--275 {
  margin: 275px !important;
}

.mx--275 {
  margin-right: 275px !important;
  margin-left: 275px !important;
}

.my--275 {
  margin-top: 275px !important;
  margin-bottom: 275px !important;
}

.mt--275 {
  margin-top: 275px !important;
}

.mr--275 {
  margin-right: 275px !important;
}

.mb--275 {
  margin-bottom: 275px !important;
}

.ml--275 {
  margin-left: 275px !important;
}

.width--275 {
  width: 275px !important;
}

.height--275 {
  height: 275px !important;
}

.gap--275 {
  gap: 275px !important;
}

.scale--275 {
  scale: 2.75 !important;
}

.p--276 {
  padding: 276px !important;
}

.px--276 {
  padding-right: 276px !important;
  padding-left: 276px !important;
}

.py--276 {
  padding-top: 276px !important;
  padding-bottom: 276px !important;
}

.pt--276 {
  padding-top: 276px !important;
}

.pr--276 {
  padding-right: 276px !important;
}

.pb--276 {
  padding-bottom: 276px !important;
}

.pl--276 {
  padding-left: 276px !important;
}

.m--276 {
  margin: 276px !important;
}

.mx--276 {
  margin-right: 276px !important;
  margin-left: 276px !important;
}

.my--276 {
  margin-top: 276px !important;
  margin-bottom: 276px !important;
}

.mt--276 {
  margin-top: 276px !important;
}

.mr--276 {
  margin-right: 276px !important;
}

.mb--276 {
  margin-bottom: 276px !important;
}

.ml--276 {
  margin-left: 276px !important;
}

.width--276 {
  width: 276px !important;
}

.height--276 {
  height: 276px !important;
}

.gap--276 {
  gap: 276px !important;
}

.scale--276 {
  scale: 2.76 !important;
}

.p--277 {
  padding: 277px !important;
}

.px--277 {
  padding-right: 277px !important;
  padding-left: 277px !important;
}

.py--277 {
  padding-top: 277px !important;
  padding-bottom: 277px !important;
}

.pt--277 {
  padding-top: 277px !important;
}

.pr--277 {
  padding-right: 277px !important;
}

.pb--277 {
  padding-bottom: 277px !important;
}

.pl--277 {
  padding-left: 277px !important;
}

.m--277 {
  margin: 277px !important;
}

.mx--277 {
  margin-right: 277px !important;
  margin-left: 277px !important;
}

.my--277 {
  margin-top: 277px !important;
  margin-bottom: 277px !important;
}

.mt--277 {
  margin-top: 277px !important;
}

.mr--277 {
  margin-right: 277px !important;
}

.mb--277 {
  margin-bottom: 277px !important;
}

.ml--277 {
  margin-left: 277px !important;
}

.width--277 {
  width: 277px !important;
}

.height--277 {
  height: 277px !important;
}

.gap--277 {
  gap: 277px !important;
}

.scale--277 {
  scale: 2.77 !important;
}

.p--278 {
  padding: 278px !important;
}

.px--278 {
  padding-right: 278px !important;
  padding-left: 278px !important;
}

.py--278 {
  padding-top: 278px !important;
  padding-bottom: 278px !important;
}

.pt--278 {
  padding-top: 278px !important;
}

.pr--278 {
  padding-right: 278px !important;
}

.pb--278 {
  padding-bottom: 278px !important;
}

.pl--278 {
  padding-left: 278px !important;
}

.m--278 {
  margin: 278px !important;
}

.mx--278 {
  margin-right: 278px !important;
  margin-left: 278px !important;
}

.my--278 {
  margin-top: 278px !important;
  margin-bottom: 278px !important;
}

.mt--278 {
  margin-top: 278px !important;
}

.mr--278 {
  margin-right: 278px !important;
}

.mb--278 {
  margin-bottom: 278px !important;
}

.ml--278 {
  margin-left: 278px !important;
}

.width--278 {
  width: 278px !important;
}

.height--278 {
  height: 278px !important;
}

.gap--278 {
  gap: 278px !important;
}

.scale--278 {
  scale: 2.78 !important;
}

.p--279 {
  padding: 279px !important;
}

.px--279 {
  padding-right: 279px !important;
  padding-left: 279px !important;
}

.py--279 {
  padding-top: 279px !important;
  padding-bottom: 279px !important;
}

.pt--279 {
  padding-top: 279px !important;
}

.pr--279 {
  padding-right: 279px !important;
}

.pb--279 {
  padding-bottom: 279px !important;
}

.pl--279 {
  padding-left: 279px !important;
}

.m--279 {
  margin: 279px !important;
}

.mx--279 {
  margin-right: 279px !important;
  margin-left: 279px !important;
}

.my--279 {
  margin-top: 279px !important;
  margin-bottom: 279px !important;
}

.mt--279 {
  margin-top: 279px !important;
}

.mr--279 {
  margin-right: 279px !important;
}

.mb--279 {
  margin-bottom: 279px !important;
}

.ml--279 {
  margin-left: 279px !important;
}

.width--279 {
  width: 279px !important;
}

.height--279 {
  height: 279px !important;
}

.gap--279 {
  gap: 279px !important;
}

.scale--279 {
  scale: 2.79 !important;
}

.p--280 {
  padding: 280px !important;
}

.px--280 {
  padding-right: 280px !important;
  padding-left: 280px !important;
}

.py--280 {
  padding-top: 280px !important;
  padding-bottom: 280px !important;
}

.pt--280 {
  padding-top: 280px !important;
}

.pr--280 {
  padding-right: 280px !important;
}

.pb--280 {
  padding-bottom: 280px !important;
}

.pl--280 {
  padding-left: 280px !important;
}

.m--280 {
  margin: 280px !important;
}

.mx--280 {
  margin-right: 280px !important;
  margin-left: 280px !important;
}

.my--280 {
  margin-top: 280px !important;
  margin-bottom: 280px !important;
}

.mt--280 {
  margin-top: 280px !important;
}

.mr--280 {
  margin-right: 280px !important;
}

.mb--280 {
  margin-bottom: 280px !important;
}

.ml--280 {
  margin-left: 280px !important;
}

.width--280 {
  width: 280px !important;
}

.height--280 {
  height: 280px !important;
}

.gap--280 {
  gap: 280px !important;
}

.scale--280 {
  scale: 2.8 !important;
}

.p--281 {
  padding: 281px !important;
}

.px--281 {
  padding-right: 281px !important;
  padding-left: 281px !important;
}

.py--281 {
  padding-top: 281px !important;
  padding-bottom: 281px !important;
}

.pt--281 {
  padding-top: 281px !important;
}

.pr--281 {
  padding-right: 281px !important;
}

.pb--281 {
  padding-bottom: 281px !important;
}

.pl--281 {
  padding-left: 281px !important;
}

.m--281 {
  margin: 281px !important;
}

.mx--281 {
  margin-right: 281px !important;
  margin-left: 281px !important;
}

.my--281 {
  margin-top: 281px !important;
  margin-bottom: 281px !important;
}

.mt--281 {
  margin-top: 281px !important;
}

.mr--281 {
  margin-right: 281px !important;
}

.mb--281 {
  margin-bottom: 281px !important;
}

.ml--281 {
  margin-left: 281px !important;
}

.width--281 {
  width: 281px !important;
}

.height--281 {
  height: 281px !important;
}

.gap--281 {
  gap: 281px !important;
}

.scale--281 {
  scale: 2.81 !important;
}

.p--282 {
  padding: 282px !important;
}

.px--282 {
  padding-right: 282px !important;
  padding-left: 282px !important;
}

.py--282 {
  padding-top: 282px !important;
  padding-bottom: 282px !important;
}

.pt--282 {
  padding-top: 282px !important;
}

.pr--282 {
  padding-right: 282px !important;
}

.pb--282 {
  padding-bottom: 282px !important;
}

.pl--282 {
  padding-left: 282px !important;
}

.m--282 {
  margin: 282px !important;
}

.mx--282 {
  margin-right: 282px !important;
  margin-left: 282px !important;
}

.my--282 {
  margin-top: 282px !important;
  margin-bottom: 282px !important;
}

.mt--282 {
  margin-top: 282px !important;
}

.mr--282 {
  margin-right: 282px !important;
}

.mb--282 {
  margin-bottom: 282px !important;
}

.ml--282 {
  margin-left: 282px !important;
}

.width--282 {
  width: 282px !important;
}

.height--282 {
  height: 282px !important;
}

.gap--282 {
  gap: 282px !important;
}

.scale--282 {
  scale: 2.82 !important;
}

.p--283 {
  padding: 283px !important;
}

.px--283 {
  padding-right: 283px !important;
  padding-left: 283px !important;
}

.py--283 {
  padding-top: 283px !important;
  padding-bottom: 283px !important;
}

.pt--283 {
  padding-top: 283px !important;
}

.pr--283 {
  padding-right: 283px !important;
}

.pb--283 {
  padding-bottom: 283px !important;
}

.pl--283 {
  padding-left: 283px !important;
}

.m--283 {
  margin: 283px !important;
}

.mx--283 {
  margin-right: 283px !important;
  margin-left: 283px !important;
}

.my--283 {
  margin-top: 283px !important;
  margin-bottom: 283px !important;
}

.mt--283 {
  margin-top: 283px !important;
}

.mr--283 {
  margin-right: 283px !important;
}

.mb--283 {
  margin-bottom: 283px !important;
}

.ml--283 {
  margin-left: 283px !important;
}

.width--283 {
  width: 283px !important;
}

.height--283 {
  height: 283px !important;
}

.gap--283 {
  gap: 283px !important;
}

.scale--283 {
  scale: 2.83 !important;
}

.p--284 {
  padding: 284px !important;
}

.px--284 {
  padding-right: 284px !important;
  padding-left: 284px !important;
}

.py--284 {
  padding-top: 284px !important;
  padding-bottom: 284px !important;
}

.pt--284 {
  padding-top: 284px !important;
}

.pr--284 {
  padding-right: 284px !important;
}

.pb--284 {
  padding-bottom: 284px !important;
}

.pl--284 {
  padding-left: 284px !important;
}

.m--284 {
  margin: 284px !important;
}

.mx--284 {
  margin-right: 284px !important;
  margin-left: 284px !important;
}

.my--284 {
  margin-top: 284px !important;
  margin-bottom: 284px !important;
}

.mt--284 {
  margin-top: 284px !important;
}

.mr--284 {
  margin-right: 284px !important;
}

.mb--284 {
  margin-bottom: 284px !important;
}

.ml--284 {
  margin-left: 284px !important;
}

.width--284 {
  width: 284px !important;
}

.height--284 {
  height: 284px !important;
}

.gap--284 {
  gap: 284px !important;
}

.scale--284 {
  scale: 2.84 !important;
}

.p--285 {
  padding: 285px !important;
}

.px--285 {
  padding-right: 285px !important;
  padding-left: 285px !important;
}

.py--285 {
  padding-top: 285px !important;
  padding-bottom: 285px !important;
}

.pt--285 {
  padding-top: 285px !important;
}

.pr--285 {
  padding-right: 285px !important;
}

.pb--285 {
  padding-bottom: 285px !important;
}

.pl--285 {
  padding-left: 285px !important;
}

.m--285 {
  margin: 285px !important;
}

.mx--285 {
  margin-right: 285px !important;
  margin-left: 285px !important;
}

.my--285 {
  margin-top: 285px !important;
  margin-bottom: 285px !important;
}

.mt--285 {
  margin-top: 285px !important;
}

.mr--285 {
  margin-right: 285px !important;
}

.mb--285 {
  margin-bottom: 285px !important;
}

.ml--285 {
  margin-left: 285px !important;
}

.width--285 {
  width: 285px !important;
}

.height--285 {
  height: 285px !important;
}

.gap--285 {
  gap: 285px !important;
}

.scale--285 {
  scale: 2.85 !important;
}

.p--286 {
  padding: 286px !important;
}

.px--286 {
  padding-right: 286px !important;
  padding-left: 286px !important;
}

.py--286 {
  padding-top: 286px !important;
  padding-bottom: 286px !important;
}

.pt--286 {
  padding-top: 286px !important;
}

.pr--286 {
  padding-right: 286px !important;
}

.pb--286 {
  padding-bottom: 286px !important;
}

.pl--286 {
  padding-left: 286px !important;
}

.m--286 {
  margin: 286px !important;
}

.mx--286 {
  margin-right: 286px !important;
  margin-left: 286px !important;
}

.my--286 {
  margin-top: 286px !important;
  margin-bottom: 286px !important;
}

.mt--286 {
  margin-top: 286px !important;
}

.mr--286 {
  margin-right: 286px !important;
}

.mb--286 {
  margin-bottom: 286px !important;
}

.ml--286 {
  margin-left: 286px !important;
}

.width--286 {
  width: 286px !important;
}

.height--286 {
  height: 286px !important;
}

.gap--286 {
  gap: 286px !important;
}

.scale--286 {
  scale: 2.86 !important;
}

.p--287 {
  padding: 287px !important;
}

.px--287 {
  padding-right: 287px !important;
  padding-left: 287px !important;
}

.py--287 {
  padding-top: 287px !important;
  padding-bottom: 287px !important;
}

.pt--287 {
  padding-top: 287px !important;
}

.pr--287 {
  padding-right: 287px !important;
}

.pb--287 {
  padding-bottom: 287px !important;
}

.pl--287 {
  padding-left: 287px !important;
}

.m--287 {
  margin: 287px !important;
}

.mx--287 {
  margin-right: 287px !important;
  margin-left: 287px !important;
}

.my--287 {
  margin-top: 287px !important;
  margin-bottom: 287px !important;
}

.mt--287 {
  margin-top: 287px !important;
}

.mr--287 {
  margin-right: 287px !important;
}

.mb--287 {
  margin-bottom: 287px !important;
}

.ml--287 {
  margin-left: 287px !important;
}

.width--287 {
  width: 287px !important;
}

.height--287 {
  height: 287px !important;
}

.gap--287 {
  gap: 287px !important;
}

.scale--287 {
  scale: 2.87 !important;
}

.p--288 {
  padding: 288px !important;
}

.px--288 {
  padding-right: 288px !important;
  padding-left: 288px !important;
}

.py--288 {
  padding-top: 288px !important;
  padding-bottom: 288px !important;
}

.pt--288 {
  padding-top: 288px !important;
}

.pr--288 {
  padding-right: 288px !important;
}

.pb--288 {
  padding-bottom: 288px !important;
}

.pl--288 {
  padding-left: 288px !important;
}

.m--288 {
  margin: 288px !important;
}

.mx--288 {
  margin-right: 288px !important;
  margin-left: 288px !important;
}

.my--288 {
  margin-top: 288px !important;
  margin-bottom: 288px !important;
}

.mt--288 {
  margin-top: 288px !important;
}

.mr--288 {
  margin-right: 288px !important;
}

.mb--288 {
  margin-bottom: 288px !important;
}

.ml--288 {
  margin-left: 288px !important;
}

.width--288 {
  width: 288px !important;
}

.height--288 {
  height: 288px !important;
}

.gap--288 {
  gap: 288px !important;
}

.scale--288 {
  scale: 2.88 !important;
}

.p--289 {
  padding: 289px !important;
}

.px--289 {
  padding-right: 289px !important;
  padding-left: 289px !important;
}

.py--289 {
  padding-top: 289px !important;
  padding-bottom: 289px !important;
}

.pt--289 {
  padding-top: 289px !important;
}

.pr--289 {
  padding-right: 289px !important;
}

.pb--289 {
  padding-bottom: 289px !important;
}

.pl--289 {
  padding-left: 289px !important;
}

.m--289 {
  margin: 289px !important;
}

.mx--289 {
  margin-right: 289px !important;
  margin-left: 289px !important;
}

.my--289 {
  margin-top: 289px !important;
  margin-bottom: 289px !important;
}

.mt--289 {
  margin-top: 289px !important;
}

.mr--289 {
  margin-right: 289px !important;
}

.mb--289 {
  margin-bottom: 289px !important;
}

.ml--289 {
  margin-left: 289px !important;
}

.width--289 {
  width: 289px !important;
}

.height--289 {
  height: 289px !important;
}

.gap--289 {
  gap: 289px !important;
}

.scale--289 {
  scale: 2.89 !important;
}

.p--290 {
  padding: 290px !important;
}

.px--290 {
  padding-right: 290px !important;
  padding-left: 290px !important;
}

.py--290 {
  padding-top: 290px !important;
  padding-bottom: 290px !important;
}

.pt--290 {
  padding-top: 290px !important;
}

.pr--290 {
  padding-right: 290px !important;
}

.pb--290 {
  padding-bottom: 290px !important;
}

.pl--290 {
  padding-left: 290px !important;
}

.m--290 {
  margin: 290px !important;
}

.mx--290 {
  margin-right: 290px !important;
  margin-left: 290px !important;
}

.my--290 {
  margin-top: 290px !important;
  margin-bottom: 290px !important;
}

.mt--290 {
  margin-top: 290px !important;
}

.mr--290 {
  margin-right: 290px !important;
}

.mb--290 {
  margin-bottom: 290px !important;
}

.ml--290 {
  margin-left: 290px !important;
}

.width--290 {
  width: 290px !important;
}

.height--290 {
  height: 290px !important;
}

.gap--290 {
  gap: 290px !important;
}

.scale--290 {
  scale: 2.9 !important;
}

.p--291 {
  padding: 291px !important;
}

.px--291 {
  padding-right: 291px !important;
  padding-left: 291px !important;
}

.py--291 {
  padding-top: 291px !important;
  padding-bottom: 291px !important;
}

.pt--291 {
  padding-top: 291px !important;
}

.pr--291 {
  padding-right: 291px !important;
}

.pb--291 {
  padding-bottom: 291px !important;
}

.pl--291 {
  padding-left: 291px !important;
}

.m--291 {
  margin: 291px !important;
}

.mx--291 {
  margin-right: 291px !important;
  margin-left: 291px !important;
}

.my--291 {
  margin-top: 291px !important;
  margin-bottom: 291px !important;
}

.mt--291 {
  margin-top: 291px !important;
}

.mr--291 {
  margin-right: 291px !important;
}

.mb--291 {
  margin-bottom: 291px !important;
}

.ml--291 {
  margin-left: 291px !important;
}

.width--291 {
  width: 291px !important;
}

.height--291 {
  height: 291px !important;
}

.gap--291 {
  gap: 291px !important;
}

.scale--291 {
  scale: 2.91 !important;
}

.p--292 {
  padding: 292px !important;
}

.px--292 {
  padding-right: 292px !important;
  padding-left: 292px !important;
}

.py--292 {
  padding-top: 292px !important;
  padding-bottom: 292px !important;
}

.pt--292 {
  padding-top: 292px !important;
}

.pr--292 {
  padding-right: 292px !important;
}

.pb--292 {
  padding-bottom: 292px !important;
}

.pl--292 {
  padding-left: 292px !important;
}

.m--292 {
  margin: 292px !important;
}

.mx--292 {
  margin-right: 292px !important;
  margin-left: 292px !important;
}

.my--292 {
  margin-top: 292px !important;
  margin-bottom: 292px !important;
}

.mt--292 {
  margin-top: 292px !important;
}

.mr--292 {
  margin-right: 292px !important;
}

.mb--292 {
  margin-bottom: 292px !important;
}

.ml--292 {
  margin-left: 292px !important;
}

.width--292 {
  width: 292px !important;
}

.height--292 {
  height: 292px !important;
}

.gap--292 {
  gap: 292px !important;
}

.scale--292 {
  scale: 2.92 !important;
}

.p--293 {
  padding: 293px !important;
}

.px--293 {
  padding-right: 293px !important;
  padding-left: 293px !important;
}

.py--293 {
  padding-top: 293px !important;
  padding-bottom: 293px !important;
}

.pt--293 {
  padding-top: 293px !important;
}

.pr--293 {
  padding-right: 293px !important;
}

.pb--293 {
  padding-bottom: 293px !important;
}

.pl--293 {
  padding-left: 293px !important;
}

.m--293 {
  margin: 293px !important;
}

.mx--293 {
  margin-right: 293px !important;
  margin-left: 293px !important;
}

.my--293 {
  margin-top: 293px !important;
  margin-bottom: 293px !important;
}

.mt--293 {
  margin-top: 293px !important;
}

.mr--293 {
  margin-right: 293px !important;
}

.mb--293 {
  margin-bottom: 293px !important;
}

.ml--293 {
  margin-left: 293px !important;
}

.width--293 {
  width: 293px !important;
}

.height--293 {
  height: 293px !important;
}

.gap--293 {
  gap: 293px !important;
}

.scale--293 {
  scale: 2.93 !important;
}

.p--294 {
  padding: 294px !important;
}

.px--294 {
  padding-right: 294px !important;
  padding-left: 294px !important;
}

.py--294 {
  padding-top: 294px !important;
  padding-bottom: 294px !important;
}

.pt--294 {
  padding-top: 294px !important;
}

.pr--294 {
  padding-right: 294px !important;
}

.pb--294 {
  padding-bottom: 294px !important;
}

.pl--294 {
  padding-left: 294px !important;
}

.m--294 {
  margin: 294px !important;
}

.mx--294 {
  margin-right: 294px !important;
  margin-left: 294px !important;
}

.my--294 {
  margin-top: 294px !important;
  margin-bottom: 294px !important;
}

.mt--294 {
  margin-top: 294px !important;
}

.mr--294 {
  margin-right: 294px !important;
}

.mb--294 {
  margin-bottom: 294px !important;
}

.ml--294 {
  margin-left: 294px !important;
}

.width--294 {
  width: 294px !important;
}

.height--294 {
  height: 294px !important;
}

.gap--294 {
  gap: 294px !important;
}

.scale--294 {
  scale: 2.94 !important;
}

.p--295 {
  padding: 295px !important;
}

.px--295 {
  padding-right: 295px !important;
  padding-left: 295px !important;
}

.py--295 {
  padding-top: 295px !important;
  padding-bottom: 295px !important;
}

.pt--295 {
  padding-top: 295px !important;
}

.pr--295 {
  padding-right: 295px !important;
}

.pb--295 {
  padding-bottom: 295px !important;
}

.pl--295 {
  padding-left: 295px !important;
}

.m--295 {
  margin: 295px !important;
}

.mx--295 {
  margin-right: 295px !important;
  margin-left: 295px !important;
}

.my--295 {
  margin-top: 295px !important;
  margin-bottom: 295px !important;
}

.mt--295 {
  margin-top: 295px !important;
}

.mr--295 {
  margin-right: 295px !important;
}

.mb--295 {
  margin-bottom: 295px !important;
}

.ml--295 {
  margin-left: 295px !important;
}

.width--295 {
  width: 295px !important;
}

.height--295 {
  height: 295px !important;
}

.gap--295 {
  gap: 295px !important;
}

.scale--295 {
  scale: 2.95 !important;
}

.p--296 {
  padding: 296px !important;
}

.px--296 {
  padding-right: 296px !important;
  padding-left: 296px !important;
}

.py--296 {
  padding-top: 296px !important;
  padding-bottom: 296px !important;
}

.pt--296 {
  padding-top: 296px !important;
}

.pr--296 {
  padding-right: 296px !important;
}

.pb--296 {
  padding-bottom: 296px !important;
}

.pl--296 {
  padding-left: 296px !important;
}

.m--296 {
  margin: 296px !important;
}

.mx--296 {
  margin-right: 296px !important;
  margin-left: 296px !important;
}

.my--296 {
  margin-top: 296px !important;
  margin-bottom: 296px !important;
}

.mt--296 {
  margin-top: 296px !important;
}

.mr--296 {
  margin-right: 296px !important;
}

.mb--296 {
  margin-bottom: 296px !important;
}

.ml--296 {
  margin-left: 296px !important;
}

.width--296 {
  width: 296px !important;
}

.height--296 {
  height: 296px !important;
}

.gap--296 {
  gap: 296px !important;
}

.scale--296 {
  scale: 2.96 !important;
}

.p--297 {
  padding: 297px !important;
}

.px--297 {
  padding-right: 297px !important;
  padding-left: 297px !important;
}

.py--297 {
  padding-top: 297px !important;
  padding-bottom: 297px !important;
}

.pt--297 {
  padding-top: 297px !important;
}

.pr--297 {
  padding-right: 297px !important;
}

.pb--297 {
  padding-bottom: 297px !important;
}

.pl--297 {
  padding-left: 297px !important;
}

.m--297 {
  margin: 297px !important;
}

.mx--297 {
  margin-right: 297px !important;
  margin-left: 297px !important;
}

.my--297 {
  margin-top: 297px !important;
  margin-bottom: 297px !important;
}

.mt--297 {
  margin-top: 297px !important;
}

.mr--297 {
  margin-right: 297px !important;
}

.mb--297 {
  margin-bottom: 297px !important;
}

.ml--297 {
  margin-left: 297px !important;
}

.width--297 {
  width: 297px !important;
}

.height--297 {
  height: 297px !important;
}

.gap--297 {
  gap: 297px !important;
}

.scale--297 {
  scale: 2.97 !important;
}

.p--298 {
  padding: 298px !important;
}

.px--298 {
  padding-right: 298px !important;
  padding-left: 298px !important;
}

.py--298 {
  padding-top: 298px !important;
  padding-bottom: 298px !important;
}

.pt--298 {
  padding-top: 298px !important;
}

.pr--298 {
  padding-right: 298px !important;
}

.pb--298 {
  padding-bottom: 298px !important;
}

.pl--298 {
  padding-left: 298px !important;
}

.m--298 {
  margin: 298px !important;
}

.mx--298 {
  margin-right: 298px !important;
  margin-left: 298px !important;
}

.my--298 {
  margin-top: 298px !important;
  margin-bottom: 298px !important;
}

.mt--298 {
  margin-top: 298px !important;
}

.mr--298 {
  margin-right: 298px !important;
}

.mb--298 {
  margin-bottom: 298px !important;
}

.ml--298 {
  margin-left: 298px !important;
}

.width--298 {
  width: 298px !important;
}

.height--298 {
  height: 298px !important;
}

.gap--298 {
  gap: 298px !important;
}

.scale--298 {
  scale: 2.98 !important;
}

.p--299 {
  padding: 299px !important;
}

.px--299 {
  padding-right: 299px !important;
  padding-left: 299px !important;
}

.py--299 {
  padding-top: 299px !important;
  padding-bottom: 299px !important;
}

.pt--299 {
  padding-top: 299px !important;
}

.pr--299 {
  padding-right: 299px !important;
}

.pb--299 {
  padding-bottom: 299px !important;
}

.pl--299 {
  padding-left: 299px !important;
}

.m--299 {
  margin: 299px !important;
}

.mx--299 {
  margin-right: 299px !important;
  margin-left: 299px !important;
}

.my--299 {
  margin-top: 299px !important;
  margin-bottom: 299px !important;
}

.mt--299 {
  margin-top: 299px !important;
}

.mr--299 {
  margin-right: 299px !important;
}

.mb--299 {
  margin-bottom: 299px !important;
}

.ml--299 {
  margin-left: 299px !important;
}

.width--299 {
  width: 299px !important;
}

.height--299 {
  height: 299px !important;
}

.gap--299 {
  gap: 299px !important;
}

.scale--299 {
  scale: 2.99 !important;
}

.p--300 {
  padding: 300px !important;
}

.px--300 {
  padding-right: 300px !important;
  padding-left: 300px !important;
}

.py--300 {
  padding-top: 300px !important;
  padding-bottom: 300px !important;
}

.pt--300 {
  padding-top: 300px !important;
}

.pr--300 {
  padding-right: 300px !important;
}

.pb--300 {
  padding-bottom: 300px !important;
}

.pl--300 {
  padding-left: 300px !important;
}

.m--300 {
  margin: 300px !important;
}

.mx--300 {
  margin-right: 300px !important;
  margin-left: 300px !important;
}

.my--300 {
  margin-top: 300px !important;
  margin-bottom: 300px !important;
}

.mt--300 {
  margin-top: 300px !important;
}

.mr--300 {
  margin-right: 300px !important;
}

.mb--300 {
  margin-bottom: 300px !important;
}

.ml--300 {
  margin-left: 300px !important;
}

.width--300 {
  width: 300px !important;
}

.height--300 {
  height: 300px !important;
}

.gap--300 {
  gap: 300px !important;
}

.scale--300 {
  scale: 3 !important;
}

.p--301 {
  padding: 301px !important;
}

.px--301 {
  padding-right: 301px !important;
  padding-left: 301px !important;
}

.py--301 {
  padding-top: 301px !important;
  padding-bottom: 301px !important;
}

.pt--301 {
  padding-top: 301px !important;
}

.pr--301 {
  padding-right: 301px !important;
}

.pb--301 {
  padding-bottom: 301px !important;
}

.pl--301 {
  padding-left: 301px !important;
}

.m--301 {
  margin: 301px !important;
}

.mx--301 {
  margin-right: 301px !important;
  margin-left: 301px !important;
}

.my--301 {
  margin-top: 301px !important;
  margin-bottom: 301px !important;
}

.mt--301 {
  margin-top: 301px !important;
}

.mr--301 {
  margin-right: 301px !important;
}

.mb--301 {
  margin-bottom: 301px !important;
}

.ml--301 {
  margin-left: 301px !important;
}

.width--301 {
  width: 301px !important;
}

.height--301 {
  height: 301px !important;
}

.gap--301 {
  gap: 301px !important;
}

.scale--301 {
  scale: 3.01 !important;
}

.p--302 {
  padding: 302px !important;
}

.px--302 {
  padding-right: 302px !important;
  padding-left: 302px !important;
}

.py--302 {
  padding-top: 302px !important;
  padding-bottom: 302px !important;
}

.pt--302 {
  padding-top: 302px !important;
}

.pr--302 {
  padding-right: 302px !important;
}

.pb--302 {
  padding-bottom: 302px !important;
}

.pl--302 {
  padding-left: 302px !important;
}

.m--302 {
  margin: 302px !important;
}

.mx--302 {
  margin-right: 302px !important;
  margin-left: 302px !important;
}

.my--302 {
  margin-top: 302px !important;
  margin-bottom: 302px !important;
}

.mt--302 {
  margin-top: 302px !important;
}

.mr--302 {
  margin-right: 302px !important;
}

.mb--302 {
  margin-bottom: 302px !important;
}

.ml--302 {
  margin-left: 302px !important;
}

.width--302 {
  width: 302px !important;
}

.height--302 {
  height: 302px !important;
}

.gap--302 {
  gap: 302px !important;
}

.scale--302 {
  scale: 3.02 !important;
}

.p--303 {
  padding: 303px !important;
}

.px--303 {
  padding-right: 303px !important;
  padding-left: 303px !important;
}

.py--303 {
  padding-top: 303px !important;
  padding-bottom: 303px !important;
}

.pt--303 {
  padding-top: 303px !important;
}

.pr--303 {
  padding-right: 303px !important;
}

.pb--303 {
  padding-bottom: 303px !important;
}

.pl--303 {
  padding-left: 303px !important;
}

.m--303 {
  margin: 303px !important;
}

.mx--303 {
  margin-right: 303px !important;
  margin-left: 303px !important;
}

.my--303 {
  margin-top: 303px !important;
  margin-bottom: 303px !important;
}

.mt--303 {
  margin-top: 303px !important;
}

.mr--303 {
  margin-right: 303px !important;
}

.mb--303 {
  margin-bottom: 303px !important;
}

.ml--303 {
  margin-left: 303px !important;
}

.width--303 {
  width: 303px !important;
}

.height--303 {
  height: 303px !important;
}

.gap--303 {
  gap: 303px !important;
}

.scale--303 {
  scale: 3.03 !important;
}

.p--304 {
  padding: 304px !important;
}

.px--304 {
  padding-right: 304px !important;
  padding-left: 304px !important;
}

.py--304 {
  padding-top: 304px !important;
  padding-bottom: 304px !important;
}

.pt--304 {
  padding-top: 304px !important;
}

.pr--304 {
  padding-right: 304px !important;
}

.pb--304 {
  padding-bottom: 304px !important;
}

.pl--304 {
  padding-left: 304px !important;
}

.m--304 {
  margin: 304px !important;
}

.mx--304 {
  margin-right: 304px !important;
  margin-left: 304px !important;
}

.my--304 {
  margin-top: 304px !important;
  margin-bottom: 304px !important;
}

.mt--304 {
  margin-top: 304px !important;
}

.mr--304 {
  margin-right: 304px !important;
}

.mb--304 {
  margin-bottom: 304px !important;
}

.ml--304 {
  margin-left: 304px !important;
}

.width--304 {
  width: 304px !important;
}

.height--304 {
  height: 304px !important;
}

.gap--304 {
  gap: 304px !important;
}

.scale--304 {
  scale: 3.04 !important;
}

.p--305 {
  padding: 305px !important;
}

.px--305 {
  padding-right: 305px !important;
  padding-left: 305px !important;
}

.py--305 {
  padding-top: 305px !important;
  padding-bottom: 305px !important;
}

.pt--305 {
  padding-top: 305px !important;
}

.pr--305 {
  padding-right: 305px !important;
}

.pb--305 {
  padding-bottom: 305px !important;
}

.pl--305 {
  padding-left: 305px !important;
}

.m--305 {
  margin: 305px !important;
}

.mx--305 {
  margin-right: 305px !important;
  margin-left: 305px !important;
}

.my--305 {
  margin-top: 305px !important;
  margin-bottom: 305px !important;
}

.mt--305 {
  margin-top: 305px !important;
}

.mr--305 {
  margin-right: 305px !important;
}

.mb--305 {
  margin-bottom: 305px !important;
}

.ml--305 {
  margin-left: 305px !important;
}

.width--305 {
  width: 305px !important;
}

.height--305 {
  height: 305px !important;
}

.gap--305 {
  gap: 305px !important;
}

.scale--305 {
  scale: 3.05 !important;
}

.p--306 {
  padding: 306px !important;
}

.px--306 {
  padding-right: 306px !important;
  padding-left: 306px !important;
}

.py--306 {
  padding-top: 306px !important;
  padding-bottom: 306px !important;
}

.pt--306 {
  padding-top: 306px !important;
}

.pr--306 {
  padding-right: 306px !important;
}

.pb--306 {
  padding-bottom: 306px !important;
}

.pl--306 {
  padding-left: 306px !important;
}

.m--306 {
  margin: 306px !important;
}

.mx--306 {
  margin-right: 306px !important;
  margin-left: 306px !important;
}

.my--306 {
  margin-top: 306px !important;
  margin-bottom: 306px !important;
}

.mt--306 {
  margin-top: 306px !important;
}

.mr--306 {
  margin-right: 306px !important;
}

.mb--306 {
  margin-bottom: 306px !important;
}

.ml--306 {
  margin-left: 306px !important;
}

.width--306 {
  width: 306px !important;
}

.height--306 {
  height: 306px !important;
}

.gap--306 {
  gap: 306px !important;
}

.scale--306 {
  scale: 3.06 !important;
}

.p--307 {
  padding: 307px !important;
}

.px--307 {
  padding-right: 307px !important;
  padding-left: 307px !important;
}

.py--307 {
  padding-top: 307px !important;
  padding-bottom: 307px !important;
}

.pt--307 {
  padding-top: 307px !important;
}

.pr--307 {
  padding-right: 307px !important;
}

.pb--307 {
  padding-bottom: 307px !important;
}

.pl--307 {
  padding-left: 307px !important;
}

.m--307 {
  margin: 307px !important;
}

.mx--307 {
  margin-right: 307px !important;
  margin-left: 307px !important;
}

.my--307 {
  margin-top: 307px !important;
  margin-bottom: 307px !important;
}

.mt--307 {
  margin-top: 307px !important;
}

.mr--307 {
  margin-right: 307px !important;
}

.mb--307 {
  margin-bottom: 307px !important;
}

.ml--307 {
  margin-left: 307px !important;
}

.width--307 {
  width: 307px !important;
}

.height--307 {
  height: 307px !important;
}

.gap--307 {
  gap: 307px !important;
}

.scale--307 {
  scale: 3.07 !important;
}

.p--308 {
  padding: 308px !important;
}

.px--308 {
  padding-right: 308px !important;
  padding-left: 308px !important;
}

.py--308 {
  padding-top: 308px !important;
  padding-bottom: 308px !important;
}

.pt--308 {
  padding-top: 308px !important;
}

.pr--308 {
  padding-right: 308px !important;
}

.pb--308 {
  padding-bottom: 308px !important;
}

.pl--308 {
  padding-left: 308px !important;
}

.m--308 {
  margin: 308px !important;
}

.mx--308 {
  margin-right: 308px !important;
  margin-left: 308px !important;
}

.my--308 {
  margin-top: 308px !important;
  margin-bottom: 308px !important;
}

.mt--308 {
  margin-top: 308px !important;
}

.mr--308 {
  margin-right: 308px !important;
}

.mb--308 {
  margin-bottom: 308px !important;
}

.ml--308 {
  margin-left: 308px !important;
}

.width--308 {
  width: 308px !important;
}

.height--308 {
  height: 308px !important;
}

.gap--308 {
  gap: 308px !important;
}

.scale--308 {
  scale: 3.08 !important;
}

.p--309 {
  padding: 309px !important;
}

.px--309 {
  padding-right: 309px !important;
  padding-left: 309px !important;
}

.py--309 {
  padding-top: 309px !important;
  padding-bottom: 309px !important;
}

.pt--309 {
  padding-top: 309px !important;
}

.pr--309 {
  padding-right: 309px !important;
}

.pb--309 {
  padding-bottom: 309px !important;
}

.pl--309 {
  padding-left: 309px !important;
}

.m--309 {
  margin: 309px !important;
}

.mx--309 {
  margin-right: 309px !important;
  margin-left: 309px !important;
}

.my--309 {
  margin-top: 309px !important;
  margin-bottom: 309px !important;
}

.mt--309 {
  margin-top: 309px !important;
}

.mr--309 {
  margin-right: 309px !important;
}

.mb--309 {
  margin-bottom: 309px !important;
}

.ml--309 {
  margin-left: 309px !important;
}

.width--309 {
  width: 309px !important;
}

.height--309 {
  height: 309px !important;
}

.gap--309 {
  gap: 309px !important;
}

.scale--309 {
  scale: 3.09 !important;
}

.p--310 {
  padding: 310px !important;
}

.px--310 {
  padding-right: 310px !important;
  padding-left: 310px !important;
}

.py--310 {
  padding-top: 310px !important;
  padding-bottom: 310px !important;
}

.pt--310 {
  padding-top: 310px !important;
}

.pr--310 {
  padding-right: 310px !important;
}

.pb--310 {
  padding-bottom: 310px !important;
}

.pl--310 {
  padding-left: 310px !important;
}

.m--310 {
  margin: 310px !important;
}

.mx--310 {
  margin-right: 310px !important;
  margin-left: 310px !important;
}

.my--310 {
  margin-top: 310px !important;
  margin-bottom: 310px !important;
}

.mt--310 {
  margin-top: 310px !important;
}

.mr--310 {
  margin-right: 310px !important;
}

.mb--310 {
  margin-bottom: 310px !important;
}

.ml--310 {
  margin-left: 310px !important;
}

.width--310 {
  width: 310px !important;
}

.height--310 {
  height: 310px !important;
}

.gap--310 {
  gap: 310px !important;
}

.scale--310 {
  scale: 3.1 !important;
}

.p--311 {
  padding: 311px !important;
}

.px--311 {
  padding-right: 311px !important;
  padding-left: 311px !important;
}

.py--311 {
  padding-top: 311px !important;
  padding-bottom: 311px !important;
}

.pt--311 {
  padding-top: 311px !important;
}

.pr--311 {
  padding-right: 311px !important;
}

.pb--311 {
  padding-bottom: 311px !important;
}

.pl--311 {
  padding-left: 311px !important;
}

.m--311 {
  margin: 311px !important;
}

.mx--311 {
  margin-right: 311px !important;
  margin-left: 311px !important;
}

.my--311 {
  margin-top: 311px !important;
  margin-bottom: 311px !important;
}

.mt--311 {
  margin-top: 311px !important;
}

.mr--311 {
  margin-right: 311px !important;
}

.mb--311 {
  margin-bottom: 311px !important;
}

.ml--311 {
  margin-left: 311px !important;
}

.width--311 {
  width: 311px !important;
}

.height--311 {
  height: 311px !important;
}

.gap--311 {
  gap: 311px !important;
}

.scale--311 {
  scale: 3.11 !important;
}

.p--312 {
  padding: 312px !important;
}

.px--312 {
  padding-right: 312px !important;
  padding-left: 312px !important;
}

.py--312 {
  padding-top: 312px !important;
  padding-bottom: 312px !important;
}

.pt--312 {
  padding-top: 312px !important;
}

.pr--312 {
  padding-right: 312px !important;
}

.pb--312 {
  padding-bottom: 312px !important;
}

.pl--312 {
  padding-left: 312px !important;
}

.m--312 {
  margin: 312px !important;
}

.mx--312 {
  margin-right: 312px !important;
  margin-left: 312px !important;
}

.my--312 {
  margin-top: 312px !important;
  margin-bottom: 312px !important;
}

.mt--312 {
  margin-top: 312px !important;
}

.mr--312 {
  margin-right: 312px !important;
}

.mb--312 {
  margin-bottom: 312px !important;
}

.ml--312 {
  margin-left: 312px !important;
}

.width--312 {
  width: 312px !important;
}

.height--312 {
  height: 312px !important;
}

.gap--312 {
  gap: 312px !important;
}

.scale--312 {
  scale: 3.12 !important;
}

.p--313 {
  padding: 313px !important;
}

.px--313 {
  padding-right: 313px !important;
  padding-left: 313px !important;
}

.py--313 {
  padding-top: 313px !important;
  padding-bottom: 313px !important;
}

.pt--313 {
  padding-top: 313px !important;
}

.pr--313 {
  padding-right: 313px !important;
}

.pb--313 {
  padding-bottom: 313px !important;
}

.pl--313 {
  padding-left: 313px !important;
}

.m--313 {
  margin: 313px !important;
}

.mx--313 {
  margin-right: 313px !important;
  margin-left: 313px !important;
}

.my--313 {
  margin-top: 313px !important;
  margin-bottom: 313px !important;
}

.mt--313 {
  margin-top: 313px !important;
}

.mr--313 {
  margin-right: 313px !important;
}

.mb--313 {
  margin-bottom: 313px !important;
}

.ml--313 {
  margin-left: 313px !important;
}

.width--313 {
  width: 313px !important;
}

.height--313 {
  height: 313px !important;
}

.gap--313 {
  gap: 313px !important;
}

.scale--313 {
  scale: 3.13 !important;
}

.p--314 {
  padding: 314px !important;
}

.px--314 {
  padding-right: 314px !important;
  padding-left: 314px !important;
}

.py--314 {
  padding-top: 314px !important;
  padding-bottom: 314px !important;
}

.pt--314 {
  padding-top: 314px !important;
}

.pr--314 {
  padding-right: 314px !important;
}

.pb--314 {
  padding-bottom: 314px !important;
}

.pl--314 {
  padding-left: 314px !important;
}

.m--314 {
  margin: 314px !important;
}

.mx--314 {
  margin-right: 314px !important;
  margin-left: 314px !important;
}

.my--314 {
  margin-top: 314px !important;
  margin-bottom: 314px !important;
}

.mt--314 {
  margin-top: 314px !important;
}

.mr--314 {
  margin-right: 314px !important;
}

.mb--314 {
  margin-bottom: 314px !important;
}

.ml--314 {
  margin-left: 314px !important;
}

.width--314 {
  width: 314px !important;
}

.height--314 {
  height: 314px !important;
}

.gap--314 {
  gap: 314px !important;
}

.scale--314 {
  scale: 3.14 !important;
}

.p--315 {
  padding: 315px !important;
}

.px--315 {
  padding-right: 315px !important;
  padding-left: 315px !important;
}

.py--315 {
  padding-top: 315px !important;
  padding-bottom: 315px !important;
}

.pt--315 {
  padding-top: 315px !important;
}

.pr--315 {
  padding-right: 315px !important;
}

.pb--315 {
  padding-bottom: 315px !important;
}

.pl--315 {
  padding-left: 315px !important;
}

.m--315 {
  margin: 315px !important;
}

.mx--315 {
  margin-right: 315px !important;
  margin-left: 315px !important;
}

.my--315 {
  margin-top: 315px !important;
  margin-bottom: 315px !important;
}

.mt--315 {
  margin-top: 315px !important;
}

.mr--315 {
  margin-right: 315px !important;
}

.mb--315 {
  margin-bottom: 315px !important;
}

.ml--315 {
  margin-left: 315px !important;
}

.width--315 {
  width: 315px !important;
}

.height--315 {
  height: 315px !important;
}

.gap--315 {
  gap: 315px !important;
}

.scale--315 {
  scale: 3.15 !important;
}

.p--316 {
  padding: 316px !important;
}

.px--316 {
  padding-right: 316px !important;
  padding-left: 316px !important;
}

.py--316 {
  padding-top: 316px !important;
  padding-bottom: 316px !important;
}

.pt--316 {
  padding-top: 316px !important;
}

.pr--316 {
  padding-right: 316px !important;
}

.pb--316 {
  padding-bottom: 316px !important;
}

.pl--316 {
  padding-left: 316px !important;
}

.m--316 {
  margin: 316px !important;
}

.mx--316 {
  margin-right: 316px !important;
  margin-left: 316px !important;
}

.my--316 {
  margin-top: 316px !important;
  margin-bottom: 316px !important;
}

.mt--316 {
  margin-top: 316px !important;
}

.mr--316 {
  margin-right: 316px !important;
}

.mb--316 {
  margin-bottom: 316px !important;
}

.ml--316 {
  margin-left: 316px !important;
}

.width--316 {
  width: 316px !important;
}

.height--316 {
  height: 316px !important;
}

.gap--316 {
  gap: 316px !important;
}

.scale--316 {
  scale: 3.16 !important;
}

.p--317 {
  padding: 317px !important;
}

.px--317 {
  padding-right: 317px !important;
  padding-left: 317px !important;
}

.py--317 {
  padding-top: 317px !important;
  padding-bottom: 317px !important;
}

.pt--317 {
  padding-top: 317px !important;
}

.pr--317 {
  padding-right: 317px !important;
}

.pb--317 {
  padding-bottom: 317px !important;
}

.pl--317 {
  padding-left: 317px !important;
}

.m--317 {
  margin: 317px !important;
}

.mx--317 {
  margin-right: 317px !important;
  margin-left: 317px !important;
}

.my--317 {
  margin-top: 317px !important;
  margin-bottom: 317px !important;
}

.mt--317 {
  margin-top: 317px !important;
}

.mr--317 {
  margin-right: 317px !important;
}

.mb--317 {
  margin-bottom: 317px !important;
}

.ml--317 {
  margin-left: 317px !important;
}

.width--317 {
  width: 317px !important;
}

.height--317 {
  height: 317px !important;
}

.gap--317 {
  gap: 317px !important;
}

.scale--317 {
  scale: 3.17 !important;
}

.p--318 {
  padding: 318px !important;
}

.px--318 {
  padding-right: 318px !important;
  padding-left: 318px !important;
}

.py--318 {
  padding-top: 318px !important;
  padding-bottom: 318px !important;
}

.pt--318 {
  padding-top: 318px !important;
}

.pr--318 {
  padding-right: 318px !important;
}

.pb--318 {
  padding-bottom: 318px !important;
}

.pl--318 {
  padding-left: 318px !important;
}

.m--318 {
  margin: 318px !important;
}

.mx--318 {
  margin-right: 318px !important;
  margin-left: 318px !important;
}

.my--318 {
  margin-top: 318px !important;
  margin-bottom: 318px !important;
}

.mt--318 {
  margin-top: 318px !important;
}

.mr--318 {
  margin-right: 318px !important;
}

.mb--318 {
  margin-bottom: 318px !important;
}

.ml--318 {
  margin-left: 318px !important;
}

.width--318 {
  width: 318px !important;
}

.height--318 {
  height: 318px !important;
}

.gap--318 {
  gap: 318px !important;
}

.scale--318 {
  scale: 3.18 !important;
}

.p--319 {
  padding: 319px !important;
}

.px--319 {
  padding-right: 319px !important;
  padding-left: 319px !important;
}

.py--319 {
  padding-top: 319px !important;
  padding-bottom: 319px !important;
}

.pt--319 {
  padding-top: 319px !important;
}

.pr--319 {
  padding-right: 319px !important;
}

.pb--319 {
  padding-bottom: 319px !important;
}

.pl--319 {
  padding-left: 319px !important;
}

.m--319 {
  margin: 319px !important;
}

.mx--319 {
  margin-right: 319px !important;
  margin-left: 319px !important;
}

.my--319 {
  margin-top: 319px !important;
  margin-bottom: 319px !important;
}

.mt--319 {
  margin-top: 319px !important;
}

.mr--319 {
  margin-right: 319px !important;
}

.mb--319 {
  margin-bottom: 319px !important;
}

.ml--319 {
  margin-left: 319px !important;
}

.width--319 {
  width: 319px !important;
}

.height--319 {
  height: 319px !important;
}

.gap--319 {
  gap: 319px !important;
}

.scale--319 {
  scale: 3.19 !important;
}

.p--320 {
  padding: 320px !important;
}

.px--320 {
  padding-right: 320px !important;
  padding-left: 320px !important;
}

.py--320 {
  padding-top: 320px !important;
  padding-bottom: 320px !important;
}

.pt--320 {
  padding-top: 320px !important;
}

.pr--320 {
  padding-right: 320px !important;
}

.pb--320 {
  padding-bottom: 320px !important;
}

.pl--320 {
  padding-left: 320px !important;
}

.m--320 {
  margin: 320px !important;
}

.mx--320 {
  margin-right: 320px !important;
  margin-left: 320px !important;
}

.my--320 {
  margin-top: 320px !important;
  margin-bottom: 320px !important;
}

.mt--320 {
  margin-top: 320px !important;
}

.mr--320 {
  margin-right: 320px !important;
}

.mb--320 {
  margin-bottom: 320px !important;
}

.ml--320 {
  margin-left: 320px !important;
}

.width--320 {
  width: 320px !important;
}

.height--320 {
  height: 320px !important;
}

.gap--320 {
  gap: 320px !important;
}

.scale--320 {
  scale: 3.2 !important;
}

.p--321 {
  padding: 321px !important;
}

.px--321 {
  padding-right: 321px !important;
  padding-left: 321px !important;
}

.py--321 {
  padding-top: 321px !important;
  padding-bottom: 321px !important;
}

.pt--321 {
  padding-top: 321px !important;
}

.pr--321 {
  padding-right: 321px !important;
}

.pb--321 {
  padding-bottom: 321px !important;
}

.pl--321 {
  padding-left: 321px !important;
}

.m--321 {
  margin: 321px !important;
}

.mx--321 {
  margin-right: 321px !important;
  margin-left: 321px !important;
}

.my--321 {
  margin-top: 321px !important;
  margin-bottom: 321px !important;
}

.mt--321 {
  margin-top: 321px !important;
}

.mr--321 {
  margin-right: 321px !important;
}

.mb--321 {
  margin-bottom: 321px !important;
}

.ml--321 {
  margin-left: 321px !important;
}

.width--321 {
  width: 321px !important;
}

.height--321 {
  height: 321px !important;
}

.gap--321 {
  gap: 321px !important;
}

.scale--321 {
  scale: 3.21 !important;
}

.p--322 {
  padding: 322px !important;
}

.px--322 {
  padding-right: 322px !important;
  padding-left: 322px !important;
}

.py--322 {
  padding-top: 322px !important;
  padding-bottom: 322px !important;
}

.pt--322 {
  padding-top: 322px !important;
}

.pr--322 {
  padding-right: 322px !important;
}

.pb--322 {
  padding-bottom: 322px !important;
}

.pl--322 {
  padding-left: 322px !important;
}

.m--322 {
  margin: 322px !important;
}

.mx--322 {
  margin-right: 322px !important;
  margin-left: 322px !important;
}

.my--322 {
  margin-top: 322px !important;
  margin-bottom: 322px !important;
}

.mt--322 {
  margin-top: 322px !important;
}

.mr--322 {
  margin-right: 322px !important;
}

.mb--322 {
  margin-bottom: 322px !important;
}

.ml--322 {
  margin-left: 322px !important;
}

.width--322 {
  width: 322px !important;
}

.height--322 {
  height: 322px !important;
}

.gap--322 {
  gap: 322px !important;
}

.scale--322 {
  scale: 3.22 !important;
}

.p--323 {
  padding: 323px !important;
}

.px--323 {
  padding-right: 323px !important;
  padding-left: 323px !important;
}

.py--323 {
  padding-top: 323px !important;
  padding-bottom: 323px !important;
}

.pt--323 {
  padding-top: 323px !important;
}

.pr--323 {
  padding-right: 323px !important;
}

.pb--323 {
  padding-bottom: 323px !important;
}

.pl--323 {
  padding-left: 323px !important;
}

.m--323 {
  margin: 323px !important;
}

.mx--323 {
  margin-right: 323px !important;
  margin-left: 323px !important;
}

.my--323 {
  margin-top: 323px !important;
  margin-bottom: 323px !important;
}

.mt--323 {
  margin-top: 323px !important;
}

.mr--323 {
  margin-right: 323px !important;
}

.mb--323 {
  margin-bottom: 323px !important;
}

.ml--323 {
  margin-left: 323px !important;
}

.width--323 {
  width: 323px !important;
}

.height--323 {
  height: 323px !important;
}

.gap--323 {
  gap: 323px !important;
}

.scale--323 {
  scale: 3.23 !important;
}

.p--324 {
  padding: 324px !important;
}

.px--324 {
  padding-right: 324px !important;
  padding-left: 324px !important;
}

.py--324 {
  padding-top: 324px !important;
  padding-bottom: 324px !important;
}

.pt--324 {
  padding-top: 324px !important;
}

.pr--324 {
  padding-right: 324px !important;
}

.pb--324 {
  padding-bottom: 324px !important;
}

.pl--324 {
  padding-left: 324px !important;
}

.m--324 {
  margin: 324px !important;
}

.mx--324 {
  margin-right: 324px !important;
  margin-left: 324px !important;
}

.my--324 {
  margin-top: 324px !important;
  margin-bottom: 324px !important;
}

.mt--324 {
  margin-top: 324px !important;
}

.mr--324 {
  margin-right: 324px !important;
}

.mb--324 {
  margin-bottom: 324px !important;
}

.ml--324 {
  margin-left: 324px !important;
}

.width--324 {
  width: 324px !important;
}

.height--324 {
  height: 324px !important;
}

.gap--324 {
  gap: 324px !important;
}

.scale--324 {
  scale: 3.24 !important;
}

.p--325 {
  padding: 325px !important;
}

.px--325 {
  padding-right: 325px !important;
  padding-left: 325px !important;
}

.py--325 {
  padding-top: 325px !important;
  padding-bottom: 325px !important;
}

.pt--325 {
  padding-top: 325px !important;
}

.pr--325 {
  padding-right: 325px !important;
}

.pb--325 {
  padding-bottom: 325px !important;
}

.pl--325 {
  padding-left: 325px !important;
}

.m--325 {
  margin: 325px !important;
}

.mx--325 {
  margin-right: 325px !important;
  margin-left: 325px !important;
}

.my--325 {
  margin-top: 325px !important;
  margin-bottom: 325px !important;
}

.mt--325 {
  margin-top: 325px !important;
}

.mr--325 {
  margin-right: 325px !important;
}

.mb--325 {
  margin-bottom: 325px !important;
}

.ml--325 {
  margin-left: 325px !important;
}

.width--325 {
  width: 325px !important;
}

.height--325 {
  height: 325px !important;
}

.gap--325 {
  gap: 325px !important;
}

.scale--325 {
  scale: 3.25 !important;
}

.p--326 {
  padding: 326px !important;
}

.px--326 {
  padding-right: 326px !important;
  padding-left: 326px !important;
}

.py--326 {
  padding-top: 326px !important;
  padding-bottom: 326px !important;
}

.pt--326 {
  padding-top: 326px !important;
}

.pr--326 {
  padding-right: 326px !important;
}

.pb--326 {
  padding-bottom: 326px !important;
}

.pl--326 {
  padding-left: 326px !important;
}

.m--326 {
  margin: 326px !important;
}

.mx--326 {
  margin-right: 326px !important;
  margin-left: 326px !important;
}

.my--326 {
  margin-top: 326px !important;
  margin-bottom: 326px !important;
}

.mt--326 {
  margin-top: 326px !important;
}

.mr--326 {
  margin-right: 326px !important;
}

.mb--326 {
  margin-bottom: 326px !important;
}

.ml--326 {
  margin-left: 326px !important;
}

.width--326 {
  width: 326px !important;
}

.height--326 {
  height: 326px !important;
}

.gap--326 {
  gap: 326px !important;
}

.scale--326 {
  scale: 3.26 !important;
}

.p--327 {
  padding: 327px !important;
}

.px--327 {
  padding-right: 327px !important;
  padding-left: 327px !important;
}

.py--327 {
  padding-top: 327px !important;
  padding-bottom: 327px !important;
}

.pt--327 {
  padding-top: 327px !important;
}

.pr--327 {
  padding-right: 327px !important;
}

.pb--327 {
  padding-bottom: 327px !important;
}

.pl--327 {
  padding-left: 327px !important;
}

.m--327 {
  margin: 327px !important;
}

.mx--327 {
  margin-right: 327px !important;
  margin-left: 327px !important;
}

.my--327 {
  margin-top: 327px !important;
  margin-bottom: 327px !important;
}

.mt--327 {
  margin-top: 327px !important;
}

.mr--327 {
  margin-right: 327px !important;
}

.mb--327 {
  margin-bottom: 327px !important;
}

.ml--327 {
  margin-left: 327px !important;
}

.width--327 {
  width: 327px !important;
}

.height--327 {
  height: 327px !important;
}

.gap--327 {
  gap: 327px !important;
}

.scale--327 {
  scale: 3.27 !important;
}

.p--328 {
  padding: 328px !important;
}

.px--328 {
  padding-right: 328px !important;
  padding-left: 328px !important;
}

.py--328 {
  padding-top: 328px !important;
  padding-bottom: 328px !important;
}

.pt--328 {
  padding-top: 328px !important;
}

.pr--328 {
  padding-right: 328px !important;
}

.pb--328 {
  padding-bottom: 328px !important;
}

.pl--328 {
  padding-left: 328px !important;
}

.m--328 {
  margin: 328px !important;
}

.mx--328 {
  margin-right: 328px !important;
  margin-left: 328px !important;
}

.my--328 {
  margin-top: 328px !important;
  margin-bottom: 328px !important;
}

.mt--328 {
  margin-top: 328px !important;
}

.mr--328 {
  margin-right: 328px !important;
}

.mb--328 {
  margin-bottom: 328px !important;
}

.ml--328 {
  margin-left: 328px !important;
}

.width--328 {
  width: 328px !important;
}

.height--328 {
  height: 328px !important;
}

.gap--328 {
  gap: 328px !important;
}

.scale--328 {
  scale: 3.28 !important;
}

.p--329 {
  padding: 329px !important;
}

.px--329 {
  padding-right: 329px !important;
  padding-left: 329px !important;
}

.py--329 {
  padding-top: 329px !important;
  padding-bottom: 329px !important;
}

.pt--329 {
  padding-top: 329px !important;
}

.pr--329 {
  padding-right: 329px !important;
}

.pb--329 {
  padding-bottom: 329px !important;
}

.pl--329 {
  padding-left: 329px !important;
}

.m--329 {
  margin: 329px !important;
}

.mx--329 {
  margin-right: 329px !important;
  margin-left: 329px !important;
}

.my--329 {
  margin-top: 329px !important;
  margin-bottom: 329px !important;
}

.mt--329 {
  margin-top: 329px !important;
}

.mr--329 {
  margin-right: 329px !important;
}

.mb--329 {
  margin-bottom: 329px !important;
}

.ml--329 {
  margin-left: 329px !important;
}

.width--329 {
  width: 329px !important;
}

.height--329 {
  height: 329px !important;
}

.gap--329 {
  gap: 329px !important;
}

.scale--329 {
  scale: 3.29 !important;
}

.p--330 {
  padding: 330px !important;
}

.px--330 {
  padding-right: 330px !important;
  padding-left: 330px !important;
}

.py--330 {
  padding-top: 330px !important;
  padding-bottom: 330px !important;
}

.pt--330 {
  padding-top: 330px !important;
}

.pr--330 {
  padding-right: 330px !important;
}

.pb--330 {
  padding-bottom: 330px !important;
}

.pl--330 {
  padding-left: 330px !important;
}

.m--330 {
  margin: 330px !important;
}

.mx--330 {
  margin-right: 330px !important;
  margin-left: 330px !important;
}

.my--330 {
  margin-top: 330px !important;
  margin-bottom: 330px !important;
}

.mt--330 {
  margin-top: 330px !important;
}

.mr--330 {
  margin-right: 330px !important;
}

.mb--330 {
  margin-bottom: 330px !important;
}

.ml--330 {
  margin-left: 330px !important;
}

.width--330 {
  width: 330px !important;
}

.height--330 {
  height: 330px !important;
}

.gap--330 {
  gap: 330px !important;
}

.scale--330 {
  scale: 3.3 !important;
}

.p--331 {
  padding: 331px !important;
}

.px--331 {
  padding-right: 331px !important;
  padding-left: 331px !important;
}

.py--331 {
  padding-top: 331px !important;
  padding-bottom: 331px !important;
}

.pt--331 {
  padding-top: 331px !important;
}

.pr--331 {
  padding-right: 331px !important;
}

.pb--331 {
  padding-bottom: 331px !important;
}

.pl--331 {
  padding-left: 331px !important;
}

.m--331 {
  margin: 331px !important;
}

.mx--331 {
  margin-right: 331px !important;
  margin-left: 331px !important;
}

.my--331 {
  margin-top: 331px !important;
  margin-bottom: 331px !important;
}

.mt--331 {
  margin-top: 331px !important;
}

.mr--331 {
  margin-right: 331px !important;
}

.mb--331 {
  margin-bottom: 331px !important;
}

.ml--331 {
  margin-left: 331px !important;
}

.width--331 {
  width: 331px !important;
}

.height--331 {
  height: 331px !important;
}

.gap--331 {
  gap: 331px !important;
}

.scale--331 {
  scale: 3.31 !important;
}

.p--332 {
  padding: 332px !important;
}

.px--332 {
  padding-right: 332px !important;
  padding-left: 332px !important;
}

.py--332 {
  padding-top: 332px !important;
  padding-bottom: 332px !important;
}

.pt--332 {
  padding-top: 332px !important;
}

.pr--332 {
  padding-right: 332px !important;
}

.pb--332 {
  padding-bottom: 332px !important;
}

.pl--332 {
  padding-left: 332px !important;
}

.m--332 {
  margin: 332px !important;
}

.mx--332 {
  margin-right: 332px !important;
  margin-left: 332px !important;
}

.my--332 {
  margin-top: 332px !important;
  margin-bottom: 332px !important;
}

.mt--332 {
  margin-top: 332px !important;
}

.mr--332 {
  margin-right: 332px !important;
}

.mb--332 {
  margin-bottom: 332px !important;
}

.ml--332 {
  margin-left: 332px !important;
}

.width--332 {
  width: 332px !important;
}

.height--332 {
  height: 332px !important;
}

.gap--332 {
  gap: 332px !important;
}

.scale--332 {
  scale: 3.32 !important;
}

.p--333 {
  padding: 333px !important;
}

.px--333 {
  padding-right: 333px !important;
  padding-left: 333px !important;
}

.py--333 {
  padding-top: 333px !important;
  padding-bottom: 333px !important;
}

.pt--333 {
  padding-top: 333px !important;
}

.pr--333 {
  padding-right: 333px !important;
}

.pb--333 {
  padding-bottom: 333px !important;
}

.pl--333 {
  padding-left: 333px !important;
}

.m--333 {
  margin: 333px !important;
}

.mx--333 {
  margin-right: 333px !important;
  margin-left: 333px !important;
}

.my--333 {
  margin-top: 333px !important;
  margin-bottom: 333px !important;
}

.mt--333 {
  margin-top: 333px !important;
}

.mr--333 {
  margin-right: 333px !important;
}

.mb--333 {
  margin-bottom: 333px !important;
}

.ml--333 {
  margin-left: 333px !important;
}

.width--333 {
  width: 333px !important;
}

.height--333 {
  height: 333px !important;
}

.gap--333 {
  gap: 333px !important;
}

.scale--333 {
  scale: 3.33 !important;
}

.p--334 {
  padding: 334px !important;
}

.px--334 {
  padding-right: 334px !important;
  padding-left: 334px !important;
}

.py--334 {
  padding-top: 334px !important;
  padding-bottom: 334px !important;
}

.pt--334 {
  padding-top: 334px !important;
}

.pr--334 {
  padding-right: 334px !important;
}

.pb--334 {
  padding-bottom: 334px !important;
}

.pl--334 {
  padding-left: 334px !important;
}

.m--334 {
  margin: 334px !important;
}

.mx--334 {
  margin-right: 334px !important;
  margin-left: 334px !important;
}

.my--334 {
  margin-top: 334px !important;
  margin-bottom: 334px !important;
}

.mt--334 {
  margin-top: 334px !important;
}

.mr--334 {
  margin-right: 334px !important;
}

.mb--334 {
  margin-bottom: 334px !important;
}

.ml--334 {
  margin-left: 334px !important;
}

.width--334 {
  width: 334px !important;
}

.height--334 {
  height: 334px !important;
}

.gap--334 {
  gap: 334px !important;
}

.scale--334 {
  scale: 3.34 !important;
}

.p--335 {
  padding: 335px !important;
}

.px--335 {
  padding-right: 335px !important;
  padding-left: 335px !important;
}

.py--335 {
  padding-top: 335px !important;
  padding-bottom: 335px !important;
}

.pt--335 {
  padding-top: 335px !important;
}

.pr--335 {
  padding-right: 335px !important;
}

.pb--335 {
  padding-bottom: 335px !important;
}

.pl--335 {
  padding-left: 335px !important;
}

.m--335 {
  margin: 335px !important;
}

.mx--335 {
  margin-right: 335px !important;
  margin-left: 335px !important;
}

.my--335 {
  margin-top: 335px !important;
  margin-bottom: 335px !important;
}

.mt--335 {
  margin-top: 335px !important;
}

.mr--335 {
  margin-right: 335px !important;
}

.mb--335 {
  margin-bottom: 335px !important;
}

.ml--335 {
  margin-left: 335px !important;
}

.width--335 {
  width: 335px !important;
}

.height--335 {
  height: 335px !important;
}

.gap--335 {
  gap: 335px !important;
}

.scale--335 {
  scale: 3.35 !important;
}

.p--336 {
  padding: 336px !important;
}

.px--336 {
  padding-right: 336px !important;
  padding-left: 336px !important;
}

.py--336 {
  padding-top: 336px !important;
  padding-bottom: 336px !important;
}

.pt--336 {
  padding-top: 336px !important;
}

.pr--336 {
  padding-right: 336px !important;
}

.pb--336 {
  padding-bottom: 336px !important;
}

.pl--336 {
  padding-left: 336px !important;
}

.m--336 {
  margin: 336px !important;
}

.mx--336 {
  margin-right: 336px !important;
  margin-left: 336px !important;
}

.my--336 {
  margin-top: 336px !important;
  margin-bottom: 336px !important;
}

.mt--336 {
  margin-top: 336px !important;
}

.mr--336 {
  margin-right: 336px !important;
}

.mb--336 {
  margin-bottom: 336px !important;
}

.ml--336 {
  margin-left: 336px !important;
}

.width--336 {
  width: 336px !important;
}

.height--336 {
  height: 336px !important;
}

.gap--336 {
  gap: 336px !important;
}

.scale--336 {
  scale: 3.36 !important;
}

.p--337 {
  padding: 337px !important;
}

.px--337 {
  padding-right: 337px !important;
  padding-left: 337px !important;
}

.py--337 {
  padding-top: 337px !important;
  padding-bottom: 337px !important;
}

.pt--337 {
  padding-top: 337px !important;
}

.pr--337 {
  padding-right: 337px !important;
}

.pb--337 {
  padding-bottom: 337px !important;
}

.pl--337 {
  padding-left: 337px !important;
}

.m--337 {
  margin: 337px !important;
}

.mx--337 {
  margin-right: 337px !important;
  margin-left: 337px !important;
}

.my--337 {
  margin-top: 337px !important;
  margin-bottom: 337px !important;
}

.mt--337 {
  margin-top: 337px !important;
}

.mr--337 {
  margin-right: 337px !important;
}

.mb--337 {
  margin-bottom: 337px !important;
}

.ml--337 {
  margin-left: 337px !important;
}

.width--337 {
  width: 337px !important;
}

.height--337 {
  height: 337px !important;
}

.gap--337 {
  gap: 337px !important;
}

.scale--337 {
  scale: 3.37 !important;
}

.p--338 {
  padding: 338px !important;
}

.px--338 {
  padding-right: 338px !important;
  padding-left: 338px !important;
}

.py--338 {
  padding-top: 338px !important;
  padding-bottom: 338px !important;
}

.pt--338 {
  padding-top: 338px !important;
}

.pr--338 {
  padding-right: 338px !important;
}

.pb--338 {
  padding-bottom: 338px !important;
}

.pl--338 {
  padding-left: 338px !important;
}

.m--338 {
  margin: 338px !important;
}

.mx--338 {
  margin-right: 338px !important;
  margin-left: 338px !important;
}

.my--338 {
  margin-top: 338px !important;
  margin-bottom: 338px !important;
}

.mt--338 {
  margin-top: 338px !important;
}

.mr--338 {
  margin-right: 338px !important;
}

.mb--338 {
  margin-bottom: 338px !important;
}

.ml--338 {
  margin-left: 338px !important;
}

.width--338 {
  width: 338px !important;
}

.height--338 {
  height: 338px !important;
}

.gap--338 {
  gap: 338px !important;
}

.scale--338 {
  scale: 3.38 !important;
}

.p--339 {
  padding: 339px !important;
}

.px--339 {
  padding-right: 339px !important;
  padding-left: 339px !important;
}

.py--339 {
  padding-top: 339px !important;
  padding-bottom: 339px !important;
}

.pt--339 {
  padding-top: 339px !important;
}

.pr--339 {
  padding-right: 339px !important;
}

.pb--339 {
  padding-bottom: 339px !important;
}

.pl--339 {
  padding-left: 339px !important;
}

.m--339 {
  margin: 339px !important;
}

.mx--339 {
  margin-right: 339px !important;
  margin-left: 339px !important;
}

.my--339 {
  margin-top: 339px !important;
  margin-bottom: 339px !important;
}

.mt--339 {
  margin-top: 339px !important;
}

.mr--339 {
  margin-right: 339px !important;
}

.mb--339 {
  margin-bottom: 339px !important;
}

.ml--339 {
  margin-left: 339px !important;
}

.width--339 {
  width: 339px !important;
}

.height--339 {
  height: 339px !important;
}

.gap--339 {
  gap: 339px !important;
}

.scale--339 {
  scale: 3.39 !important;
}

.p--340 {
  padding: 340px !important;
}

.px--340 {
  padding-right: 340px !important;
  padding-left: 340px !important;
}

.py--340 {
  padding-top: 340px !important;
  padding-bottom: 340px !important;
}

.pt--340 {
  padding-top: 340px !important;
}

.pr--340 {
  padding-right: 340px !important;
}

.pb--340 {
  padding-bottom: 340px !important;
}

.pl--340 {
  padding-left: 340px !important;
}

.m--340 {
  margin: 340px !important;
}

.mx--340 {
  margin-right: 340px !important;
  margin-left: 340px !important;
}

.my--340 {
  margin-top: 340px !important;
  margin-bottom: 340px !important;
}

.mt--340 {
  margin-top: 340px !important;
}

.mr--340 {
  margin-right: 340px !important;
}

.mb--340 {
  margin-bottom: 340px !important;
}

.ml--340 {
  margin-left: 340px !important;
}

.width--340 {
  width: 340px !important;
}

.height--340 {
  height: 340px !important;
}

.gap--340 {
  gap: 340px !important;
}

.scale--340 {
  scale: 3.4 !important;
}

.p--341 {
  padding: 341px !important;
}

.px--341 {
  padding-right: 341px !important;
  padding-left: 341px !important;
}

.py--341 {
  padding-top: 341px !important;
  padding-bottom: 341px !important;
}

.pt--341 {
  padding-top: 341px !important;
}

.pr--341 {
  padding-right: 341px !important;
}

.pb--341 {
  padding-bottom: 341px !important;
}

.pl--341 {
  padding-left: 341px !important;
}

.m--341 {
  margin: 341px !important;
}

.mx--341 {
  margin-right: 341px !important;
  margin-left: 341px !important;
}

.my--341 {
  margin-top: 341px !important;
  margin-bottom: 341px !important;
}

.mt--341 {
  margin-top: 341px !important;
}

.mr--341 {
  margin-right: 341px !important;
}

.mb--341 {
  margin-bottom: 341px !important;
}

.ml--341 {
  margin-left: 341px !important;
}

.width--341 {
  width: 341px !important;
}

.height--341 {
  height: 341px !important;
}

.gap--341 {
  gap: 341px !important;
}

.scale--341 {
  scale: 3.41 !important;
}

.p--342 {
  padding: 342px !important;
}

.px--342 {
  padding-right: 342px !important;
  padding-left: 342px !important;
}

.py--342 {
  padding-top: 342px !important;
  padding-bottom: 342px !important;
}

.pt--342 {
  padding-top: 342px !important;
}

.pr--342 {
  padding-right: 342px !important;
}

.pb--342 {
  padding-bottom: 342px !important;
}

.pl--342 {
  padding-left: 342px !important;
}

.m--342 {
  margin: 342px !important;
}

.mx--342 {
  margin-right: 342px !important;
  margin-left: 342px !important;
}

.my--342 {
  margin-top: 342px !important;
  margin-bottom: 342px !important;
}

.mt--342 {
  margin-top: 342px !important;
}

.mr--342 {
  margin-right: 342px !important;
}

.mb--342 {
  margin-bottom: 342px !important;
}

.ml--342 {
  margin-left: 342px !important;
}

.width--342 {
  width: 342px !important;
}

.height--342 {
  height: 342px !important;
}

.gap--342 {
  gap: 342px !important;
}

.scale--342 {
  scale: 3.42 !important;
}

.p--343 {
  padding: 343px !important;
}

.px--343 {
  padding-right: 343px !important;
  padding-left: 343px !important;
}

.py--343 {
  padding-top: 343px !important;
  padding-bottom: 343px !important;
}

.pt--343 {
  padding-top: 343px !important;
}

.pr--343 {
  padding-right: 343px !important;
}

.pb--343 {
  padding-bottom: 343px !important;
}

.pl--343 {
  padding-left: 343px !important;
}

.m--343 {
  margin: 343px !important;
}

.mx--343 {
  margin-right: 343px !important;
  margin-left: 343px !important;
}

.my--343 {
  margin-top: 343px !important;
  margin-bottom: 343px !important;
}

.mt--343 {
  margin-top: 343px !important;
}

.mr--343 {
  margin-right: 343px !important;
}

.mb--343 {
  margin-bottom: 343px !important;
}

.ml--343 {
  margin-left: 343px !important;
}

.width--343 {
  width: 343px !important;
}

.height--343 {
  height: 343px !important;
}

.gap--343 {
  gap: 343px !important;
}

.scale--343 {
  scale: 3.43 !important;
}

.p--344 {
  padding: 344px !important;
}

.px--344 {
  padding-right: 344px !important;
  padding-left: 344px !important;
}

.py--344 {
  padding-top: 344px !important;
  padding-bottom: 344px !important;
}

.pt--344 {
  padding-top: 344px !important;
}

.pr--344 {
  padding-right: 344px !important;
}

.pb--344 {
  padding-bottom: 344px !important;
}

.pl--344 {
  padding-left: 344px !important;
}

.m--344 {
  margin: 344px !important;
}

.mx--344 {
  margin-right: 344px !important;
  margin-left: 344px !important;
}

.my--344 {
  margin-top: 344px !important;
  margin-bottom: 344px !important;
}

.mt--344 {
  margin-top: 344px !important;
}

.mr--344 {
  margin-right: 344px !important;
}

.mb--344 {
  margin-bottom: 344px !important;
}

.ml--344 {
  margin-left: 344px !important;
}

.width--344 {
  width: 344px !important;
}

.height--344 {
  height: 344px !important;
}

.gap--344 {
  gap: 344px !important;
}

.scale--344 {
  scale: 3.44 !important;
}

.p--345 {
  padding: 345px !important;
}

.px--345 {
  padding-right: 345px !important;
  padding-left: 345px !important;
}

.py--345 {
  padding-top: 345px !important;
  padding-bottom: 345px !important;
}

.pt--345 {
  padding-top: 345px !important;
}

.pr--345 {
  padding-right: 345px !important;
}

.pb--345 {
  padding-bottom: 345px !important;
}

.pl--345 {
  padding-left: 345px !important;
}

.m--345 {
  margin: 345px !important;
}

.mx--345 {
  margin-right: 345px !important;
  margin-left: 345px !important;
}

.my--345 {
  margin-top: 345px !important;
  margin-bottom: 345px !important;
}

.mt--345 {
  margin-top: 345px !important;
}

.mr--345 {
  margin-right: 345px !important;
}

.mb--345 {
  margin-bottom: 345px !important;
}

.ml--345 {
  margin-left: 345px !important;
}

.width--345 {
  width: 345px !important;
}

.height--345 {
  height: 345px !important;
}

.gap--345 {
  gap: 345px !important;
}

.scale--345 {
  scale: 3.45 !important;
}

.p--346 {
  padding: 346px !important;
}

.px--346 {
  padding-right: 346px !important;
  padding-left: 346px !important;
}

.py--346 {
  padding-top: 346px !important;
  padding-bottom: 346px !important;
}

.pt--346 {
  padding-top: 346px !important;
}

.pr--346 {
  padding-right: 346px !important;
}

.pb--346 {
  padding-bottom: 346px !important;
}

.pl--346 {
  padding-left: 346px !important;
}

.m--346 {
  margin: 346px !important;
}

.mx--346 {
  margin-right: 346px !important;
  margin-left: 346px !important;
}

.my--346 {
  margin-top: 346px !important;
  margin-bottom: 346px !important;
}

.mt--346 {
  margin-top: 346px !important;
}

.mr--346 {
  margin-right: 346px !important;
}

.mb--346 {
  margin-bottom: 346px !important;
}

.ml--346 {
  margin-left: 346px !important;
}

.width--346 {
  width: 346px !important;
}

.height--346 {
  height: 346px !important;
}

.gap--346 {
  gap: 346px !important;
}

.scale--346 {
  scale: 3.46 !important;
}

.p--347 {
  padding: 347px !important;
}

.px--347 {
  padding-right: 347px !important;
  padding-left: 347px !important;
}

.py--347 {
  padding-top: 347px !important;
  padding-bottom: 347px !important;
}

.pt--347 {
  padding-top: 347px !important;
}

.pr--347 {
  padding-right: 347px !important;
}

.pb--347 {
  padding-bottom: 347px !important;
}

.pl--347 {
  padding-left: 347px !important;
}

.m--347 {
  margin: 347px !important;
}

.mx--347 {
  margin-right: 347px !important;
  margin-left: 347px !important;
}

.my--347 {
  margin-top: 347px !important;
  margin-bottom: 347px !important;
}

.mt--347 {
  margin-top: 347px !important;
}

.mr--347 {
  margin-right: 347px !important;
}

.mb--347 {
  margin-bottom: 347px !important;
}

.ml--347 {
  margin-left: 347px !important;
}

.width--347 {
  width: 347px !important;
}

.height--347 {
  height: 347px !important;
}

.gap--347 {
  gap: 347px !important;
}

.scale--347 {
  scale: 3.47 !important;
}

.p--348 {
  padding: 348px !important;
}

.px--348 {
  padding-right: 348px !important;
  padding-left: 348px !important;
}

.py--348 {
  padding-top: 348px !important;
  padding-bottom: 348px !important;
}

.pt--348 {
  padding-top: 348px !important;
}

.pr--348 {
  padding-right: 348px !important;
}

.pb--348 {
  padding-bottom: 348px !important;
}

.pl--348 {
  padding-left: 348px !important;
}

.m--348 {
  margin: 348px !important;
}

.mx--348 {
  margin-right: 348px !important;
  margin-left: 348px !important;
}

.my--348 {
  margin-top: 348px !important;
  margin-bottom: 348px !important;
}

.mt--348 {
  margin-top: 348px !important;
}

.mr--348 {
  margin-right: 348px !important;
}

.mb--348 {
  margin-bottom: 348px !important;
}

.ml--348 {
  margin-left: 348px !important;
}

.width--348 {
  width: 348px !important;
}

.height--348 {
  height: 348px !important;
}

.gap--348 {
  gap: 348px !important;
}

.scale--348 {
  scale: 3.48 !important;
}

.p--349 {
  padding: 349px !important;
}

.px--349 {
  padding-right: 349px !important;
  padding-left: 349px !important;
}

.py--349 {
  padding-top: 349px !important;
  padding-bottom: 349px !important;
}

.pt--349 {
  padding-top: 349px !important;
}

.pr--349 {
  padding-right: 349px !important;
}

.pb--349 {
  padding-bottom: 349px !important;
}

.pl--349 {
  padding-left: 349px !important;
}

.m--349 {
  margin: 349px !important;
}

.mx--349 {
  margin-right: 349px !important;
  margin-left: 349px !important;
}

.my--349 {
  margin-top: 349px !important;
  margin-bottom: 349px !important;
}

.mt--349 {
  margin-top: 349px !important;
}

.mr--349 {
  margin-right: 349px !important;
}

.mb--349 {
  margin-bottom: 349px !important;
}

.ml--349 {
  margin-left: 349px !important;
}

.width--349 {
  width: 349px !important;
}

.height--349 {
  height: 349px !important;
}

.gap--349 {
  gap: 349px !important;
}

.scale--349 {
  scale: 3.49 !important;
}

.p--350 {
  padding: 350px !important;
}

.px--350 {
  padding-right: 350px !important;
  padding-left: 350px !important;
}

.py--350 {
  padding-top: 350px !important;
  padding-bottom: 350px !important;
}

.pt--350 {
  padding-top: 350px !important;
}

.pr--350 {
  padding-right: 350px !important;
}

.pb--350 {
  padding-bottom: 350px !important;
}

.pl--350 {
  padding-left: 350px !important;
}

.m--350 {
  margin: 350px !important;
}

.mx--350 {
  margin-right: 350px !important;
  margin-left: 350px !important;
}

.my--350 {
  margin-top: 350px !important;
  margin-bottom: 350px !important;
}

.mt--350 {
  margin-top: 350px !important;
}

.mr--350 {
  margin-right: 350px !important;
}

.mb--350 {
  margin-bottom: 350px !important;
}

.ml--350 {
  margin-left: 350px !important;
}

.width--350 {
  width: 350px !important;
}

.height--350 {
  height: 350px !important;
}

.gap--350 {
  gap: 350px !important;
}

.scale--350 {
  scale: 3.5 !important;
}

.p--351 {
  padding: 351px !important;
}

.px--351 {
  padding-right: 351px !important;
  padding-left: 351px !important;
}

.py--351 {
  padding-top: 351px !important;
  padding-bottom: 351px !important;
}

.pt--351 {
  padding-top: 351px !important;
}

.pr--351 {
  padding-right: 351px !important;
}

.pb--351 {
  padding-bottom: 351px !important;
}

.pl--351 {
  padding-left: 351px !important;
}

.m--351 {
  margin: 351px !important;
}

.mx--351 {
  margin-right: 351px !important;
  margin-left: 351px !important;
}

.my--351 {
  margin-top: 351px !important;
  margin-bottom: 351px !important;
}

.mt--351 {
  margin-top: 351px !important;
}

.mr--351 {
  margin-right: 351px !important;
}

.mb--351 {
  margin-bottom: 351px !important;
}

.ml--351 {
  margin-left: 351px !important;
}

.width--351 {
  width: 351px !important;
}

.height--351 {
  height: 351px !important;
}

.gap--351 {
  gap: 351px !important;
}

.scale--351 {
  scale: 3.51 !important;
}

.p--352 {
  padding: 352px !important;
}

.px--352 {
  padding-right: 352px !important;
  padding-left: 352px !important;
}

.py--352 {
  padding-top: 352px !important;
  padding-bottom: 352px !important;
}

.pt--352 {
  padding-top: 352px !important;
}

.pr--352 {
  padding-right: 352px !important;
}

.pb--352 {
  padding-bottom: 352px !important;
}

.pl--352 {
  padding-left: 352px !important;
}

.m--352 {
  margin: 352px !important;
}

.mx--352 {
  margin-right: 352px !important;
  margin-left: 352px !important;
}

.my--352 {
  margin-top: 352px !important;
  margin-bottom: 352px !important;
}

.mt--352 {
  margin-top: 352px !important;
}

.mr--352 {
  margin-right: 352px !important;
}

.mb--352 {
  margin-bottom: 352px !important;
}

.ml--352 {
  margin-left: 352px !important;
}

.width--352 {
  width: 352px !important;
}

.height--352 {
  height: 352px !important;
}

.gap--352 {
  gap: 352px !important;
}

.scale--352 {
  scale: 3.52 !important;
}

.p--353 {
  padding: 353px !important;
}

.px--353 {
  padding-right: 353px !important;
  padding-left: 353px !important;
}

.py--353 {
  padding-top: 353px !important;
  padding-bottom: 353px !important;
}

.pt--353 {
  padding-top: 353px !important;
}

.pr--353 {
  padding-right: 353px !important;
}

.pb--353 {
  padding-bottom: 353px !important;
}

.pl--353 {
  padding-left: 353px !important;
}

.m--353 {
  margin: 353px !important;
}

.mx--353 {
  margin-right: 353px !important;
  margin-left: 353px !important;
}

.my--353 {
  margin-top: 353px !important;
  margin-bottom: 353px !important;
}

.mt--353 {
  margin-top: 353px !important;
}

.mr--353 {
  margin-right: 353px !important;
}

.mb--353 {
  margin-bottom: 353px !important;
}

.ml--353 {
  margin-left: 353px !important;
}

.width--353 {
  width: 353px !important;
}

.height--353 {
  height: 353px !important;
}

.gap--353 {
  gap: 353px !important;
}

.scale--353 {
  scale: 3.53 !important;
}

.p--354 {
  padding: 354px !important;
}

.px--354 {
  padding-right: 354px !important;
  padding-left: 354px !important;
}

.py--354 {
  padding-top: 354px !important;
  padding-bottom: 354px !important;
}

.pt--354 {
  padding-top: 354px !important;
}

.pr--354 {
  padding-right: 354px !important;
}

.pb--354 {
  padding-bottom: 354px !important;
}

.pl--354 {
  padding-left: 354px !important;
}

.m--354 {
  margin: 354px !important;
}

.mx--354 {
  margin-right: 354px !important;
  margin-left: 354px !important;
}

.my--354 {
  margin-top: 354px !important;
  margin-bottom: 354px !important;
}

.mt--354 {
  margin-top: 354px !important;
}

.mr--354 {
  margin-right: 354px !important;
}

.mb--354 {
  margin-bottom: 354px !important;
}

.ml--354 {
  margin-left: 354px !important;
}

.width--354 {
  width: 354px !important;
}

.height--354 {
  height: 354px !important;
}

.gap--354 {
  gap: 354px !important;
}

.scale--354 {
  scale: 3.54 !important;
}

.p--355 {
  padding: 355px !important;
}

.px--355 {
  padding-right: 355px !important;
  padding-left: 355px !important;
}

.py--355 {
  padding-top: 355px !important;
  padding-bottom: 355px !important;
}

.pt--355 {
  padding-top: 355px !important;
}

.pr--355 {
  padding-right: 355px !important;
}

.pb--355 {
  padding-bottom: 355px !important;
}

.pl--355 {
  padding-left: 355px !important;
}

.m--355 {
  margin: 355px !important;
}

.mx--355 {
  margin-right: 355px !important;
  margin-left: 355px !important;
}

.my--355 {
  margin-top: 355px !important;
  margin-bottom: 355px !important;
}

.mt--355 {
  margin-top: 355px !important;
}

.mr--355 {
  margin-right: 355px !important;
}

.mb--355 {
  margin-bottom: 355px !important;
}

.ml--355 {
  margin-left: 355px !important;
}

.width--355 {
  width: 355px !important;
}

.height--355 {
  height: 355px !important;
}

.gap--355 {
  gap: 355px !important;
}

.scale--355 {
  scale: 3.55 !important;
}

.p--356 {
  padding: 356px !important;
}

.px--356 {
  padding-right: 356px !important;
  padding-left: 356px !important;
}

.py--356 {
  padding-top: 356px !important;
  padding-bottom: 356px !important;
}

.pt--356 {
  padding-top: 356px !important;
}

.pr--356 {
  padding-right: 356px !important;
}

.pb--356 {
  padding-bottom: 356px !important;
}

.pl--356 {
  padding-left: 356px !important;
}

.m--356 {
  margin: 356px !important;
}

.mx--356 {
  margin-right: 356px !important;
  margin-left: 356px !important;
}

.my--356 {
  margin-top: 356px !important;
  margin-bottom: 356px !important;
}

.mt--356 {
  margin-top: 356px !important;
}

.mr--356 {
  margin-right: 356px !important;
}

.mb--356 {
  margin-bottom: 356px !important;
}

.ml--356 {
  margin-left: 356px !important;
}

.width--356 {
  width: 356px !important;
}

.height--356 {
  height: 356px !important;
}

.gap--356 {
  gap: 356px !important;
}

.scale--356 {
  scale: 3.56 !important;
}

.p--357 {
  padding: 357px !important;
}

.px--357 {
  padding-right: 357px !important;
  padding-left: 357px !important;
}

.py--357 {
  padding-top: 357px !important;
  padding-bottom: 357px !important;
}

.pt--357 {
  padding-top: 357px !important;
}

.pr--357 {
  padding-right: 357px !important;
}

.pb--357 {
  padding-bottom: 357px !important;
}

.pl--357 {
  padding-left: 357px !important;
}

.m--357 {
  margin: 357px !important;
}

.mx--357 {
  margin-right: 357px !important;
  margin-left: 357px !important;
}

.my--357 {
  margin-top: 357px !important;
  margin-bottom: 357px !important;
}

.mt--357 {
  margin-top: 357px !important;
}

.mr--357 {
  margin-right: 357px !important;
}

.mb--357 {
  margin-bottom: 357px !important;
}

.ml--357 {
  margin-left: 357px !important;
}

.width--357 {
  width: 357px !important;
}

.height--357 {
  height: 357px !important;
}

.gap--357 {
  gap: 357px !important;
}

.scale--357 {
  scale: 3.57 !important;
}

.p--358 {
  padding: 358px !important;
}

.px--358 {
  padding-right: 358px !important;
  padding-left: 358px !important;
}

.py--358 {
  padding-top: 358px !important;
  padding-bottom: 358px !important;
}

.pt--358 {
  padding-top: 358px !important;
}

.pr--358 {
  padding-right: 358px !important;
}

.pb--358 {
  padding-bottom: 358px !important;
}

.pl--358 {
  padding-left: 358px !important;
}

.m--358 {
  margin: 358px !important;
}

.mx--358 {
  margin-right: 358px !important;
  margin-left: 358px !important;
}

.my--358 {
  margin-top: 358px !important;
  margin-bottom: 358px !important;
}

.mt--358 {
  margin-top: 358px !important;
}

.mr--358 {
  margin-right: 358px !important;
}

.mb--358 {
  margin-bottom: 358px !important;
}

.ml--358 {
  margin-left: 358px !important;
}

.width--358 {
  width: 358px !important;
}

.height--358 {
  height: 358px !important;
}

.gap--358 {
  gap: 358px !important;
}

.scale--358 {
  scale: 3.58 !important;
}

.p--359 {
  padding: 359px !important;
}

.px--359 {
  padding-right: 359px !important;
  padding-left: 359px !important;
}

.py--359 {
  padding-top: 359px !important;
  padding-bottom: 359px !important;
}

.pt--359 {
  padding-top: 359px !important;
}

.pr--359 {
  padding-right: 359px !important;
}

.pb--359 {
  padding-bottom: 359px !important;
}

.pl--359 {
  padding-left: 359px !important;
}

.m--359 {
  margin: 359px !important;
}

.mx--359 {
  margin-right: 359px !important;
  margin-left: 359px !important;
}

.my--359 {
  margin-top: 359px !important;
  margin-bottom: 359px !important;
}

.mt--359 {
  margin-top: 359px !important;
}

.mr--359 {
  margin-right: 359px !important;
}

.mb--359 {
  margin-bottom: 359px !important;
}

.ml--359 {
  margin-left: 359px !important;
}

.width--359 {
  width: 359px !important;
}

.height--359 {
  height: 359px !important;
}

.gap--359 {
  gap: 359px !important;
}

.scale--359 {
  scale: 3.59 !important;
}

.p--360 {
  padding: 360px !important;
}

.px--360 {
  padding-right: 360px !important;
  padding-left: 360px !important;
}

.py--360 {
  padding-top: 360px !important;
  padding-bottom: 360px !important;
}

.pt--360 {
  padding-top: 360px !important;
}

.pr--360 {
  padding-right: 360px !important;
}

.pb--360 {
  padding-bottom: 360px !important;
}

.pl--360 {
  padding-left: 360px !important;
}

.m--360 {
  margin: 360px !important;
}

.mx--360 {
  margin-right: 360px !important;
  margin-left: 360px !important;
}

.my--360 {
  margin-top: 360px !important;
  margin-bottom: 360px !important;
}

.mt--360 {
  margin-top: 360px !important;
}

.mr--360 {
  margin-right: 360px !important;
}

.mb--360 {
  margin-bottom: 360px !important;
}

.ml--360 {
  margin-left: 360px !important;
}

.width--360 {
  width: 360px !important;
}

.height--360 {
  height: 360px !important;
}

.gap--360 {
  gap: 360px !important;
}

.scale--360 {
  scale: 3.6 !important;
}

.p--361 {
  padding: 361px !important;
}

.px--361 {
  padding-right: 361px !important;
  padding-left: 361px !important;
}

.py--361 {
  padding-top: 361px !important;
  padding-bottom: 361px !important;
}

.pt--361 {
  padding-top: 361px !important;
}

.pr--361 {
  padding-right: 361px !important;
}

.pb--361 {
  padding-bottom: 361px !important;
}

.pl--361 {
  padding-left: 361px !important;
}

.m--361 {
  margin: 361px !important;
}

.mx--361 {
  margin-right: 361px !important;
  margin-left: 361px !important;
}

.my--361 {
  margin-top: 361px !important;
  margin-bottom: 361px !important;
}

.mt--361 {
  margin-top: 361px !important;
}

.mr--361 {
  margin-right: 361px !important;
}

.mb--361 {
  margin-bottom: 361px !important;
}

.ml--361 {
  margin-left: 361px !important;
}

.width--361 {
  width: 361px !important;
}

.height--361 {
  height: 361px !important;
}

.gap--361 {
  gap: 361px !important;
}

.scale--361 {
  scale: 3.61 !important;
}

.p--362 {
  padding: 362px !important;
}

.px--362 {
  padding-right: 362px !important;
  padding-left: 362px !important;
}

.py--362 {
  padding-top: 362px !important;
  padding-bottom: 362px !important;
}

.pt--362 {
  padding-top: 362px !important;
}

.pr--362 {
  padding-right: 362px !important;
}

.pb--362 {
  padding-bottom: 362px !important;
}

.pl--362 {
  padding-left: 362px !important;
}

.m--362 {
  margin: 362px !important;
}

.mx--362 {
  margin-right: 362px !important;
  margin-left: 362px !important;
}

.my--362 {
  margin-top: 362px !important;
  margin-bottom: 362px !important;
}

.mt--362 {
  margin-top: 362px !important;
}

.mr--362 {
  margin-right: 362px !important;
}

.mb--362 {
  margin-bottom: 362px !important;
}

.ml--362 {
  margin-left: 362px !important;
}

.width--362 {
  width: 362px !important;
}

.height--362 {
  height: 362px !important;
}

.gap--362 {
  gap: 362px !important;
}

.scale--362 {
  scale: 3.62 !important;
}

.p--363 {
  padding: 363px !important;
}

.px--363 {
  padding-right: 363px !important;
  padding-left: 363px !important;
}

.py--363 {
  padding-top: 363px !important;
  padding-bottom: 363px !important;
}

.pt--363 {
  padding-top: 363px !important;
}

.pr--363 {
  padding-right: 363px !important;
}

.pb--363 {
  padding-bottom: 363px !important;
}

.pl--363 {
  padding-left: 363px !important;
}

.m--363 {
  margin: 363px !important;
}

.mx--363 {
  margin-right: 363px !important;
  margin-left: 363px !important;
}

.my--363 {
  margin-top: 363px !important;
  margin-bottom: 363px !important;
}

.mt--363 {
  margin-top: 363px !important;
}

.mr--363 {
  margin-right: 363px !important;
}

.mb--363 {
  margin-bottom: 363px !important;
}

.ml--363 {
  margin-left: 363px !important;
}

.width--363 {
  width: 363px !important;
}

.height--363 {
  height: 363px !important;
}

.gap--363 {
  gap: 363px !important;
}

.scale--363 {
  scale: 3.63 !important;
}

.p--364 {
  padding: 364px !important;
}

.px--364 {
  padding-right: 364px !important;
  padding-left: 364px !important;
}

.py--364 {
  padding-top: 364px !important;
  padding-bottom: 364px !important;
}

.pt--364 {
  padding-top: 364px !important;
}

.pr--364 {
  padding-right: 364px !important;
}

.pb--364 {
  padding-bottom: 364px !important;
}

.pl--364 {
  padding-left: 364px !important;
}

.m--364 {
  margin: 364px !important;
}

.mx--364 {
  margin-right: 364px !important;
  margin-left: 364px !important;
}

.my--364 {
  margin-top: 364px !important;
  margin-bottom: 364px !important;
}

.mt--364 {
  margin-top: 364px !important;
}

.mr--364 {
  margin-right: 364px !important;
}

.mb--364 {
  margin-bottom: 364px !important;
}

.ml--364 {
  margin-left: 364px !important;
}

.width--364 {
  width: 364px !important;
}

.height--364 {
  height: 364px !important;
}

.gap--364 {
  gap: 364px !important;
}

.scale--364 {
  scale: 3.64 !important;
}

.p--365 {
  padding: 365px !important;
}

.px--365 {
  padding-right: 365px !important;
  padding-left: 365px !important;
}

.py--365 {
  padding-top: 365px !important;
  padding-bottom: 365px !important;
}

.pt--365 {
  padding-top: 365px !important;
}

.pr--365 {
  padding-right: 365px !important;
}

.pb--365 {
  padding-bottom: 365px !important;
}

.pl--365 {
  padding-left: 365px !important;
}

.m--365 {
  margin: 365px !important;
}

.mx--365 {
  margin-right: 365px !important;
  margin-left: 365px !important;
}

.my--365 {
  margin-top: 365px !important;
  margin-bottom: 365px !important;
}

.mt--365 {
  margin-top: 365px !important;
}

.mr--365 {
  margin-right: 365px !important;
}

.mb--365 {
  margin-bottom: 365px !important;
}

.ml--365 {
  margin-left: 365px !important;
}

.width--365 {
  width: 365px !important;
}

.height--365 {
  height: 365px !important;
}

.gap--365 {
  gap: 365px !important;
}

.scale--365 {
  scale: 3.65 !important;
}

.p--366 {
  padding: 366px !important;
}

.px--366 {
  padding-right: 366px !important;
  padding-left: 366px !important;
}

.py--366 {
  padding-top: 366px !important;
  padding-bottom: 366px !important;
}

.pt--366 {
  padding-top: 366px !important;
}

.pr--366 {
  padding-right: 366px !important;
}

.pb--366 {
  padding-bottom: 366px !important;
}

.pl--366 {
  padding-left: 366px !important;
}

.m--366 {
  margin: 366px !important;
}

.mx--366 {
  margin-right: 366px !important;
  margin-left: 366px !important;
}

.my--366 {
  margin-top: 366px !important;
  margin-bottom: 366px !important;
}

.mt--366 {
  margin-top: 366px !important;
}

.mr--366 {
  margin-right: 366px !important;
}

.mb--366 {
  margin-bottom: 366px !important;
}

.ml--366 {
  margin-left: 366px !important;
}

.width--366 {
  width: 366px !important;
}

.height--366 {
  height: 366px !important;
}

.gap--366 {
  gap: 366px !important;
}

.scale--366 {
  scale: 3.66 !important;
}

.p--367 {
  padding: 367px !important;
}

.px--367 {
  padding-right: 367px !important;
  padding-left: 367px !important;
}

.py--367 {
  padding-top: 367px !important;
  padding-bottom: 367px !important;
}

.pt--367 {
  padding-top: 367px !important;
}

.pr--367 {
  padding-right: 367px !important;
}

.pb--367 {
  padding-bottom: 367px !important;
}

.pl--367 {
  padding-left: 367px !important;
}

.m--367 {
  margin: 367px !important;
}

.mx--367 {
  margin-right: 367px !important;
  margin-left: 367px !important;
}

.my--367 {
  margin-top: 367px !important;
  margin-bottom: 367px !important;
}

.mt--367 {
  margin-top: 367px !important;
}

.mr--367 {
  margin-right: 367px !important;
}

.mb--367 {
  margin-bottom: 367px !important;
}

.ml--367 {
  margin-left: 367px !important;
}

.width--367 {
  width: 367px !important;
}

.height--367 {
  height: 367px !important;
}

.gap--367 {
  gap: 367px !important;
}

.scale--367 {
  scale: 3.67 !important;
}

.p--368 {
  padding: 368px !important;
}

.px--368 {
  padding-right: 368px !important;
  padding-left: 368px !important;
}

.py--368 {
  padding-top: 368px !important;
  padding-bottom: 368px !important;
}

.pt--368 {
  padding-top: 368px !important;
}

.pr--368 {
  padding-right: 368px !important;
}

.pb--368 {
  padding-bottom: 368px !important;
}

.pl--368 {
  padding-left: 368px !important;
}

.m--368 {
  margin: 368px !important;
}

.mx--368 {
  margin-right: 368px !important;
  margin-left: 368px !important;
}

.my--368 {
  margin-top: 368px !important;
  margin-bottom: 368px !important;
}

.mt--368 {
  margin-top: 368px !important;
}

.mr--368 {
  margin-right: 368px !important;
}

.mb--368 {
  margin-bottom: 368px !important;
}

.ml--368 {
  margin-left: 368px !important;
}

.width--368 {
  width: 368px !important;
}

.height--368 {
  height: 368px !important;
}

.gap--368 {
  gap: 368px !important;
}

.scale--368 {
  scale: 3.68 !important;
}

.p--369 {
  padding: 369px !important;
}

.px--369 {
  padding-right: 369px !important;
  padding-left: 369px !important;
}

.py--369 {
  padding-top: 369px !important;
  padding-bottom: 369px !important;
}

.pt--369 {
  padding-top: 369px !important;
}

.pr--369 {
  padding-right: 369px !important;
}

.pb--369 {
  padding-bottom: 369px !important;
}

.pl--369 {
  padding-left: 369px !important;
}

.m--369 {
  margin: 369px !important;
}

.mx--369 {
  margin-right: 369px !important;
  margin-left: 369px !important;
}

.my--369 {
  margin-top: 369px !important;
  margin-bottom: 369px !important;
}

.mt--369 {
  margin-top: 369px !important;
}

.mr--369 {
  margin-right: 369px !important;
}

.mb--369 {
  margin-bottom: 369px !important;
}

.ml--369 {
  margin-left: 369px !important;
}

.width--369 {
  width: 369px !important;
}

.height--369 {
  height: 369px !important;
}

.gap--369 {
  gap: 369px !important;
}

.scale--369 {
  scale: 3.69 !important;
}

.p--370 {
  padding: 370px !important;
}

.px--370 {
  padding-right: 370px !important;
  padding-left: 370px !important;
}

.py--370 {
  padding-top: 370px !important;
  padding-bottom: 370px !important;
}

.pt--370 {
  padding-top: 370px !important;
}

.pr--370 {
  padding-right: 370px !important;
}

.pb--370 {
  padding-bottom: 370px !important;
}

.pl--370 {
  padding-left: 370px !important;
}

.m--370 {
  margin: 370px !important;
}

.mx--370 {
  margin-right: 370px !important;
  margin-left: 370px !important;
}

.my--370 {
  margin-top: 370px !important;
  margin-bottom: 370px !important;
}

.mt--370 {
  margin-top: 370px !important;
}

.mr--370 {
  margin-right: 370px !important;
}

.mb--370 {
  margin-bottom: 370px !important;
}

.ml--370 {
  margin-left: 370px !important;
}

.width--370 {
  width: 370px !important;
}

.height--370 {
  height: 370px !important;
}

.gap--370 {
  gap: 370px !important;
}

.scale--370 {
  scale: 3.7 !important;
}

.p--371 {
  padding: 371px !important;
}

.px--371 {
  padding-right: 371px !important;
  padding-left: 371px !important;
}

.py--371 {
  padding-top: 371px !important;
  padding-bottom: 371px !important;
}

.pt--371 {
  padding-top: 371px !important;
}

.pr--371 {
  padding-right: 371px !important;
}

.pb--371 {
  padding-bottom: 371px !important;
}

.pl--371 {
  padding-left: 371px !important;
}

.m--371 {
  margin: 371px !important;
}

.mx--371 {
  margin-right: 371px !important;
  margin-left: 371px !important;
}

.my--371 {
  margin-top: 371px !important;
  margin-bottom: 371px !important;
}

.mt--371 {
  margin-top: 371px !important;
}

.mr--371 {
  margin-right: 371px !important;
}

.mb--371 {
  margin-bottom: 371px !important;
}

.ml--371 {
  margin-left: 371px !important;
}

.width--371 {
  width: 371px !important;
}

.height--371 {
  height: 371px !important;
}

.gap--371 {
  gap: 371px !important;
}

.scale--371 {
  scale: 3.71 !important;
}

.p--372 {
  padding: 372px !important;
}

.px--372 {
  padding-right: 372px !important;
  padding-left: 372px !important;
}

.py--372 {
  padding-top: 372px !important;
  padding-bottom: 372px !important;
}

.pt--372 {
  padding-top: 372px !important;
}

.pr--372 {
  padding-right: 372px !important;
}

.pb--372 {
  padding-bottom: 372px !important;
}

.pl--372 {
  padding-left: 372px !important;
}

.m--372 {
  margin: 372px !important;
}

.mx--372 {
  margin-right: 372px !important;
  margin-left: 372px !important;
}

.my--372 {
  margin-top: 372px !important;
  margin-bottom: 372px !important;
}

.mt--372 {
  margin-top: 372px !important;
}

.mr--372 {
  margin-right: 372px !important;
}

.mb--372 {
  margin-bottom: 372px !important;
}

.ml--372 {
  margin-left: 372px !important;
}

.width--372 {
  width: 372px !important;
}

.height--372 {
  height: 372px !important;
}

.gap--372 {
  gap: 372px !important;
}

.scale--372 {
  scale: 3.72 !important;
}

.p--373 {
  padding: 373px !important;
}

.px--373 {
  padding-right: 373px !important;
  padding-left: 373px !important;
}

.py--373 {
  padding-top: 373px !important;
  padding-bottom: 373px !important;
}

.pt--373 {
  padding-top: 373px !important;
}

.pr--373 {
  padding-right: 373px !important;
}

.pb--373 {
  padding-bottom: 373px !important;
}

.pl--373 {
  padding-left: 373px !important;
}

.m--373 {
  margin: 373px !important;
}

.mx--373 {
  margin-right: 373px !important;
  margin-left: 373px !important;
}

.my--373 {
  margin-top: 373px !important;
  margin-bottom: 373px !important;
}

.mt--373 {
  margin-top: 373px !important;
}

.mr--373 {
  margin-right: 373px !important;
}

.mb--373 {
  margin-bottom: 373px !important;
}

.ml--373 {
  margin-left: 373px !important;
}

.width--373 {
  width: 373px !important;
}

.height--373 {
  height: 373px !important;
}

.gap--373 {
  gap: 373px !important;
}

.scale--373 {
  scale: 3.73 !important;
}

.p--374 {
  padding: 374px !important;
}

.px--374 {
  padding-right: 374px !important;
  padding-left: 374px !important;
}

.py--374 {
  padding-top: 374px !important;
  padding-bottom: 374px !important;
}

.pt--374 {
  padding-top: 374px !important;
}

.pr--374 {
  padding-right: 374px !important;
}

.pb--374 {
  padding-bottom: 374px !important;
}

.pl--374 {
  padding-left: 374px !important;
}

.m--374 {
  margin: 374px !important;
}

.mx--374 {
  margin-right: 374px !important;
  margin-left: 374px !important;
}

.my--374 {
  margin-top: 374px !important;
  margin-bottom: 374px !important;
}

.mt--374 {
  margin-top: 374px !important;
}

.mr--374 {
  margin-right: 374px !important;
}

.mb--374 {
  margin-bottom: 374px !important;
}

.ml--374 {
  margin-left: 374px !important;
}

.width--374 {
  width: 374px !important;
}

.height--374 {
  height: 374px !important;
}

.gap--374 {
  gap: 374px !important;
}

.scale--374 {
  scale: 3.74 !important;
}

.p--375 {
  padding: 375px !important;
}

.px--375 {
  padding-right: 375px !important;
  padding-left: 375px !important;
}

.py--375 {
  padding-top: 375px !important;
  padding-bottom: 375px !important;
}

.pt--375 {
  padding-top: 375px !important;
}

.pr--375 {
  padding-right: 375px !important;
}

.pb--375 {
  padding-bottom: 375px !important;
}

.pl--375 {
  padding-left: 375px !important;
}

.m--375 {
  margin: 375px !important;
}

.mx--375 {
  margin-right: 375px !important;
  margin-left: 375px !important;
}

.my--375 {
  margin-top: 375px !important;
  margin-bottom: 375px !important;
}

.mt--375 {
  margin-top: 375px !important;
}

.mr--375 {
  margin-right: 375px !important;
}

.mb--375 {
  margin-bottom: 375px !important;
}

.ml--375 {
  margin-left: 375px !important;
}

.width--375 {
  width: 375px !important;
}

.height--375 {
  height: 375px !important;
}

.gap--375 {
  gap: 375px !important;
}

.scale--375 {
  scale: 3.75 !important;
}

.p--376 {
  padding: 376px !important;
}

.px--376 {
  padding-right: 376px !important;
  padding-left: 376px !important;
}

.py--376 {
  padding-top: 376px !important;
  padding-bottom: 376px !important;
}

.pt--376 {
  padding-top: 376px !important;
}

.pr--376 {
  padding-right: 376px !important;
}

.pb--376 {
  padding-bottom: 376px !important;
}

.pl--376 {
  padding-left: 376px !important;
}

.m--376 {
  margin: 376px !important;
}

.mx--376 {
  margin-right: 376px !important;
  margin-left: 376px !important;
}

.my--376 {
  margin-top: 376px !important;
  margin-bottom: 376px !important;
}

.mt--376 {
  margin-top: 376px !important;
}

.mr--376 {
  margin-right: 376px !important;
}

.mb--376 {
  margin-bottom: 376px !important;
}

.ml--376 {
  margin-left: 376px !important;
}

.width--376 {
  width: 376px !important;
}

.height--376 {
  height: 376px !important;
}

.gap--376 {
  gap: 376px !important;
}

.scale--376 {
  scale: 3.76 !important;
}

.p--377 {
  padding: 377px !important;
}

.px--377 {
  padding-right: 377px !important;
  padding-left: 377px !important;
}

.py--377 {
  padding-top: 377px !important;
  padding-bottom: 377px !important;
}

.pt--377 {
  padding-top: 377px !important;
}

.pr--377 {
  padding-right: 377px !important;
}

.pb--377 {
  padding-bottom: 377px !important;
}

.pl--377 {
  padding-left: 377px !important;
}

.m--377 {
  margin: 377px !important;
}

.mx--377 {
  margin-right: 377px !important;
  margin-left: 377px !important;
}

.my--377 {
  margin-top: 377px !important;
  margin-bottom: 377px !important;
}

.mt--377 {
  margin-top: 377px !important;
}

.mr--377 {
  margin-right: 377px !important;
}

.mb--377 {
  margin-bottom: 377px !important;
}

.ml--377 {
  margin-left: 377px !important;
}

.width--377 {
  width: 377px !important;
}

.height--377 {
  height: 377px !important;
}

.gap--377 {
  gap: 377px !important;
}

.scale--377 {
  scale: 3.77 !important;
}

.p--378 {
  padding: 378px !important;
}

.px--378 {
  padding-right: 378px !important;
  padding-left: 378px !important;
}

.py--378 {
  padding-top: 378px !important;
  padding-bottom: 378px !important;
}

.pt--378 {
  padding-top: 378px !important;
}

.pr--378 {
  padding-right: 378px !important;
}

.pb--378 {
  padding-bottom: 378px !important;
}

.pl--378 {
  padding-left: 378px !important;
}

.m--378 {
  margin: 378px !important;
}

.mx--378 {
  margin-right: 378px !important;
  margin-left: 378px !important;
}

.my--378 {
  margin-top: 378px !important;
  margin-bottom: 378px !important;
}

.mt--378 {
  margin-top: 378px !important;
}

.mr--378 {
  margin-right: 378px !important;
}

.mb--378 {
  margin-bottom: 378px !important;
}

.ml--378 {
  margin-left: 378px !important;
}

.width--378 {
  width: 378px !important;
}

.height--378 {
  height: 378px !important;
}

.gap--378 {
  gap: 378px !important;
}

.scale--378 {
  scale: 3.78 !important;
}

.p--379 {
  padding: 379px !important;
}

.px--379 {
  padding-right: 379px !important;
  padding-left: 379px !important;
}

.py--379 {
  padding-top: 379px !important;
  padding-bottom: 379px !important;
}

.pt--379 {
  padding-top: 379px !important;
}

.pr--379 {
  padding-right: 379px !important;
}

.pb--379 {
  padding-bottom: 379px !important;
}

.pl--379 {
  padding-left: 379px !important;
}

.m--379 {
  margin: 379px !important;
}

.mx--379 {
  margin-right: 379px !important;
  margin-left: 379px !important;
}

.my--379 {
  margin-top: 379px !important;
  margin-bottom: 379px !important;
}

.mt--379 {
  margin-top: 379px !important;
}

.mr--379 {
  margin-right: 379px !important;
}

.mb--379 {
  margin-bottom: 379px !important;
}

.ml--379 {
  margin-left: 379px !important;
}

.width--379 {
  width: 379px !important;
}

.height--379 {
  height: 379px !important;
}

.gap--379 {
  gap: 379px !important;
}

.scale--379 {
  scale: 3.79 !important;
}

.p--380 {
  padding: 380px !important;
}

.px--380 {
  padding-right: 380px !important;
  padding-left: 380px !important;
}

.py--380 {
  padding-top: 380px !important;
  padding-bottom: 380px !important;
}

.pt--380 {
  padding-top: 380px !important;
}

.pr--380 {
  padding-right: 380px !important;
}

.pb--380 {
  padding-bottom: 380px !important;
}

.pl--380 {
  padding-left: 380px !important;
}

.m--380 {
  margin: 380px !important;
}

.mx--380 {
  margin-right: 380px !important;
  margin-left: 380px !important;
}

.my--380 {
  margin-top: 380px !important;
  margin-bottom: 380px !important;
}

.mt--380 {
  margin-top: 380px !important;
}

.mr--380 {
  margin-right: 380px !important;
}

.mb--380 {
  margin-bottom: 380px !important;
}

.ml--380 {
  margin-left: 380px !important;
}

.width--380 {
  width: 380px !important;
}

.height--380 {
  height: 380px !important;
}

.gap--380 {
  gap: 380px !important;
}

.scale--380 {
  scale: 3.8 !important;
}

.p--381 {
  padding: 381px !important;
}

.px--381 {
  padding-right: 381px !important;
  padding-left: 381px !important;
}

.py--381 {
  padding-top: 381px !important;
  padding-bottom: 381px !important;
}

.pt--381 {
  padding-top: 381px !important;
}

.pr--381 {
  padding-right: 381px !important;
}

.pb--381 {
  padding-bottom: 381px !important;
}

.pl--381 {
  padding-left: 381px !important;
}

.m--381 {
  margin: 381px !important;
}

.mx--381 {
  margin-right: 381px !important;
  margin-left: 381px !important;
}

.my--381 {
  margin-top: 381px !important;
  margin-bottom: 381px !important;
}

.mt--381 {
  margin-top: 381px !important;
}

.mr--381 {
  margin-right: 381px !important;
}

.mb--381 {
  margin-bottom: 381px !important;
}

.ml--381 {
  margin-left: 381px !important;
}

.width--381 {
  width: 381px !important;
}

.height--381 {
  height: 381px !important;
}

.gap--381 {
  gap: 381px !important;
}

.scale--381 {
  scale: 3.81 !important;
}

.p--382 {
  padding: 382px !important;
}

.px--382 {
  padding-right: 382px !important;
  padding-left: 382px !important;
}

.py--382 {
  padding-top: 382px !important;
  padding-bottom: 382px !important;
}

.pt--382 {
  padding-top: 382px !important;
}

.pr--382 {
  padding-right: 382px !important;
}

.pb--382 {
  padding-bottom: 382px !important;
}

.pl--382 {
  padding-left: 382px !important;
}

.m--382 {
  margin: 382px !important;
}

.mx--382 {
  margin-right: 382px !important;
  margin-left: 382px !important;
}

.my--382 {
  margin-top: 382px !important;
  margin-bottom: 382px !important;
}

.mt--382 {
  margin-top: 382px !important;
}

.mr--382 {
  margin-right: 382px !important;
}

.mb--382 {
  margin-bottom: 382px !important;
}

.ml--382 {
  margin-left: 382px !important;
}

.width--382 {
  width: 382px !important;
}

.height--382 {
  height: 382px !important;
}

.gap--382 {
  gap: 382px !important;
}

.scale--382 {
  scale: 3.82 !important;
}

.p--383 {
  padding: 383px !important;
}

.px--383 {
  padding-right: 383px !important;
  padding-left: 383px !important;
}

.py--383 {
  padding-top: 383px !important;
  padding-bottom: 383px !important;
}

.pt--383 {
  padding-top: 383px !important;
}

.pr--383 {
  padding-right: 383px !important;
}

.pb--383 {
  padding-bottom: 383px !important;
}

.pl--383 {
  padding-left: 383px !important;
}

.m--383 {
  margin: 383px !important;
}

.mx--383 {
  margin-right: 383px !important;
  margin-left: 383px !important;
}

.my--383 {
  margin-top: 383px !important;
  margin-bottom: 383px !important;
}

.mt--383 {
  margin-top: 383px !important;
}

.mr--383 {
  margin-right: 383px !important;
}

.mb--383 {
  margin-bottom: 383px !important;
}

.ml--383 {
  margin-left: 383px !important;
}

.width--383 {
  width: 383px !important;
}

.height--383 {
  height: 383px !important;
}

.gap--383 {
  gap: 383px !important;
}

.scale--383 {
  scale: 3.83 !important;
}

.p--384 {
  padding: 384px !important;
}

.px--384 {
  padding-right: 384px !important;
  padding-left: 384px !important;
}

.py--384 {
  padding-top: 384px !important;
  padding-bottom: 384px !important;
}

.pt--384 {
  padding-top: 384px !important;
}

.pr--384 {
  padding-right: 384px !important;
}

.pb--384 {
  padding-bottom: 384px !important;
}

.pl--384 {
  padding-left: 384px !important;
}

.m--384 {
  margin: 384px !important;
}

.mx--384 {
  margin-right: 384px !important;
  margin-left: 384px !important;
}

.my--384 {
  margin-top: 384px !important;
  margin-bottom: 384px !important;
}

.mt--384 {
  margin-top: 384px !important;
}

.mr--384 {
  margin-right: 384px !important;
}

.mb--384 {
  margin-bottom: 384px !important;
}

.ml--384 {
  margin-left: 384px !important;
}

.width--384 {
  width: 384px !important;
}

.height--384 {
  height: 384px !important;
}

.gap--384 {
  gap: 384px !important;
}

.scale--384 {
  scale: 3.84 !important;
}

.p--385 {
  padding: 385px !important;
}

.px--385 {
  padding-right: 385px !important;
  padding-left: 385px !important;
}

.py--385 {
  padding-top: 385px !important;
  padding-bottom: 385px !important;
}

.pt--385 {
  padding-top: 385px !important;
}

.pr--385 {
  padding-right: 385px !important;
}

.pb--385 {
  padding-bottom: 385px !important;
}

.pl--385 {
  padding-left: 385px !important;
}

.m--385 {
  margin: 385px !important;
}

.mx--385 {
  margin-right: 385px !important;
  margin-left: 385px !important;
}

.my--385 {
  margin-top: 385px !important;
  margin-bottom: 385px !important;
}

.mt--385 {
  margin-top: 385px !important;
}

.mr--385 {
  margin-right: 385px !important;
}

.mb--385 {
  margin-bottom: 385px !important;
}

.ml--385 {
  margin-left: 385px !important;
}

.width--385 {
  width: 385px !important;
}

.height--385 {
  height: 385px !important;
}

.gap--385 {
  gap: 385px !important;
}

.scale--385 {
  scale: 3.85 !important;
}

.p--386 {
  padding: 386px !important;
}

.px--386 {
  padding-right: 386px !important;
  padding-left: 386px !important;
}

.py--386 {
  padding-top: 386px !important;
  padding-bottom: 386px !important;
}

.pt--386 {
  padding-top: 386px !important;
}

.pr--386 {
  padding-right: 386px !important;
}

.pb--386 {
  padding-bottom: 386px !important;
}

.pl--386 {
  padding-left: 386px !important;
}

.m--386 {
  margin: 386px !important;
}

.mx--386 {
  margin-right: 386px !important;
  margin-left: 386px !important;
}

.my--386 {
  margin-top: 386px !important;
  margin-bottom: 386px !important;
}

.mt--386 {
  margin-top: 386px !important;
}

.mr--386 {
  margin-right: 386px !important;
}

.mb--386 {
  margin-bottom: 386px !important;
}

.ml--386 {
  margin-left: 386px !important;
}

.width--386 {
  width: 386px !important;
}

.height--386 {
  height: 386px !important;
}

.gap--386 {
  gap: 386px !important;
}

.scale--386 {
  scale: 3.86 !important;
}

.p--387 {
  padding: 387px !important;
}

.px--387 {
  padding-right: 387px !important;
  padding-left: 387px !important;
}

.py--387 {
  padding-top: 387px !important;
  padding-bottom: 387px !important;
}

.pt--387 {
  padding-top: 387px !important;
}

.pr--387 {
  padding-right: 387px !important;
}

.pb--387 {
  padding-bottom: 387px !important;
}

.pl--387 {
  padding-left: 387px !important;
}

.m--387 {
  margin: 387px !important;
}

.mx--387 {
  margin-right: 387px !important;
  margin-left: 387px !important;
}

.my--387 {
  margin-top: 387px !important;
  margin-bottom: 387px !important;
}

.mt--387 {
  margin-top: 387px !important;
}

.mr--387 {
  margin-right: 387px !important;
}

.mb--387 {
  margin-bottom: 387px !important;
}

.ml--387 {
  margin-left: 387px !important;
}

.width--387 {
  width: 387px !important;
}

.height--387 {
  height: 387px !important;
}

.gap--387 {
  gap: 387px !important;
}

.scale--387 {
  scale: 3.87 !important;
}

.p--388 {
  padding: 388px !important;
}

.px--388 {
  padding-right: 388px !important;
  padding-left: 388px !important;
}

.py--388 {
  padding-top: 388px !important;
  padding-bottom: 388px !important;
}

.pt--388 {
  padding-top: 388px !important;
}

.pr--388 {
  padding-right: 388px !important;
}

.pb--388 {
  padding-bottom: 388px !important;
}

.pl--388 {
  padding-left: 388px !important;
}

.m--388 {
  margin: 388px !important;
}

.mx--388 {
  margin-right: 388px !important;
  margin-left: 388px !important;
}

.my--388 {
  margin-top: 388px !important;
  margin-bottom: 388px !important;
}

.mt--388 {
  margin-top: 388px !important;
}

.mr--388 {
  margin-right: 388px !important;
}

.mb--388 {
  margin-bottom: 388px !important;
}

.ml--388 {
  margin-left: 388px !important;
}

.width--388 {
  width: 388px !important;
}

.height--388 {
  height: 388px !important;
}

.gap--388 {
  gap: 388px !important;
}

.scale--388 {
  scale: 3.88 !important;
}

.p--389 {
  padding: 389px !important;
}

.px--389 {
  padding-right: 389px !important;
  padding-left: 389px !important;
}

.py--389 {
  padding-top: 389px !important;
  padding-bottom: 389px !important;
}

.pt--389 {
  padding-top: 389px !important;
}

.pr--389 {
  padding-right: 389px !important;
}

.pb--389 {
  padding-bottom: 389px !important;
}

.pl--389 {
  padding-left: 389px !important;
}

.m--389 {
  margin: 389px !important;
}

.mx--389 {
  margin-right: 389px !important;
  margin-left: 389px !important;
}

.my--389 {
  margin-top: 389px !important;
  margin-bottom: 389px !important;
}

.mt--389 {
  margin-top: 389px !important;
}

.mr--389 {
  margin-right: 389px !important;
}

.mb--389 {
  margin-bottom: 389px !important;
}

.ml--389 {
  margin-left: 389px !important;
}

.width--389 {
  width: 389px !important;
}

.height--389 {
  height: 389px !important;
}

.gap--389 {
  gap: 389px !important;
}

.scale--389 {
  scale: 3.89 !important;
}

.p--390 {
  padding: 390px !important;
}

.px--390 {
  padding-right: 390px !important;
  padding-left: 390px !important;
}

.py--390 {
  padding-top: 390px !important;
  padding-bottom: 390px !important;
}

.pt--390 {
  padding-top: 390px !important;
}

.pr--390 {
  padding-right: 390px !important;
}

.pb--390 {
  padding-bottom: 390px !important;
}

.pl--390 {
  padding-left: 390px !important;
}

.m--390 {
  margin: 390px !important;
}

.mx--390 {
  margin-right: 390px !important;
  margin-left: 390px !important;
}

.my--390 {
  margin-top: 390px !important;
  margin-bottom: 390px !important;
}

.mt--390 {
  margin-top: 390px !important;
}

.mr--390 {
  margin-right: 390px !important;
}

.mb--390 {
  margin-bottom: 390px !important;
}

.ml--390 {
  margin-left: 390px !important;
}

.width--390 {
  width: 390px !important;
}

.height--390 {
  height: 390px !important;
}

.gap--390 {
  gap: 390px !important;
}

.scale--390 {
  scale: 3.9 !important;
}

.p--391 {
  padding: 391px !important;
}

.px--391 {
  padding-right: 391px !important;
  padding-left: 391px !important;
}

.py--391 {
  padding-top: 391px !important;
  padding-bottom: 391px !important;
}

.pt--391 {
  padding-top: 391px !important;
}

.pr--391 {
  padding-right: 391px !important;
}

.pb--391 {
  padding-bottom: 391px !important;
}

.pl--391 {
  padding-left: 391px !important;
}

.m--391 {
  margin: 391px !important;
}

.mx--391 {
  margin-right: 391px !important;
  margin-left: 391px !important;
}

.my--391 {
  margin-top: 391px !important;
  margin-bottom: 391px !important;
}

.mt--391 {
  margin-top: 391px !important;
}

.mr--391 {
  margin-right: 391px !important;
}

.mb--391 {
  margin-bottom: 391px !important;
}

.ml--391 {
  margin-left: 391px !important;
}

.width--391 {
  width: 391px !important;
}

.height--391 {
  height: 391px !important;
}

.gap--391 {
  gap: 391px !important;
}

.scale--391 {
  scale: 3.91 !important;
}

.p--392 {
  padding: 392px !important;
}

.px--392 {
  padding-right: 392px !important;
  padding-left: 392px !important;
}

.py--392 {
  padding-top: 392px !important;
  padding-bottom: 392px !important;
}

.pt--392 {
  padding-top: 392px !important;
}

.pr--392 {
  padding-right: 392px !important;
}

.pb--392 {
  padding-bottom: 392px !important;
}

.pl--392 {
  padding-left: 392px !important;
}

.m--392 {
  margin: 392px !important;
}

.mx--392 {
  margin-right: 392px !important;
  margin-left: 392px !important;
}

.my--392 {
  margin-top: 392px !important;
  margin-bottom: 392px !important;
}

.mt--392 {
  margin-top: 392px !important;
}

.mr--392 {
  margin-right: 392px !important;
}

.mb--392 {
  margin-bottom: 392px !important;
}

.ml--392 {
  margin-left: 392px !important;
}

.width--392 {
  width: 392px !important;
}

.height--392 {
  height: 392px !important;
}

.gap--392 {
  gap: 392px !important;
}

.scale--392 {
  scale: 3.92 !important;
}

.p--393 {
  padding: 393px !important;
}

.px--393 {
  padding-right: 393px !important;
  padding-left: 393px !important;
}

.py--393 {
  padding-top: 393px !important;
  padding-bottom: 393px !important;
}

.pt--393 {
  padding-top: 393px !important;
}

.pr--393 {
  padding-right: 393px !important;
}

.pb--393 {
  padding-bottom: 393px !important;
}

.pl--393 {
  padding-left: 393px !important;
}

.m--393 {
  margin: 393px !important;
}

.mx--393 {
  margin-right: 393px !important;
  margin-left: 393px !important;
}

.my--393 {
  margin-top: 393px !important;
  margin-bottom: 393px !important;
}

.mt--393 {
  margin-top: 393px !important;
}

.mr--393 {
  margin-right: 393px !important;
}

.mb--393 {
  margin-bottom: 393px !important;
}

.ml--393 {
  margin-left: 393px !important;
}

.width--393 {
  width: 393px !important;
}

.height--393 {
  height: 393px !important;
}

.gap--393 {
  gap: 393px !important;
}

.scale--393 {
  scale: 3.93 !important;
}

.p--394 {
  padding: 394px !important;
}

.px--394 {
  padding-right: 394px !important;
  padding-left: 394px !important;
}

.py--394 {
  padding-top: 394px !important;
  padding-bottom: 394px !important;
}

.pt--394 {
  padding-top: 394px !important;
}

.pr--394 {
  padding-right: 394px !important;
}

.pb--394 {
  padding-bottom: 394px !important;
}

.pl--394 {
  padding-left: 394px !important;
}

.m--394 {
  margin: 394px !important;
}

.mx--394 {
  margin-right: 394px !important;
  margin-left: 394px !important;
}

.my--394 {
  margin-top: 394px !important;
  margin-bottom: 394px !important;
}

.mt--394 {
  margin-top: 394px !important;
}

.mr--394 {
  margin-right: 394px !important;
}

.mb--394 {
  margin-bottom: 394px !important;
}

.ml--394 {
  margin-left: 394px !important;
}

.width--394 {
  width: 394px !important;
}

.height--394 {
  height: 394px !important;
}

.gap--394 {
  gap: 394px !important;
}

.scale--394 {
  scale: 3.94 !important;
}

.p--395 {
  padding: 395px !important;
}

.px--395 {
  padding-right: 395px !important;
  padding-left: 395px !important;
}

.py--395 {
  padding-top: 395px !important;
  padding-bottom: 395px !important;
}

.pt--395 {
  padding-top: 395px !important;
}

.pr--395 {
  padding-right: 395px !important;
}

.pb--395 {
  padding-bottom: 395px !important;
}

.pl--395 {
  padding-left: 395px !important;
}

.m--395 {
  margin: 395px !important;
}

.mx--395 {
  margin-right: 395px !important;
  margin-left: 395px !important;
}

.my--395 {
  margin-top: 395px !important;
  margin-bottom: 395px !important;
}

.mt--395 {
  margin-top: 395px !important;
}

.mr--395 {
  margin-right: 395px !important;
}

.mb--395 {
  margin-bottom: 395px !important;
}

.ml--395 {
  margin-left: 395px !important;
}

.width--395 {
  width: 395px !important;
}

.height--395 {
  height: 395px !important;
}

.gap--395 {
  gap: 395px !important;
}

.scale--395 {
  scale: 3.95 !important;
}

.p--396 {
  padding: 396px !important;
}

.px--396 {
  padding-right: 396px !important;
  padding-left: 396px !important;
}

.py--396 {
  padding-top: 396px !important;
  padding-bottom: 396px !important;
}

.pt--396 {
  padding-top: 396px !important;
}

.pr--396 {
  padding-right: 396px !important;
}

.pb--396 {
  padding-bottom: 396px !important;
}

.pl--396 {
  padding-left: 396px !important;
}

.m--396 {
  margin: 396px !important;
}

.mx--396 {
  margin-right: 396px !important;
  margin-left: 396px !important;
}

.my--396 {
  margin-top: 396px !important;
  margin-bottom: 396px !important;
}

.mt--396 {
  margin-top: 396px !important;
}

.mr--396 {
  margin-right: 396px !important;
}

.mb--396 {
  margin-bottom: 396px !important;
}

.ml--396 {
  margin-left: 396px !important;
}

.width--396 {
  width: 396px !important;
}

.height--396 {
  height: 396px !important;
}

.gap--396 {
  gap: 396px !important;
}

.scale--396 {
  scale: 3.96 !important;
}

.p--397 {
  padding: 397px !important;
}

.px--397 {
  padding-right: 397px !important;
  padding-left: 397px !important;
}

.py--397 {
  padding-top: 397px !important;
  padding-bottom: 397px !important;
}

.pt--397 {
  padding-top: 397px !important;
}

.pr--397 {
  padding-right: 397px !important;
}

.pb--397 {
  padding-bottom: 397px !important;
}

.pl--397 {
  padding-left: 397px !important;
}

.m--397 {
  margin: 397px !important;
}

.mx--397 {
  margin-right: 397px !important;
  margin-left: 397px !important;
}

.my--397 {
  margin-top: 397px !important;
  margin-bottom: 397px !important;
}

.mt--397 {
  margin-top: 397px !important;
}

.mr--397 {
  margin-right: 397px !important;
}

.mb--397 {
  margin-bottom: 397px !important;
}

.ml--397 {
  margin-left: 397px !important;
}

.width--397 {
  width: 397px !important;
}

.height--397 {
  height: 397px !important;
}

.gap--397 {
  gap: 397px !important;
}

.scale--397 {
  scale: 3.97 !important;
}

.p--398 {
  padding: 398px !important;
}

.px--398 {
  padding-right: 398px !important;
  padding-left: 398px !important;
}

.py--398 {
  padding-top: 398px !important;
  padding-bottom: 398px !important;
}

.pt--398 {
  padding-top: 398px !important;
}

.pr--398 {
  padding-right: 398px !important;
}

.pb--398 {
  padding-bottom: 398px !important;
}

.pl--398 {
  padding-left: 398px !important;
}

.m--398 {
  margin: 398px !important;
}

.mx--398 {
  margin-right: 398px !important;
  margin-left: 398px !important;
}

.my--398 {
  margin-top: 398px !important;
  margin-bottom: 398px !important;
}

.mt--398 {
  margin-top: 398px !important;
}

.mr--398 {
  margin-right: 398px !important;
}

.mb--398 {
  margin-bottom: 398px !important;
}

.ml--398 {
  margin-left: 398px !important;
}

.width--398 {
  width: 398px !important;
}

.height--398 {
  height: 398px !important;
}

.gap--398 {
  gap: 398px !important;
}

.scale--398 {
  scale: 3.98 !important;
}

.p--399 {
  padding: 399px !important;
}

.px--399 {
  padding-right: 399px !important;
  padding-left: 399px !important;
}

.py--399 {
  padding-top: 399px !important;
  padding-bottom: 399px !important;
}

.pt--399 {
  padding-top: 399px !important;
}

.pr--399 {
  padding-right: 399px !important;
}

.pb--399 {
  padding-bottom: 399px !important;
}

.pl--399 {
  padding-left: 399px !important;
}

.m--399 {
  margin: 399px !important;
}

.mx--399 {
  margin-right: 399px !important;
  margin-left: 399px !important;
}

.my--399 {
  margin-top: 399px !important;
  margin-bottom: 399px !important;
}

.mt--399 {
  margin-top: 399px !important;
}

.mr--399 {
  margin-right: 399px !important;
}

.mb--399 {
  margin-bottom: 399px !important;
}

.ml--399 {
  margin-left: 399px !important;
}

.width--399 {
  width: 399px !important;
}

.height--399 {
  height: 399px !important;
}

.gap--399 {
  gap: 399px !important;
}

.scale--399 {
  scale: 3.99 !important;
}

.p--400 {
  padding: 400px !important;
}

.px--400 {
  padding-right: 400px !important;
  padding-left: 400px !important;
}

.py--400 {
  padding-top: 400px !important;
  padding-bottom: 400px !important;
}

.pt--400 {
  padding-top: 400px !important;
}

.pr--400 {
  padding-right: 400px !important;
}

.pb--400 {
  padding-bottom: 400px !important;
}

.pl--400 {
  padding-left: 400px !important;
}

.m--400 {
  margin: 400px !important;
}

.mx--400 {
  margin-right: 400px !important;
  margin-left: 400px !important;
}

.my--400 {
  margin-top: 400px !important;
  margin-bottom: 400px !important;
}

.mt--400 {
  margin-top: 400px !important;
}

.mr--400 {
  margin-right: 400px !important;
}

.mb--400 {
  margin-bottom: 400px !important;
}

.ml--400 {
  margin-left: 400px !important;
}

.width--400 {
  width: 400px !important;
}

.height--400 {
  height: 400px !important;
}

.gap--400 {
  gap: 400px !important;
}

.scale--400 {
  scale: 4 !important;
}

.p--401 {
  padding: 401px !important;
}

.px--401 {
  padding-right: 401px !important;
  padding-left: 401px !important;
}

.py--401 {
  padding-top: 401px !important;
  padding-bottom: 401px !important;
}

.pt--401 {
  padding-top: 401px !important;
}

.pr--401 {
  padding-right: 401px !important;
}

.pb--401 {
  padding-bottom: 401px !important;
}

.pl--401 {
  padding-left: 401px !important;
}

.m--401 {
  margin: 401px !important;
}

.mx--401 {
  margin-right: 401px !important;
  margin-left: 401px !important;
}

.my--401 {
  margin-top: 401px !important;
  margin-bottom: 401px !important;
}

.mt--401 {
  margin-top: 401px !important;
}

.mr--401 {
  margin-right: 401px !important;
}

.mb--401 {
  margin-bottom: 401px !important;
}

.ml--401 {
  margin-left: 401px !important;
}

.width--401 {
  width: 401px !important;
}

.height--401 {
  height: 401px !important;
}

.gap--401 {
  gap: 401px !important;
}

.scale--401 {
  scale: 4.01 !important;
}

.p--402 {
  padding: 402px !important;
}

.px--402 {
  padding-right: 402px !important;
  padding-left: 402px !important;
}

.py--402 {
  padding-top: 402px !important;
  padding-bottom: 402px !important;
}

.pt--402 {
  padding-top: 402px !important;
}

.pr--402 {
  padding-right: 402px !important;
}

.pb--402 {
  padding-bottom: 402px !important;
}

.pl--402 {
  padding-left: 402px !important;
}

.m--402 {
  margin: 402px !important;
}

.mx--402 {
  margin-right: 402px !important;
  margin-left: 402px !important;
}

.my--402 {
  margin-top: 402px !important;
  margin-bottom: 402px !important;
}

.mt--402 {
  margin-top: 402px !important;
}

.mr--402 {
  margin-right: 402px !important;
}

.mb--402 {
  margin-bottom: 402px !important;
}

.ml--402 {
  margin-left: 402px !important;
}

.width--402 {
  width: 402px !important;
}

.height--402 {
  height: 402px !important;
}

.gap--402 {
  gap: 402px !important;
}

.scale--402 {
  scale: 4.02 !important;
}

.p--403 {
  padding: 403px !important;
}

.px--403 {
  padding-right: 403px !important;
  padding-left: 403px !important;
}

.py--403 {
  padding-top: 403px !important;
  padding-bottom: 403px !important;
}

.pt--403 {
  padding-top: 403px !important;
}

.pr--403 {
  padding-right: 403px !important;
}

.pb--403 {
  padding-bottom: 403px !important;
}

.pl--403 {
  padding-left: 403px !important;
}

.m--403 {
  margin: 403px !important;
}

.mx--403 {
  margin-right: 403px !important;
  margin-left: 403px !important;
}

.my--403 {
  margin-top: 403px !important;
  margin-bottom: 403px !important;
}

.mt--403 {
  margin-top: 403px !important;
}

.mr--403 {
  margin-right: 403px !important;
}

.mb--403 {
  margin-bottom: 403px !important;
}

.ml--403 {
  margin-left: 403px !important;
}

.width--403 {
  width: 403px !important;
}

.height--403 {
  height: 403px !important;
}

.gap--403 {
  gap: 403px !important;
}

.scale--403 {
  scale: 4.03 !important;
}

.p--404 {
  padding: 404px !important;
}

.px--404 {
  padding-right: 404px !important;
  padding-left: 404px !important;
}

.py--404 {
  padding-top: 404px !important;
  padding-bottom: 404px !important;
}

.pt--404 {
  padding-top: 404px !important;
}

.pr--404 {
  padding-right: 404px !important;
}

.pb--404 {
  padding-bottom: 404px !important;
}

.pl--404 {
  padding-left: 404px !important;
}

.m--404 {
  margin: 404px !important;
}

.mx--404 {
  margin-right: 404px !important;
  margin-left: 404px !important;
}

.my--404 {
  margin-top: 404px !important;
  margin-bottom: 404px !important;
}

.mt--404 {
  margin-top: 404px !important;
}

.mr--404 {
  margin-right: 404px !important;
}

.mb--404 {
  margin-bottom: 404px !important;
}

.ml--404 {
  margin-left: 404px !important;
}

.width--404 {
  width: 404px !important;
}

.height--404 {
  height: 404px !important;
}

.gap--404 {
  gap: 404px !important;
}

.scale--404 {
  scale: 4.04 !important;
}

.p--405 {
  padding: 405px !important;
}

.px--405 {
  padding-right: 405px !important;
  padding-left: 405px !important;
}

.py--405 {
  padding-top: 405px !important;
  padding-bottom: 405px !important;
}

.pt--405 {
  padding-top: 405px !important;
}

.pr--405 {
  padding-right: 405px !important;
}

.pb--405 {
  padding-bottom: 405px !important;
}

.pl--405 {
  padding-left: 405px !important;
}

.m--405 {
  margin: 405px !important;
}

.mx--405 {
  margin-right: 405px !important;
  margin-left: 405px !important;
}

.my--405 {
  margin-top: 405px !important;
  margin-bottom: 405px !important;
}

.mt--405 {
  margin-top: 405px !important;
}

.mr--405 {
  margin-right: 405px !important;
}

.mb--405 {
  margin-bottom: 405px !important;
}

.ml--405 {
  margin-left: 405px !important;
}

.width--405 {
  width: 405px !important;
}

.height--405 {
  height: 405px !important;
}

.gap--405 {
  gap: 405px !important;
}

.scale--405 {
  scale: 4.05 !important;
}

.p--406 {
  padding: 406px !important;
}

.px--406 {
  padding-right: 406px !important;
  padding-left: 406px !important;
}

.py--406 {
  padding-top: 406px !important;
  padding-bottom: 406px !important;
}

.pt--406 {
  padding-top: 406px !important;
}

.pr--406 {
  padding-right: 406px !important;
}

.pb--406 {
  padding-bottom: 406px !important;
}

.pl--406 {
  padding-left: 406px !important;
}

.m--406 {
  margin: 406px !important;
}

.mx--406 {
  margin-right: 406px !important;
  margin-left: 406px !important;
}

.my--406 {
  margin-top: 406px !important;
  margin-bottom: 406px !important;
}

.mt--406 {
  margin-top: 406px !important;
}

.mr--406 {
  margin-right: 406px !important;
}

.mb--406 {
  margin-bottom: 406px !important;
}

.ml--406 {
  margin-left: 406px !important;
}

.width--406 {
  width: 406px !important;
}

.height--406 {
  height: 406px !important;
}

.gap--406 {
  gap: 406px !important;
}

.scale--406 {
  scale: 4.06 !important;
}

.p--407 {
  padding: 407px !important;
}

.px--407 {
  padding-right: 407px !important;
  padding-left: 407px !important;
}

.py--407 {
  padding-top: 407px !important;
  padding-bottom: 407px !important;
}

.pt--407 {
  padding-top: 407px !important;
}

.pr--407 {
  padding-right: 407px !important;
}

.pb--407 {
  padding-bottom: 407px !important;
}

.pl--407 {
  padding-left: 407px !important;
}

.m--407 {
  margin: 407px !important;
}

.mx--407 {
  margin-right: 407px !important;
  margin-left: 407px !important;
}

.my--407 {
  margin-top: 407px !important;
  margin-bottom: 407px !important;
}

.mt--407 {
  margin-top: 407px !important;
}

.mr--407 {
  margin-right: 407px !important;
}

.mb--407 {
  margin-bottom: 407px !important;
}

.ml--407 {
  margin-left: 407px !important;
}

.width--407 {
  width: 407px !important;
}

.height--407 {
  height: 407px !important;
}

.gap--407 {
  gap: 407px !important;
}

.scale--407 {
  scale: 4.07 !important;
}

.p--408 {
  padding: 408px !important;
}

.px--408 {
  padding-right: 408px !important;
  padding-left: 408px !important;
}

.py--408 {
  padding-top: 408px !important;
  padding-bottom: 408px !important;
}

.pt--408 {
  padding-top: 408px !important;
}

.pr--408 {
  padding-right: 408px !important;
}

.pb--408 {
  padding-bottom: 408px !important;
}

.pl--408 {
  padding-left: 408px !important;
}

.m--408 {
  margin: 408px !important;
}

.mx--408 {
  margin-right: 408px !important;
  margin-left: 408px !important;
}

.my--408 {
  margin-top: 408px !important;
  margin-bottom: 408px !important;
}

.mt--408 {
  margin-top: 408px !important;
}

.mr--408 {
  margin-right: 408px !important;
}

.mb--408 {
  margin-bottom: 408px !important;
}

.ml--408 {
  margin-left: 408px !important;
}

.width--408 {
  width: 408px !important;
}

.height--408 {
  height: 408px !important;
}

.gap--408 {
  gap: 408px !important;
}

.scale--408 {
  scale: 4.08 !important;
}

.p--409 {
  padding: 409px !important;
}

.px--409 {
  padding-right: 409px !important;
  padding-left: 409px !important;
}

.py--409 {
  padding-top: 409px !important;
  padding-bottom: 409px !important;
}

.pt--409 {
  padding-top: 409px !important;
}

.pr--409 {
  padding-right: 409px !important;
}

.pb--409 {
  padding-bottom: 409px !important;
}

.pl--409 {
  padding-left: 409px !important;
}

.m--409 {
  margin: 409px !important;
}

.mx--409 {
  margin-right: 409px !important;
  margin-left: 409px !important;
}

.my--409 {
  margin-top: 409px !important;
  margin-bottom: 409px !important;
}

.mt--409 {
  margin-top: 409px !important;
}

.mr--409 {
  margin-right: 409px !important;
}

.mb--409 {
  margin-bottom: 409px !important;
}

.ml--409 {
  margin-left: 409px !important;
}

.width--409 {
  width: 409px !important;
}

.height--409 {
  height: 409px !important;
}

.gap--409 {
  gap: 409px !important;
}

.scale--409 {
  scale: 4.09 !important;
}

.p--410 {
  padding: 410px !important;
}

.px--410 {
  padding-right: 410px !important;
  padding-left: 410px !important;
}

.py--410 {
  padding-top: 410px !important;
  padding-bottom: 410px !important;
}

.pt--410 {
  padding-top: 410px !important;
}

.pr--410 {
  padding-right: 410px !important;
}

.pb--410 {
  padding-bottom: 410px !important;
}

.pl--410 {
  padding-left: 410px !important;
}

.m--410 {
  margin: 410px !important;
}

.mx--410 {
  margin-right: 410px !important;
  margin-left: 410px !important;
}

.my--410 {
  margin-top: 410px !important;
  margin-bottom: 410px !important;
}

.mt--410 {
  margin-top: 410px !important;
}

.mr--410 {
  margin-right: 410px !important;
}

.mb--410 {
  margin-bottom: 410px !important;
}

.ml--410 {
  margin-left: 410px !important;
}

.width--410 {
  width: 410px !important;
}

.height--410 {
  height: 410px !important;
}

.gap--410 {
  gap: 410px !important;
}

.scale--410 {
  scale: 4.1 !important;
}

.p--411 {
  padding: 411px !important;
}

.px--411 {
  padding-right: 411px !important;
  padding-left: 411px !important;
}

.py--411 {
  padding-top: 411px !important;
  padding-bottom: 411px !important;
}

.pt--411 {
  padding-top: 411px !important;
}

.pr--411 {
  padding-right: 411px !important;
}

.pb--411 {
  padding-bottom: 411px !important;
}

.pl--411 {
  padding-left: 411px !important;
}

.m--411 {
  margin: 411px !important;
}

.mx--411 {
  margin-right: 411px !important;
  margin-left: 411px !important;
}

.my--411 {
  margin-top: 411px !important;
  margin-bottom: 411px !important;
}

.mt--411 {
  margin-top: 411px !important;
}

.mr--411 {
  margin-right: 411px !important;
}

.mb--411 {
  margin-bottom: 411px !important;
}

.ml--411 {
  margin-left: 411px !important;
}

.width--411 {
  width: 411px !important;
}

.height--411 {
  height: 411px !important;
}

.gap--411 {
  gap: 411px !important;
}

.scale--411 {
  scale: 4.11 !important;
}

.p--412 {
  padding: 412px !important;
}

.px--412 {
  padding-right: 412px !important;
  padding-left: 412px !important;
}

.py--412 {
  padding-top: 412px !important;
  padding-bottom: 412px !important;
}

.pt--412 {
  padding-top: 412px !important;
}

.pr--412 {
  padding-right: 412px !important;
}

.pb--412 {
  padding-bottom: 412px !important;
}

.pl--412 {
  padding-left: 412px !important;
}

.m--412 {
  margin: 412px !important;
}

.mx--412 {
  margin-right: 412px !important;
  margin-left: 412px !important;
}

.my--412 {
  margin-top: 412px !important;
  margin-bottom: 412px !important;
}

.mt--412 {
  margin-top: 412px !important;
}

.mr--412 {
  margin-right: 412px !important;
}

.mb--412 {
  margin-bottom: 412px !important;
}

.ml--412 {
  margin-left: 412px !important;
}

.width--412 {
  width: 412px !important;
}

.height--412 {
  height: 412px !important;
}

.gap--412 {
  gap: 412px !important;
}

.scale--412 {
  scale: 4.12 !important;
}

.p--413 {
  padding: 413px !important;
}

.px--413 {
  padding-right: 413px !important;
  padding-left: 413px !important;
}

.py--413 {
  padding-top: 413px !important;
  padding-bottom: 413px !important;
}

.pt--413 {
  padding-top: 413px !important;
}

.pr--413 {
  padding-right: 413px !important;
}

.pb--413 {
  padding-bottom: 413px !important;
}

.pl--413 {
  padding-left: 413px !important;
}

.m--413 {
  margin: 413px !important;
}

.mx--413 {
  margin-right: 413px !important;
  margin-left: 413px !important;
}

.my--413 {
  margin-top: 413px !important;
  margin-bottom: 413px !important;
}

.mt--413 {
  margin-top: 413px !important;
}

.mr--413 {
  margin-right: 413px !important;
}

.mb--413 {
  margin-bottom: 413px !important;
}

.ml--413 {
  margin-left: 413px !important;
}

.width--413 {
  width: 413px !important;
}

.height--413 {
  height: 413px !important;
}

.gap--413 {
  gap: 413px !important;
}

.scale--413 {
  scale: 4.13 !important;
}

.p--414 {
  padding: 414px !important;
}

.px--414 {
  padding-right: 414px !important;
  padding-left: 414px !important;
}

.py--414 {
  padding-top: 414px !important;
  padding-bottom: 414px !important;
}

.pt--414 {
  padding-top: 414px !important;
}

.pr--414 {
  padding-right: 414px !important;
}

.pb--414 {
  padding-bottom: 414px !important;
}

.pl--414 {
  padding-left: 414px !important;
}

.m--414 {
  margin: 414px !important;
}

.mx--414 {
  margin-right: 414px !important;
  margin-left: 414px !important;
}

.my--414 {
  margin-top: 414px !important;
  margin-bottom: 414px !important;
}

.mt--414 {
  margin-top: 414px !important;
}

.mr--414 {
  margin-right: 414px !important;
}

.mb--414 {
  margin-bottom: 414px !important;
}

.ml--414 {
  margin-left: 414px !important;
}

.width--414 {
  width: 414px !important;
}

.height--414 {
  height: 414px !important;
}

.gap--414 {
  gap: 414px !important;
}

.scale--414 {
  scale: 4.14 !important;
}

.p--415 {
  padding: 415px !important;
}

.px--415 {
  padding-right: 415px !important;
  padding-left: 415px !important;
}

.py--415 {
  padding-top: 415px !important;
  padding-bottom: 415px !important;
}

.pt--415 {
  padding-top: 415px !important;
}

.pr--415 {
  padding-right: 415px !important;
}

.pb--415 {
  padding-bottom: 415px !important;
}

.pl--415 {
  padding-left: 415px !important;
}

.m--415 {
  margin: 415px !important;
}

.mx--415 {
  margin-right: 415px !important;
  margin-left: 415px !important;
}

.my--415 {
  margin-top: 415px !important;
  margin-bottom: 415px !important;
}

.mt--415 {
  margin-top: 415px !important;
}

.mr--415 {
  margin-right: 415px !important;
}

.mb--415 {
  margin-bottom: 415px !important;
}

.ml--415 {
  margin-left: 415px !important;
}

.width--415 {
  width: 415px !important;
}

.height--415 {
  height: 415px !important;
}

.gap--415 {
  gap: 415px !important;
}

.scale--415 {
  scale: 4.15 !important;
}

.p--416 {
  padding: 416px !important;
}

.px--416 {
  padding-right: 416px !important;
  padding-left: 416px !important;
}

.py--416 {
  padding-top: 416px !important;
  padding-bottom: 416px !important;
}

.pt--416 {
  padding-top: 416px !important;
}

.pr--416 {
  padding-right: 416px !important;
}

.pb--416 {
  padding-bottom: 416px !important;
}

.pl--416 {
  padding-left: 416px !important;
}

.m--416 {
  margin: 416px !important;
}

.mx--416 {
  margin-right: 416px !important;
  margin-left: 416px !important;
}

.my--416 {
  margin-top: 416px !important;
  margin-bottom: 416px !important;
}

.mt--416 {
  margin-top: 416px !important;
}

.mr--416 {
  margin-right: 416px !important;
}

.mb--416 {
  margin-bottom: 416px !important;
}

.ml--416 {
  margin-left: 416px !important;
}

.width--416 {
  width: 416px !important;
}

.height--416 {
  height: 416px !important;
}

.gap--416 {
  gap: 416px !important;
}

.scale--416 {
  scale: 4.16 !important;
}

.p--417 {
  padding: 417px !important;
}

.px--417 {
  padding-right: 417px !important;
  padding-left: 417px !important;
}

.py--417 {
  padding-top: 417px !important;
  padding-bottom: 417px !important;
}

.pt--417 {
  padding-top: 417px !important;
}

.pr--417 {
  padding-right: 417px !important;
}

.pb--417 {
  padding-bottom: 417px !important;
}

.pl--417 {
  padding-left: 417px !important;
}

.m--417 {
  margin: 417px !important;
}

.mx--417 {
  margin-right: 417px !important;
  margin-left: 417px !important;
}

.my--417 {
  margin-top: 417px !important;
  margin-bottom: 417px !important;
}

.mt--417 {
  margin-top: 417px !important;
}

.mr--417 {
  margin-right: 417px !important;
}

.mb--417 {
  margin-bottom: 417px !important;
}

.ml--417 {
  margin-left: 417px !important;
}

.width--417 {
  width: 417px !important;
}

.height--417 {
  height: 417px !important;
}

.gap--417 {
  gap: 417px !important;
}

.scale--417 {
  scale: 4.17 !important;
}

.p--418 {
  padding: 418px !important;
}

.px--418 {
  padding-right: 418px !important;
  padding-left: 418px !important;
}

.py--418 {
  padding-top: 418px !important;
  padding-bottom: 418px !important;
}

.pt--418 {
  padding-top: 418px !important;
}

.pr--418 {
  padding-right: 418px !important;
}

.pb--418 {
  padding-bottom: 418px !important;
}

.pl--418 {
  padding-left: 418px !important;
}

.m--418 {
  margin: 418px !important;
}

.mx--418 {
  margin-right: 418px !important;
  margin-left: 418px !important;
}

.my--418 {
  margin-top: 418px !important;
  margin-bottom: 418px !important;
}

.mt--418 {
  margin-top: 418px !important;
}

.mr--418 {
  margin-right: 418px !important;
}

.mb--418 {
  margin-bottom: 418px !important;
}

.ml--418 {
  margin-left: 418px !important;
}

.width--418 {
  width: 418px !important;
}

.height--418 {
  height: 418px !important;
}

.gap--418 {
  gap: 418px !important;
}

.scale--418 {
  scale: 4.18 !important;
}

.p--419 {
  padding: 419px !important;
}

.px--419 {
  padding-right: 419px !important;
  padding-left: 419px !important;
}

.py--419 {
  padding-top: 419px !important;
  padding-bottom: 419px !important;
}

.pt--419 {
  padding-top: 419px !important;
}

.pr--419 {
  padding-right: 419px !important;
}

.pb--419 {
  padding-bottom: 419px !important;
}

.pl--419 {
  padding-left: 419px !important;
}

.m--419 {
  margin: 419px !important;
}

.mx--419 {
  margin-right: 419px !important;
  margin-left: 419px !important;
}

.my--419 {
  margin-top: 419px !important;
  margin-bottom: 419px !important;
}

.mt--419 {
  margin-top: 419px !important;
}

.mr--419 {
  margin-right: 419px !important;
}

.mb--419 {
  margin-bottom: 419px !important;
}

.ml--419 {
  margin-left: 419px !important;
}

.width--419 {
  width: 419px !important;
}

.height--419 {
  height: 419px !important;
}

.gap--419 {
  gap: 419px !important;
}

.scale--419 {
  scale: 4.19 !important;
}

.p--420 {
  padding: 420px !important;
}

.px--420 {
  padding-right: 420px !important;
  padding-left: 420px !important;
}

.py--420 {
  padding-top: 420px !important;
  padding-bottom: 420px !important;
}

.pt--420 {
  padding-top: 420px !important;
}

.pr--420 {
  padding-right: 420px !important;
}

.pb--420 {
  padding-bottom: 420px !important;
}

.pl--420 {
  padding-left: 420px !important;
}

.m--420 {
  margin: 420px !important;
}

.mx--420 {
  margin-right: 420px !important;
  margin-left: 420px !important;
}

.my--420 {
  margin-top: 420px !important;
  margin-bottom: 420px !important;
}

.mt--420 {
  margin-top: 420px !important;
}

.mr--420 {
  margin-right: 420px !important;
}

.mb--420 {
  margin-bottom: 420px !important;
}

.ml--420 {
  margin-left: 420px !important;
}

.width--420 {
  width: 420px !important;
}

.height--420 {
  height: 420px !important;
}

.gap--420 {
  gap: 420px !important;
}

.scale--420 {
  scale: 4.2 !important;
}

.p--421 {
  padding: 421px !important;
}

.px--421 {
  padding-right: 421px !important;
  padding-left: 421px !important;
}

.py--421 {
  padding-top: 421px !important;
  padding-bottom: 421px !important;
}

.pt--421 {
  padding-top: 421px !important;
}

.pr--421 {
  padding-right: 421px !important;
}

.pb--421 {
  padding-bottom: 421px !important;
}

.pl--421 {
  padding-left: 421px !important;
}

.m--421 {
  margin: 421px !important;
}

.mx--421 {
  margin-right: 421px !important;
  margin-left: 421px !important;
}

.my--421 {
  margin-top: 421px !important;
  margin-bottom: 421px !important;
}

.mt--421 {
  margin-top: 421px !important;
}

.mr--421 {
  margin-right: 421px !important;
}

.mb--421 {
  margin-bottom: 421px !important;
}

.ml--421 {
  margin-left: 421px !important;
}

.width--421 {
  width: 421px !important;
}

.height--421 {
  height: 421px !important;
}

.gap--421 {
  gap: 421px !important;
}

.scale--421 {
  scale: 4.21 !important;
}

.p--422 {
  padding: 422px !important;
}

.px--422 {
  padding-right: 422px !important;
  padding-left: 422px !important;
}

.py--422 {
  padding-top: 422px !important;
  padding-bottom: 422px !important;
}

.pt--422 {
  padding-top: 422px !important;
}

.pr--422 {
  padding-right: 422px !important;
}

.pb--422 {
  padding-bottom: 422px !important;
}

.pl--422 {
  padding-left: 422px !important;
}

.m--422 {
  margin: 422px !important;
}

.mx--422 {
  margin-right: 422px !important;
  margin-left: 422px !important;
}

.my--422 {
  margin-top: 422px !important;
  margin-bottom: 422px !important;
}

.mt--422 {
  margin-top: 422px !important;
}

.mr--422 {
  margin-right: 422px !important;
}

.mb--422 {
  margin-bottom: 422px !important;
}

.ml--422 {
  margin-left: 422px !important;
}

.width--422 {
  width: 422px !important;
}

.height--422 {
  height: 422px !important;
}

.gap--422 {
  gap: 422px !important;
}

.scale--422 {
  scale: 4.22 !important;
}

.p--423 {
  padding: 423px !important;
}

.px--423 {
  padding-right: 423px !important;
  padding-left: 423px !important;
}

.py--423 {
  padding-top: 423px !important;
  padding-bottom: 423px !important;
}

.pt--423 {
  padding-top: 423px !important;
}

.pr--423 {
  padding-right: 423px !important;
}

.pb--423 {
  padding-bottom: 423px !important;
}

.pl--423 {
  padding-left: 423px !important;
}

.m--423 {
  margin: 423px !important;
}

.mx--423 {
  margin-right: 423px !important;
  margin-left: 423px !important;
}

.my--423 {
  margin-top: 423px !important;
  margin-bottom: 423px !important;
}

.mt--423 {
  margin-top: 423px !important;
}

.mr--423 {
  margin-right: 423px !important;
}

.mb--423 {
  margin-bottom: 423px !important;
}

.ml--423 {
  margin-left: 423px !important;
}

.width--423 {
  width: 423px !important;
}

.height--423 {
  height: 423px !important;
}

.gap--423 {
  gap: 423px !important;
}

.scale--423 {
  scale: 4.23 !important;
}

.p--424 {
  padding: 424px !important;
}

.px--424 {
  padding-right: 424px !important;
  padding-left: 424px !important;
}

.py--424 {
  padding-top: 424px !important;
  padding-bottom: 424px !important;
}

.pt--424 {
  padding-top: 424px !important;
}

.pr--424 {
  padding-right: 424px !important;
}

.pb--424 {
  padding-bottom: 424px !important;
}

.pl--424 {
  padding-left: 424px !important;
}

.m--424 {
  margin: 424px !important;
}

.mx--424 {
  margin-right: 424px !important;
  margin-left: 424px !important;
}

.my--424 {
  margin-top: 424px !important;
  margin-bottom: 424px !important;
}

.mt--424 {
  margin-top: 424px !important;
}

.mr--424 {
  margin-right: 424px !important;
}

.mb--424 {
  margin-bottom: 424px !important;
}

.ml--424 {
  margin-left: 424px !important;
}

.width--424 {
  width: 424px !important;
}

.height--424 {
  height: 424px !important;
}

.gap--424 {
  gap: 424px !important;
}

.scale--424 {
  scale: 4.24 !important;
}

.p--425 {
  padding: 425px !important;
}

.px--425 {
  padding-right: 425px !important;
  padding-left: 425px !important;
}

.py--425 {
  padding-top: 425px !important;
  padding-bottom: 425px !important;
}

.pt--425 {
  padding-top: 425px !important;
}

.pr--425 {
  padding-right: 425px !important;
}

.pb--425 {
  padding-bottom: 425px !important;
}

.pl--425 {
  padding-left: 425px !important;
}

.m--425 {
  margin: 425px !important;
}

.mx--425 {
  margin-right: 425px !important;
  margin-left: 425px !important;
}

.my--425 {
  margin-top: 425px !important;
  margin-bottom: 425px !important;
}

.mt--425 {
  margin-top: 425px !important;
}

.mr--425 {
  margin-right: 425px !important;
}

.mb--425 {
  margin-bottom: 425px !important;
}

.ml--425 {
  margin-left: 425px !important;
}

.width--425 {
  width: 425px !important;
}

.height--425 {
  height: 425px !important;
}

.gap--425 {
  gap: 425px !important;
}

.scale--425 {
  scale: 4.25 !important;
}

.p--426 {
  padding: 426px !important;
}

.px--426 {
  padding-right: 426px !important;
  padding-left: 426px !important;
}

.py--426 {
  padding-top: 426px !important;
  padding-bottom: 426px !important;
}

.pt--426 {
  padding-top: 426px !important;
}

.pr--426 {
  padding-right: 426px !important;
}

.pb--426 {
  padding-bottom: 426px !important;
}

.pl--426 {
  padding-left: 426px !important;
}

.m--426 {
  margin: 426px !important;
}

.mx--426 {
  margin-right: 426px !important;
  margin-left: 426px !important;
}

.my--426 {
  margin-top: 426px !important;
  margin-bottom: 426px !important;
}

.mt--426 {
  margin-top: 426px !important;
}

.mr--426 {
  margin-right: 426px !important;
}

.mb--426 {
  margin-bottom: 426px !important;
}

.ml--426 {
  margin-left: 426px !important;
}

.width--426 {
  width: 426px !important;
}

.height--426 {
  height: 426px !important;
}

.gap--426 {
  gap: 426px !important;
}

.scale--426 {
  scale: 4.26 !important;
}

.p--427 {
  padding: 427px !important;
}

.px--427 {
  padding-right: 427px !important;
  padding-left: 427px !important;
}

.py--427 {
  padding-top: 427px !important;
  padding-bottom: 427px !important;
}

.pt--427 {
  padding-top: 427px !important;
}

.pr--427 {
  padding-right: 427px !important;
}

.pb--427 {
  padding-bottom: 427px !important;
}

.pl--427 {
  padding-left: 427px !important;
}

.m--427 {
  margin: 427px !important;
}

.mx--427 {
  margin-right: 427px !important;
  margin-left: 427px !important;
}

.my--427 {
  margin-top: 427px !important;
  margin-bottom: 427px !important;
}

.mt--427 {
  margin-top: 427px !important;
}

.mr--427 {
  margin-right: 427px !important;
}

.mb--427 {
  margin-bottom: 427px !important;
}

.ml--427 {
  margin-left: 427px !important;
}

.width--427 {
  width: 427px !important;
}

.height--427 {
  height: 427px !important;
}

.gap--427 {
  gap: 427px !important;
}

.scale--427 {
  scale: 4.27 !important;
}

.p--428 {
  padding: 428px !important;
}

.px--428 {
  padding-right: 428px !important;
  padding-left: 428px !important;
}

.py--428 {
  padding-top: 428px !important;
  padding-bottom: 428px !important;
}

.pt--428 {
  padding-top: 428px !important;
}

.pr--428 {
  padding-right: 428px !important;
}

.pb--428 {
  padding-bottom: 428px !important;
}

.pl--428 {
  padding-left: 428px !important;
}

.m--428 {
  margin: 428px !important;
}

.mx--428 {
  margin-right: 428px !important;
  margin-left: 428px !important;
}

.my--428 {
  margin-top: 428px !important;
  margin-bottom: 428px !important;
}

.mt--428 {
  margin-top: 428px !important;
}

.mr--428 {
  margin-right: 428px !important;
}

.mb--428 {
  margin-bottom: 428px !important;
}

.ml--428 {
  margin-left: 428px !important;
}

.width--428 {
  width: 428px !important;
}

.height--428 {
  height: 428px !important;
}

.gap--428 {
  gap: 428px !important;
}

.scale--428 {
  scale: 4.28 !important;
}

.p--429 {
  padding: 429px !important;
}

.px--429 {
  padding-right: 429px !important;
  padding-left: 429px !important;
}

.py--429 {
  padding-top: 429px !important;
  padding-bottom: 429px !important;
}

.pt--429 {
  padding-top: 429px !important;
}

.pr--429 {
  padding-right: 429px !important;
}

.pb--429 {
  padding-bottom: 429px !important;
}

.pl--429 {
  padding-left: 429px !important;
}

.m--429 {
  margin: 429px !important;
}

.mx--429 {
  margin-right: 429px !important;
  margin-left: 429px !important;
}

.my--429 {
  margin-top: 429px !important;
  margin-bottom: 429px !important;
}

.mt--429 {
  margin-top: 429px !important;
}

.mr--429 {
  margin-right: 429px !important;
}

.mb--429 {
  margin-bottom: 429px !important;
}

.ml--429 {
  margin-left: 429px !important;
}

.width--429 {
  width: 429px !important;
}

.height--429 {
  height: 429px !important;
}

.gap--429 {
  gap: 429px !important;
}

.scale--429 {
  scale: 4.29 !important;
}

.p--430 {
  padding: 430px !important;
}

.px--430 {
  padding-right: 430px !important;
  padding-left: 430px !important;
}

.py--430 {
  padding-top: 430px !important;
  padding-bottom: 430px !important;
}

.pt--430 {
  padding-top: 430px !important;
}

.pr--430 {
  padding-right: 430px !important;
}

.pb--430 {
  padding-bottom: 430px !important;
}

.pl--430 {
  padding-left: 430px !important;
}

.m--430 {
  margin: 430px !important;
}

.mx--430 {
  margin-right: 430px !important;
  margin-left: 430px !important;
}

.my--430 {
  margin-top: 430px !important;
  margin-bottom: 430px !important;
}

.mt--430 {
  margin-top: 430px !important;
}

.mr--430 {
  margin-right: 430px !important;
}

.mb--430 {
  margin-bottom: 430px !important;
}

.ml--430 {
  margin-left: 430px !important;
}

.width--430 {
  width: 430px !important;
}

.height--430 {
  height: 430px !important;
}

.gap--430 {
  gap: 430px !important;
}

.scale--430 {
  scale: 4.3 !important;
}

.p--431 {
  padding: 431px !important;
}

.px--431 {
  padding-right: 431px !important;
  padding-left: 431px !important;
}

.py--431 {
  padding-top: 431px !important;
  padding-bottom: 431px !important;
}

.pt--431 {
  padding-top: 431px !important;
}

.pr--431 {
  padding-right: 431px !important;
}

.pb--431 {
  padding-bottom: 431px !important;
}

.pl--431 {
  padding-left: 431px !important;
}

.m--431 {
  margin: 431px !important;
}

.mx--431 {
  margin-right: 431px !important;
  margin-left: 431px !important;
}

.my--431 {
  margin-top: 431px !important;
  margin-bottom: 431px !important;
}

.mt--431 {
  margin-top: 431px !important;
}

.mr--431 {
  margin-right: 431px !important;
}

.mb--431 {
  margin-bottom: 431px !important;
}

.ml--431 {
  margin-left: 431px !important;
}

.width--431 {
  width: 431px !important;
}

.height--431 {
  height: 431px !important;
}

.gap--431 {
  gap: 431px !important;
}

.scale--431 {
  scale: 4.31 !important;
}

.p--432 {
  padding: 432px !important;
}

.px--432 {
  padding-right: 432px !important;
  padding-left: 432px !important;
}

.py--432 {
  padding-top: 432px !important;
  padding-bottom: 432px !important;
}

.pt--432 {
  padding-top: 432px !important;
}

.pr--432 {
  padding-right: 432px !important;
}

.pb--432 {
  padding-bottom: 432px !important;
}

.pl--432 {
  padding-left: 432px !important;
}

.m--432 {
  margin: 432px !important;
}

.mx--432 {
  margin-right: 432px !important;
  margin-left: 432px !important;
}

.my--432 {
  margin-top: 432px !important;
  margin-bottom: 432px !important;
}

.mt--432 {
  margin-top: 432px !important;
}

.mr--432 {
  margin-right: 432px !important;
}

.mb--432 {
  margin-bottom: 432px !important;
}

.ml--432 {
  margin-left: 432px !important;
}

.width--432 {
  width: 432px !important;
}

.height--432 {
  height: 432px !important;
}

.gap--432 {
  gap: 432px !important;
}

.scale--432 {
  scale: 4.32 !important;
}

.p--433 {
  padding: 433px !important;
}

.px--433 {
  padding-right: 433px !important;
  padding-left: 433px !important;
}

.py--433 {
  padding-top: 433px !important;
  padding-bottom: 433px !important;
}

.pt--433 {
  padding-top: 433px !important;
}

.pr--433 {
  padding-right: 433px !important;
}

.pb--433 {
  padding-bottom: 433px !important;
}

.pl--433 {
  padding-left: 433px !important;
}

.m--433 {
  margin: 433px !important;
}

.mx--433 {
  margin-right: 433px !important;
  margin-left: 433px !important;
}

.my--433 {
  margin-top: 433px !important;
  margin-bottom: 433px !important;
}

.mt--433 {
  margin-top: 433px !important;
}

.mr--433 {
  margin-right: 433px !important;
}

.mb--433 {
  margin-bottom: 433px !important;
}

.ml--433 {
  margin-left: 433px !important;
}

.width--433 {
  width: 433px !important;
}

.height--433 {
  height: 433px !important;
}

.gap--433 {
  gap: 433px !important;
}

.scale--433 {
  scale: 4.33 !important;
}

.p--434 {
  padding: 434px !important;
}

.px--434 {
  padding-right: 434px !important;
  padding-left: 434px !important;
}

.py--434 {
  padding-top: 434px !important;
  padding-bottom: 434px !important;
}

.pt--434 {
  padding-top: 434px !important;
}

.pr--434 {
  padding-right: 434px !important;
}

.pb--434 {
  padding-bottom: 434px !important;
}

.pl--434 {
  padding-left: 434px !important;
}

.m--434 {
  margin: 434px !important;
}

.mx--434 {
  margin-right: 434px !important;
  margin-left: 434px !important;
}

.my--434 {
  margin-top: 434px !important;
  margin-bottom: 434px !important;
}

.mt--434 {
  margin-top: 434px !important;
}

.mr--434 {
  margin-right: 434px !important;
}

.mb--434 {
  margin-bottom: 434px !important;
}

.ml--434 {
  margin-left: 434px !important;
}

.width--434 {
  width: 434px !important;
}

.height--434 {
  height: 434px !important;
}

.gap--434 {
  gap: 434px !important;
}

.scale--434 {
  scale: 4.34 !important;
}

.p--435 {
  padding: 435px !important;
}

.px--435 {
  padding-right: 435px !important;
  padding-left: 435px !important;
}

.py--435 {
  padding-top: 435px !important;
  padding-bottom: 435px !important;
}

.pt--435 {
  padding-top: 435px !important;
}

.pr--435 {
  padding-right: 435px !important;
}

.pb--435 {
  padding-bottom: 435px !important;
}

.pl--435 {
  padding-left: 435px !important;
}

.m--435 {
  margin: 435px !important;
}

.mx--435 {
  margin-right: 435px !important;
  margin-left: 435px !important;
}

.my--435 {
  margin-top: 435px !important;
  margin-bottom: 435px !important;
}

.mt--435 {
  margin-top: 435px !important;
}

.mr--435 {
  margin-right: 435px !important;
}

.mb--435 {
  margin-bottom: 435px !important;
}

.ml--435 {
  margin-left: 435px !important;
}

.width--435 {
  width: 435px !important;
}

.height--435 {
  height: 435px !important;
}

.gap--435 {
  gap: 435px !important;
}

.scale--435 {
  scale: 4.35 !important;
}

.p--436 {
  padding: 436px !important;
}

.px--436 {
  padding-right: 436px !important;
  padding-left: 436px !important;
}

.py--436 {
  padding-top: 436px !important;
  padding-bottom: 436px !important;
}

.pt--436 {
  padding-top: 436px !important;
}

.pr--436 {
  padding-right: 436px !important;
}

.pb--436 {
  padding-bottom: 436px !important;
}

.pl--436 {
  padding-left: 436px !important;
}

.m--436 {
  margin: 436px !important;
}

.mx--436 {
  margin-right: 436px !important;
  margin-left: 436px !important;
}

.my--436 {
  margin-top: 436px !important;
  margin-bottom: 436px !important;
}

.mt--436 {
  margin-top: 436px !important;
}

.mr--436 {
  margin-right: 436px !important;
}

.mb--436 {
  margin-bottom: 436px !important;
}

.ml--436 {
  margin-left: 436px !important;
}

.width--436 {
  width: 436px !important;
}

.height--436 {
  height: 436px !important;
}

.gap--436 {
  gap: 436px !important;
}

.scale--436 {
  scale: 4.36 !important;
}

.p--437 {
  padding: 437px !important;
}

.px--437 {
  padding-right: 437px !important;
  padding-left: 437px !important;
}

.py--437 {
  padding-top: 437px !important;
  padding-bottom: 437px !important;
}

.pt--437 {
  padding-top: 437px !important;
}

.pr--437 {
  padding-right: 437px !important;
}

.pb--437 {
  padding-bottom: 437px !important;
}

.pl--437 {
  padding-left: 437px !important;
}

.m--437 {
  margin: 437px !important;
}

.mx--437 {
  margin-right: 437px !important;
  margin-left: 437px !important;
}

.my--437 {
  margin-top: 437px !important;
  margin-bottom: 437px !important;
}

.mt--437 {
  margin-top: 437px !important;
}

.mr--437 {
  margin-right: 437px !important;
}

.mb--437 {
  margin-bottom: 437px !important;
}

.ml--437 {
  margin-left: 437px !important;
}

.width--437 {
  width: 437px !important;
}

.height--437 {
  height: 437px !important;
}

.gap--437 {
  gap: 437px !important;
}

.scale--437 {
  scale: 4.37 !important;
}

.p--438 {
  padding: 438px !important;
}

.px--438 {
  padding-right: 438px !important;
  padding-left: 438px !important;
}

.py--438 {
  padding-top: 438px !important;
  padding-bottom: 438px !important;
}

.pt--438 {
  padding-top: 438px !important;
}

.pr--438 {
  padding-right: 438px !important;
}

.pb--438 {
  padding-bottom: 438px !important;
}

.pl--438 {
  padding-left: 438px !important;
}

.m--438 {
  margin: 438px !important;
}

.mx--438 {
  margin-right: 438px !important;
  margin-left: 438px !important;
}

.my--438 {
  margin-top: 438px !important;
  margin-bottom: 438px !important;
}

.mt--438 {
  margin-top: 438px !important;
}

.mr--438 {
  margin-right: 438px !important;
}

.mb--438 {
  margin-bottom: 438px !important;
}

.ml--438 {
  margin-left: 438px !important;
}

.width--438 {
  width: 438px !important;
}

.height--438 {
  height: 438px !important;
}

.gap--438 {
  gap: 438px !important;
}

.scale--438 {
  scale: 4.38 !important;
}

.p--439 {
  padding: 439px !important;
}

.px--439 {
  padding-right: 439px !important;
  padding-left: 439px !important;
}

.py--439 {
  padding-top: 439px !important;
  padding-bottom: 439px !important;
}

.pt--439 {
  padding-top: 439px !important;
}

.pr--439 {
  padding-right: 439px !important;
}

.pb--439 {
  padding-bottom: 439px !important;
}

.pl--439 {
  padding-left: 439px !important;
}

.m--439 {
  margin: 439px !important;
}

.mx--439 {
  margin-right: 439px !important;
  margin-left: 439px !important;
}

.my--439 {
  margin-top: 439px !important;
  margin-bottom: 439px !important;
}

.mt--439 {
  margin-top: 439px !important;
}

.mr--439 {
  margin-right: 439px !important;
}

.mb--439 {
  margin-bottom: 439px !important;
}

.ml--439 {
  margin-left: 439px !important;
}

.width--439 {
  width: 439px !important;
}

.height--439 {
  height: 439px !important;
}

.gap--439 {
  gap: 439px !important;
}

.scale--439 {
  scale: 4.39 !important;
}

.p--440 {
  padding: 440px !important;
}

.px--440 {
  padding-right: 440px !important;
  padding-left: 440px !important;
}

.py--440 {
  padding-top: 440px !important;
  padding-bottom: 440px !important;
}

.pt--440 {
  padding-top: 440px !important;
}

.pr--440 {
  padding-right: 440px !important;
}

.pb--440 {
  padding-bottom: 440px !important;
}

.pl--440 {
  padding-left: 440px !important;
}

.m--440 {
  margin: 440px !important;
}

.mx--440 {
  margin-right: 440px !important;
  margin-left: 440px !important;
}

.my--440 {
  margin-top: 440px !important;
  margin-bottom: 440px !important;
}

.mt--440 {
  margin-top: 440px !important;
}

.mr--440 {
  margin-right: 440px !important;
}

.mb--440 {
  margin-bottom: 440px !important;
}

.ml--440 {
  margin-left: 440px !important;
}

.width--440 {
  width: 440px !important;
}

.height--440 {
  height: 440px !important;
}

.gap--440 {
  gap: 440px !important;
}

.scale--440 {
  scale: 4.4 !important;
}

.p--441 {
  padding: 441px !important;
}

.px--441 {
  padding-right: 441px !important;
  padding-left: 441px !important;
}

.py--441 {
  padding-top: 441px !important;
  padding-bottom: 441px !important;
}

.pt--441 {
  padding-top: 441px !important;
}

.pr--441 {
  padding-right: 441px !important;
}

.pb--441 {
  padding-bottom: 441px !important;
}

.pl--441 {
  padding-left: 441px !important;
}

.m--441 {
  margin: 441px !important;
}

.mx--441 {
  margin-right: 441px !important;
  margin-left: 441px !important;
}

.my--441 {
  margin-top: 441px !important;
  margin-bottom: 441px !important;
}

.mt--441 {
  margin-top: 441px !important;
}

.mr--441 {
  margin-right: 441px !important;
}

.mb--441 {
  margin-bottom: 441px !important;
}

.ml--441 {
  margin-left: 441px !important;
}

.width--441 {
  width: 441px !important;
}

.height--441 {
  height: 441px !important;
}

.gap--441 {
  gap: 441px !important;
}

.scale--441 {
  scale: 4.41 !important;
}

.p--442 {
  padding: 442px !important;
}

.px--442 {
  padding-right: 442px !important;
  padding-left: 442px !important;
}

.py--442 {
  padding-top: 442px !important;
  padding-bottom: 442px !important;
}

.pt--442 {
  padding-top: 442px !important;
}

.pr--442 {
  padding-right: 442px !important;
}

.pb--442 {
  padding-bottom: 442px !important;
}

.pl--442 {
  padding-left: 442px !important;
}

.m--442 {
  margin: 442px !important;
}

.mx--442 {
  margin-right: 442px !important;
  margin-left: 442px !important;
}

.my--442 {
  margin-top: 442px !important;
  margin-bottom: 442px !important;
}

.mt--442 {
  margin-top: 442px !important;
}

.mr--442 {
  margin-right: 442px !important;
}

.mb--442 {
  margin-bottom: 442px !important;
}

.ml--442 {
  margin-left: 442px !important;
}

.width--442 {
  width: 442px !important;
}

.height--442 {
  height: 442px !important;
}

.gap--442 {
  gap: 442px !important;
}

.scale--442 {
  scale: 4.42 !important;
}

.p--443 {
  padding: 443px !important;
}

.px--443 {
  padding-right: 443px !important;
  padding-left: 443px !important;
}

.py--443 {
  padding-top: 443px !important;
  padding-bottom: 443px !important;
}

.pt--443 {
  padding-top: 443px !important;
}

.pr--443 {
  padding-right: 443px !important;
}

.pb--443 {
  padding-bottom: 443px !important;
}

.pl--443 {
  padding-left: 443px !important;
}

.m--443 {
  margin: 443px !important;
}

.mx--443 {
  margin-right: 443px !important;
  margin-left: 443px !important;
}

.my--443 {
  margin-top: 443px !important;
  margin-bottom: 443px !important;
}

.mt--443 {
  margin-top: 443px !important;
}

.mr--443 {
  margin-right: 443px !important;
}

.mb--443 {
  margin-bottom: 443px !important;
}

.ml--443 {
  margin-left: 443px !important;
}

.width--443 {
  width: 443px !important;
}

.height--443 {
  height: 443px !important;
}

.gap--443 {
  gap: 443px !important;
}

.scale--443 {
  scale: 4.43 !important;
}

.p--444 {
  padding: 444px !important;
}

.px--444 {
  padding-right: 444px !important;
  padding-left: 444px !important;
}

.py--444 {
  padding-top: 444px !important;
  padding-bottom: 444px !important;
}

.pt--444 {
  padding-top: 444px !important;
}

.pr--444 {
  padding-right: 444px !important;
}

.pb--444 {
  padding-bottom: 444px !important;
}

.pl--444 {
  padding-left: 444px !important;
}

.m--444 {
  margin: 444px !important;
}

.mx--444 {
  margin-right: 444px !important;
  margin-left: 444px !important;
}

.my--444 {
  margin-top: 444px !important;
  margin-bottom: 444px !important;
}

.mt--444 {
  margin-top: 444px !important;
}

.mr--444 {
  margin-right: 444px !important;
}

.mb--444 {
  margin-bottom: 444px !important;
}

.ml--444 {
  margin-left: 444px !important;
}

.width--444 {
  width: 444px !important;
}

.height--444 {
  height: 444px !important;
}

.gap--444 {
  gap: 444px !important;
}

.scale--444 {
  scale: 4.44 !important;
}

.p--445 {
  padding: 445px !important;
}

.px--445 {
  padding-right: 445px !important;
  padding-left: 445px !important;
}

.py--445 {
  padding-top: 445px !important;
  padding-bottom: 445px !important;
}

.pt--445 {
  padding-top: 445px !important;
}

.pr--445 {
  padding-right: 445px !important;
}

.pb--445 {
  padding-bottom: 445px !important;
}

.pl--445 {
  padding-left: 445px !important;
}

.m--445 {
  margin: 445px !important;
}

.mx--445 {
  margin-right: 445px !important;
  margin-left: 445px !important;
}

.my--445 {
  margin-top: 445px !important;
  margin-bottom: 445px !important;
}

.mt--445 {
  margin-top: 445px !important;
}

.mr--445 {
  margin-right: 445px !important;
}

.mb--445 {
  margin-bottom: 445px !important;
}

.ml--445 {
  margin-left: 445px !important;
}

.width--445 {
  width: 445px !important;
}

.height--445 {
  height: 445px !important;
}

.gap--445 {
  gap: 445px !important;
}

.scale--445 {
  scale: 4.45 !important;
}

.p--446 {
  padding: 446px !important;
}

.px--446 {
  padding-right: 446px !important;
  padding-left: 446px !important;
}

.py--446 {
  padding-top: 446px !important;
  padding-bottom: 446px !important;
}

.pt--446 {
  padding-top: 446px !important;
}

.pr--446 {
  padding-right: 446px !important;
}

.pb--446 {
  padding-bottom: 446px !important;
}

.pl--446 {
  padding-left: 446px !important;
}

.m--446 {
  margin: 446px !important;
}

.mx--446 {
  margin-right: 446px !important;
  margin-left: 446px !important;
}

.my--446 {
  margin-top: 446px !important;
  margin-bottom: 446px !important;
}

.mt--446 {
  margin-top: 446px !important;
}

.mr--446 {
  margin-right: 446px !important;
}

.mb--446 {
  margin-bottom: 446px !important;
}

.ml--446 {
  margin-left: 446px !important;
}

.width--446 {
  width: 446px !important;
}

.height--446 {
  height: 446px !important;
}

.gap--446 {
  gap: 446px !important;
}

.scale--446 {
  scale: 4.46 !important;
}

.p--447 {
  padding: 447px !important;
}

.px--447 {
  padding-right: 447px !important;
  padding-left: 447px !important;
}

.py--447 {
  padding-top: 447px !important;
  padding-bottom: 447px !important;
}

.pt--447 {
  padding-top: 447px !important;
}

.pr--447 {
  padding-right: 447px !important;
}

.pb--447 {
  padding-bottom: 447px !important;
}

.pl--447 {
  padding-left: 447px !important;
}

.m--447 {
  margin: 447px !important;
}

.mx--447 {
  margin-right: 447px !important;
  margin-left: 447px !important;
}

.my--447 {
  margin-top: 447px !important;
  margin-bottom: 447px !important;
}

.mt--447 {
  margin-top: 447px !important;
}

.mr--447 {
  margin-right: 447px !important;
}

.mb--447 {
  margin-bottom: 447px !important;
}

.ml--447 {
  margin-left: 447px !important;
}

.width--447 {
  width: 447px !important;
}

.height--447 {
  height: 447px !important;
}

.gap--447 {
  gap: 447px !important;
}

.scale--447 {
  scale: 4.47 !important;
}

.p--448 {
  padding: 448px !important;
}

.px--448 {
  padding-right: 448px !important;
  padding-left: 448px !important;
}

.py--448 {
  padding-top: 448px !important;
  padding-bottom: 448px !important;
}

.pt--448 {
  padding-top: 448px !important;
}

.pr--448 {
  padding-right: 448px !important;
}

.pb--448 {
  padding-bottom: 448px !important;
}

.pl--448 {
  padding-left: 448px !important;
}

.m--448 {
  margin: 448px !important;
}

.mx--448 {
  margin-right: 448px !important;
  margin-left: 448px !important;
}

.my--448 {
  margin-top: 448px !important;
  margin-bottom: 448px !important;
}

.mt--448 {
  margin-top: 448px !important;
}

.mr--448 {
  margin-right: 448px !important;
}

.mb--448 {
  margin-bottom: 448px !important;
}

.ml--448 {
  margin-left: 448px !important;
}

.width--448 {
  width: 448px !important;
}

.height--448 {
  height: 448px !important;
}

.gap--448 {
  gap: 448px !important;
}

.scale--448 {
  scale: 4.48 !important;
}

.p--449 {
  padding: 449px !important;
}

.px--449 {
  padding-right: 449px !important;
  padding-left: 449px !important;
}

.py--449 {
  padding-top: 449px !important;
  padding-bottom: 449px !important;
}

.pt--449 {
  padding-top: 449px !important;
}

.pr--449 {
  padding-right: 449px !important;
}

.pb--449 {
  padding-bottom: 449px !important;
}

.pl--449 {
  padding-left: 449px !important;
}

.m--449 {
  margin: 449px !important;
}

.mx--449 {
  margin-right: 449px !important;
  margin-left: 449px !important;
}

.my--449 {
  margin-top: 449px !important;
  margin-bottom: 449px !important;
}

.mt--449 {
  margin-top: 449px !important;
}

.mr--449 {
  margin-right: 449px !important;
}

.mb--449 {
  margin-bottom: 449px !important;
}

.ml--449 {
  margin-left: 449px !important;
}

.width--449 {
  width: 449px !important;
}

.height--449 {
  height: 449px !important;
}

.gap--449 {
  gap: 449px !important;
}

.scale--449 {
  scale: 4.49 !important;
}

.p--450 {
  padding: 450px !important;
}

.px--450 {
  padding-right: 450px !important;
  padding-left: 450px !important;
}

.py--450 {
  padding-top: 450px !important;
  padding-bottom: 450px !important;
}

.pt--450 {
  padding-top: 450px !important;
}

.pr--450 {
  padding-right: 450px !important;
}

.pb--450 {
  padding-bottom: 450px !important;
}

.pl--450 {
  padding-left: 450px !important;
}

.m--450 {
  margin: 450px !important;
}

.mx--450 {
  margin-right: 450px !important;
  margin-left: 450px !important;
}

.my--450 {
  margin-top: 450px !important;
  margin-bottom: 450px !important;
}

.mt--450 {
  margin-top: 450px !important;
}

.mr--450 {
  margin-right: 450px !important;
}

.mb--450 {
  margin-bottom: 450px !important;
}

.ml--450 {
  margin-left: 450px !important;
}

.width--450 {
  width: 450px !important;
}

.height--450 {
  height: 450px !important;
}

.gap--450 {
  gap: 450px !important;
}

.scale--450 {
  scale: 4.5 !important;
}

.p--451 {
  padding: 451px !important;
}

.px--451 {
  padding-right: 451px !important;
  padding-left: 451px !important;
}

.py--451 {
  padding-top: 451px !important;
  padding-bottom: 451px !important;
}

.pt--451 {
  padding-top: 451px !important;
}

.pr--451 {
  padding-right: 451px !important;
}

.pb--451 {
  padding-bottom: 451px !important;
}

.pl--451 {
  padding-left: 451px !important;
}

.m--451 {
  margin: 451px !important;
}

.mx--451 {
  margin-right: 451px !important;
  margin-left: 451px !important;
}

.my--451 {
  margin-top: 451px !important;
  margin-bottom: 451px !important;
}

.mt--451 {
  margin-top: 451px !important;
}

.mr--451 {
  margin-right: 451px !important;
}

.mb--451 {
  margin-bottom: 451px !important;
}

.ml--451 {
  margin-left: 451px !important;
}

.width--451 {
  width: 451px !important;
}

.height--451 {
  height: 451px !important;
}

.gap--451 {
  gap: 451px !important;
}

.scale--451 {
  scale: 4.51 !important;
}

.p--452 {
  padding: 452px !important;
}

.px--452 {
  padding-right: 452px !important;
  padding-left: 452px !important;
}

.py--452 {
  padding-top: 452px !important;
  padding-bottom: 452px !important;
}

.pt--452 {
  padding-top: 452px !important;
}

.pr--452 {
  padding-right: 452px !important;
}

.pb--452 {
  padding-bottom: 452px !important;
}

.pl--452 {
  padding-left: 452px !important;
}

.m--452 {
  margin: 452px !important;
}

.mx--452 {
  margin-right: 452px !important;
  margin-left: 452px !important;
}

.my--452 {
  margin-top: 452px !important;
  margin-bottom: 452px !important;
}

.mt--452 {
  margin-top: 452px !important;
}

.mr--452 {
  margin-right: 452px !important;
}

.mb--452 {
  margin-bottom: 452px !important;
}

.ml--452 {
  margin-left: 452px !important;
}

.width--452 {
  width: 452px !important;
}

.height--452 {
  height: 452px !important;
}

.gap--452 {
  gap: 452px !important;
}

.scale--452 {
  scale: 4.52 !important;
}

.p--453 {
  padding: 453px !important;
}

.px--453 {
  padding-right: 453px !important;
  padding-left: 453px !important;
}

.py--453 {
  padding-top: 453px !important;
  padding-bottom: 453px !important;
}

.pt--453 {
  padding-top: 453px !important;
}

.pr--453 {
  padding-right: 453px !important;
}

.pb--453 {
  padding-bottom: 453px !important;
}

.pl--453 {
  padding-left: 453px !important;
}

.m--453 {
  margin: 453px !important;
}

.mx--453 {
  margin-right: 453px !important;
  margin-left: 453px !important;
}

.my--453 {
  margin-top: 453px !important;
  margin-bottom: 453px !important;
}

.mt--453 {
  margin-top: 453px !important;
}

.mr--453 {
  margin-right: 453px !important;
}

.mb--453 {
  margin-bottom: 453px !important;
}

.ml--453 {
  margin-left: 453px !important;
}

.width--453 {
  width: 453px !important;
}

.height--453 {
  height: 453px !important;
}

.gap--453 {
  gap: 453px !important;
}

.scale--453 {
  scale: 4.53 !important;
}

.p--454 {
  padding: 454px !important;
}

.px--454 {
  padding-right: 454px !important;
  padding-left: 454px !important;
}

.py--454 {
  padding-top: 454px !important;
  padding-bottom: 454px !important;
}

.pt--454 {
  padding-top: 454px !important;
}

.pr--454 {
  padding-right: 454px !important;
}

.pb--454 {
  padding-bottom: 454px !important;
}

.pl--454 {
  padding-left: 454px !important;
}

.m--454 {
  margin: 454px !important;
}

.mx--454 {
  margin-right: 454px !important;
  margin-left: 454px !important;
}

.my--454 {
  margin-top: 454px !important;
  margin-bottom: 454px !important;
}

.mt--454 {
  margin-top: 454px !important;
}

.mr--454 {
  margin-right: 454px !important;
}

.mb--454 {
  margin-bottom: 454px !important;
}

.ml--454 {
  margin-left: 454px !important;
}

.width--454 {
  width: 454px !important;
}

.height--454 {
  height: 454px !important;
}

.gap--454 {
  gap: 454px !important;
}

.scale--454 {
  scale: 4.54 !important;
}

.p--455 {
  padding: 455px !important;
}

.px--455 {
  padding-right: 455px !important;
  padding-left: 455px !important;
}

.py--455 {
  padding-top: 455px !important;
  padding-bottom: 455px !important;
}

.pt--455 {
  padding-top: 455px !important;
}

.pr--455 {
  padding-right: 455px !important;
}

.pb--455 {
  padding-bottom: 455px !important;
}

.pl--455 {
  padding-left: 455px !important;
}

.m--455 {
  margin: 455px !important;
}

.mx--455 {
  margin-right: 455px !important;
  margin-left: 455px !important;
}

.my--455 {
  margin-top: 455px !important;
  margin-bottom: 455px !important;
}

.mt--455 {
  margin-top: 455px !important;
}

.mr--455 {
  margin-right: 455px !important;
}

.mb--455 {
  margin-bottom: 455px !important;
}

.ml--455 {
  margin-left: 455px !important;
}

.width--455 {
  width: 455px !important;
}

.height--455 {
  height: 455px !important;
}

.gap--455 {
  gap: 455px !important;
}

.scale--455 {
  scale: 4.55 !important;
}

.p--456 {
  padding: 456px !important;
}

.px--456 {
  padding-right: 456px !important;
  padding-left: 456px !important;
}

.py--456 {
  padding-top: 456px !important;
  padding-bottom: 456px !important;
}

.pt--456 {
  padding-top: 456px !important;
}

.pr--456 {
  padding-right: 456px !important;
}

.pb--456 {
  padding-bottom: 456px !important;
}

.pl--456 {
  padding-left: 456px !important;
}

.m--456 {
  margin: 456px !important;
}

.mx--456 {
  margin-right: 456px !important;
  margin-left: 456px !important;
}

.my--456 {
  margin-top: 456px !important;
  margin-bottom: 456px !important;
}

.mt--456 {
  margin-top: 456px !important;
}

.mr--456 {
  margin-right: 456px !important;
}

.mb--456 {
  margin-bottom: 456px !important;
}

.ml--456 {
  margin-left: 456px !important;
}

.width--456 {
  width: 456px !important;
}

.height--456 {
  height: 456px !important;
}

.gap--456 {
  gap: 456px !important;
}

.scale--456 {
  scale: 4.56 !important;
}

.p--457 {
  padding: 457px !important;
}

.px--457 {
  padding-right: 457px !important;
  padding-left: 457px !important;
}

.py--457 {
  padding-top: 457px !important;
  padding-bottom: 457px !important;
}

.pt--457 {
  padding-top: 457px !important;
}

.pr--457 {
  padding-right: 457px !important;
}

.pb--457 {
  padding-bottom: 457px !important;
}

.pl--457 {
  padding-left: 457px !important;
}

.m--457 {
  margin: 457px !important;
}

.mx--457 {
  margin-right: 457px !important;
  margin-left: 457px !important;
}

.my--457 {
  margin-top: 457px !important;
  margin-bottom: 457px !important;
}

.mt--457 {
  margin-top: 457px !important;
}

.mr--457 {
  margin-right: 457px !important;
}

.mb--457 {
  margin-bottom: 457px !important;
}

.ml--457 {
  margin-left: 457px !important;
}

.width--457 {
  width: 457px !important;
}

.height--457 {
  height: 457px !important;
}

.gap--457 {
  gap: 457px !important;
}

.scale--457 {
  scale: 4.57 !important;
}

.p--458 {
  padding: 458px !important;
}

.px--458 {
  padding-right: 458px !important;
  padding-left: 458px !important;
}

.py--458 {
  padding-top: 458px !important;
  padding-bottom: 458px !important;
}

.pt--458 {
  padding-top: 458px !important;
}

.pr--458 {
  padding-right: 458px !important;
}

.pb--458 {
  padding-bottom: 458px !important;
}

.pl--458 {
  padding-left: 458px !important;
}

.m--458 {
  margin: 458px !important;
}

.mx--458 {
  margin-right: 458px !important;
  margin-left: 458px !important;
}

.my--458 {
  margin-top: 458px !important;
  margin-bottom: 458px !important;
}

.mt--458 {
  margin-top: 458px !important;
}

.mr--458 {
  margin-right: 458px !important;
}

.mb--458 {
  margin-bottom: 458px !important;
}

.ml--458 {
  margin-left: 458px !important;
}

.width--458 {
  width: 458px !important;
}

.height--458 {
  height: 458px !important;
}

.gap--458 {
  gap: 458px !important;
}

.scale--458 {
  scale: 4.58 !important;
}

.p--459 {
  padding: 459px !important;
}

.px--459 {
  padding-right: 459px !important;
  padding-left: 459px !important;
}

.py--459 {
  padding-top: 459px !important;
  padding-bottom: 459px !important;
}

.pt--459 {
  padding-top: 459px !important;
}

.pr--459 {
  padding-right: 459px !important;
}

.pb--459 {
  padding-bottom: 459px !important;
}

.pl--459 {
  padding-left: 459px !important;
}

.m--459 {
  margin: 459px !important;
}

.mx--459 {
  margin-right: 459px !important;
  margin-left: 459px !important;
}

.my--459 {
  margin-top: 459px !important;
  margin-bottom: 459px !important;
}

.mt--459 {
  margin-top: 459px !important;
}

.mr--459 {
  margin-right: 459px !important;
}

.mb--459 {
  margin-bottom: 459px !important;
}

.ml--459 {
  margin-left: 459px !important;
}

.width--459 {
  width: 459px !important;
}

.height--459 {
  height: 459px !important;
}

.gap--459 {
  gap: 459px !important;
}

.scale--459 {
  scale: 4.59 !important;
}

.p--460 {
  padding: 460px !important;
}

.px--460 {
  padding-right: 460px !important;
  padding-left: 460px !important;
}

.py--460 {
  padding-top: 460px !important;
  padding-bottom: 460px !important;
}

.pt--460 {
  padding-top: 460px !important;
}

.pr--460 {
  padding-right: 460px !important;
}

.pb--460 {
  padding-bottom: 460px !important;
}

.pl--460 {
  padding-left: 460px !important;
}

.m--460 {
  margin: 460px !important;
}

.mx--460 {
  margin-right: 460px !important;
  margin-left: 460px !important;
}

.my--460 {
  margin-top: 460px !important;
  margin-bottom: 460px !important;
}

.mt--460 {
  margin-top: 460px !important;
}

.mr--460 {
  margin-right: 460px !important;
}

.mb--460 {
  margin-bottom: 460px !important;
}

.ml--460 {
  margin-left: 460px !important;
}

.width--460 {
  width: 460px !important;
}

.height--460 {
  height: 460px !important;
}

.gap--460 {
  gap: 460px !important;
}

.scale--460 {
  scale: 4.6 !important;
}

.p--461 {
  padding: 461px !important;
}

.px--461 {
  padding-right: 461px !important;
  padding-left: 461px !important;
}

.py--461 {
  padding-top: 461px !important;
  padding-bottom: 461px !important;
}

.pt--461 {
  padding-top: 461px !important;
}

.pr--461 {
  padding-right: 461px !important;
}

.pb--461 {
  padding-bottom: 461px !important;
}

.pl--461 {
  padding-left: 461px !important;
}

.m--461 {
  margin: 461px !important;
}

.mx--461 {
  margin-right: 461px !important;
  margin-left: 461px !important;
}

.my--461 {
  margin-top: 461px !important;
  margin-bottom: 461px !important;
}

.mt--461 {
  margin-top: 461px !important;
}

.mr--461 {
  margin-right: 461px !important;
}

.mb--461 {
  margin-bottom: 461px !important;
}

.ml--461 {
  margin-left: 461px !important;
}

.width--461 {
  width: 461px !important;
}

.height--461 {
  height: 461px !important;
}

.gap--461 {
  gap: 461px !important;
}

.scale--461 {
  scale: 4.61 !important;
}

.p--462 {
  padding: 462px !important;
}

.px--462 {
  padding-right: 462px !important;
  padding-left: 462px !important;
}

.py--462 {
  padding-top: 462px !important;
  padding-bottom: 462px !important;
}

.pt--462 {
  padding-top: 462px !important;
}

.pr--462 {
  padding-right: 462px !important;
}

.pb--462 {
  padding-bottom: 462px !important;
}

.pl--462 {
  padding-left: 462px !important;
}

.m--462 {
  margin: 462px !important;
}

.mx--462 {
  margin-right: 462px !important;
  margin-left: 462px !important;
}

.my--462 {
  margin-top: 462px !important;
  margin-bottom: 462px !important;
}

.mt--462 {
  margin-top: 462px !important;
}

.mr--462 {
  margin-right: 462px !important;
}

.mb--462 {
  margin-bottom: 462px !important;
}

.ml--462 {
  margin-left: 462px !important;
}

.width--462 {
  width: 462px !important;
}

.height--462 {
  height: 462px !important;
}

.gap--462 {
  gap: 462px !important;
}

.scale--462 {
  scale: 4.62 !important;
}

.p--463 {
  padding: 463px !important;
}

.px--463 {
  padding-right: 463px !important;
  padding-left: 463px !important;
}

.py--463 {
  padding-top: 463px !important;
  padding-bottom: 463px !important;
}

.pt--463 {
  padding-top: 463px !important;
}

.pr--463 {
  padding-right: 463px !important;
}

.pb--463 {
  padding-bottom: 463px !important;
}

.pl--463 {
  padding-left: 463px !important;
}

.m--463 {
  margin: 463px !important;
}

.mx--463 {
  margin-right: 463px !important;
  margin-left: 463px !important;
}

.my--463 {
  margin-top: 463px !important;
  margin-bottom: 463px !important;
}

.mt--463 {
  margin-top: 463px !important;
}

.mr--463 {
  margin-right: 463px !important;
}

.mb--463 {
  margin-bottom: 463px !important;
}

.ml--463 {
  margin-left: 463px !important;
}

.width--463 {
  width: 463px !important;
}

.height--463 {
  height: 463px !important;
}

.gap--463 {
  gap: 463px !important;
}

.scale--463 {
  scale: 4.63 !important;
}

.p--464 {
  padding: 464px !important;
}

.px--464 {
  padding-right: 464px !important;
  padding-left: 464px !important;
}

.py--464 {
  padding-top: 464px !important;
  padding-bottom: 464px !important;
}

.pt--464 {
  padding-top: 464px !important;
}

.pr--464 {
  padding-right: 464px !important;
}

.pb--464 {
  padding-bottom: 464px !important;
}

.pl--464 {
  padding-left: 464px !important;
}

.m--464 {
  margin: 464px !important;
}

.mx--464 {
  margin-right: 464px !important;
  margin-left: 464px !important;
}

.my--464 {
  margin-top: 464px !important;
  margin-bottom: 464px !important;
}

.mt--464 {
  margin-top: 464px !important;
}

.mr--464 {
  margin-right: 464px !important;
}

.mb--464 {
  margin-bottom: 464px !important;
}

.ml--464 {
  margin-left: 464px !important;
}

.width--464 {
  width: 464px !important;
}

.height--464 {
  height: 464px !important;
}

.gap--464 {
  gap: 464px !important;
}

.scale--464 {
  scale: 4.64 !important;
}

.p--465 {
  padding: 465px !important;
}

.px--465 {
  padding-right: 465px !important;
  padding-left: 465px !important;
}

.py--465 {
  padding-top: 465px !important;
  padding-bottom: 465px !important;
}

.pt--465 {
  padding-top: 465px !important;
}

.pr--465 {
  padding-right: 465px !important;
}

.pb--465 {
  padding-bottom: 465px !important;
}

.pl--465 {
  padding-left: 465px !important;
}

.m--465 {
  margin: 465px !important;
}

.mx--465 {
  margin-right: 465px !important;
  margin-left: 465px !important;
}

.my--465 {
  margin-top: 465px !important;
  margin-bottom: 465px !important;
}

.mt--465 {
  margin-top: 465px !important;
}

.mr--465 {
  margin-right: 465px !important;
}

.mb--465 {
  margin-bottom: 465px !important;
}

.ml--465 {
  margin-left: 465px !important;
}

.width--465 {
  width: 465px !important;
}

.height--465 {
  height: 465px !important;
}

.gap--465 {
  gap: 465px !important;
}

.scale--465 {
  scale: 4.65 !important;
}

.p--466 {
  padding: 466px !important;
}

.px--466 {
  padding-right: 466px !important;
  padding-left: 466px !important;
}

.py--466 {
  padding-top: 466px !important;
  padding-bottom: 466px !important;
}

.pt--466 {
  padding-top: 466px !important;
}

.pr--466 {
  padding-right: 466px !important;
}

.pb--466 {
  padding-bottom: 466px !important;
}

.pl--466 {
  padding-left: 466px !important;
}

.m--466 {
  margin: 466px !important;
}

.mx--466 {
  margin-right: 466px !important;
  margin-left: 466px !important;
}

.my--466 {
  margin-top: 466px !important;
  margin-bottom: 466px !important;
}

.mt--466 {
  margin-top: 466px !important;
}

.mr--466 {
  margin-right: 466px !important;
}

.mb--466 {
  margin-bottom: 466px !important;
}

.ml--466 {
  margin-left: 466px !important;
}

.width--466 {
  width: 466px !important;
}

.height--466 {
  height: 466px !important;
}

.gap--466 {
  gap: 466px !important;
}

.scale--466 {
  scale: 4.66 !important;
}

.p--467 {
  padding: 467px !important;
}

.px--467 {
  padding-right: 467px !important;
  padding-left: 467px !important;
}

.py--467 {
  padding-top: 467px !important;
  padding-bottom: 467px !important;
}

.pt--467 {
  padding-top: 467px !important;
}

.pr--467 {
  padding-right: 467px !important;
}

.pb--467 {
  padding-bottom: 467px !important;
}

.pl--467 {
  padding-left: 467px !important;
}

.m--467 {
  margin: 467px !important;
}

.mx--467 {
  margin-right: 467px !important;
  margin-left: 467px !important;
}

.my--467 {
  margin-top: 467px !important;
  margin-bottom: 467px !important;
}

.mt--467 {
  margin-top: 467px !important;
}

.mr--467 {
  margin-right: 467px !important;
}

.mb--467 {
  margin-bottom: 467px !important;
}

.ml--467 {
  margin-left: 467px !important;
}

.width--467 {
  width: 467px !important;
}

.height--467 {
  height: 467px !important;
}

.gap--467 {
  gap: 467px !important;
}

.scale--467 {
  scale: 4.67 !important;
}

.p--468 {
  padding: 468px !important;
}

.px--468 {
  padding-right: 468px !important;
  padding-left: 468px !important;
}

.py--468 {
  padding-top: 468px !important;
  padding-bottom: 468px !important;
}

.pt--468 {
  padding-top: 468px !important;
}

.pr--468 {
  padding-right: 468px !important;
}

.pb--468 {
  padding-bottom: 468px !important;
}

.pl--468 {
  padding-left: 468px !important;
}

.m--468 {
  margin: 468px !important;
}

.mx--468 {
  margin-right: 468px !important;
  margin-left: 468px !important;
}

.my--468 {
  margin-top: 468px !important;
  margin-bottom: 468px !important;
}

.mt--468 {
  margin-top: 468px !important;
}

.mr--468 {
  margin-right: 468px !important;
}

.mb--468 {
  margin-bottom: 468px !important;
}

.ml--468 {
  margin-left: 468px !important;
}

.width--468 {
  width: 468px !important;
}

.height--468 {
  height: 468px !important;
}

.gap--468 {
  gap: 468px !important;
}

.scale--468 {
  scale: 4.68 !important;
}

.p--469 {
  padding: 469px !important;
}

.px--469 {
  padding-right: 469px !important;
  padding-left: 469px !important;
}

.py--469 {
  padding-top: 469px !important;
  padding-bottom: 469px !important;
}

.pt--469 {
  padding-top: 469px !important;
}

.pr--469 {
  padding-right: 469px !important;
}

.pb--469 {
  padding-bottom: 469px !important;
}

.pl--469 {
  padding-left: 469px !important;
}

.m--469 {
  margin: 469px !important;
}

.mx--469 {
  margin-right: 469px !important;
  margin-left: 469px !important;
}

.my--469 {
  margin-top: 469px !important;
  margin-bottom: 469px !important;
}

.mt--469 {
  margin-top: 469px !important;
}

.mr--469 {
  margin-right: 469px !important;
}

.mb--469 {
  margin-bottom: 469px !important;
}

.ml--469 {
  margin-left: 469px !important;
}

.width--469 {
  width: 469px !important;
}

.height--469 {
  height: 469px !important;
}

.gap--469 {
  gap: 469px !important;
}

.scale--469 {
  scale: 4.69 !important;
}

.p--470 {
  padding: 470px !important;
}

.px--470 {
  padding-right: 470px !important;
  padding-left: 470px !important;
}

.py--470 {
  padding-top: 470px !important;
  padding-bottom: 470px !important;
}

.pt--470 {
  padding-top: 470px !important;
}

.pr--470 {
  padding-right: 470px !important;
}

.pb--470 {
  padding-bottom: 470px !important;
}

.pl--470 {
  padding-left: 470px !important;
}

.m--470 {
  margin: 470px !important;
}

.mx--470 {
  margin-right: 470px !important;
  margin-left: 470px !important;
}

.my--470 {
  margin-top: 470px !important;
  margin-bottom: 470px !important;
}

.mt--470 {
  margin-top: 470px !important;
}

.mr--470 {
  margin-right: 470px !important;
}

.mb--470 {
  margin-bottom: 470px !important;
}

.ml--470 {
  margin-left: 470px !important;
}

.width--470 {
  width: 470px !important;
}

.height--470 {
  height: 470px !important;
}

.gap--470 {
  gap: 470px !important;
}

.scale--470 {
  scale: 4.7 !important;
}

.p--471 {
  padding: 471px !important;
}

.px--471 {
  padding-right: 471px !important;
  padding-left: 471px !important;
}

.py--471 {
  padding-top: 471px !important;
  padding-bottom: 471px !important;
}

.pt--471 {
  padding-top: 471px !important;
}

.pr--471 {
  padding-right: 471px !important;
}

.pb--471 {
  padding-bottom: 471px !important;
}

.pl--471 {
  padding-left: 471px !important;
}

.m--471 {
  margin: 471px !important;
}

.mx--471 {
  margin-right: 471px !important;
  margin-left: 471px !important;
}

.my--471 {
  margin-top: 471px !important;
  margin-bottom: 471px !important;
}

.mt--471 {
  margin-top: 471px !important;
}

.mr--471 {
  margin-right: 471px !important;
}

.mb--471 {
  margin-bottom: 471px !important;
}

.ml--471 {
  margin-left: 471px !important;
}

.width--471 {
  width: 471px !important;
}

.height--471 {
  height: 471px !important;
}

.gap--471 {
  gap: 471px !important;
}

.scale--471 {
  scale: 4.71 !important;
}

.p--472 {
  padding: 472px !important;
}

.px--472 {
  padding-right: 472px !important;
  padding-left: 472px !important;
}

.py--472 {
  padding-top: 472px !important;
  padding-bottom: 472px !important;
}

.pt--472 {
  padding-top: 472px !important;
}

.pr--472 {
  padding-right: 472px !important;
}

.pb--472 {
  padding-bottom: 472px !important;
}

.pl--472 {
  padding-left: 472px !important;
}

.m--472 {
  margin: 472px !important;
}

.mx--472 {
  margin-right: 472px !important;
  margin-left: 472px !important;
}

.my--472 {
  margin-top: 472px !important;
  margin-bottom: 472px !important;
}

.mt--472 {
  margin-top: 472px !important;
}

.mr--472 {
  margin-right: 472px !important;
}

.mb--472 {
  margin-bottom: 472px !important;
}

.ml--472 {
  margin-left: 472px !important;
}

.width--472 {
  width: 472px !important;
}

.height--472 {
  height: 472px !important;
}

.gap--472 {
  gap: 472px !important;
}

.scale--472 {
  scale: 4.72 !important;
}

.p--473 {
  padding: 473px !important;
}

.px--473 {
  padding-right: 473px !important;
  padding-left: 473px !important;
}

.py--473 {
  padding-top: 473px !important;
  padding-bottom: 473px !important;
}

.pt--473 {
  padding-top: 473px !important;
}

.pr--473 {
  padding-right: 473px !important;
}

.pb--473 {
  padding-bottom: 473px !important;
}

.pl--473 {
  padding-left: 473px !important;
}

.m--473 {
  margin: 473px !important;
}

.mx--473 {
  margin-right: 473px !important;
  margin-left: 473px !important;
}

.my--473 {
  margin-top: 473px !important;
  margin-bottom: 473px !important;
}

.mt--473 {
  margin-top: 473px !important;
}

.mr--473 {
  margin-right: 473px !important;
}

.mb--473 {
  margin-bottom: 473px !important;
}

.ml--473 {
  margin-left: 473px !important;
}

.width--473 {
  width: 473px !important;
}

.height--473 {
  height: 473px !important;
}

.gap--473 {
  gap: 473px !important;
}

.scale--473 {
  scale: 4.73 !important;
}

.p--474 {
  padding: 474px !important;
}

.px--474 {
  padding-right: 474px !important;
  padding-left: 474px !important;
}

.py--474 {
  padding-top: 474px !important;
  padding-bottom: 474px !important;
}

.pt--474 {
  padding-top: 474px !important;
}

.pr--474 {
  padding-right: 474px !important;
}

.pb--474 {
  padding-bottom: 474px !important;
}

.pl--474 {
  padding-left: 474px !important;
}

.m--474 {
  margin: 474px !important;
}

.mx--474 {
  margin-right: 474px !important;
  margin-left: 474px !important;
}

.my--474 {
  margin-top: 474px !important;
  margin-bottom: 474px !important;
}

.mt--474 {
  margin-top: 474px !important;
}

.mr--474 {
  margin-right: 474px !important;
}

.mb--474 {
  margin-bottom: 474px !important;
}

.ml--474 {
  margin-left: 474px !important;
}

.width--474 {
  width: 474px !important;
}

.height--474 {
  height: 474px !important;
}

.gap--474 {
  gap: 474px !important;
}

.scale--474 {
  scale: 4.74 !important;
}

.p--475 {
  padding: 475px !important;
}

.px--475 {
  padding-right: 475px !important;
  padding-left: 475px !important;
}

.py--475 {
  padding-top: 475px !important;
  padding-bottom: 475px !important;
}

.pt--475 {
  padding-top: 475px !important;
}

.pr--475 {
  padding-right: 475px !important;
}

.pb--475 {
  padding-bottom: 475px !important;
}

.pl--475 {
  padding-left: 475px !important;
}

.m--475 {
  margin: 475px !important;
}

.mx--475 {
  margin-right: 475px !important;
  margin-left: 475px !important;
}

.my--475 {
  margin-top: 475px !important;
  margin-bottom: 475px !important;
}

.mt--475 {
  margin-top: 475px !important;
}

.mr--475 {
  margin-right: 475px !important;
}

.mb--475 {
  margin-bottom: 475px !important;
}

.ml--475 {
  margin-left: 475px !important;
}

.width--475 {
  width: 475px !important;
}

.height--475 {
  height: 475px !important;
}

.gap--475 {
  gap: 475px !important;
}

.scale--475 {
  scale: 4.75 !important;
}

.p--476 {
  padding: 476px !important;
}

.px--476 {
  padding-right: 476px !important;
  padding-left: 476px !important;
}

.py--476 {
  padding-top: 476px !important;
  padding-bottom: 476px !important;
}

.pt--476 {
  padding-top: 476px !important;
}

.pr--476 {
  padding-right: 476px !important;
}

.pb--476 {
  padding-bottom: 476px !important;
}

.pl--476 {
  padding-left: 476px !important;
}

.m--476 {
  margin: 476px !important;
}

.mx--476 {
  margin-right: 476px !important;
  margin-left: 476px !important;
}

.my--476 {
  margin-top: 476px !important;
  margin-bottom: 476px !important;
}

.mt--476 {
  margin-top: 476px !important;
}

.mr--476 {
  margin-right: 476px !important;
}

.mb--476 {
  margin-bottom: 476px !important;
}

.ml--476 {
  margin-left: 476px !important;
}

.width--476 {
  width: 476px !important;
}

.height--476 {
  height: 476px !important;
}

.gap--476 {
  gap: 476px !important;
}

.scale--476 {
  scale: 4.76 !important;
}

.p--477 {
  padding: 477px !important;
}

.px--477 {
  padding-right: 477px !important;
  padding-left: 477px !important;
}

.py--477 {
  padding-top: 477px !important;
  padding-bottom: 477px !important;
}

.pt--477 {
  padding-top: 477px !important;
}

.pr--477 {
  padding-right: 477px !important;
}

.pb--477 {
  padding-bottom: 477px !important;
}

.pl--477 {
  padding-left: 477px !important;
}

.m--477 {
  margin: 477px !important;
}

.mx--477 {
  margin-right: 477px !important;
  margin-left: 477px !important;
}

.my--477 {
  margin-top: 477px !important;
  margin-bottom: 477px !important;
}

.mt--477 {
  margin-top: 477px !important;
}

.mr--477 {
  margin-right: 477px !important;
}

.mb--477 {
  margin-bottom: 477px !important;
}

.ml--477 {
  margin-left: 477px !important;
}

.width--477 {
  width: 477px !important;
}

.height--477 {
  height: 477px !important;
}

.gap--477 {
  gap: 477px !important;
}

.scale--477 {
  scale: 4.77 !important;
}

.p--478 {
  padding: 478px !important;
}

.px--478 {
  padding-right: 478px !important;
  padding-left: 478px !important;
}

.py--478 {
  padding-top: 478px !important;
  padding-bottom: 478px !important;
}

.pt--478 {
  padding-top: 478px !important;
}

.pr--478 {
  padding-right: 478px !important;
}

.pb--478 {
  padding-bottom: 478px !important;
}

.pl--478 {
  padding-left: 478px !important;
}

.m--478 {
  margin: 478px !important;
}

.mx--478 {
  margin-right: 478px !important;
  margin-left: 478px !important;
}

.my--478 {
  margin-top: 478px !important;
  margin-bottom: 478px !important;
}

.mt--478 {
  margin-top: 478px !important;
}

.mr--478 {
  margin-right: 478px !important;
}

.mb--478 {
  margin-bottom: 478px !important;
}

.ml--478 {
  margin-left: 478px !important;
}

.width--478 {
  width: 478px !important;
}

.height--478 {
  height: 478px !important;
}

.gap--478 {
  gap: 478px !important;
}

.scale--478 {
  scale: 4.78 !important;
}

.p--479 {
  padding: 479px !important;
}

.px--479 {
  padding-right: 479px !important;
  padding-left: 479px !important;
}

.py--479 {
  padding-top: 479px !important;
  padding-bottom: 479px !important;
}

.pt--479 {
  padding-top: 479px !important;
}

.pr--479 {
  padding-right: 479px !important;
}

.pb--479 {
  padding-bottom: 479px !important;
}

.pl--479 {
  padding-left: 479px !important;
}

.m--479 {
  margin: 479px !important;
}

.mx--479 {
  margin-right: 479px !important;
  margin-left: 479px !important;
}

.my--479 {
  margin-top: 479px !important;
  margin-bottom: 479px !important;
}

.mt--479 {
  margin-top: 479px !important;
}

.mr--479 {
  margin-right: 479px !important;
}

.mb--479 {
  margin-bottom: 479px !important;
}

.ml--479 {
  margin-left: 479px !important;
}

.width--479 {
  width: 479px !important;
}

.height--479 {
  height: 479px !important;
}

.gap--479 {
  gap: 479px !important;
}

.scale--479 {
  scale: 4.79 !important;
}

.p--480 {
  padding: 480px !important;
}

.px--480 {
  padding-right: 480px !important;
  padding-left: 480px !important;
}

.py--480 {
  padding-top: 480px !important;
  padding-bottom: 480px !important;
}

.pt--480 {
  padding-top: 480px !important;
}

.pr--480 {
  padding-right: 480px !important;
}

.pb--480 {
  padding-bottom: 480px !important;
}

.pl--480 {
  padding-left: 480px !important;
}

.m--480 {
  margin: 480px !important;
}

.mx--480 {
  margin-right: 480px !important;
  margin-left: 480px !important;
}

.my--480 {
  margin-top: 480px !important;
  margin-bottom: 480px !important;
}

.mt--480 {
  margin-top: 480px !important;
}

.mr--480 {
  margin-right: 480px !important;
}

.mb--480 {
  margin-bottom: 480px !important;
}

.ml--480 {
  margin-left: 480px !important;
}

.width--480 {
  width: 480px !important;
}

.height--480 {
  height: 480px !important;
}

.gap--480 {
  gap: 480px !important;
}

.scale--480 {
  scale: 4.8 !important;
}

.p--481 {
  padding: 481px !important;
}

.px--481 {
  padding-right: 481px !important;
  padding-left: 481px !important;
}

.py--481 {
  padding-top: 481px !important;
  padding-bottom: 481px !important;
}

.pt--481 {
  padding-top: 481px !important;
}

.pr--481 {
  padding-right: 481px !important;
}

.pb--481 {
  padding-bottom: 481px !important;
}

.pl--481 {
  padding-left: 481px !important;
}

.m--481 {
  margin: 481px !important;
}

.mx--481 {
  margin-right: 481px !important;
  margin-left: 481px !important;
}

.my--481 {
  margin-top: 481px !important;
  margin-bottom: 481px !important;
}

.mt--481 {
  margin-top: 481px !important;
}

.mr--481 {
  margin-right: 481px !important;
}

.mb--481 {
  margin-bottom: 481px !important;
}

.ml--481 {
  margin-left: 481px !important;
}

.width--481 {
  width: 481px !important;
}

.height--481 {
  height: 481px !important;
}

.gap--481 {
  gap: 481px !important;
}

.scale--481 {
  scale: 4.81 !important;
}

.p--482 {
  padding: 482px !important;
}

.px--482 {
  padding-right: 482px !important;
  padding-left: 482px !important;
}

.py--482 {
  padding-top: 482px !important;
  padding-bottom: 482px !important;
}

.pt--482 {
  padding-top: 482px !important;
}

.pr--482 {
  padding-right: 482px !important;
}

.pb--482 {
  padding-bottom: 482px !important;
}

.pl--482 {
  padding-left: 482px !important;
}

.m--482 {
  margin: 482px !important;
}

.mx--482 {
  margin-right: 482px !important;
  margin-left: 482px !important;
}

.my--482 {
  margin-top: 482px !important;
  margin-bottom: 482px !important;
}

.mt--482 {
  margin-top: 482px !important;
}

.mr--482 {
  margin-right: 482px !important;
}

.mb--482 {
  margin-bottom: 482px !important;
}

.ml--482 {
  margin-left: 482px !important;
}

.width--482 {
  width: 482px !important;
}

.height--482 {
  height: 482px !important;
}

.gap--482 {
  gap: 482px !important;
}

.scale--482 {
  scale: 4.82 !important;
}

.p--483 {
  padding: 483px !important;
}

.px--483 {
  padding-right: 483px !important;
  padding-left: 483px !important;
}

.py--483 {
  padding-top: 483px !important;
  padding-bottom: 483px !important;
}

.pt--483 {
  padding-top: 483px !important;
}

.pr--483 {
  padding-right: 483px !important;
}

.pb--483 {
  padding-bottom: 483px !important;
}

.pl--483 {
  padding-left: 483px !important;
}

.m--483 {
  margin: 483px !important;
}

.mx--483 {
  margin-right: 483px !important;
  margin-left: 483px !important;
}

.my--483 {
  margin-top: 483px !important;
  margin-bottom: 483px !important;
}

.mt--483 {
  margin-top: 483px !important;
}

.mr--483 {
  margin-right: 483px !important;
}

.mb--483 {
  margin-bottom: 483px !important;
}

.ml--483 {
  margin-left: 483px !important;
}

.width--483 {
  width: 483px !important;
}

.height--483 {
  height: 483px !important;
}

.gap--483 {
  gap: 483px !important;
}

.scale--483 {
  scale: 4.83 !important;
}

.p--484 {
  padding: 484px !important;
}

.px--484 {
  padding-right: 484px !important;
  padding-left: 484px !important;
}

.py--484 {
  padding-top: 484px !important;
  padding-bottom: 484px !important;
}

.pt--484 {
  padding-top: 484px !important;
}

.pr--484 {
  padding-right: 484px !important;
}

.pb--484 {
  padding-bottom: 484px !important;
}

.pl--484 {
  padding-left: 484px !important;
}

.m--484 {
  margin: 484px !important;
}

.mx--484 {
  margin-right: 484px !important;
  margin-left: 484px !important;
}

.my--484 {
  margin-top: 484px !important;
  margin-bottom: 484px !important;
}

.mt--484 {
  margin-top: 484px !important;
}

.mr--484 {
  margin-right: 484px !important;
}

.mb--484 {
  margin-bottom: 484px !important;
}

.ml--484 {
  margin-left: 484px !important;
}

.width--484 {
  width: 484px !important;
}

.height--484 {
  height: 484px !important;
}

.gap--484 {
  gap: 484px !important;
}

.scale--484 {
  scale: 4.84 !important;
}

.p--485 {
  padding: 485px !important;
}

.px--485 {
  padding-right: 485px !important;
  padding-left: 485px !important;
}

.py--485 {
  padding-top: 485px !important;
  padding-bottom: 485px !important;
}

.pt--485 {
  padding-top: 485px !important;
}

.pr--485 {
  padding-right: 485px !important;
}

.pb--485 {
  padding-bottom: 485px !important;
}

.pl--485 {
  padding-left: 485px !important;
}

.m--485 {
  margin: 485px !important;
}

.mx--485 {
  margin-right: 485px !important;
  margin-left: 485px !important;
}

.my--485 {
  margin-top: 485px !important;
  margin-bottom: 485px !important;
}

.mt--485 {
  margin-top: 485px !important;
}

.mr--485 {
  margin-right: 485px !important;
}

.mb--485 {
  margin-bottom: 485px !important;
}

.ml--485 {
  margin-left: 485px !important;
}

.width--485 {
  width: 485px !important;
}

.height--485 {
  height: 485px !important;
}

.gap--485 {
  gap: 485px !important;
}

.scale--485 {
  scale: 4.85 !important;
}

.p--486 {
  padding: 486px !important;
}

.px--486 {
  padding-right: 486px !important;
  padding-left: 486px !important;
}

.py--486 {
  padding-top: 486px !important;
  padding-bottom: 486px !important;
}

.pt--486 {
  padding-top: 486px !important;
}

.pr--486 {
  padding-right: 486px !important;
}

.pb--486 {
  padding-bottom: 486px !important;
}

.pl--486 {
  padding-left: 486px !important;
}

.m--486 {
  margin: 486px !important;
}

.mx--486 {
  margin-right: 486px !important;
  margin-left: 486px !important;
}

.my--486 {
  margin-top: 486px !important;
  margin-bottom: 486px !important;
}

.mt--486 {
  margin-top: 486px !important;
}

.mr--486 {
  margin-right: 486px !important;
}

.mb--486 {
  margin-bottom: 486px !important;
}

.ml--486 {
  margin-left: 486px !important;
}

.width--486 {
  width: 486px !important;
}

.height--486 {
  height: 486px !important;
}

.gap--486 {
  gap: 486px !important;
}

.scale--486 {
  scale: 4.86 !important;
}

.p--487 {
  padding: 487px !important;
}

.px--487 {
  padding-right: 487px !important;
  padding-left: 487px !important;
}

.py--487 {
  padding-top: 487px !important;
  padding-bottom: 487px !important;
}

.pt--487 {
  padding-top: 487px !important;
}

.pr--487 {
  padding-right: 487px !important;
}

.pb--487 {
  padding-bottom: 487px !important;
}

.pl--487 {
  padding-left: 487px !important;
}

.m--487 {
  margin: 487px !important;
}

.mx--487 {
  margin-right: 487px !important;
  margin-left: 487px !important;
}

.my--487 {
  margin-top: 487px !important;
  margin-bottom: 487px !important;
}

.mt--487 {
  margin-top: 487px !important;
}

.mr--487 {
  margin-right: 487px !important;
}

.mb--487 {
  margin-bottom: 487px !important;
}

.ml--487 {
  margin-left: 487px !important;
}

.width--487 {
  width: 487px !important;
}

.height--487 {
  height: 487px !important;
}

.gap--487 {
  gap: 487px !important;
}

.scale--487 {
  scale: 4.87 !important;
}

.p--488 {
  padding: 488px !important;
}

.px--488 {
  padding-right: 488px !important;
  padding-left: 488px !important;
}

.py--488 {
  padding-top: 488px !important;
  padding-bottom: 488px !important;
}

.pt--488 {
  padding-top: 488px !important;
}

.pr--488 {
  padding-right: 488px !important;
}

.pb--488 {
  padding-bottom: 488px !important;
}

.pl--488 {
  padding-left: 488px !important;
}

.m--488 {
  margin: 488px !important;
}

.mx--488 {
  margin-right: 488px !important;
  margin-left: 488px !important;
}

.my--488 {
  margin-top: 488px !important;
  margin-bottom: 488px !important;
}

.mt--488 {
  margin-top: 488px !important;
}

.mr--488 {
  margin-right: 488px !important;
}

.mb--488 {
  margin-bottom: 488px !important;
}

.ml--488 {
  margin-left: 488px !important;
}

.width--488 {
  width: 488px !important;
}

.height--488 {
  height: 488px !important;
}

.gap--488 {
  gap: 488px !important;
}

.scale--488 {
  scale: 4.88 !important;
}

.p--489 {
  padding: 489px !important;
}

.px--489 {
  padding-right: 489px !important;
  padding-left: 489px !important;
}

.py--489 {
  padding-top: 489px !important;
  padding-bottom: 489px !important;
}

.pt--489 {
  padding-top: 489px !important;
}

.pr--489 {
  padding-right: 489px !important;
}

.pb--489 {
  padding-bottom: 489px !important;
}

.pl--489 {
  padding-left: 489px !important;
}

.m--489 {
  margin: 489px !important;
}

.mx--489 {
  margin-right: 489px !important;
  margin-left: 489px !important;
}

.my--489 {
  margin-top: 489px !important;
  margin-bottom: 489px !important;
}

.mt--489 {
  margin-top: 489px !important;
}

.mr--489 {
  margin-right: 489px !important;
}

.mb--489 {
  margin-bottom: 489px !important;
}

.ml--489 {
  margin-left: 489px !important;
}

.width--489 {
  width: 489px !important;
}

.height--489 {
  height: 489px !important;
}

.gap--489 {
  gap: 489px !important;
}

.scale--489 {
  scale: 4.89 !important;
}

.p--490 {
  padding: 490px !important;
}

.px--490 {
  padding-right: 490px !important;
  padding-left: 490px !important;
}

.py--490 {
  padding-top: 490px !important;
  padding-bottom: 490px !important;
}

.pt--490 {
  padding-top: 490px !important;
}

.pr--490 {
  padding-right: 490px !important;
}

.pb--490 {
  padding-bottom: 490px !important;
}

.pl--490 {
  padding-left: 490px !important;
}

.m--490 {
  margin: 490px !important;
}

.mx--490 {
  margin-right: 490px !important;
  margin-left: 490px !important;
}

.my--490 {
  margin-top: 490px !important;
  margin-bottom: 490px !important;
}

.mt--490 {
  margin-top: 490px !important;
}

.mr--490 {
  margin-right: 490px !important;
}

.mb--490 {
  margin-bottom: 490px !important;
}

.ml--490 {
  margin-left: 490px !important;
}

.width--490 {
  width: 490px !important;
}

.height--490 {
  height: 490px !important;
}

.gap--490 {
  gap: 490px !important;
}

.scale--490 {
  scale: 4.9 !important;
}

.p--491 {
  padding: 491px !important;
}

.px--491 {
  padding-right: 491px !important;
  padding-left: 491px !important;
}

.py--491 {
  padding-top: 491px !important;
  padding-bottom: 491px !important;
}

.pt--491 {
  padding-top: 491px !important;
}

.pr--491 {
  padding-right: 491px !important;
}

.pb--491 {
  padding-bottom: 491px !important;
}

.pl--491 {
  padding-left: 491px !important;
}

.m--491 {
  margin: 491px !important;
}

.mx--491 {
  margin-right: 491px !important;
  margin-left: 491px !important;
}

.my--491 {
  margin-top: 491px !important;
  margin-bottom: 491px !important;
}

.mt--491 {
  margin-top: 491px !important;
}

.mr--491 {
  margin-right: 491px !important;
}

.mb--491 {
  margin-bottom: 491px !important;
}

.ml--491 {
  margin-left: 491px !important;
}

.width--491 {
  width: 491px !important;
}

.height--491 {
  height: 491px !important;
}

.gap--491 {
  gap: 491px !important;
}

.scale--491 {
  scale: 4.91 !important;
}

.p--492 {
  padding: 492px !important;
}

.px--492 {
  padding-right: 492px !important;
  padding-left: 492px !important;
}

.py--492 {
  padding-top: 492px !important;
  padding-bottom: 492px !important;
}

.pt--492 {
  padding-top: 492px !important;
}

.pr--492 {
  padding-right: 492px !important;
}

.pb--492 {
  padding-bottom: 492px !important;
}

.pl--492 {
  padding-left: 492px !important;
}

.m--492 {
  margin: 492px !important;
}

.mx--492 {
  margin-right: 492px !important;
  margin-left: 492px !important;
}

.my--492 {
  margin-top: 492px !important;
  margin-bottom: 492px !important;
}

.mt--492 {
  margin-top: 492px !important;
}

.mr--492 {
  margin-right: 492px !important;
}

.mb--492 {
  margin-bottom: 492px !important;
}

.ml--492 {
  margin-left: 492px !important;
}

.width--492 {
  width: 492px !important;
}

.height--492 {
  height: 492px !important;
}

.gap--492 {
  gap: 492px !important;
}

.scale--492 {
  scale: 4.92 !important;
}

.p--493 {
  padding: 493px !important;
}

.px--493 {
  padding-right: 493px !important;
  padding-left: 493px !important;
}

.py--493 {
  padding-top: 493px !important;
  padding-bottom: 493px !important;
}

.pt--493 {
  padding-top: 493px !important;
}

.pr--493 {
  padding-right: 493px !important;
}

.pb--493 {
  padding-bottom: 493px !important;
}

.pl--493 {
  padding-left: 493px !important;
}

.m--493 {
  margin: 493px !important;
}

.mx--493 {
  margin-right: 493px !important;
  margin-left: 493px !important;
}

.my--493 {
  margin-top: 493px !important;
  margin-bottom: 493px !important;
}

.mt--493 {
  margin-top: 493px !important;
}

.mr--493 {
  margin-right: 493px !important;
}

.mb--493 {
  margin-bottom: 493px !important;
}

.ml--493 {
  margin-left: 493px !important;
}

.width--493 {
  width: 493px !important;
}

.height--493 {
  height: 493px !important;
}

.gap--493 {
  gap: 493px !important;
}

.scale--493 {
  scale: 4.93 !important;
}

.p--494 {
  padding: 494px !important;
}

.px--494 {
  padding-right: 494px !important;
  padding-left: 494px !important;
}

.py--494 {
  padding-top: 494px !important;
  padding-bottom: 494px !important;
}

.pt--494 {
  padding-top: 494px !important;
}

.pr--494 {
  padding-right: 494px !important;
}

.pb--494 {
  padding-bottom: 494px !important;
}

.pl--494 {
  padding-left: 494px !important;
}

.m--494 {
  margin: 494px !important;
}

.mx--494 {
  margin-right: 494px !important;
  margin-left: 494px !important;
}

.my--494 {
  margin-top: 494px !important;
  margin-bottom: 494px !important;
}

.mt--494 {
  margin-top: 494px !important;
}

.mr--494 {
  margin-right: 494px !important;
}

.mb--494 {
  margin-bottom: 494px !important;
}

.ml--494 {
  margin-left: 494px !important;
}

.width--494 {
  width: 494px !important;
}

.height--494 {
  height: 494px !important;
}

.gap--494 {
  gap: 494px !important;
}

.scale--494 {
  scale: 4.94 !important;
}

.p--495 {
  padding: 495px !important;
}

.px--495 {
  padding-right: 495px !important;
  padding-left: 495px !important;
}

.py--495 {
  padding-top: 495px !important;
  padding-bottom: 495px !important;
}

.pt--495 {
  padding-top: 495px !important;
}

.pr--495 {
  padding-right: 495px !important;
}

.pb--495 {
  padding-bottom: 495px !important;
}

.pl--495 {
  padding-left: 495px !important;
}

.m--495 {
  margin: 495px !important;
}

.mx--495 {
  margin-right: 495px !important;
  margin-left: 495px !important;
}

.my--495 {
  margin-top: 495px !important;
  margin-bottom: 495px !important;
}

.mt--495 {
  margin-top: 495px !important;
}

.mr--495 {
  margin-right: 495px !important;
}

.mb--495 {
  margin-bottom: 495px !important;
}

.ml--495 {
  margin-left: 495px !important;
}

.width--495 {
  width: 495px !important;
}

.height--495 {
  height: 495px !important;
}

.gap--495 {
  gap: 495px !important;
}

.scale--495 {
  scale: 4.95 !important;
}

.p--496 {
  padding: 496px !important;
}

.px--496 {
  padding-right: 496px !important;
  padding-left: 496px !important;
}

.py--496 {
  padding-top: 496px !important;
  padding-bottom: 496px !important;
}

.pt--496 {
  padding-top: 496px !important;
}

.pr--496 {
  padding-right: 496px !important;
}

.pb--496 {
  padding-bottom: 496px !important;
}

.pl--496 {
  padding-left: 496px !important;
}

.m--496 {
  margin: 496px !important;
}

.mx--496 {
  margin-right: 496px !important;
  margin-left: 496px !important;
}

.my--496 {
  margin-top: 496px !important;
  margin-bottom: 496px !important;
}

.mt--496 {
  margin-top: 496px !important;
}

.mr--496 {
  margin-right: 496px !important;
}

.mb--496 {
  margin-bottom: 496px !important;
}

.ml--496 {
  margin-left: 496px !important;
}

.width--496 {
  width: 496px !important;
}

.height--496 {
  height: 496px !important;
}

.gap--496 {
  gap: 496px !important;
}

.scale--496 {
  scale: 4.96 !important;
}

.p--497 {
  padding: 497px !important;
}

.px--497 {
  padding-right: 497px !important;
  padding-left: 497px !important;
}

.py--497 {
  padding-top: 497px !important;
  padding-bottom: 497px !important;
}

.pt--497 {
  padding-top: 497px !important;
}

.pr--497 {
  padding-right: 497px !important;
}

.pb--497 {
  padding-bottom: 497px !important;
}

.pl--497 {
  padding-left: 497px !important;
}

.m--497 {
  margin: 497px !important;
}

.mx--497 {
  margin-right: 497px !important;
  margin-left: 497px !important;
}

.my--497 {
  margin-top: 497px !important;
  margin-bottom: 497px !important;
}

.mt--497 {
  margin-top: 497px !important;
}

.mr--497 {
  margin-right: 497px !important;
}

.mb--497 {
  margin-bottom: 497px !important;
}

.ml--497 {
  margin-left: 497px !important;
}

.width--497 {
  width: 497px !important;
}

.height--497 {
  height: 497px !important;
}

.gap--497 {
  gap: 497px !important;
}

.scale--497 {
  scale: 4.97 !important;
}

.p--498 {
  padding: 498px !important;
}

.px--498 {
  padding-right: 498px !important;
  padding-left: 498px !important;
}

.py--498 {
  padding-top: 498px !important;
  padding-bottom: 498px !important;
}

.pt--498 {
  padding-top: 498px !important;
}

.pr--498 {
  padding-right: 498px !important;
}

.pb--498 {
  padding-bottom: 498px !important;
}

.pl--498 {
  padding-left: 498px !important;
}

.m--498 {
  margin: 498px !important;
}

.mx--498 {
  margin-right: 498px !important;
  margin-left: 498px !important;
}

.my--498 {
  margin-top: 498px !important;
  margin-bottom: 498px !important;
}

.mt--498 {
  margin-top: 498px !important;
}

.mr--498 {
  margin-right: 498px !important;
}

.mb--498 {
  margin-bottom: 498px !important;
}

.ml--498 {
  margin-left: 498px !important;
}

.width--498 {
  width: 498px !important;
}

.height--498 {
  height: 498px !important;
}

.gap--498 {
  gap: 498px !important;
}

.scale--498 {
  scale: 4.98 !important;
}

.p--499 {
  padding: 499px !important;
}

.px--499 {
  padding-right: 499px !important;
  padding-left: 499px !important;
}

.py--499 {
  padding-top: 499px !important;
  padding-bottom: 499px !important;
}

.pt--499 {
  padding-top: 499px !important;
}

.pr--499 {
  padding-right: 499px !important;
}

.pb--499 {
  padding-bottom: 499px !important;
}

.pl--499 {
  padding-left: 499px !important;
}

.m--499 {
  margin: 499px !important;
}

.mx--499 {
  margin-right: 499px !important;
  margin-left: 499px !important;
}

.my--499 {
  margin-top: 499px !important;
  margin-bottom: 499px !important;
}

.mt--499 {
  margin-top: 499px !important;
}

.mr--499 {
  margin-right: 499px !important;
}

.mb--499 {
  margin-bottom: 499px !important;
}

.ml--499 {
  margin-left: 499px !important;
}

.width--499 {
  width: 499px !important;
}

.height--499 {
  height: 499px !important;
}

.gap--499 {
  gap: 499px !important;
}

.scale--499 {
  scale: 4.99 !important;
}

.p--500 {
  padding: 500px !important;
}

.px--500 {
  padding-right: 500px !important;
  padding-left: 500px !important;
}

.py--500 {
  padding-top: 500px !important;
  padding-bottom: 500px !important;
}

.pt--500 {
  padding-top: 500px !important;
}

.pr--500 {
  padding-right: 500px !important;
}

.pb--500 {
  padding-bottom: 500px !important;
}

.pl--500 {
  padding-left: 500px !important;
}

.m--500 {
  margin: 500px !important;
}

.mx--500 {
  margin-right: 500px !important;
  margin-left: 500px !important;
}

.my--500 {
  margin-top: 500px !important;
  margin-bottom: 500px !important;
}

.mt--500 {
  margin-top: 500px !important;
}

.mr--500 {
  margin-right: 500px !important;
}

.mb--500 {
  margin-bottom: 500px !important;
}

.ml--500 {
  margin-left: 500px !important;
}

.width--500 {
  width: 500px !important;
}

.height--500 {
  height: 500px !important;
}

.gap--500 {
  gap: 500px !important;
}

.scale--500 {
  scale: 5 !important;
}

.p--501 {
  padding: 501px !important;
}

.px--501 {
  padding-right: 501px !important;
  padding-left: 501px !important;
}

.py--501 {
  padding-top: 501px !important;
  padding-bottom: 501px !important;
}

.pt--501 {
  padding-top: 501px !important;
}

.pr--501 {
  padding-right: 501px !important;
}

.pb--501 {
  padding-bottom: 501px !important;
}

.pl--501 {
  padding-left: 501px !important;
}

.m--501 {
  margin: 501px !important;
}

.mx--501 {
  margin-right: 501px !important;
  margin-left: 501px !important;
}

.my--501 {
  margin-top: 501px !important;
  margin-bottom: 501px !important;
}

.mt--501 {
  margin-top: 501px !important;
}

.mr--501 {
  margin-right: 501px !important;
}

.mb--501 {
  margin-bottom: 501px !important;
}

.ml--501 {
  margin-left: 501px !important;
}

.width--501 {
  width: 501px !important;
}

.height--501 {
  height: 501px !important;
}

.gap--501 {
  gap: 501px !important;
}

.scale--501 {
  scale: 5.01 !important;
}

.p--502 {
  padding: 502px !important;
}

.px--502 {
  padding-right: 502px !important;
  padding-left: 502px !important;
}

.py--502 {
  padding-top: 502px !important;
  padding-bottom: 502px !important;
}

.pt--502 {
  padding-top: 502px !important;
}

.pr--502 {
  padding-right: 502px !important;
}

.pb--502 {
  padding-bottom: 502px !important;
}

.pl--502 {
  padding-left: 502px !important;
}

.m--502 {
  margin: 502px !important;
}

.mx--502 {
  margin-right: 502px !important;
  margin-left: 502px !important;
}

.my--502 {
  margin-top: 502px !important;
  margin-bottom: 502px !important;
}

.mt--502 {
  margin-top: 502px !important;
}

.mr--502 {
  margin-right: 502px !important;
}

.mb--502 {
  margin-bottom: 502px !important;
}

.ml--502 {
  margin-left: 502px !important;
}

.width--502 {
  width: 502px !important;
}

.height--502 {
  height: 502px !important;
}

.gap--502 {
  gap: 502px !important;
}

.scale--502 {
  scale: 5.02 !important;
}

.p--503 {
  padding: 503px !important;
}

.px--503 {
  padding-right: 503px !important;
  padding-left: 503px !important;
}

.py--503 {
  padding-top: 503px !important;
  padding-bottom: 503px !important;
}

.pt--503 {
  padding-top: 503px !important;
}

.pr--503 {
  padding-right: 503px !important;
}

.pb--503 {
  padding-bottom: 503px !important;
}

.pl--503 {
  padding-left: 503px !important;
}

.m--503 {
  margin: 503px !important;
}

.mx--503 {
  margin-right: 503px !important;
  margin-left: 503px !important;
}

.my--503 {
  margin-top: 503px !important;
  margin-bottom: 503px !important;
}

.mt--503 {
  margin-top: 503px !important;
}

.mr--503 {
  margin-right: 503px !important;
}

.mb--503 {
  margin-bottom: 503px !important;
}

.ml--503 {
  margin-left: 503px !important;
}

.width--503 {
  width: 503px !important;
}

.height--503 {
  height: 503px !important;
}

.gap--503 {
  gap: 503px !important;
}

.scale--503 {
  scale: 5.03 !important;
}

.p--504 {
  padding: 504px !important;
}

.px--504 {
  padding-right: 504px !important;
  padding-left: 504px !important;
}

.py--504 {
  padding-top: 504px !important;
  padding-bottom: 504px !important;
}

.pt--504 {
  padding-top: 504px !important;
}

.pr--504 {
  padding-right: 504px !important;
}

.pb--504 {
  padding-bottom: 504px !important;
}

.pl--504 {
  padding-left: 504px !important;
}

.m--504 {
  margin: 504px !important;
}

.mx--504 {
  margin-right: 504px !important;
  margin-left: 504px !important;
}

.my--504 {
  margin-top: 504px !important;
  margin-bottom: 504px !important;
}

.mt--504 {
  margin-top: 504px !important;
}

.mr--504 {
  margin-right: 504px !important;
}

.mb--504 {
  margin-bottom: 504px !important;
}

.ml--504 {
  margin-left: 504px !important;
}

.width--504 {
  width: 504px !important;
}

.height--504 {
  height: 504px !important;
}

.gap--504 {
  gap: 504px !important;
}

.scale--504 {
  scale: 5.04 !important;
}

.p--505 {
  padding: 505px !important;
}

.px--505 {
  padding-right: 505px !important;
  padding-left: 505px !important;
}

.py--505 {
  padding-top: 505px !important;
  padding-bottom: 505px !important;
}

.pt--505 {
  padding-top: 505px !important;
}

.pr--505 {
  padding-right: 505px !important;
}

.pb--505 {
  padding-bottom: 505px !important;
}

.pl--505 {
  padding-left: 505px !important;
}

.m--505 {
  margin: 505px !important;
}

.mx--505 {
  margin-right: 505px !important;
  margin-left: 505px !important;
}

.my--505 {
  margin-top: 505px !important;
  margin-bottom: 505px !important;
}

.mt--505 {
  margin-top: 505px !important;
}

.mr--505 {
  margin-right: 505px !important;
}

.mb--505 {
  margin-bottom: 505px !important;
}

.ml--505 {
  margin-left: 505px !important;
}

.width--505 {
  width: 505px !important;
}

.height--505 {
  height: 505px !important;
}

.gap--505 {
  gap: 505px !important;
}

.scale--505 {
  scale: 5.05 !important;
}

.p--506 {
  padding: 506px !important;
}

.px--506 {
  padding-right: 506px !important;
  padding-left: 506px !important;
}

.py--506 {
  padding-top: 506px !important;
  padding-bottom: 506px !important;
}

.pt--506 {
  padding-top: 506px !important;
}

.pr--506 {
  padding-right: 506px !important;
}

.pb--506 {
  padding-bottom: 506px !important;
}

.pl--506 {
  padding-left: 506px !important;
}

.m--506 {
  margin: 506px !important;
}

.mx--506 {
  margin-right: 506px !important;
  margin-left: 506px !important;
}

.my--506 {
  margin-top: 506px !important;
  margin-bottom: 506px !important;
}

.mt--506 {
  margin-top: 506px !important;
}

.mr--506 {
  margin-right: 506px !important;
}

.mb--506 {
  margin-bottom: 506px !important;
}

.ml--506 {
  margin-left: 506px !important;
}

.width--506 {
  width: 506px !important;
}

.height--506 {
  height: 506px !important;
}

.gap--506 {
  gap: 506px !important;
}

.scale--506 {
  scale: 5.06 !important;
}

.p--507 {
  padding: 507px !important;
}

.px--507 {
  padding-right: 507px !important;
  padding-left: 507px !important;
}

.py--507 {
  padding-top: 507px !important;
  padding-bottom: 507px !important;
}

.pt--507 {
  padding-top: 507px !important;
}

.pr--507 {
  padding-right: 507px !important;
}

.pb--507 {
  padding-bottom: 507px !important;
}

.pl--507 {
  padding-left: 507px !important;
}

.m--507 {
  margin: 507px !important;
}

.mx--507 {
  margin-right: 507px !important;
  margin-left: 507px !important;
}

.my--507 {
  margin-top: 507px !important;
  margin-bottom: 507px !important;
}

.mt--507 {
  margin-top: 507px !important;
}

.mr--507 {
  margin-right: 507px !important;
}

.mb--507 {
  margin-bottom: 507px !important;
}

.ml--507 {
  margin-left: 507px !important;
}

.width--507 {
  width: 507px !important;
}

.height--507 {
  height: 507px !important;
}

.gap--507 {
  gap: 507px !important;
}

.scale--507 {
  scale: 5.07 !important;
}

.p--508 {
  padding: 508px !important;
}

.px--508 {
  padding-right: 508px !important;
  padding-left: 508px !important;
}

.py--508 {
  padding-top: 508px !important;
  padding-bottom: 508px !important;
}

.pt--508 {
  padding-top: 508px !important;
}

.pr--508 {
  padding-right: 508px !important;
}

.pb--508 {
  padding-bottom: 508px !important;
}

.pl--508 {
  padding-left: 508px !important;
}

.m--508 {
  margin: 508px !important;
}

.mx--508 {
  margin-right: 508px !important;
  margin-left: 508px !important;
}

.my--508 {
  margin-top: 508px !important;
  margin-bottom: 508px !important;
}

.mt--508 {
  margin-top: 508px !important;
}

.mr--508 {
  margin-right: 508px !important;
}

.mb--508 {
  margin-bottom: 508px !important;
}

.ml--508 {
  margin-left: 508px !important;
}

.width--508 {
  width: 508px !important;
}

.height--508 {
  height: 508px !important;
}

.gap--508 {
  gap: 508px !important;
}

.scale--508 {
  scale: 5.08 !important;
}

.p--509 {
  padding: 509px !important;
}

.px--509 {
  padding-right: 509px !important;
  padding-left: 509px !important;
}

.py--509 {
  padding-top: 509px !important;
  padding-bottom: 509px !important;
}

.pt--509 {
  padding-top: 509px !important;
}

.pr--509 {
  padding-right: 509px !important;
}

.pb--509 {
  padding-bottom: 509px !important;
}

.pl--509 {
  padding-left: 509px !important;
}

.m--509 {
  margin: 509px !important;
}

.mx--509 {
  margin-right: 509px !important;
  margin-left: 509px !important;
}

.my--509 {
  margin-top: 509px !important;
  margin-bottom: 509px !important;
}

.mt--509 {
  margin-top: 509px !important;
}

.mr--509 {
  margin-right: 509px !important;
}

.mb--509 {
  margin-bottom: 509px !important;
}

.ml--509 {
  margin-left: 509px !important;
}

.width--509 {
  width: 509px !important;
}

.height--509 {
  height: 509px !important;
}

.gap--509 {
  gap: 509px !important;
}

.scale--509 {
  scale: 5.09 !important;
}

.p--510 {
  padding: 510px !important;
}

.px--510 {
  padding-right: 510px !important;
  padding-left: 510px !important;
}

.py--510 {
  padding-top: 510px !important;
  padding-bottom: 510px !important;
}

.pt--510 {
  padding-top: 510px !important;
}

.pr--510 {
  padding-right: 510px !important;
}

.pb--510 {
  padding-bottom: 510px !important;
}

.pl--510 {
  padding-left: 510px !important;
}

.m--510 {
  margin: 510px !important;
}

.mx--510 {
  margin-right: 510px !important;
  margin-left: 510px !important;
}

.my--510 {
  margin-top: 510px !important;
  margin-bottom: 510px !important;
}

.mt--510 {
  margin-top: 510px !important;
}

.mr--510 {
  margin-right: 510px !important;
}

.mb--510 {
  margin-bottom: 510px !important;
}

.ml--510 {
  margin-left: 510px !important;
}

.width--510 {
  width: 510px !important;
}

.height--510 {
  height: 510px !important;
}

.gap--510 {
  gap: 510px !important;
}

.scale--510 {
  scale: 5.1 !important;
}

.p--511 {
  padding: 511px !important;
}

.px--511 {
  padding-right: 511px !important;
  padding-left: 511px !important;
}

.py--511 {
  padding-top: 511px !important;
  padding-bottom: 511px !important;
}

.pt--511 {
  padding-top: 511px !important;
}

.pr--511 {
  padding-right: 511px !important;
}

.pb--511 {
  padding-bottom: 511px !important;
}

.pl--511 {
  padding-left: 511px !important;
}

.m--511 {
  margin: 511px !important;
}

.mx--511 {
  margin-right: 511px !important;
  margin-left: 511px !important;
}

.my--511 {
  margin-top: 511px !important;
  margin-bottom: 511px !important;
}

.mt--511 {
  margin-top: 511px !important;
}

.mr--511 {
  margin-right: 511px !important;
}

.mb--511 {
  margin-bottom: 511px !important;
}

.ml--511 {
  margin-left: 511px !important;
}

.width--511 {
  width: 511px !important;
}

.height--511 {
  height: 511px !important;
}

.gap--511 {
  gap: 511px !important;
}

.scale--511 {
  scale: 5.11 !important;
}

.p--512 {
  padding: 512px !important;
}

.px--512 {
  padding-right: 512px !important;
  padding-left: 512px !important;
}

.py--512 {
  padding-top: 512px !important;
  padding-bottom: 512px !important;
}

.pt--512 {
  padding-top: 512px !important;
}

.pr--512 {
  padding-right: 512px !important;
}

.pb--512 {
  padding-bottom: 512px !important;
}

.pl--512 {
  padding-left: 512px !important;
}

.m--512 {
  margin: 512px !important;
}

.mx--512 {
  margin-right: 512px !important;
  margin-left: 512px !important;
}

.my--512 {
  margin-top: 512px !important;
  margin-bottom: 512px !important;
}

.mt--512 {
  margin-top: 512px !important;
}

.mr--512 {
  margin-right: 512px !important;
}

.mb--512 {
  margin-bottom: 512px !important;
}

.ml--512 {
  margin-left: 512px !important;
}

.width--512 {
  width: 512px !important;
}

.height--512 {
  height: 512px !important;
}

.gap--512 {
  gap: 512px !important;
}

.scale--512 {
  scale: 5.12 !important;
}

/*
* Headline 2 = h2
* Headline 3 = h3
* Paragraph = p (also used for input & textarea)
* Subtitle = h4
* Small Text = small
* Small Tooltips = caption
* Footprints = caption
*/
h1 {
  font-family: "Open Sans";
  font-size: 34px;
  font-weight: 500;
  margin-bottom: 0;
  color: #1B1B1B;
}

h2 {
  font-family: "Open Sans";
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 0;
  color: #1B1B1B;
}

h3 {
  font-family: "Open Sans";
  font-size: 20px;
  letter-spacing: 0.15px;
  line-height: 28px;
  font-weight: 500;
  margin-bottom: 0;
  color: #1B1B1B;
}

h4 {
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.1px;
  margin-bottom: 0;
  line-height: 20px;
  color: #1B1B1B;
}

input, textarea, p, *[contenteditable] {
  font-family: "Open Sans";
  font-size: 16px;
  line-height: 24px !important;
  margin: 0 !important;
  letter-spacing: 0.44px;
  margin-bottom: 0;
  color: #1B1B1B;
  outline: none;
}

*[contenteditable] {
  padding: 2px;
}

input, textarea {
  padding: 8px;
  margin-bottom: 0;
}

input {
  height: 24px !important;
}

small, .mat-error, .mat-hint {
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0.4px;
  margin-bottom: 0;
  line-height: 16px;
}

caption {
  font-family: "Open Sans";
  padding: 0;
  font-size: 10px;
  letter-spacing: 0.15px;
  margin-bottom: 0;
}

.mat-error, .mat-hint {
  font-weight: 500;
}

.mat-error {
  font-weight: 500;
  margin-top: 4px;
}

.search-field:not(mat-form-field) {
  border: 1px solid transparent;
  color: transparent;
}
.search-field:not(mat-form-field).filled {
  border: 1px solid #C4C8CC;
}
.search-field:not(mat-form-field):hover {
  border: 1px solid #1B1B1B;
}
.search-field:not(mat-form-field).focused {
  border: 1px solid #003FAE;
}

::-webkit-input-placeholder {
  font-family: "Open Sans";
  color: #A3B4CA !important;
  font-size: 14px;
  font-weight: 400;
}

::-moz-placeholder {
  color: #A3B4CA !important;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
}

::-ms-placeholder {
  color: #A3B4CA !important;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
}

::placeholder {
  color: #A3B4CA !important;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
}

body {
  font-family: "Open Sans";
}

/* set the overriding variables */
.container {
  padding-right: 16px;
  padding-left: 16px;
}

.not-touch-screen a:hover {
  text-decoration: underline !important;
}
.not-touch-screen .simple-text-button:hover {
  color: #00238E !important;
  text-decoration: underline !important;
}
.not-touch-screen .se-icon-button,
.not-touch-screen .se-button {
  border-radius: 8px;
}
.not-touch-screen .se-icon-button:not(.no-hover):hover,
.not-touch-screen .se-button:not(.no-hover):hover {
  background-color: #f3f4f5;
  color: #1B1B1B;
}
.not-touch-screen .se-icon-button:not(.no-hover):hover svg,
.not-touch-screen .se-button:not(.no-hover):hover svg {
  fill: #1B1B1B;
}
.not-touch-screen .se-icon-button:not(.no-hover):hover svg path,
.not-touch-screen .se-button:not(.no-hover):hover svg path {
  fill: #1B1B1B;
}

.se-icon-button {
  background-color: #FAFBFC;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
}
.se-icon-button.no-bg {
  background-color: transparent;
}
.se-icon-button[src=close] {
  border-radius: 5px;
}
.se-icon-button[src=close_md] {
  border-radius: 3px;
}

.se-icon-button,
.se-button {
  cursor: pointer;
  color: #65707C;
}
.se-icon-button svg,
.se-button svg {
  fill: #65707C;
}
.se-icon-button svg path,
.se-button svg path {
  fill: #65707C;
}

.se-button {
  display: flex;
  align-items: center;
  height: 36px;
  gap: 8px;
  padding: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
.se-button.aligned-by-content {
  margin: -8px;
}
.se-button.small {
  font-size: 14px;
}

a {
  text-decoration: none;
}
@media (hover: none) {
  a {
    text-decoration: underline;
  }
}

.mat-ripple-element {
  background-color: rgba(54, 70, 55, 0.22) !important;
}

.simple-text-button {
  cursor: pointer;
  outline: none !important;
  padding-bottom: 8px !important;
  padding-top: 16px !important;
  margin-bottom: -8px !important;
  margin-top: -16px !important;
  font-weight: 600;
  color: #1851C4 !important;
  position: relative;
  z-index: 1;
  text-decoration: none !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.simple-text-button.bold {
  font-weight: 700;
}
.simple-text-button:active {
  color: #003FAE !important;
}
.simple-text-button:active:hover {
  color: #003FAE !important;
}

.no-outline {
  outline: none !important;
}

.mat-buttton,
.mat-flat-button,
.mat-stroked-button {
  min-height: 40px;
  height: 40px !important;
  max-height: 44px;
  font-family: "Open Sans" !important;
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px !important;
}
.mat-buttton .mat-button-focus-overlay,
.mat-flat-button .mat-button-focus-overlay,
.mat-stroked-button .mat-button-focus-overlay {
  opacity: 0 !important;
}

@media (min-width: 576px) {
  .mat-buttton,
  .mat-flat-button,
  .mat-stroked-button {
    min-height: 44px;
    height: 44px !important;
    max-height: 44px;
  }
}
.not-touch-screen .mat-flat-button.mat-primary:hover:not(:disabled) {
  background-color: #00238E;
}

.mat-flat-button.mat-primary:disabled {
  opacity: 0.5;
  background-color: #1851C4;
  color: white;
}

.not-touch-screen .mat-flat-button.mat-warn:hover:not(:disabled) {
  background-color: #BF3329;
}

.mat-flat-button.mat-warn:disabled {
  opacity: 0.5;
  background-color: #DA4133;
  color: white;
}

.mat-stroked-button {
  border-color: #65707C !important;
  color: #1B1B1B;
}

.not-touch-screen .mat-stroked-button:hover {
  border-color: #00238E !important;
  color: #00238E;
}

.mat-stroked-button:active {
  border-color: #65707C !important;
  color: #1B1B1B;
}

.mat-stroked-button.large {
  border-color: #003FAE !important;
  color: #003FAE;
}

.not-touch-screen .mat-stroked-button.large:hover {
  background-color: #003FAE;
  color: white;
}

.mat-stroked-button.large:active {
  border-color: #0035A2 !important;
  color: #0035A2;
}

.date-filter {
  cursor: pointer;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px 8px 8px;
  border-radius: 8px;
  border: 1px solid #65707C;
}
.date-filter.disabled {
  cursor: default;
  opacity: 0.25;
}
.date-filter.open {
  border-color: #003FAE !important;
  outline: 1px solid #003fae !important;
}
.date-filter.set {
  padding: 8px 8px 8px 8px;
}
.date-filter.set:not(:hover):not(.open) {
  border: 1px solid #A3B4CA;
}
.date-filter .calendar {
  background-color: #65707C !important;
  width: 18px;
  height: 18px;
}
.date-filter h4 {
  margin: 0 12px;
  color: #65707C;
}
.date-filter.set h4 {
  color: #1B1B1B;
}
.date-filter.set .calendar {
  background-color: #1B1B1B !important;
}

.date-filter:hover {
  border: 1px solid #1B1B1B;
}
.date-filter:hover h4 {
  color: #1B1B1B;
}

.date-picker {
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  border: none;
  visibility: hidden;
}

.selection-tile {
  background-color: transparent;
  color: #65707C;
  border: 1px solid #C4C8CC;
  color: #65707C;
  padding: 6px 12px;
}
.selection-tile h4 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.selection-tile:hover:not(.selected):not(.disabled) {
  background-color: #F3F4F5;
  color: #1B1B1B;
  border: 1px solid transparent;
}
.selection-tile.selected {
  background-color: white;
  color: #1B1B1B;
  border: 1px solid #1851C4;
  padding: 6px 8px;
}
.selection-tile.selected:hover {
  border: 1px solid #00238E;
}
.selection-tile.suspended {
  border-color: #C8372D;
}
.selection-tile.suspended:hover {
  border-color: #AB281C;
}

/* set the overriding variables */
.container {
  padding-right: 16px;
  padding-left: 16px;
}

.mat-form-field-appearance-outline .mat-form-field-outline-start {
  border-radius: 8px 0 0 8px !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-radius: 0 8px 8px 0 !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-start, .mat-form-field-appearance-outline .mat-form-field-outline-end {
  min-width: 8px !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-start {
  border-radius: 8px 0 0 8px !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-radius: 0 8px 8px 0 !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-start, .mat-form-field-appearance-outline .mat-form-field-outline-end {
  min-width: 8px !important;
}

.mat-tooltip {
  color: #1B1B1B !important;
  font-weight: 600;
  background-color: white;
  max-width: 300px !important;
  margin: 8px 14px !important;
  padding: 8px 14px !important;
  text-align: center;
  border-radius: 5px;
  white-space: pre-line;
  font-family: "Open Sans";
  font-size: 12px;
  box-shadow: 0 0.8500000238px 3px 0 rgba(0, 0, 0, 0.1882352941);
}

.mat-tooltip-hide {
  display: none !important;
}

.mat-menu-panel {
  max-width: unset !important;
  min-height: unset !important;
}

.small-tooltip {
  padding: 8px 12px !important;
}

.mat-tooltip-left-text {
  text-align: start;
}

.flex-fill {
  flex-basis: 0 !important;
}

.mat-snack-bar-handset {
  width: unset !important;
}

.mat-snack-bar-container {
  min-width: unset !important;
  min-height: unset !important;
  max-width: unset !important;
  max-height: unset !important;
  border-radius: 5px;
  padding: 0 !important;
  background-color: transparent;
}

@media (max-width: 575.98px) {
  .not-mobile {
    display: none !important;
  }
}
@media (min-width: 576px) {
  .only-mobile {
    display: none !important;
  }
}
@media (max-width: 767.98px) {
  .not-mobile-sm {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .only-mobile-sm {
    display: none !important;
  }
}
* {
  scrollbar-width: none;
  scrollbar-height: none;
  scrollbar-color: #A3B4CA transparent;
  min-height: 0;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background: #A3B4CA;
  border-radius: 5px;
  border: 2px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background: #65707C;
  border: 2px solid transparent;
  background-clip: content-box;
}

.primary-scrollbar ::-webkit-scrollbar-thumb {
  background: #003FAE;
  border-radius: 5px;
  border: 2px solid transparent;
}
.primary-scrollbar ::-webkit-scrollbar-thumb:hover {
  background: #115cc7;
}

.overlay-scrollbar {
  overflow-y: scroll !important;
}

.isIOS .overlay-scrollbar {
  padding-left: 0;
}

.page {
  height: 100%;
  background-color: white;
  overflow-x: hidden;
}

.mirrored {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.hidden {
  height: 0 !important;
  width: 0 !important;
  display: block;
  opacity: 0;
}

html, body {
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  position: relative;
}
@keyframes play90 {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: -28920px 0px;
  }
}
body .shapeshifter {
  animation-duration: 4000ms;
  animation-timing-function: steps(120);
  animation-iteration-count: infinite;
  width: 241px;
  height: 228px;
  background-repeat: no-repeat;
}
body .shapeshifter.play {
  animation-name: play90;
}
body .preloader {
  position: absolute;
  z-index: 1;
}
body .app-root {
  position: absolute;
  z-index: 2;
}
body .pac-container {
  z-index: 10000 !important;
}