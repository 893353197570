/*
* Headline 2 = h2
* Headline 3 = h3
* Paragraph = p (also used for input & textarea)
* Subtitle = h4
* Small Text = small
* Small Tooltips = caption
* Footprints = caption
*/

h1 {
    font-family: 'Open Sans';
    font-size: 34px;
    font-weight: 500;
    margin-bottom: 0;
    color: $black;
}
  
h2 {
    font-family: 'Open Sans';
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 0;
    color: $black;
}
  
h3 {
    font-family: 'Open Sans';
    font-size: 20px;
    letter-spacing: 0.15px;
    line-height: 28px;
    font-weight: 500;
    margin-bottom: 0;
    color: $black;
}
  
  
h4 {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.1px;
    margin-bottom: 0;
    line-height: 20px;
    color: $black;
}
  
input, textarea, p, *[contenteditable] {
    font-family: 'Open Sans';
    font-size: 16px;
    line-height: 24px !important;
    margin: 0 !important;
    letter-spacing: 0.44px;
    margin-bottom: 0;
    color: $black;
    outline: none;
}

*[contenteditable] {
    padding: 2px;
    //caret-color: $dark-blue-500 !important;
}

input, textarea {
    padding: 8px;
    margin-bottom: 0;
}
  
input {
    height: 24px !important;
}
  
small, .mat-error, .mat-hint {
    font-family: 'Open Sans';
    font-size: 12px;
    letter-spacing: 0.4px;
    margin-bottom: 0;
    line-height: 16px;
}
  
caption {
    font-family: 'Open Sans';
    padding: 0;
    font-size: 10px;
    letter-spacing: 0.15px;
    margin-bottom: 0;
}

.mat-error, .mat-hint {
    font-weight: 500;
}
  
.mat-error {
    font-weight: 500;
    margin-top: 4px;
}

.search-field:not(mat-form-field) {
    border: 1px solid transparent;
    color: transparent;

    &.filled {
        border: 1px solid $grey-400;
    }

    &:hover {
        border: 1px solid $black;
    }

    &.focused {
        border: 1px solid $primary;
    }
}

::-webkit-input-placeholder {
    font-family: 'Open Sans';
    color: $grey !important;
    font-size: 14px;
    font-weight: 400;
}
  
::-moz-placeholder {
    color: $grey !important;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 400;
}
  
::-ms-placeholder {
    color: $grey !important;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 400;
}
  
::placeholder {
    color: $grey !important;
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 400;
}

body {
  font-family: "Open Sans";
}