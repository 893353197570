/* set the overriding variables */

.container {
  padding-right: 16px;
  padding-left: 16px;
}

$grid-breakpoints: (
        xxs: 0,
        xs: 320px,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px,
        xxxl: 1600px,
        xxxxl: 1800px
);
$container-max-widths: (
        xxs: 0,
        xs: 320px,
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1320px,
        xxxl: 1500px,
        xxxxl: 1640px
);

$primary: #003FAE;
$primaryDark: #115cc7;

$secondary: #ffcc00;
$background: #353535;

$danger: #DA4133;
$dangerLight: #FEF2F1;

$black: #1B1B1B;
$grey: #A3B4CA;
$dark: #65707C;
$medium: #B8C1CC;
$light: #FAFBFC;

$grey-100: #FAFBFC;
$grey-200: #F3F4F5;
$grey-300: #E2E2E2;
$grey-400: #C4C8CC;
$grey-500: #BCBBC1;
$grey-600: #8E8E93;
$grey-700: #65707C;
$dark-grey: #5E6668;

$brown-900: #66500E;

$green-50: #E7F9E2;
$green-300: #00B058;
$green-500: #1F8505;
$green-700: #1B7604;
$green-900: #215A45;
$blue-50: #E3F3FF;
$blue-100: #BAE0FF;
$blue-700: #009AFF;
$blue-800: #1166E9;

$dark-blue-50: #E7EAF8;
$dark-blue-100: #C2CAED;
$dark-blue-200: #99A8E1;
$dark-blue-300: #6E86D6;
$dark-blue-400: #4A6BCD;
$dark-blue-500: #1851C4;
$dark-blue-600: #0E49BA;
$dark-blue-700: #003FAE;
$dark-blue-800: #0035A2;
$dark-blue-900: #00238E;

$primary-palette: (
        50: $dark-blue-50,
        100: $dark-blue-100,
        200: $dark-blue-200,
        300: $dark-blue-300,
        400: $dark-blue-400,
        500: $dark-blue-500,
        600: $dark-blue-600,
        700: $dark-blue-700,
        800: $dark-blue-800,
        900: $dark-blue-900,
        contrast: (
                50: $black,
                100: $black,
                200: $black,
                300: $black,
                400: $black,
                500: $black,
                600: $black,
                700: white,
                800: white,
                900: white,
        )
);

$yellow-50: #FFF8E1;
$yellow-100: #FFEBB2;
$yellow-200: #FFDF81;
$yellow-300: #FFD44D;
$yellow-400: #FFC825;
$yellow-500: #FFBF00;
$yellow-600: #FFB100;
$yellow-700: #FF9E00;
$yellow-800: #FF8D00;
$yellow-900: #FF6D00;

$secondary-palette: (
        50: $yellow-50,
        100: $yellow-100,
        200: $yellow-200,
        300: $yellow-300,
        400: $yellow-400,
        500: $yellow-500,
        600: $yellow-600,
        700: $yellow-700,
        800: $yellow-800,
        900: $yellow-900,
        contrast: (
                50: $black,
                100: $black,
                200: $black,
                300: $black,
                400: $black,
                500: $black,
                600: $black,
                700: $black,
                800: $black,
                900: $black,
        )
);


$red-50: #FFF5F5;
$red-100: #FBCDD0;
$red-200: #E99A98;
$red-300: #DD7570;
$red-400: #E5574D;
$red-500: #E84A34;
$red-600: #DA4133;
$red-700: #C8372D;
$red-800: #BF3329;
$red-900: #AB281C;

$danger-palette: (
        50: $red-50,
        100: $red-100,
        200: $red-200,
        300: $red-300,
        400: $red-400,
        500: $red-500,
        600: $red-600,
        700: $red-700,
        800: $red-800,
        900: $red-900,
        contrast: (
                50: $black,
                100: $black,
                200: $black,
                300: $black,
                400: $black,
                500: $black,
                600: white,
                700: white,
                800: white,
                900: white,
        )
);
