@import "../../assets/styles/vars";

.not-touch-screen {
  a:hover {
    text-decoration: underline !important;
  }

  .simple-text-button:hover {
    color: $dark-blue-900 !important;
    text-decoration: underline !important;
  }

  .se-icon-button,
  .se-button {
    border-radius: 8px;

    &:not(.no-hover) {
      &:hover {
        background-color: #f3f4f5;
        color: $black;

        svg {
          fill: $black;

          path {
            fill: $black;
          }
        }
      }
    }
  }
}

.se-icon-button {
  background-color: $light;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;

  &.no-bg {
    background-color: transparent;
  }

  &[src="close"] {
    border-radius: 5px;
  }

  &[src="close_md"] {
    border-radius: 3px;
  }

}


.se-icon-button,
.se-button {
  cursor: pointer;
  color: $dark;

  svg {
    fill: $dark;

    path {
      fill: $dark;
    }
  }
}

.se-button {
  display: flex;
  align-items: center;
  height: 36px;
  gap: 8px;
  padding: 8px;

  &.aligned-by-content {
    margin: -8px;
  }

  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  &.small {
    font-size: 14px;
  }
}

a {
  text-decoration: none;
  @media (hover: none) {
    text-decoration: underline;
  }
}

.mat-ripple-element {
  background-color: rgba(54, 70, 55, 0.22) !important;
}

.simple-text-button {
  cursor: pointer;
  outline: none !important;
  padding-bottom: 8px !important;
  padding-top: 16px !important;
  margin-bottom: -8px !important;
  margin-top: -16px !important;
  font-weight: 600;
  color: $dark-blue-500 !important;
  position: relative;
  z-index: 1;
  text-decoration: none !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.bold {
    font-weight: 700;
  }

  &:active {
    color: $dark-blue-700 !important;

    &:hover {
      color: $dark-blue-700 !important;
    }
  }
}

.no-outline {
  outline: none !important;
}

.mat-buttton,
.mat-flat-button,
.mat-stroked-button {
  min-height: 40px;
  height: 40px !important;
  max-height: 44px;
  font-family: "Open Sans" !important;
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px !important;

  .mat-button-focus-overlay {
    opacity: 0 !important;
  }
}

@include media-breakpoint-up(sm) {
  .mat-buttton,
  .mat-flat-button,
  .mat-stroked-button {
    min-height: 44px;
    height: 44px !important;
    max-height: 44px;
  }
}

// Flat
.not-touch-screen {
  .mat-flat-button.mat-primary:hover:not(:disabled) {
    background-color: $dark-blue-900;
  }
}

.mat-flat-button.mat-primary:disabled {
  opacity: 0.5;
  background-color: $dark-blue-500;
  color: white;
}

.not-touch-screen {
  .mat-flat-button.mat-warn:hover:not(:disabled) {
    background-color: $red-800;
  }
}

.mat-flat-button.mat-warn:disabled {
  opacity: 0.5;
  background-color: $danger;
  color: white;
}

// Stroked
.mat-stroked-button {
  border-color: $dark !important;
  color: $black;
}

.not-touch-screen {
  .mat-stroked-button:hover {
    border-color: $dark-blue-900 !important;
    color: $dark-blue-900;
  }
}

.mat-stroked-button:active {
  border-color: $dark !important;
  color: $black;
}

.mat-stroked-button.large {
  border-color: $dark-blue-700 !important;
  color: $dark-blue-700;
}

.not-touch-screen {
  .mat-stroked-button.large:hover {
    background-color: $primary;
    color: white;
  }
}

.mat-stroked-button.large:active {
  border-color: $dark-blue-800 !important;
  color: $dark-blue-800;
}

.date-filter {
  cursor: pointer;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px 8px 8px;
  border-radius: 8px;
  border: 1px solid $dark;

  &.disabled {
    cursor: default;
    opacity: 0.25;
  }

  &.open {
    border-color: $primary !important;
    outline: 1px solid #003fae !important;
  }

  &.set {
    padding: 8px 8px 8px 8px;

    &:not(:hover):not(.open) {
      border: 1px solid $grey;
    }
  }

  .calendar {
    background-color: $dark !important;
    width: 18px;
    height: 18px;
  }

  h4 {
    margin: 0 12px;
    color: $dark;
  }

  &.set {
    h4 {
      color: $black;
    }

    .calendar {
      background-color: $black !important;
    }
  }
}

.date-filter:hover {
  border: 1px solid $black;

  h4 {
    color: $black;
  }
}

.date-picker {
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  border: none;
  visibility: hidden;
}

.selection-tile {
  background-color: transparent;
  color: $grey-700;
  border: 1px solid $grey-400;
  color: $grey-700;
  padding: 6px 12px;

  h4 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover:not(.selected):not(.disabled) {
    background-color: $grey-200;
    color: $black;
    border: 1px solid transparent;
  }

  &.selected {
    background-color: white;
    color: $black;
    border: 1px solid $dark-blue-500;
    padding: 6px 8px;

    &:hover {
      border: 1px solid $dark-blue-900;
    }
  }

  &.suspended {
    border-color: $red-700;

    &:hover {
      border-color: $red-900;
    }
  }
}
