// Export material
@import "vars";

.mat-form-field-appearance-outline .mat-form-field-outline-start {
  border-radius: 8px 0 0 8px !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-radius: 0 8px 8px 0 !important;
}
.mat-form-field-appearance-outline .mat-form-field-outline-start, .mat-form-field-appearance-outline .mat-form-field-outline-end {
  min-width: 8px !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-start {
  border-radius: 8px 0 0 8px !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline-end {
  border-radius: 0 8px 8px 0 !important;
}

 .mat-form-field-appearance-outline .mat-form-field-outline-start, .mat-form-field-appearance-outline .mat-form-field-outline-end {
   min-width: 8px !important;
 }

.mat-tooltip {
  color: $black !important;
  font-weight: 600;
  background-color: white ;
  max-width: 300px !important;
  margin: 8px 14px !important;
  padding: 8px 14px !important;
  text-align: center;
  border-radius: 5px;
  white-space: pre-line;
  font-family: 'Open Sans';
  font-size: 12px;
  box-shadow: 0 0.8500000238418579px 3px 0 #00000030;

}

.mat-tooltip-hide {
  display: none !important;
}

.mat-menu-panel {
  max-width: unset !important;
  min-height: unset !important;
}

.small-tooltip {
  padding: 8px 12px !important;
}

.mat-tooltip-left-text {
  text-align: start;
}

.flex-fill {
  flex-basis: 0 !important;
}



.mat-snack-bar-handset {
  width: unset !important;
}

.mat-snack-bar-container {
  min-width: unset !important;
  min-height: unset !important;
  max-width: unset !important;
  max-height: unset !important;
  border-radius: 5px;
  padding: 0 !important;
  background-color: transparent;
}

@include media-breakpoint-down(xs) {
  .not-mobile {
    display: none !important;
  }
}

@include media-breakpoint-up(sm) {
  .only-mobile {
    display: none !important;
  }
}

@include media-breakpoint-down(sm) {
  .not-mobile-sm {
    display: none !important;
  }
}

@include media-breakpoint-up(md) {
  .only-mobile-sm {
    display: none !important;
  }
}

* {
  //scrollbar-width: thin;
  scrollbar-width: none;
  scrollbar-height: none;
  scrollbar-color: $grey transparent;
  min-height: 0;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background: $grey;
  border-radius: 5px;
  border: 2px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background: $dark;
  border: 2px solid transparent;
  background-clip: content-box;
}

.primary-scrollbar {
  ::-webkit-scrollbar-thumb {
    background: $primary;
    border-radius: 5px;
    border: 2px solid transparent;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: $primaryDark;
  }
}

.overlay-scrollbar {
  overflow-y: scroll !important;
}

.isIOS {
  .overlay-scrollbar {
    padding-left: 0;
  }
}

.page {
  height: 100%;
  background-color: white;
  overflow-x: hidden;
}

.mirrored {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.hidden {
  height: 0 !important;
  width: 0 !important;
  display: block;
  opacity: 0;
}

html, body {
  height: 100%;
}

body {

  padding: 0;
  margin: 0;
  position: relative;

  @keyframes play90 {
    0% {
      background-position: 0px 0px;
    }
    100% {
      background-position: -28920px 0px;
    }
  }

  .shapeshifter {
    animation-duration: 4000ms;
    animation-timing-function: steps(120);
    animation-iteration-count: infinite;
    width: 241px;
    height: 228px;
    background-repeat: no-repeat;
  }

  .shapeshifter.play {
    animation-name: play90;
  }

  .preloader {
    position: absolute;
    z-index: 1;
  }

  .app-root {
    position: absolute;
    z-index: 2;
  }

  .pac-container {
    z-index: 10000 !important;
  }
}
