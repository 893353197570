@use "@angular/material" as mat;

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

@import "../../../assets/styles/vars.scss";

@import "bootstrap/scss/mixins/breakpoints";

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

@include media-breakpoint-down(xs) {
  .container {
    max-width: none;
  }
}

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for allx components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$business-slick-estimates-primary: mat.define-palette($primary-palette, 700, 300, 800);
$business-slick-estimates-accent: mat.define-palette($secondary-palette, 400);

// The warn palette is optional (defaults to red).
$business-slick-estimates-warn: mat.define-palette($danger-palette, 700);

$typography: mat.define-legacy-typography-config(
  $font-family: "Open Sans"
);

$business-slick-estimates-theme: mat.define-light-theme(
  (
    color: (
      primary: $business-slick-estimates-primary,
      accent: $business-slick-estimates-accent,
      warn: $business-slick-estimates-warn
    ),
    typography: $typography
  )
);

@include mat.core-theme($business-slick-estimates-theme);
@include mat.all-component-themes($business-slick-estimates-theme);
// // Include theme styles for core and each component used in your app.
// // Alternatively, you can import and @include the theme mixins for each component
// // that you are using.
@include mat.all-legacy-component-themes($business-slick-estimates-theme);

.mat-card-title,
.mat-card-subtitle {
  margin-top: 0 !important;
}

.mat-input-element {
  caret-color: unset !important;
}

.mat-input-element:not(input):not(textarea) {
  height: 21px !important;
}

.mat-form-field:not(.textarea) {
  .mat-form-field-outline {
    height: 40px !important;
  }
}

.mat-form-field-invalid.ng-invalid {
  .mat-form-field-wrapper {
    padding-bottom: 20px;
    // height: 60px;
  }
}

mat-form-field:not(.textarea) {
  .mat-form-field-wrapper {
    height: 56px;
  }

  &.error-line {
    @include media-breakpoint-down(xs) {
      // height: 80px;
    }
  }
}

.mat-form-field-wrapper {
  margin: 8px 0 4px 0 !important;
  padding-bottom: 16px;
}

.mat-form-field-infix {
  padding: 10px 0 10px 0 !important;
  border-top: 2px solid transparent !important;
}

.mat-form-field-suffix {
  top: -1px !important;

  img {
    vertical-align: unset;
    height: 20px;
  }
}

.filled {
  .mat-input-element:disabled {
    color: $black;
    opacity: 1;
    -webkit-text-fill-color: $black;
  }
}

@supports (-webkit-touch-callout: none) {
  .mat-form-field-infix {
    border-top-width: 2px !important;
  }
  .mat-form-field-suffix {
    top: -1px !important;
  }
}

.mat-focused {
  .mat-form-field-outline {
    box-shadow: 0 0 5px rgba(5, 101, 90, 0.2);
  }

  mat-error {
    display: none;
  }
}

.not-touch-screen
  .mat-form-field-appearance-outline:hover:not(.mat-form-field-invalid):not(.mat-focused)
  .mat-form-field-outline-thick {
  .mat-form-field-outline-start,
  .mat-form-field-outline-gap,
  .mat-form-field-outline-end {
    border-width: 1px !important;
  }
}

.search-field {
  .mat-form-field-prefix {
    top: 2px !important;
    margin-right: 4px;
  }
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: $grey-700;
}

.mat-form-field-appearance-outline.filled:not(.mat-form-field-invalid):not(.mat-focused):not(:hover)
  .mat-form-field-outline {
  color: $grey-400;
}

.not-touch-screen
  .mat-form-field-appearance-outline.filled:not(.mat-form-field-invalid):not(.mat-focused):hover
  .mat-form-field-outline {
  color: $black;
}

.mat-form-field-appearance-outline.filled:not(.mat-form-field-invalid).mat-focused .mat-form-field-outline {
  color: $dark-blue-700 !important;
}

.mat-focused,
.mat-focused.mat-form-field-invalid.ng-invalid {
  .mat-form-field-outline {
    color: $dark-blue-700 !important;
    box-shadow: 0 0 5px rgba(5, 101, 90, 0.2);
  }
}

.mat-focused .mat-form-field-outline {
  box-shadow: none;
}

.not-touch-screen .mat-form-field-appearance-outline:hover .mat-form-field-outline:hover,
.not-touch-screen .mat-form-field-flex:hover .mat-form-field-outline {
  color: $dark-blue-500;
}

.mat-form-field-appearance-outline .mat-form-field-flex {
  padding: 0 8px 0 8px !important;
}

.mat-form-field-subscript-wrapper {
  padding: 0 0 !important;
  margin-top: 4px !important;
  overflow: visible !important;
}

.mat-form-field-appearance-outline.note .mat-form-field-outline {
  color: transparent !important;
  background-color: $grey-100;
  border-radius: 5px;
}

// .mat-form-field-appearance-outline.note.filled:not(.mat-form-field-invalid):not(.mat-focused):not(:hover) .mat-form-field-outline {
//   color: transparent !important;
// }

.not-touch-screen
  .mat-form-field-appearance-outline.note:not(.mat-form-field-invalid):not(.mat-focused):hover
  .mat-form-field-outline {
  color: $grey-400 !important;
}

.mat-form-field-appearance-outline.note:not(.mat-form-field-invalid).mat-focused .mat-form-field-outline {
  color: $dark-blue-700 !important;
  background-color: white;
}

.mat-autocomplete-panel {
  min-width: unset !important;
  max-height: 40dvh !important;
}

.small-mat-autocomplete {
  .mat-autocomplete-panel {
    max-height: 20dvh !important;
  }
}

.mat-selected {
  background-color: unset !important;
}

.mat-option:not(.time-suggestion) {
  font-size: inherit;
  letter-spacing: 0.44px;
  position: relative;
  //height: 64px !important;
  // min-width: 200px;

  &:not(:last-of-type)::after {
    position: absolute;
    content: "";
    height: 1px;
    width: calc(100% - 32px);
    background-color: $grey-500;
    bottom: 0;
  }
}

.mat-option.time-suggestion {
  padding-left: 10px;
  padding-right: 0;
  height: 32px;
}

.dimmed {
  .mat-option-text {
    color: $grey-700;
  }
}

.mat-option.withSubtitle:not(.mat-option-disabled) {
  min-height: 88px !important;

  .mat-option-text {
    height: 88px !important;
    display: flex;
    flex-direction: column;
    align-items: start !important;
    overflow: hidden !important;
    .subtitle {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.1px;
      margin-bottom: 0;
      line-height: 20px;
      color: $grey-700;
      display: block;
    }
  }
}

.mat-menu-content:not(:empty) {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.not-touch-screen .mat-option:hover:not(.mat-option-disabled) {
  background-color: $grey-100 !important;
}

.not-touch-screen .mat-option.withSubtitle:hover:not(.mat-option-disabled) .mat-option-text,
.not-touch-screen .mat-option:hover:not(.mat-option-disabled) .mat-option-text,
.not-touch-screen .mat-menu-item:hover .mat-option-text {
  text-shadow:
    0 0 0.4px black,
    0 0 0.4px black;
  height: 32px;
  display: flex;
  align-items: center;
}

.mat-option.mat-selected:not(.mat-option-disabled) {
  color: $black !important;
  text-shadow:
    0 0 0.4px black,
    0 0 0.4px black !important;
  background-color: transparent !important;
}

.mat-option:hover:not(.mat-option-disabled),
.mat-option:focus:not(.mat-option-disabled) {
  background: transparent;
}

.mat-select-arrow-wrapper {
  transform: none !important;
}

.mat-select-arrow {
  border: none !important;
  background: url("../../../assets/icons/cheveron_down.svg") no-repeat;
  width: 16px !important;
  height: 8px !important;
}

.mat-step-header,
.mat-stepper-horizontal-line {
  display: none !important;
}

.mat-datepicker-close-button {
  display: none !important;
}

.cdk-overlay-backdrop:not(.mat-datepicker-0-backdrop):not(.mat-overlay-transparent-backdrop) {
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(4px);
}

.mat-horizontal-content-container {
  padding: 0 0 0 0 !important;
}

.mat-dialog-container {
  border-radius: 8px !important;
  padding: 0 !important;
  height: unset !important;
  box-shadow:
    0 1px 1px 0 rgba(101, 112, 124, 0.2),
    0 2px 2px 0 rgba(101, 112, 124, 0.14),
    0 1px 5px 0 rgba(27, 27, 27, 0.12);
}

.cdk-overlay-pane {
  max-width: calc(100% - 32px) !important;
  max-height: calc(100% - 32px) !important;
  overflow: visible;
  border-radius: 5px;

  &:not(.mat-datepicker-popup) {
    right: unset;
  }
}

.image-viewer-overlay {
  max-width: 100% !important;
  max-height: 100% !important;

  app-image-viewer {
    width: 100%;
    height: 100%;
  }
}

@include media-breakpoint-down(xs) {
  .cdk-overlay-pane {
    .mat-menu-panel:not(.no-margin) {
      margin-left: 16px;
      margin-right: 16px;
    }
  }
}

.hide-active {
  .active {
    display: none !important;
  }
}

.info-icon-wrapper {
  display: flex;
  align-items: center;

  &.mt {
    margin-top: 8px;
  }

  &.mb {
    margin-bottom: 8px;
  }

  .icon {
    margin-left: 4px;
    margin-right: 4px;
    height: 16px;
    width: 16px;
    background-color: $grey-700;
  }
}

.not-touch-screen .info-icon-wrapper {
  .icon:hover {
    background-color: $black;
  }
}

.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical):not(
    :hover
  ) {
  background-color: transparent !important;
}

.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical):hover {
  background-color: rgba($dark-blue-700, 0.15) !important;
}

.mat-calendar-body-disabled {
  .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical):hover {
    background-color: transparent !important;
  }
}

.dialog {
  border-radius: 8px;
  overflow-y: auto;
  border: 0.5px solid #f8f8f8;
  background-color: white;
}

.dialog-full-height {
  height: 100% !important;

  .mat-dialog-container {
    max-height: unset !important;
    overflow: hidden !important;
  }
}

.mat-datepicker-content {
  margin-top: -4px;
}

.dialog-full-height {
  height: 100%;
  .cdk-overlay-pane {
    height: 100% !important;
  }

  .mat-dialog-container {
    max-height: unset !important;
    overflow: hidden !important;
  }
}

.icon-button {
  display: flex;
  justify-content: start;
  cursor: pointer;
  align-items: center;
  gap: 8px;
  padding: 4px 2px;

  &:hover {
    svg {
      fill: $black;

      path {
        fill: $black;
      }
    }

    p {
      color: $black;
    }
  }

  svg {
    fill: $grey-700;

    path {
      fill: $grey-700;
    }
  }

  p {
    font-weight: 500;
    color: $grey-700;
  }
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.mdc-switch__track {
  height: 20px !important;
  border-radius: 16px !important;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after,
.mat-mdc-slide-toggle .mdc-switch__handle::after,
.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle::before {
  background: #ffffff !important;
  width: 14px;
  height: 14px;
  border-radius: 16px;
  top: 3px !important;
  left: 4px !important;
}

.mat-mdc-slide-toggle .mdc-switch__handle::after {
  left: 2px !important;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle::before {
  display: none !important;
}

.mdc-switch__icons,
.mdc-switch__ripple,
.mdc-switch__shadow {
  display: none !important;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
  background: $dark-blue-500 !important;
}

.not-touch-screen {
  .mdc-form-field:hover .mdc-switch:enabled .mdc-switch__track::after {
    background: $dark-blue-700 !important;
  }

  .mdc-form-field:hover .mdc-switch:enabled .mdc-switch__track::before {
    background: $medium !important;
  }
}

.mdc-form-field > label {
  padding-left: 10px !important;
}

.mdc-form-field {
  cursor: pointer !important;

  label {
    cursor: pointer !important;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: auto;
}

@include media-breakpoint-down(sm) {
  .mdc-form-field:hover .mdc-switch:enabled .mdc-switch__track::after {
    background: $dark-blue-500 !important;
  }
}

.clickable {
  cursor: pointer !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.hide {
  display: none !important;
}

.tar {
  text-align: right !important;
}

.tal {
  text-align: left !important;
}

.tac {
  text-align: center !important;
}

.h--0f05 {
  height: 0.5px !important;
}

.bc-grey-300 {
  background-color: $grey-300 !important;
}

.col-black {
  color: $black !important;
}

@for $i from 0 through 48 {
  .br--#{$i} {
    border-radius: $i + px !important;
  }
}

@for $i from 0 through 512 {
  .p--#{$i} {
    padding: $i + px !important;
  }

  .px--#{$i} {
    padding-right: $i + px !important;
    padding-left: $i + px !important;
  }

  .py--#{$i} {
    padding-top: $i + px !important;
    padding-bottom: $i + px !important;
  }

  .pt--#{$i} {
    padding-top: $i + px !important;
  }

  .pr--#{$i} {
    padding-right: $i + px !important;
  }

  .pb--#{$i} {
    padding-bottom: $i + px !important;
  }

  .pl--#{$i} {
    padding-left: $i + px !important;
  }

  .m--#{$i} {
    margin: $i + px !important;
  }

  .mx--#{$i} {
    margin-right: $i + px !important;
    margin-left: $i + px !important;
  }

  .my--#{$i} {
    margin-top: $i + px !important;
    margin-bottom: $i + px !important;
  }

  .mt--#{$i} {
    margin-top: $i + px !important;
  }

  .mr--#{$i} {
    margin-right: $i + px !important;
  }

  .mb--#{$i} {
    margin-bottom: $i + px !important;
  }

  .ml--#{$i} {
    margin-left: $i + px !important;
  }

  .width--#{$i} {
    width: $i + px !important;
  }

  .height--#{$i} {
    height: $i + px !important;
  }

  .gap--#{$i} {
    gap: $i + px !important;
  }

  //usage: scale--100 => scale: 1; scale--85 => scale: 0.85;
  .scale--#{$i} {
    scale: $i/100 !important;
  }
}

@import "../../../assets/styles/typography.scss";
@import "../../../assets/styles/buttons.scss";
@import "../../../assets/styles/general.scss";
